import styled from 'styled-components'

const WrapperMain = styled.div`
    flex-grow: 1;
    padding: 21px 40px 0;

    .title {
        margin-top: 35px;
        font-family: SVN-Gilroy;
        font-style: normal;
        font-weight: bold;
        font-size: 11px;
        line-height: 126%;
        color: #005993;
        text-transform: uppercase;

        &:first-child {
            margin: 0 0 5px 0;
        }
    }

    .wrap-dropdown {
        width: 320px;
        min-height: 40px;
    }

    .wrap-roles {
        margin-top: 19px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20%;
        grid-row-gap: 22px;
        overflow: auto;
        max-height: 243px;

        .grid-full-col {
            grid-column: 1/3;

            .wrap-main-role {
                display: flex;
                justify-content: space-between;

                .sum-of-sub-role-selected {
                    display: flex;
                    align-items: center;
                    font-family: SVN-Gilroy;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 128%;
                    color: #005993;

                    .down-arrow {
                        display: block;
                        margin-left: 5px;
                        width: 7px;
                        height: 4px;
                        background: #005993;
                        clip-path: polygon(50% 100%, 0 0, 100% 0);
                    }

                    &:hover {
                        cursor: pointer;
                    }
                }
            }

            .wrap-list-children {
                padding-left: 24px;

                & > * {
                    margin-top: 22px;
                }
            }
        }
    }
`;

export { WrapperMain }