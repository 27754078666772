import React, {
    useReducer,
    useState,
    useCallback,
    useEffect,
    useMemo,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

// styles
import { extendStylesTable, extendStylesTeller } from './index.styles'
import {
    WrapGridTools,
    GridColTools,
    GridBtnTools,
    GridSplitHeadTools,
    GridBtnToolsEnd,
} from '../../controls/Tools/index.styles'

// component
import Header from '../../controls/Header'
import Tools from '../../controls/Tools'
import ExportExcel from '../../controls/Tools/ExportExcel'
import SelectRange from '../../controls/Tools/SelectRange'
import SelectOffice from '../../controls/Tools/SelectOffice'
import SelectTeller from '../../controls/Tools/SelectTeller'
import ButtonFilter from '../../controls/Tools/ButtonFilter'
import Table from '../../controls/Table'
import SelectTrans from '../../controls/Tools/SelectTrans'
import SelectStartDate from '../../controls/Tools/SelectStartDate'
import SelectEndDate from '../../controls/Tools/SelectEndDate'
import SelectOfficeV2 from '../../controls/Tools/SelectOfficeV2'

// action
import {
    getListTimeServicesOfTellerByServices,
    exportListTimeServicesOfTellerByServices,
} from '../../../../../../../actions/reportsAction/detailsReport/averageOfficeTime/averageTellerTimeByService'
import { getListOfUsername } from '../../../../../../../actions/commonAction'

// hooks
import useDidMount from '../../../../../../../helper/Hooks/useDidMount'

// constant
import { ADMIN_ROLE } from '../../../../../../../data/userRole'

const lstTitleCols = [
    'Mã cn',
    'Tên cn',
    'user GDV',
    'Tên GDV',
    'loại giao dịch',
    'Số lượng gd',
    <>
        <div>tg đợi</div>
        <div>trung bình</div>
    </>,
    <>
        <div>TG TÁC NGHIỆP</div>
        <div>TRUNG BÌNH TRÊN CORE</div>
    </>,
    <>
        <div>tG phục vụ</div>
        <div>trung bình TRÊN counter</div>
    </>,
]

const lstTrans = [
    {
        key: 'DEPINT',
        value: 'Nộp tiền trong VietinBank',
    },
    {
        key: 'DEPEXT',
        value: 'Nộp tiền ngoài VietinBank',
    },
    {
        key: 'WITHDRAW',
        value: 'Rút tiền',
    },
    {
        key: 'TRANSINT',
        value: 'Chuyển khoản trong VietinBank',
    },
    {
        key: 'TRANSEXT',
        value: 'Chuyển khoản ngoài VietinBank thông thường',
    },
    {
        key: 'TRANSEXTACC',
        value: 'Chuyển khoản nhanh ngoài VietinBank đến số tài khoản (≤300trđ)',
    },
    {
        key: 'TRANSEXTCARD',
        value: 'Chuyển khoản nhanh ngoài VietinBank đến số thẻ (≤300trđ)',
    },
    {
        key: 'SAVINGDEP',
        value: 'Gửi tiết kiệm',
    },
    {
        key: 'WDSVG',
        value: 'Rút tiết kiệm',
    },
    {
        key: 'FCY',
        value: 'Ngoại tệ',
    },
    {
        key: 'CARD',
        value: 'Thẻ',
    },
    {
        key: 'CORPCUST',
        value: 'Khách hàng doanh nghiệp',
    },
    {
        key: 'OTHER',
        value: 'Khác',
    },
    {
        key: 'DIRECTCALL',
        value: 'Trống (Vé load CIF)',
    },
]

// const DEFAULT_FROM_DATE = moment().startOf('days')
// const DEFAULT_TO_DATE = moment().endOf('days')

const ReportOfTellerByTransaction = ({ title, codeName, ...props }) => {
    const DEFAULT_FROM_DATE = useMemo(() => moment().startOf('days'), [])
    const DEFAULT_TO_DATE = useMemo(() => moment().endOf('days'), [])

    const dispatch = useDispatch()
    const { detailsReport, userReducer } = useSelector((state) => ({
        detailsReport: state.reports.detailsReport,
        userReducer: state.userReducer,
    }))

    const [stateFilter, dispatchFilter] = useReducer(
        (state, action) => ({ ...state, ...action }),
        {
            fromDate: DEFAULT_FROM_DATE.toDate(),
            toDate: DEFAULT_TO_DATE.toDate(),
            office: null,
            teller: '',
            transaction: null,
        }
    )

    const [dataTable, setDataTable] = useState([])

    const [BranchIDs, BranchName, BranchCodes] = useMemo(() => {
        if (!stateFilter.office) return [null, null, null]
        const lenListOffice = stateFilter.office.length
        if (lenListOffice === 0) {
            return [null, null, null]
        }
        if (lenListOffice === 1) {
            const { key, value, BranchCode } = stateFilter.office[0]
            if (Array.isArray(key))
                return [key, value, BranchCode ? [BranchCode] : ['ALL']]
            return [[key], value, BranchCode ? [BranchCode] : ['ALL']]
        }

        const lstBranchId = [],
            lstBranchName = [],
            lstBranchCode = []

        const lstOfficeSort = [...stateFilter.office].sort((a, b) => 
            Number(a.BranchCode) - Number(b.BranchCode)
        )

        lstOfficeSort.forEach((element) => {
            lstBranchId.push(element.key)
            lstBranchName.push(element.value)
            lstBranchCode.push(element.BranchCode)
        })
        return [lstBranchId, lstBranchName.join(', '), lstBranchCode]
    }, [stateFilter.office])

    const _handleSubmit = useCallback(() => {
        const { fromDate, toDate, teller, transaction, office } = stateFilter
        dispatch(
            getListTimeServicesOfTellerByServices({
                FromDate: fromDate.toISOString(),
                ToDate: toDate.toISOString(),
                ServiceCode: transaction?.key,
                TellerUserName: teller,
                ReportCodeName: codeName,
                BranchName,
                BranchIDs,
            })
        )
    }, [stateFilter, BranchName, BranchIDs])

    const _handleExportExcel = useCallback(() => {
        const { fromDate, toDate, teller, transaction, office } = stateFilter
        dispatch(
            exportListTimeServicesOfTellerByServices({
                FromDate: fromDate.toISOString(),
                ToDate: toDate.toISOString(),
                ServiceCode: transaction?.key,
                TellerUserName: teller,
                ReportCodeName: codeName,
                BranchName,
                BranchIDs,
                BranchCodes,
            })
        )
    }, [stateFilter, BranchName, BranchIDs, BranchCodes])

    useDidMount(() => {
        if (!userReducer.dataLogin) return false
        const { Roles } = userReducer.dataLogin
        if (Roles.includes(ADMIN_ROLE)) return true

        if (!BranchName || !BranchIDs) return false
        dispatch(
            getListTimeServicesOfTellerByServices({
                FromDate: DEFAULT_FROM_DATE.toISOString(),
                ToDate: DEFAULT_TO_DATE.toISOString(),
                ReportCodeName: codeName,
                BranchName,
                BranchIDs,
            })
        )
        return true
    }, [userReducer.dataLogin, BranchName, BranchIDs])

    useEffect(() => {
        // const dataTable = detailsReport.listData.map(item => [
        //     item.TellerUserName,
        //     item.TellerName,
        //     item.TotalTicket,
        //     item.TotalService,
        //     item.AverageWait,
        //     item.AverageServingByTicket,
        //     item.AverageServingByService
        // ])
        setDataTable(detailsReport.listData)
    }, [detailsReport.listData])

    useEffect(() => {
        if(!BranchIDs){
            dispatch(getListOfUsername())
            return;
        }
        dispatch(getListOfUsername(BranchIDs))
    }, [BranchIDs])

    return (
        <div>
            <Header title={title} />
            {/* <Tools> */}
            {/* <WrapTools>
                <RowTools>
                    <SelectRange
                        defaultValue={[
                            stateFilter.fromDate,
                            stateFilter.toDate,
                        ]}
                        onChange={[
                            (fromDate) => dispatchFilter({ fromDate }),
                            (toDate) => dispatchFilter({ toDate }),
                        ]}
                    />
                    <ButtonFilter onClick={null} className="btn-visible" />
                </RowTools>
                <RowTools>
                    <ExportExcel onClick={_handleExportExcel} />
                    <SelectOffice
                        defaultValue={stateFilter.office}
                        onChange={(office) => dispatchFilter({ office })}
                    />
                    <SelectTeller
                        defaultValue={stateFilter.teller}
                        onChange={(teller) => dispatchFilter({ teller })}
                    />
                    <SelectTrans
                        defaultValue={stateFilter.transaction}
                        onChange={(transaction) =>
                            dispatchFilter({ transaction })
                        }
                        lstTrans={lstTrans}
                    />
                    <ButtonFilter data={stateFilter} onClick={_handleSubmit} />
                </RowTools>
            </WrapTools> */}
            <WrapGridTools numOfCol={3}>
                <GridSplitHeadTools
                    template={['auto 1.4fr 0.6fr 1fr', 'auto 1fr']}
                >
                    <div>
                        <SelectOfficeV2
                            defaultValue={stateFilter.office}
                            onChange={(office) => dispatchFilter({ office })}
                        />
                        <SelectStartDate
                            defaultValue={stateFilter.fromDate}
                            onChange={(fromDate) =>
                                dispatchFilter({ fromDate })
                            }
                        />
                    </div>
                    <div>
                        <SelectTrans
                            defaultValue={stateFilter.transaction}
                            onChange={(transaction) =>
                                dispatchFilter({ transaction })
                            }
                            lstTrans={lstTrans}
                        />
                    </div>
                </GridSplitHeadTools>
                <GridColTools>
                    <SelectEndDate
                        defaultValue={stateFilter.toDate}
                        onChange={(toDate) => dispatchFilter({ toDate })}
                    />
                    <SelectTeller
                        defaultValue={stateFilter.teller}
                        onChange={(teller) => dispatchFilter({ teller })}
                        extendStyles={extendStylesTeller}
                    />
                </GridColTools>
                <GridBtnToolsEnd>
                    <ButtonFilter
                        data={stateFilter}
                        onClick={_handleSubmit}
                        isDisable={!BranchIDs}
                    />
                    <ExportExcel
                        onClick={_handleExportExcel}
                        isDisable={!BranchIDs}
                    />
                </GridBtnToolsEnd>
            </WrapGridTools>
            {/* </Tools> */}
            <Table
                lstTitleCols={lstTitleCols}
                lstData={dataTable}
                extendStyles={extendStylesTable}
            />
        </div>
    )
}

export default ReportOfTellerByTransaction
