import styled from 'styled-components'

const GoToDetails = styled.div`
    text-decoration-line: underline;
    color: #1477EC;
    :hover {
        cursor: pointer;
    }
`;

const Id = styled.div`
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const ReportName = styled(Id)`

`;

export {
    GoToDetails,
    Id,
    ReportName
}