import React, { Component } from 'react'
import styled from 'styled-components'

const Bound = styled.button`
    /* width:  ${props => props.width || '100px'}; */
    width:  ${props => props.width || '108px'};
    height: 36px;
    opacity: ${props => props.opacity || "unset"};
    border-radius: 18px;
    background-color: ${props => props.bg_color || '#005993'};
    display: flex;
    justify-content: center;
    align-items: center;
    outline:none;
    border:none;
    cursor: ${props => props.cursor || 'pointer'};
    .titleConfirm{
        font-family: 'SVN-Gilroy';
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`

export default class ButtonConfirmPopup extends Component {
    // componentDidMount() {
    //     window.addEventListener("keyup", event => {
    //         if(event.keyCode === 13){
    //             this.onClick()

    //         }
    //     })
    // }
    // componentWillUnmount() {
    //     window.removeEventListener("keyup", event => {
    //         if(event.keyCode === 13){
    //             this.onClick()

    //         }
    //     })
    // }
    // onClick = () => {
    //     this.props.onClick()
    // }
    
    render() {
        // let {dataNeedModify} = this.props
        return (
            <Bound
                width={this.props.width}
                opacity={this.props.opacity}
                bg_color={this.props.bg_color}
                cursor={this.props.cursor}
                onClick={this.props.onClick}
                // onClick={this.onClick}
                id = 'button'>
                <div className='titleConfirm'>
                    {this.props.titleConfirm}
                </div>
            </Bound>
        )
    }
}
