import styled from 'styled-components'

const WrapOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10;
    /* background: rgba(0, 0, 0, 0.1); */
`;

const OptionListDiv = styled.div`
    display: flex;
    flex-direction: column;
    padding: 5px 0;
    width: 89px;
    position: fixed;
    top: ${(props) => props.offset.top}px;
    left: ${(props) => props.offset.left}px;
    background-color: white;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.06);
    background-color: #ffffff;
    div {
        box-sizing: border-box;
        width: 100%;
        height: 22px;
        padding: 0 7px;
        font-family: 'SVN-Gilroy';
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.68;
        letter-spacing: normal;
        cursor: pointer;
    }
    .modify,
    .reset-counter {
        color: #222222;
    }

    .reset-counter,
    .remove {
        margin-top: 8px;
    }

    .remove {
        color: #f31919;
    }
`

export { WrapOverlay, OptionListDiv };