import React from 'react'
import * as styles from './index.styles'

const ButtonControl = ({ icon, content, widthButton, heightButton, ...props }) => {
    return (
        <styles.Bound widthButton={widthButton} heightButton={heightButton} {...props}>
            <img src={icon} alt="icon" />
            <p>{content}</p>
        </styles.Bound>
    )
}

export default ButtonControl
