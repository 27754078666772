import styled from 'styled-components'

const WrapCustom = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 36px;
    width: ${props => props.isDisableTime? "118px" : "158px"};
    background: #FAFAFA;
    border-radius: 4px;

    :hover { 
        cursor: pointer;
    }

    .value {
        font-family: SVN-Gilroy;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 128%;
        color: #222222;
        margin-left: 7px;
    }

    img {
        margin-right: 10px;
    }
`;

export {
    WrapCustom
}