export const SELECT_REPORT = 'SELECT_REPORT'
export const CLEAR_REPORT = 'CLEAR_REPORT'

export const GET_LIST_TIME_SERVICES_AT_OFFICE_BY_TRANS =
    'GET_LIST_TIME_SERVICES_AT_OFFICE_BY_TRANS'
export const GET_LIST_TIME_SERVICES_AT_OFFICE_BY_TRANS_ERROR =
    'GET_LIST_TIME_SERVICES_AT_OFFICE_BY_TRANS_ERROR'

export const GET_LIST_TIME_SERVICES_AT_OFFICE_BY_TICKET =
    'GET_LIST_TIME_SERVICES_AT_OFFICE_BY_TICKET'
export const GET_LIST_TIME_SERVICES_AT_OFFICE_BY_TICKET_ERROR =
    'GET_LIST_TIME_SERVICES_AT_OFFICE_BY_TICKET_ERROR'

export const GET_LIST_TIME_SERVICES_OF_TELLER_BY_TICKET =
    'GET_LIST_TIME_SERVICES_OF_TELLER_BY_TICKET'
export const GET_LIST_TIME_SERVICES_OF_TELLER_BY_TICKET_ERROR =
    'GET_LIST_TIME_SERVICES_OF_TELLER_BY_TICKET_ERROR'

export const GET_LIST_TIME_SERVICES_OF_TELLER_BY_TRANS =
    'GET_LIST_TIME_SERVICES_OF_TELLER_BY_TRANS'
export const GET_LIST_TIME_SERVICES_OF_TELLER_BY_TRANS_ERROR =
    'GET_LIST_TIME_SERVICES_OF_TELLER_BY_TRANS_ERROR'

export const GET_LIST_SERVICES_RATING_BY_OFFICE =
    'GET_LIST_SERVICES_RATING_BY_OFFICE'
export const GET_LIST_SERVICES_RATING_BY_OFFICE_ERROR =
    'GET_LIST_SERVICES_RATING_BY_OFFICE_ERROR'

export const GET_LIST_SERVICES_RATING_BY_SERVICE =
    'GET_LIST_SERVICES_RATING_BY_SERVICE'
export const GET_LIST_SERVICES_RATING_BY_SERVICE_ERROR =
    'GET_LIST_SERVICES_RATING_BY_SERVICE_ERROR'

export const GET_LIST_SERVICES_RATING_BY_TELLER =
    'GET_LIST_SERVICES_RATING_BY_TELLER'
export const GET_LIST_SERVICES_RATING_BY_TELLER_ERROR =
    'GET_LIST_SERVICES_RATING_BY_TELLER_ERROR'

export const GET_LIST_SELF_SERVE_BY_OFFICES_AND_TRANS =
    'GET_LIST_SELF_SERVE_BY_OFFICES_AND_TRANS'
export const GET_LIST_SELF_SERVE_BY_OFFICES_AND_TRANS_ERROR =
    'GET_LIST_SELF_SERVE_BY_OFFICES_AND_TRANS_ERROR'

export const GET_LIST_NUM_OF_SELF_SERVE_NOT_SUCCESS =
    'GET_LIST_NUM_OF_SELF_SERVE_NOT_SUCCESS'
export const GET_LIST_NUM_OF_SELF_SERVE_NOT_SUCCESS_ERROR =
    'GET_LIST_NUM_OF_SELF_SERVE_NOT_SUCCESS_ERROR'

export const LIST_DETAILS_OF_SELF_SERVE_AUTH_NOT_SUCCESS =
    'LIST_DETAILS_OF_SELF_SERVE_AUTH_NOT_SUCCESS'
export const LIST_DETAILS_OF_SELF_SERVE_AUTH_NOT_SUCCESS_ERROR =
    'LIST_DETAILS_OF_SELF_SERVE_AUTH_NOT_SUCCESS_ERROR'

export const GET_LIST_NUM_OF_REG_BIO = 'GET_LIST_NUM_OF_REG_BIO'
export const GET_LIST_NUM_OF_REG_BIO_ERROR = 'GET_LIST_NUM_OF_REG_BIO_ERROR'

export const GET_LIST_NUM_OF_NOT_REG_BIO = 'GET_LIST_NUM_OF_NOT_REG_BIO'
export const GET_LIST_NUM_OF_NOT_REG_BIO_ERROR =
    'GET_LIST_NUM_OF_NOT_REG_BIO_ERROR'

export const GET_LIST_NUM_OF_UPDATE_BIO = 'GET_LIST_NUM_OF_UPDATE_BIO'
export const GET_LIST_NUM_OF_UPDATE_BIO_ERROR =
    'GET_LIST_NUM_OF_UPDATE_BIO_ERROR'

export const GET_CHANGE_CUSTOMER_INFO = 'GET_CHANGE_CUSTOMER_INFO'
export const GET_CHANGE_CUSTOMER_INFO_ERROR = 'GET_CHANGE_CUSTOMER_INFO_ERROR'

export const GET_FACE_REGCONITION_REPORT = 'GET_FACE_REGCONITION_REPORT'
export const GET_FACE_REGCONITION_REPORT_ERROR =
    'GET_FACE_REGCONITION_REPORT_ERROR'

export const GET_LIST_STATUS_NUM_OF_AUTH = 'GET_LIST_STATUS_NUM_OF_AUTH'
export const GET_LIST_STATUS_NUM_OF_AUTH_ERROR =
    'GET_LIST_STATUS_NUM_OF_AUTH_ERROR'

export const GET_LIST_DETAILS_OF_REG_BIO = 'GET_LIST_DETAILS_OF_REG_BIO'
export const GET_LIST_DETAILS_OF_REG_BIO_ERROR =
    'GET_LIST_DETAILS_OF_REG_BIO_ERROR'

export const GET_LIST_DETAILS_OF_NOT_REG_BIO = 'GET_LIST_DETAILS_OF_NOT_REG_BIO'
export const GET_LIST_DETAILS_OF_NOT_REG_BIO_ERROR =
    'GET_LIST_DETAILS_OF_NOT_REG_BIO_ERROR'

export const GET_LIST_DETAILS_OF_UPDATE_BIO = 'GET_LIST_DETAILS_OF_UPDATE_BIO'
export const GET_LIST_DETAILS_OF_UPDATE_BIO_ERROR =
    'GET_LIST_DETAILS_OF_UPDATE_BIO_ERROR'

export const GET_LIST_DETAILS_OF_REMOVE_BIO = 'GET_LIST_DETAILS_OF_REMOVE_BIO'
export const GET_LIST_DETAILS_OF_REMOVE_BIO_ERROR =
    'GET_LIST_DETAILS_OF_REMOVE_BIO_ERROR'

export const GET_LIST_DETAILS_OF_FINGER_AUTH = 'GET_LIST_DETAILS_OF_FINGER_AUTH'
export const GET_LIST_DETAILS_OF_FINGER_AUTH_ERROR =
    'GET_LIST_DETAILS_OF_FINGER_AUTH_ERROR'

export const GET_LIST_DETAILS_OF_BIO_NOT_REVIEW =
    'GET_LIST_DETAILS_OF_BIO_NOT_REVIEW'
export const GET_LIST_DETAILS_OF_BIO_NOT_REVIEW_ERROR =
    'GET_LIST_DETAILS_OF_BIO_NOT_REVIEW_ERROR'

export const GET_HISTORY_CUS_CHANGE_REG_BIO = 'GET_HISTORY_CUS_CHANGE_REG_BIO'
export const GET_HISTORY_CUS_CHANGE_REG_BIO_ERROR =
    'GET_HISTORY_CUS_CHANGE_REG_BIO_ERROR'

export const GET_LIST_DUPLICATED_FINGER = 'GET_LIST_DUPLICATED_FINGER'
export const GET_LIST_DUPLICATED_FINGER_ERROR =
    'GET_LIST_DUPLICATED_FINGER_ERROR'

export const GET_LIST_DUPLICATED_FACE = 'GET_LIST_DUPLICATED_FACE'
export const GET_LIST_DUPLICATED_FACE_ERROR = 'GET_LIST_DUPLICATED_FACE_ERROR'

export const GET_LIST_REGISTER_APP_GO_TO_COUNTER_REPORT = 'GET_LIST_REGISTER_APP_GO_TO_COUNTER_REPORT'
export const GET_LIST_REGISTER_APP_GO_TO_COUNTER_REPORT_ERROR = 'GET_LIST_REGISTER_APP_GO_TO_COUNTER_REPORT_ERROR'
