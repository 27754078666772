import React, { useState, useEffect } from 'react'

// styles
import { WrapperHeader } from './index.styles'

// images
import ic_close from '../../../../../images/ic_close_gray.svg';

const Header = ({ dataUser, outPopup, readOnly }) => {
    const title = readOnly
        ? 'Thông tin tài khoản'
        : 'Chỉnh sửa tài khoản'
    return (
        <WrapperHeader className='row'>
            <div className="content">
                <div className="title">{title}</div>
                <div className='row infos-user'>
                    <div className='main-content'>{dataUser[2]}</div>
                    <div></div>
                    <div title={dataUser[1]}>{dataUser[1]}</div>
                </div>
            </div>
            <img
                onClick={outPopup}
                src={ic_close}
                alt="close"
            />
        </WrapperHeader>
    )
}

export default Header
