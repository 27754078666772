export const PENDING = 'Pending'
export const SEEN = 'Seen'
export const VERIFYUNSUCCESSFULLY = 'Unsuccessfully'
export const VERIFYSUCCESSFULLY = 'Successfully'
export const NOTVERIFY = 'NotVerify'
export const SAVINGDEP = 'SAVINGDEP'
export const DEPINT = 'DEPINT'
export const DEPEXT = 'DEPEXT'
export const WITHDRAW = 'WITHDRAW'

export const M = 'M'
export const MAE = 'MAE'
export const month = ' tháng'
export const year = ' năm'
export const Y = 'Y'
export const YAE = 'YAE'

export const TRANSEXTACC = 'TRANSEXTACC'
export const TRANSEXT = 'TRANSEXT'
export const TRANSINT = 'TRANSINT'
export const TRANSEXTCARD = 'TRANSEXTCARD'

export const VerifyCustomer = {
    [PENDING]: {
        name: 'Pending',
        value: 'Chi tiết',
        label: 'Chưa xem'
    },
    [SEEN]: {
        name: 'Seen',
        value: 'Chi tiết',
        label: 'Đã xem',
    },
    [VERIFYUNSUCCESSFULLY]: {
        name: 'Unsuccessfully',
        label: 'Không thành công',
        value: 'Không thành công',
        data: 'Xác thực không thành công',
        color: 'rgb(243, 25, 25)', //'#f31919'
        content: 'Chưa xem'
    },
    [VERIFYSUCCESSFULLY]: {
        name: 'Successfully',
        label: 'Thành công',
        data: 'Xác thực thành công',
        value: 'Thành công',
        color: '#0fb11f',
        content: 'Đã xem'
    },
    [NOTVERIFY]: {
        name: 'NotVerify',
        label: 'Không xác thực',
        value: 'Không xác thực',
        color: 'rgb(243, 25, 25)',
        content: 'Chưa xem'
    },
}

export const verifyResults = {
    [VERIFYSUCCESSFULLY]: {
        key: VERIFYSUCCESSFULLY,
        text: 'Thành công'
    },
    [VERIFYUNSUCCESSFULLY]: {
        key: VERIFYUNSUCCESSFULLY,
        text: 'Không thành công'
    },
    [NOTVERIFY]: {
        key: NOTVERIFY,
        text: 'Không xác thực'
    },
}