import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import DatePicker from 'react-date-picker'
import moment from 'moment'

import SingleChoiseDropDownControl from '../Control/SingleChoiseDropDownControl'
import { getListVerifyFingerHistory } from '../../../../actions/supervisorAction/verifyCustomerAction'
import DropDownSearch from '../Control/DropDownSearch'

const Bound = styled.div`
    padding: 0 0 20px 20px;
    box-sizing: border-box;
    font-family: SVN-Gilroy;
    font-style: normal;
    height: 100%;

    .block-btn {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 27px 20px 40px 0;
        font-size: 14px;

        .btn-reset,
        .btn-search {
            background: #005993;
            border-radius: 18px;
            padding: 10px 13px;
            box-sizing: border-box;
            text-transform: uppercase;
            color: #ffffff;
            font-weight: bold;
            cursor: pointer;
        }
        .btn-reset {
            margin: 0 12px 0 0;
        }
    }

    .main-scroll {
        overflow: auto;
        height: 100%;
        /* margin: 0 0 150px 0; */

        ::-webkit-scrollbar {
            /* height: 8px; */
            width: 5px;
        }
        ::-webkit-scrollbar-thumb {
            background-color: #c4c4c4;
            border-radius: 5px;
        }

        .dateBefore {
            font-weight: bold;
            margin: 0 20px 30px 0;
            p {
                margin: 3px 0 0 0;
                font-size: 11px;
                text-transform: uppercase;
                color: #005993;
            }
        }
        .dateAfter {
            font-weight: bold;
            margin: 0 20px 0 0;
            p {
                margin: 3px 0 0 0%;
                font-size: 11px;
                text-transform: uppercase;
                color: #005993;
            }
        }

        .select-user {
            margin: 30px 20px 0 0;

            label {
                margin: 3px 0 0 0%;
                font-size: 11px;
                text-transform: uppercase;
                color: #005993;
                font-weight: bold;
            }
        }

        /* .input-gdv,
        .input-ksv {
            margin: 30px 20px 0 0;

            label {
                margin: 3px 0 0 0%;
                font-size: 11px;
                text-transform: uppercase;
                color: #005993;
                font-weight: bold;
            }

            input {
                width: 100%;
                height: 40px;
                background: #ffffff;
                border: solid 0.5px #c8c8c8;
                box-sizing: border-box;
                border-radius: 4px;
                padding: 0 10px;
                &:focus {
                    outline: none;
                }
                &::placeholder {
                    color: #bebebe;
                    font-weight: 500;
                }
            }
        } */

        .dropdown-typeData {
            height: 60px;
            margin: 30px 20px 0 0;
            &.last {
                width: 100%;
                height: 30px;
                margin: 30px 20px 150px 0;
            }
        }
    }

    .date-picker {
        width: 100%;
        .react-date-picker__wrapper {
            width: 100%;
            height: 40px;
            border: 0.5px solid #d6d6d6;
            border-radius: 4px;
            .react-date-picker__inputGroup {
                font-family: SVN-Gilroy;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 128%;
                display: flex;
                align-items: center;
                color: #222222;
                margin: 0 0 0 9px;
            }
        }
        .react-calendar {
            width: 94.5%;
            position: absolute;
            right: 11px;
            top: 4px;
            border: 0.5px solid #bebebe;
            border-radius: 4px;

            .react-calendar__tile--active {
                background: #005993;
                /* border-radius: 50%; */
            }
            .react-calendar__navigation {
                button:first-child,
                button:last-child {
                    display: none;
                }
                button:nth-child(3) {
                    font-family: SVN-Gilroy;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 15px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #222222;
                }
            }
            .react-calendar__viewContainer {
                .react-calendar__month-view__weekdays {
                    display: none !important;
                }
                .react-calendar__month-view__days {
                    font-family: SVN-Gilroy;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 130%;
                }
            }
        }
    }
`
const dateFormat = 'dd/MM/yyyy'

const listDataStatus = [
    {
        type: 'ALL',
        name: 'Tất cả',
    },
    {
        type: 'Pending',
        name: 'Chưa xem',
    },
    {
        type: 'Seen',
        name: 'Đã xem',
    },
]

const listVerifyStatus = [
    {
        type: 'ALL',
        name: 'Tất cả',
    },
    {
        type: 'Successfully',
        name: 'Thành công',
    },
    {
        type: 'Unsuccessfully',
        name: 'Không thành công',
    },
    {
        type: 'NotVerify',
        name: 'Không xác thực',
    },
]

const CustomerType = [
    {
        type: 'ALL',
        name: 'Tất cả',
    },
    {
        type: 'INCUST',
        name: 'KH Nội bộ',
    },
    {
        type: 'EXCUST',
        name: 'KH Thông thường',
    },
]

const ALL_VAL = 'Tất cả'

function CustomerFilter({
    callBackGetSearch,
    valueCustNumOrName,
    callBackReset,
}) {
    // const { userReducer } = useSelector((state) => ({
    //     userReducer: state.userReducer,
    // }))

    const dispatch = useDispatch()

    const commonReducer = useSelector(state => state.commonReducer)

    // const [listBioInfosType, setListBioInfosType] = useState([])
    const [dateBefore, setDateBefore] = useState(moment().startOf('days')._d)
    const [dateAfter, setDateAfter] = useState(moment().endOf('days')._d)
    const [idShow, setIdShow] = useState('')

    const [valueGDV, setValueGDV] = useState(ALL_VAL)
    const [valueKSV, setValueKSV] = useState(ALL_VAL)

    // const [selectBioInfosType, setSelectBioInfosType] = useState(null)
    // const [selectStatusType, setSelectStatusType] = useState(null)
    const [selectCustomerType, setSelectCustomerType] = useState(null)

    const [selectDataStatus, setSelectDataStatus] = useState(null)
    const [selectVerifyStatus, setSelectVerifyStatus] = useState(null)

    // useEffect(() => {
    //     let listBioInfosTypeEX = setSupervisorSingleChoise.bioInfosType
    //     listBioInfosTypeEX = listBioInfosTypeEX.map((item) => ({ ...item }))
    //     let listBioInfosTypeIN = listBioInfosTypeEX.filter(
    //         (item) => item.name !== BioInfosType[NR].label
    //     )
    //     let listBioInfosType =
    //         userReducer.dataLogin.Role === OFFINSUPERVISOR
    //             ? listBioInfosTypeIN
    //             : listBioInfosTypeEX

    //     setListBioInfosType(listBioInfosType)
    // }, [userReducer])

    useEffect(() => {
        const calendars = document.querySelectorAll('.react-date-picker__inputGroup');
        calendars.forEach(item => {
            const inputs = item.querySelectorAll('input');
            inputs.forEach(input => input.setAttribute('disabled', 'disabled'))
        })
    }, [])

    useEffect(() => {
        if (callBackGetSearch) _onSearch()
    }, [callBackGetSearch])

    useEffect(() => {
        if (selectVerifyStatus?.type === 'Successfully') {
            setSelectDataStatus(null)
        }
    }, [selectVerifyStatus])

    const _getDate = (type, date) => {
        if (type === 'dateBefore') {
            setDateBefore(moment(date).startOf('days')._d)

            if (moment(date).add(30, 'days') > moment())
                setDateAfter(moment().endOf('days')._d)
            else setDateAfter(moment(date).add(30, 'days').endOf('days')._d)
        } else {
            setDateAfter(moment(date).endOf('days')._d)
        }
    }

    const _setIdShow = (id) => {
        setIdShow(id)
    }

    const _getValueGDV = (e) => {
        const { value } = e.target
        setValueGDV(value)
    }
    const _getValueKSV = (e) => {
        const { value } = e.target
        setValueKSV(value)
    }

    const _onReset = () => {
        callBackReset()
        setDateBefore(moment().startOf('days')._d)
        setDateAfter(moment().endOf('days')._d)

        setValueGDV(ALL_VAL)
        setValueKSV(ALL_VAL)

        setSelectDataStatus(null)
        setSelectVerifyStatus(null)
        setSelectCustomerType(null)

        const data = {
            PageNumber: 1,
            PageSize: 10,
            CustNumOrName: '',
            FromCreateAt: moment(moment().startOf('days')._d),
            ToCreateAt: moment().endOf('days').toISOString(),
            DataStatus: 'Pending',
        }

        dispatch(getListVerifyFingerHistory(data))
    }
    const _onSearch = () => {
        // console.log('valueCustNumOrName', valueCustNumOrName)
        // console.log('valueGDV', valueGDV)
        // console.log('valueKSV', valueKSV)

        // console.log('dateBefore', dateBefore)
        // console.log('dateAfter', dateAfter)

        // console.log('selectDataStatus', selectDataStatus)

        // console.log('selectVerifyStatus', selectVerifyStatus)

        const dataStatus =
            !selectDataStatus
                ? 'Pending'
                : selectDataStatus.type === 'ALL'
                    ? ''
                    : selectDataStatus.type

        const verifyStatus =
            !selectVerifyStatus || selectVerifyStatus.type === 'ALL'
                ? ''
                : selectVerifyStatus.type

        const customerType =
            !selectCustomerType || selectCustomerType.type === 'ALL'
                ? ''
                : selectCustomerType.type

        const data = {
            PageNumber: 1,
            PageSize: 10,
            CustNumOrName: valueCustNumOrName ? valueCustNumOrName : '',
            TellerName: valueGDV === ALL_VAL ? '' : valueGDV,
            SupervisorName: valueKSV === ALL_VAL ? '' : valueKSV,
            FromCreateAt: moment(dateBefore),
            ToCreateAt: moment(dateAfter),
            DataStatus: verifyStatus === 'Successfully' ? '' : dataStatus,
            CustomerType: customerType,
            VerifyStatus: verifyStatus,
        }

        dispatch(getListVerifyFingerHistory(data))
    }

    return (
        <Bound>
            <div className="block-btn">
                <div className="btn-reset" onClick={_onReset}>
                    Reset
                </div>
                <div className="btn-search" onClick={_onSearch}>
                    Tìm kiếm
                </div>
            </div>

            <div className="main-scroll">
                <div className="dateBefore">
                    <p>Từ ngày</p>
                    <DatePicker
                        className="date-picker"
                        onChange={(date) => _getDate('dateBefore', date)}
                        value={dateBefore}
                        format={dateFormat}
                        maxDate={moment().endOf('days')._d}
                        clearIcon={null}
                        showLeadingZeros={false}
                    />
                </div>
                <div className="dateAfter">
                    <p>Đến ngày</p>
                    <DatePicker
                        className="date-picker"
                        onChange={(date) => _getDate('dateAfter', date)}
                        value={dateAfter}
                        format={dateFormat}
                        maxDate={
                            moment(dateBefore).add(30, 'days').endOf('days') >
                                moment().endOf('days')
                                ? moment().endOf('days')._d
                                : moment(dateBefore)
                                    .add(30, 'days')
                                    .endOf('days')._d
                        }
                        minDate={dateBefore}
                        clearIcon={null}
                        showLeadingZeros={false}
                    />
                </div>

                <div className="select-user">
                    <label>USER GDV</label>
                    <DropDownSearch
                        lstItemFixed={[ALL_VAL]}
                        lstItems={commonReducer.listTellers}
                        onChange={(val) => setValueGDV(val)}
                        defaultValue={valueGDV}
                        placeHolder="Nhập User GDV"
                    />
                </div>

                <div className="select-user">
                    <label>USER KSV</label>
                    <DropDownSearch
                        lstItemFixed={[ALL_VAL]}
                        lstItems={commonReducer.listExSupervisors}
                        onChange={(val) => setValueKSV(val)}
                        defaultValue={valueKSV}
                        placeHolder="Nhập User KSV"
                    />
                </div>

                {/* <div className="input-gdv">
                    <label>USER GDV</label>
                    <input
                        type="text"
                        placeholder="Nhập user GDV"
                        onChange={_getValueGDV}
                        value={valueGDV}
                    />
                </div>
                <div className="input-ksv">
                    <label>USER KSV</label>
                    <input
                        type="text"
                        placeholder="Nhập user KSV"
                        onChange={_getValueKSV}
                        value={valueKSV}
                    />
                </div> */}

                <div className="dropdown-typeData">
                    <SingleChoiseDropDownControl
                        id="selectCustomerType"
                        getText={(data) => setSelectCustomerType(data)}
                        data={CustomerType}
                        title="loại khách hàng"
                        titleChoise="Chọn loại khách hàng"
                        notSort={true}
                        idShow={idShow}
                        setIdShow={_setIdShow}
                        defaultValue={
                            !selectCustomerType && 'Tất cả'
                        }
                    />
                </div>

                <div className="dropdown-typeData">
                    <SingleChoiseDropDownControl
                        id="setSelectVerifyStatus"
                        getText={(data) => setSelectVerifyStatus(data)}
                        data={listVerifyStatus}
                        title="trạng thái xác thực"
                        titleChoise="Tất cả"
                        notSort={true}
                        idShow={idShow}
                        setIdShow={_setIdShow}
                        defaultValue={
                            !selectVerifyStatus && 'Tất cả'
                        }
                    />
                </div>
                <div className="dropdown-typeData">
                    {!selectVerifyStatus ||
                        (!!selectVerifyStatus &&
                            selectVerifyStatus.type !== 'Successfully') ? (
                        <SingleChoiseDropDownControl
                            id="selectDataStatus"
                            getText={(data) => setSelectDataStatus(data)}
                            data={listDataStatus}
                            title="Trạng thái phê duyệt"
                            titleChoise="Chọn trạng thái phê duyệt"
                            idShow={idShow}
                            notSort={true}
                            setIdShow={_setIdShow}
                            defaultValue={!selectDataStatus && 'Chưa xem'}
                        />
                    ) : null}
                </div>

                <div className="dropdown-typeData last"></div>
            </div>
        </Bound>
    )
}

export default React.memo(CustomerFilter)
