import React, { Component } from 'react'
import styled, { css } from 'styled-components'

//Image
// import ic_star_gray from '../../../../images/ic_star_gray.svg';
import ic_star_yellow from '../../../../images/starKHUT.svg'
import ic_more_gray from '../../../../images/ic_more_gray.svg'
import ic_refresh_gray from '../../../../images/ic_refresh_gray.svg'
import ic_refresh_blue from '../../../../images/ic_refresh_blue.svg'

// component
import PopupOptionListCounterItem from './PopupOptionListCounterItem'
import Modal from '../../../../helper/Modal'
import PopupConfirmReset from './PopupConfirmReset'
import CountDownTimer from '../Control/countDownTimer'

const styleCountTime = css`
    color: #bebebe;
`

const Bound = styled.div`
    box-sizing: border-box;
    cursor: ${(props) => props.cursor || 'default'};
    height: 288px;
    border-radius: 4px;
    background-color: #ffffff;
    padding: 14px 20px;
    font-family: 'SVN-Gilroy';
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .counter-top {
        position: relative;
        display: flex;
        img {
            margin-right: 4px;
        }
        .title_counter {
            width: 100%;
            font-size: 20px;
            font-weight: bold;
            color: #222222;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            padding: 0 15px 0 0;
            height: 60px;
        }
        .ic_more_gray {
            width: 24px;
            height: 24px;
            background-image: url(${ic_more_gray});
            background-size: cover;
            background-repeat: no-repeat;
            position: absolute;
            right: -7px;
            top: 4px;
            cursor: pointer;
        }
    }
    .block_star {
        height: 38px;
        width: 100%;
        margin: 8px 0 0 0;
        .div_star {
            width: 64px;
            height: 20px;
            border-radius: 3px;
            background-color: #ffbb00;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: default;
            .title_vip {
                font-size: 12px;
                font-weight: bold;
                line-height: 1.2;
                color: #ffffff;
            }
        }
    }

    .counter-main {
        display: flex;
        flex-direction: column;
        flex: 1;
        .service,
        .counter {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
        }
        .counter {
            margin: 5px 0 10px 0;
        }
        .service {
            margin: 0 0 20px 0;
        }
        .name {
            font-size: 11px;
            font-weight: bold;
            line-height: 1.26;
            color: #005993;
        }
        .number {
            font-size: 14px;
            font-weight: 500;
            line-height: 1.2;
            color: #222222;
        }
        .f-cover {
            display: flex;
            flex: 1;
            align-items: flex-start;
            justify-content: space-between;
            position: relative;
            ~ .f-cover:last-child {
                margin: 20px 0 0 0;
            }
            .name {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
            }
            .connect {
                position: absolute;
                right: 0;
                text-align: right;
                text-decoration: underline;
                font-size: 14px;
                font-weight: 500;
                line-height: 1;
                color: #0062d7;
                cursor: pointer;
                font-family: 'SVN-Gilroy';
                &.active {
                    color: #0fb11f;
                    text-decoration: none;
                    cursor: default;
                    display: flex;
                    span {
                        margin: 0 6px 0 0;
                    }
                    img {
                        cursor: pointer;
                    }
                    .refresh {
                        margin: 0 0 0 6px;
                    }
                }
            }

            .ic_refresh_connect {
                cursor: pointer;
                position: absolute;
                right: 0;
                bottom: -3px;
                display: flex;
                align-items: flex-end;
                img {
                    padding: 0 0 4px 10px;
                }
            }
            .sub-name {
                font-family: 'SVN-Gilroy';
                font-size: 14px;
                font-weight: 500;
                line-height: 1.3;
                color: #979797;
                margin: 5px 0;
                /* padding-top: 10px; */
                &.active {
                    color: #222222;
                    max-width: 100%;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
        }
    }
`
class TransactionCounterItem extends Component {
    state = {
        positionControl: {},
        isShowControl: false,
        isShowConfirmReset: false,

        desktop_timer_expired: false,
        tablet_timer_expired: false,
    }

    onShowControl = (index, data) => {
        let moreBtn = document.getElementById('more_button_id_' + index)
        if (moreBtn) {
            let bound = moreBtn.getBoundingClientRect()
            let scrollLeft =
                window.pageXOffset || document.documentElement.scrollLeft
            let scrollTop =
                window.pageYOffset || document.documentElement.scrollTop
            let topBound = bound.top + scrollTop
            let leftBound = bound.left + scrollLeft
            let offsetPopup = {
                top: topBound + bound.height / 2,
                left: leftBound - 78 + bound.width / 2,
            }
            let dataNeed = {
                ...JSON.parse(JSON.stringify(data)),
                currentPage: this.props.currentPage,
            }
            this.props.onShowControl(offsetPopup, 'CounterTrans', dataNeed)
        }
    }

    excutePositionPopup = () => {
        const { item, index } = this.props
        let moreBtn = document.getElementById('more_button_id_' + index)
        if (moreBtn) {
            let bound = moreBtn.getBoundingClientRect()
            let scrollLeft =
                window.pageXOffset || document.documentElement.scrollLeft
            let scrollTop =
                window.pageYOffset || document.documentElement.scrollTop
            // let topBound = bound.top + scrollTop
            let topBound = bound.top
            // let leftBound = bound.left + scrollLeft
            let leftBound = bound.left
            let offsetPopup = {
                top: topBound + bound.height / 2,
                left: leftBound - 89 + bound.width / 2,
            }
            // let dataNeed = {
            //     ...JSON.parse(JSON.stringify(data)),
            //     currentPage: this.props.currentPage,
            // }
            // this.props.onShowControl(offsetPopup, 'CounterTrans', dataNeed)
            this.setState({
                positionControl: offsetPopup,
            })
        }
    }

    showPopupControl = () => {
        this.setState({ isShowControl: !this.state.isShowControl }, () => {
            if (this.state.isShowControl) this.excutePositionPopup()
        })
    }

    n(n) {
        if (n > 0) {
            return n > 9 ? '' + n : '0' + n
        } else {
            return 0
        }
    }

    disConnectCounterTeller = (item) => {
        const data = {
            DesktopDevice: item.DesktopDevice,
            TabletDevice: item.TabletDevice,
            ID: item.ID,
            type: 'disConnectCounterTeller',
            key: 'disConnect',
        }
        this.props.onShowDisConnectCounter(data)
    }

    disConnectCounterTablet = (item) => {
        const data = {
            DesktopDevice: item.DesktopDevice,
            TabletDevice: item.TabletDevice,
            ID: item.ID,
            type: 'disConnectCounterTablet',
            key: 'disConnect',
        }
        this.props.onShowDisConnectCounter(data)
    }

    _refreshCounterConnected = (type) => {
        const { item, index } = this.props
        if (type === 'DesktopDevice') {
            // console.log('Refresh DesktopDevice')
            this.props.genDesktopKey(item.ID, item.AtOfficeID)
        } else if (type === 'TabletDevice') {
            // console.log('Refresh TabletDevice')
            this.props.genTabletKey(item.ID, item.AtOfficeID)
        }
    }

    onShowPopupReset = () => {
        this.setState({ isShowConfirmReset: true })
    }

    _callBackTime = (type, time) => {
        if (time === 0)
            this.setState({
                [type]: false,
            })
    }

    componentDidUpdate(prevProps, prevState) {
        // if (
        //     JSON.stringify(prevProps.item) !== JSON.stringify(this.props.item)
        // ) {
        //     const { item } = this.props
        //     if (
        //         JSON.stringify(item.desktopKey) !==
        //             JSON.stringify(prevProps.item.desktopKey) &&
        //         item.desktopKey.MaxAge > 0
        //     ) {
        //         this.setState({
        //             desktop_timer_expired: true,
        //         })
        //     }
        //     if (
        //         JSON.stringify(item.tabletKey) !==
        //             JSON.stringify(prevProps.item.tabletKey) &&
        //         item.tabletKey.MaxAge > 0
        //     ) {
        //         this.setState({
        //             tablet_timer_expired: true,
        //         })
        //     }
        // }
    }

    render() {
        const { item, index } = this.props
        const { desktop_timer_expired, tablet_timer_expired } = this.state

        let desktopKey
        let tabletKey
        if (typeof item.tabletKey !== 'undefined') {
            if (Object.keys(item.tabletKey).length !== 0) {
                tabletKey = item.tabletKey
            }
        }
        if (typeof item.desktopKey !== 'undefined') {
            if (Object.keys(item.desktopKey).length !== 0) {
                desktopKey = item.desktopKey
            }
        }

        return (
            <Bound
                onDoubleClick={() => {
                    this.props.onShowPopupEdit({
                        ...JSON.parse(JSON.stringify(item)),
                        currentPage: this.props.currentPage,
                    })
                }}
                cursor="pointer"
            >
                <div className="counter-top">
                    {/* {item.IsVIP ?
            <div className='ic_star' style={{ backgroundImage: `url(${ic_star_yellow})` }}></div>
            : null} */}

                    <h2 className="title_counter">{item.CounterName}</h2>
                    <div
                        className="ic_more_gray"
                        id={'more_button_id_' + index}
                        onClick={this.showPopupControl}
                        // onClick={() => this.onShowControl(index, item)}
                    >
                        <Modal isShow={this.state.isShowControl}>
                            <PopupOptionListCounterItem
                                offsetPopup={this.state.positionControl}
                                counterInfos={item}
                                currentPage={this.props.currentPage}
                                funcCall={{
                                    onShowPopupEdit: this.props.onShowPopupEdit,
                                    onShowPopupDelete: this.props
                                        .onShowPopupDelete,
                                    onShowPopupReset: this.onShowPopupReset,
                                }}
                            />
                        </Modal>
                    </div>
                    <Modal isShow={this.state.isShowConfirmReset}>
                        <PopupConfirmReset
                            counterInfos={item}
                            currentPage={this.props.currentPage}
                            setIsShowConfirmReset={(isShow) =>
                                this.setState({ isShowConfirmReset: isShow })
                            }
                        />
                    </Modal>
                </div>
                <div className="block_star">
                    {item.IsVIP ? (
                        <div className="div_star">
                            <img
                                className="ic_star"
                                src={ic_star_yellow}
                                alt="star"
                            />
                            <p className="title_vip">Ưu tiên</p>
                        </div>
                    ) : null}
                </div>
                <div className="counter-main">
                    {/* <div className="device-counter"> */}
                    <div className="counter">
                        <div className="name">SỐ QUẦY</div>
                        <div className="number">
                            {' '}
                            {this.n(item.CounterNumber)}{' '}
                        </div>
                    </div>
                    <div className="service">
                        <div className="name">SỐ LƯỢNG GIAO DỊCH:</div>
                        <div className="number">
                            {item.ServAllService
                                ? this.n(this.props.listTypeCounterServices)
                                : this.n(
                                      item.ServiceList
                                          ? item.ServiceList.length
                                          : 0
                                  )}
                        </div>
                    </div>
                    {/* </div> */}

                    <div className="f-cover">
                        <div className="name">
                            <div className="main-name">COUNTER</div>
                            {item.DeviceMac ? (
                                <div
                                    className="sub-name active"
                                    title={item.DeviceMac}
                                >
                                    {item.DeviceMac}
                                </div>
                            ) : // ) : desktopKey && desktop_timer_expired ? (
                            desktopKey ? (
                                <div className="sub-name">
                                    {desktopKey.ConnectID}-{desktopKey.Pwd}
                                </div>
                            ) : null}
                        </div>
                        {/* {desktopKey && desktop_timer_expired ? ( */}
                        {desktopKey ? (
                            <div
                                className="ic_refresh_connect"
                                onClick={() => {
                                    this.props.genDesktopKey(
                                        item.ID,
                                        item.AtOfficeID
                                    )
                                    // this.setState({
                                    //     desktop_timer_expired: false,
                                    // })
                                }}
                            >
                                <CountDownTimer
                                    key={1}
                                    condition={desktopKey}
                                    timer={desktopKey.MaxAge}
                                    extendStyles={styleCountTime}
                                    callBackTime={(time) =>
                                        // this._callBackTime(
                                        //     'desktop_timer_expired',
                                        //     time
                                        // )
                                        this.props.clearKeyGenCounter(
                                            item.ID,
                                            'desktopKey'
                                        )
                                    }
                                />
                                <img
                                    src={ic_refresh_blue}
                                    alt="ic_refresh_connect"
                                />
                            </div>
                        ) : item.DeviceMac ? (
                            <div className="connect active">
                                Connected
                                {/* <span>Connected</span> */}
                                {/* <img
                                    className="refresh"
                                    onClick={() =>
                                        this._refreshCounterConnected(
                                            'DesktopDevice'
                                        )
                                    }
                                    src={ic_refresh_gray}
                                    alt="ic_refresh_connect"
                                /> */}
                                {/*<div 
                                    style={{margin: "3px 0 0 0", cursor: "pointer"}}
                                    onClick={()=>{this.disConnectCounterTeller(item)}}
                                    >
                                    <img src={ic_close_red} alt="disConnect" />
                                    </div>*/}
                            </div>
                        ) : (
                            <div
                                className="connect"
                                onClick={() =>
                                    this.props.genDesktopKey(
                                        item.ID,
                                        item.AtOfficeID
                                    )
                                }
                            >
                                <span>Connect ID</span>
                            </div>
                        )}
                    </div>
                    <div className="f-cover">
                        <div className="name">
                            <div className="main-name">TABLET</div>
                            {/* {item.TabletDevice ? (
                                <div
                                    className="sub-name active"
                                    title={item.TabletDevice}
                                >
                                    {item.TabletDevice}
                                </div>
                            ) : // ) : tabletKey && tablet_timer_expired ? (
                            tabletKey ? (
                                <div className="sub-name">
                                    {tabletKey.ConnectID}-{tabletKey.Pwd}
                                </div>
                            ) : null} */}
                            {tabletKey ? (
                                <div className="sub-name">
                                    {tabletKey.ConnectID}-{tabletKey.Pwd}
                                </div>
                            ) : item.TabletDevice ? (
                                <div
                                    className="sub-name active"
                                    title={item.TabletDevice}
                                >
                                    {item.TabletDevice}
                                </div>
                            ) : null}
                        </div>
                        {/* {tabletKey && tablet_timer_expired ? ( */}
                        {tabletKey ? (
                            <div
                                className="ic_refresh_connect"
                                onClick={() => {
                                    this.props.genTabletKey(
                                        item.ID,
                                        item.AtOfficeID
                                    )
                                    // this.setState({
                                    //     tablet_timer_expired: false,
                                    // })
                                }}
                            >
                                <CountDownTimer
                                    key={2}
                                    condition={tabletKey}
                                    timer={tabletKey.MaxAge}
                                    extendStyles={styleCountTime}
                                    callBackTime={(time) =>
                                        // this._callBackTime(
                                        //     'tablet_timer_expired',
                                        //     time
                                        // )
                                        this.props.clearKeyGenCounter(
                                            item.ID,
                                            'tabletKey'
                                        )
                                    }
                                />
                                <img
                                    src={ic_refresh_blue}
                                    alt="ic_refresh_connect"
                                />
                            </div>
                        ) : item.TabletDevice ? (
                            <div className="connect active">
                                <span>Connected</span>
                                <img
                                    onClick={() =>
                                        this._refreshCounterConnected(
                                            'TabletDevice'
                                        )
                                    }
                                    src={ic_refresh_gray}
                                    alt="ic_refresh_connect"
                                />
                                {/*<div 
                  style={{margin: "3px 0 0 0", cursor: "pointer"}}
                  onClick={()=>{this.disConnectCounterTablet(item)}}
                >
                  <img src={ic_close_red} alt="disConnect" />
                </div>*/}
                            </div>
                        ) : (
                            <div
                                className="connect"
                                onClick={() =>
                                    this.props.genTabletKey(
                                        item.ID,
                                        item.AtOfficeID
                                    )
                                }
                            >
                                <span>Connect ID</span>
                            </div>
                        )}
                    </div>
                </div>
            </Bound>
        )
    }
}

export default TransactionCounterItem
