import { css } from 'styled-components'

const extendStylesOffice = css`
    width: 200px;
`

const extendStylesTeller = css`
    width: 200px;
`

const extendStylesChannel = css`
    width: 200px;
`

export {
    extendStylesOffice,
    extendStylesTeller,
    extendStylesChannel
}