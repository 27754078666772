import styled from 'styled-components'

const HEIGHT_ROW = 48

const ScrollTable = styled.div`
    /* max-width: calc(100vw - 180px); */
    position: absolute;
    width: 100%;
    /* max-width: 100%; */
    overflow-x: auto;
    &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }
`

const WrapTable = styled.div`
    margin-top: 25px;
    min-width: max-content;

    .header,
    .row {
        display: grid;
        padding: 0 25px;
        /* grid-gap: 5px; */

        & > *:not(:first-child) {
            padding-left: 10px;
        }

        & > *:not(:last-child) {
            padding-right: 10px;
        }
    }

    .header {
        min-height: 40px;
        background: #0059930d;
        box-shadow: inset 0px -0.5px 0px rgba(0, 0, 0, 0.15);
        box-sizing: border-box;
        padding: 10px 25px;

        & > * {
            font-family: SVN-Gilroy;
            font-style: normal;
            font-weight: bold;
            font-size: 10px;
            line-height: 130%;
            text-transform: uppercase;
            color: #005993;
            align-self: center;
        }
    }

    .body {
        min-width: min-content;
        position: relative;
        overflow-y: auto;
        overflow-y: overlay;
        height: ${(props) => props.numOfRow * HEIGHT_ROW}px;

        .wrapper {
            height: 100%;
            width: 100%;
        }

        .row {
            position: relative;
            z-index: 1;
            box-sizing: border-box;
            min-height: ${HEIGHT_ROW}px;
            padding: 0 25px;
            background: #efefef;
            /* box-shadow: inset 0px -0.5px 0px rgba(0, 0, 0, 0.12); */
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);

            & > * {
                background: #efefef;
                font-family: SVN-Gilroy;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 128%;
                color: #222222;
                align-self: center;
                max-width: 100%;
                /* white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis; */
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                box-sizing: border-box;
                padding-top: 10px;
                padding-bottom: 10px;
                min-width: 0;

                & > * {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            & > *:not(:last-child) {
                border-right: 1px solid rgba(0, 0, 0, 0.12);
            }

            .limit-length {
                max-width: 45ch;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        &::-webkit-scrollbar {
            width: 10px;
            height: 10px;
        }
    }

    ${(props) => props.extendStyles}
`

const WrapRow = styled.div``

export { ScrollTable, WrapTable, WrapRow }
