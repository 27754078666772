import React, { Component } from "react";
import styled from "styled-components";

// import image-icon
import icMoreGray from "../../../../images/ic_more_gray.svg";
import icLCDGreen from "../../../../images/ic_LCD_green.svg";
import icLCDGrey from "../../../../images/ic_LCD_grey.svg";
import ic_refresh from "../../../../images/ic_refresh.svg";
import ic_refresh_gray from '../../../../images/ic_refresh_gray.svg'
import CountDownTimer from "../Control/countDownTimer";

const StyledDeviceLCDItem = styled.div`
    /* width: 280px; */
    height: 140px;
    position: relative;
    background: #ffffff;
    font-family: 'SVN-Gilroy';
    cursor: ${(props) => props.cursor || 'default'};
    &:last-child {
        margin-bottom: 40px;
    }
    .LCD-item__title {
        display: flex;
        align-items: center;
        margin-top: 10px;
        padding-left: 12px;
        img {
            width: 24px;
            height: 24px;
            cursor: pointer;
        }
        .ic_more {
            margin-right: 12px;
        }
        div {
            flex-grow: 1;
            font-size: 20px;
            font-weight: bold;
            color: #222222;
            margin-left: 4px;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
    }

    .connected-text, .wrap-key-time, .connect {
      font-size: 14px;
      font-weight: 500;
      line-height: 1;
      margin: 14px 13px 0 16px;
    }

    .connect {
      text-decoration: underline;
      cursor: pointer;
      color: #0062d7;
    }

    .wrap-key-time {
      color: #222222;
      display: flex;
      flex-basis: calc(100% - 14px);
      justify-content: space-between;
      align-items: center;
      .wrap-key {
        display: flex;
        align-items: center;

        .sub-name {
          max-width: 21ch;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .ic_refresh_connect {
          margin-left: 7px;
        }
      }

    }

    .connected-text {
      color: #0FB11F;
    }

    .connected-mac {
      margin: 45px 13px 0 16px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 128%;
      color: #222222;
      max-width: 30ch;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }


    /* .LCD-active__content{
      font-family: 'SVN-Gilroy';
      font-size: 14px;
      font-weight: 500;     
      margin-left: 20px;
      margin-top: 15px;
      .LCD-item__status{
          color: #979797;
      }
      .LCD-item__id-number {        
          color: #222222;  
      }
    }
    .LCD-item__footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
    font-family: SVN-Gilroy; 
    .connect{
      position: absolute;
      right: 0;
      bottom: 20px;
      text-align: right;
      margin: 0 14px 0 0;
      text-decoration: underline;
      font-size: 14px;
      font-weight: 500;
      line-height: 1;
      color: #0062d7;
      cursor: pointer;
    }
    .ic_refresh_connect{      
      cursor: pointer;
      position: absolute;
      right: 10px;
      bottom: 10px;
    }
    .sub-name{      
      position: absolute;
      left: 20px;
      bottom: 18px;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.3;
      color: #222222;
    }    
  }*/
`
const styleCountTime = `
    color: #222222;
`
const DEVICE_STATUS = 'Chưa kết nối...';
export default class DeviceLCDItem extends Component {
  _isMounted = false;
  state = {
    data: this.props.data,
    isHover: false,
    dataGenkey: {}
  }
  onShowControl(index, data) {
    let moreBtn = document.getElementById('more_button_id_' + index);
    if (moreBtn) {
      let bound = moreBtn.getBoundingClientRect()
      let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      let topBound = bound.top + scrollTop
      let leftBound = bound.left + scrollLeft
      let offsetPopup = {
        top: topBound + bound.height / 2,
        left: leftBound - 78 + bound.width / 2
      }
      let dataNeed = { ...data, type: 'DeviceLcd', currentPage: this.props.currentPage }
      //console.log(index,dataNeed);
      this.props.onShowControl(offsetPopup, 'DeviceLCDPGD', dataNeed)
    }
  }

  async onConnect() {
    const { data } = this.props;
    await this.props.generateKeyConnectLCD(data.AtOffice, data.ID)
    // console.log(this.props.dataGenkey)
    this.setState({
      dataGenkey: { ...this.props.dataGenkey, ID: this.props.data.ID }
    });
  }

  render() {
    const { IsActive, Name, ID, Mac } = this.props.data;
    const { keyValue } = this.props;
    let { dataGenkey } = this.state

    return (
      <StyledDeviceLCDItem
        onDoubleClick={() => this.props.onShowPopupEditLCD({ ...this.props.data, type: 'DeviceLCDPGD', currentPage: this.props.currentPage })}
        cursor="pointer"
      >
        {/* title */}
        <div className="LCD-item__title">
          <img
            src={!IsActive ? icLCDGrey : icLCDGreen}
            alt="status"
          />
          <div>{Name}</div>
          <img src={icMoreGray} className='ic_more' alt="more" id={'more_button_id_' + this.props.keyValue}
            onClick={() => this.onShowControl(keyValue, this.props.data)} />
        </div>
        {
          !IsActive && !(Object.keys(this.state.dataGenkey).length > 0 && dataGenkey.ID === ID) &&
            <div className='connect' onClick={() => this.onConnect()}>
              Connect ID
            </div>
        }
        {
          !IsActive && Object.keys(this.state.dataGenkey).length > 0 && dataGenkey.ID === ID &&
            <div className="wrap-key-time">
              <div class="wrap-key">
                <div className="sub-name" title={`${this.state.dataGenkey.ConnectID}-${this.state.dataGenkey.Pwd}`}>
                  {this.state.dataGenkey.ConnectID}-{this.state.dataGenkey.Pwd}
                </div>
                <div className="ic_refresh_connect" onClick={() => this.onConnect()}>
                  <img src={ic_refresh_gray} alt='ic_refresh_connect' />
                </div>
              </div>
              <CountDownTimer
                  key={1}
                  condition={dataGenkey}
                  timer={dataGenkey.MaxAge}
                  extendStyles={styleCountTime}
                  callBackTime={() => {
                      this.props.clearKeyGenLcd(
                          ID,
                          Name,
                          'dataGenKey'
                      )
                  }}
              />
            </div>
        }
        {
          Mac &&
          <>
            <div className="connected-text">Đã kết nối</div>
            <div className="connected-mac" title={Mac}>{Mac}</div>
          </>
        }
        {/* <div className='LCD-active__content'>
          {!IsActive ?
            <div className='LCD-item__status'>{DEVICE_STATUS}</div>
            :
            <div className="LCD-item__id-number">{Mac}</div>
          }
        </div>
        <div className="LCD-item__footer">
          {
            !IsActive && Object.keys(this.state.dataGenkey).length > 0 && dataGenkey.ID === ID ?
              <div className="sub-name">
                {this.state.dataGenkey.ConnectID}-{this.state.dataGenkey.Pwd}
              </div>
              :
              null
          }
          {
            !IsActive ?
              Object.keys(this.state.dataGenkey).length > 0 && dataGenkey.ID === ID ?
                <div className="ic_refresh_connect" onClick={() => this.onConnect()}>
                  <img src={ic_refresh} alt='ic_refresh_connect' />
                </div>
                :
                <div className='connect' onClick={() => this.onConnect()}>
                  Connect ID
                </div>
              :
              null
          } 
        </div>*/}
      </StyledDeviceLCDItem>
    );
  }
}
