import styled from 'styled-components'

const WraperHeader = styled.div`
    height: 65px;
    display: flex;
    align-items: center;
    padding-left: 10.6px;

    img {
        padding: 14px;

        :hover {
            cursor: pointer;
        }
    }

    .title {
        font-family: SVN-Gilroy;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        color: #222222;
    }
`;

export {
    WraperHeader
}