import Axios from 'axios';

import * as types from './types';
import {host} from "../host";
// import { resetStore } from './userAction';
import { 
  addLoading, 
  removeLoading, 
  alertLogout, 
  showErrConnection 
} from "./commonAction";
import catchErrorApi from '../helper/catchErrorApi';

// api
const apiCreateLCD = host + "admin/lcd/create";
const apiUpdateLCD = host + "admin/lcd/update";
const apiGetAllLCD = host + "admin/lcd/getAll";
const apiDeleteLCD = host + "admin/lcd/delete";
const apiGenKeyLCD = host + "admin/lcd/generate-keys";

/**
 * 
 * @param {string} idOffice id of office which create LCD
 * @param {string} nameLCD name of new LCD
 */
export const createLCD = (idOffice, nameLCD) => async dispatch => {
  const queryDataFormat = JSON.stringify({
    AtOffice: idOffice,
    Name: nameLCD
  })

  try {
    const res = await Axios.post(apiCreateLCD, queryDataFormat);
    const {data} = res;
    dispatch({
      type: types.CREATE_LCD,
      data
    })
  }
  catch(err){
    catchErrorApi(
      err,
      dispatch,
      types.CREATE_LCD_ERR,
      () => false
    )
  }
}


/**
 * 
 * @param {string} idLCD id of LCD need update
 * @param {string} nameLCD new name of LCD
 */
export const updateLCD = (idLCD, nameLCD, mac) => async dispatch => {
  const queryDataFormat = JSON.stringify({
    ID: idLCD,
    Name: nameLCD,
    DeviceMac: mac
  })

  try {
    const res = await Axios.post(apiUpdateLCD, queryDataFormat);
    const {data} = res;

    dispatch({
      type: types.UPDATE_LCD,
      data
    })
  }
  catch(err){
    catchErrorApi(
      err,
      dispatch,
      types.UPDATE_LCD_ERR,
      () => false
    )
  }
}

/**
 * 
 * @param {string} idOffice id of office need get all LCD
 * @param {number} pageSize quantity element in one page
 * @param {number} pageNumber index of page
 */
export const getAllLCD = (idOffice, pageSize, pageNumber) => async dispatch => {
  dispatch(addLoading())
  const queryDataFormat = JSON.stringify({
    AtOffice: idOffice,
    PageSize: pageSize,
    PageNumber: pageNumber
  })

  try {
    const res = await Axios.post(apiGetAllLCD, queryDataFormat);
    const {data} = res;

    dispatch({
      type: types.GET_ALL_LCD,
      listLCD: data.ListLCD? data.ListLCD : [],
      total: data.Total,
      pageNumber
    })
    dispatch(removeLoading())
  }
  catch(err){
    dispatch(removeLoading())
    catchErrorApi(
      err,
      dispatch,
      types.GET_ALL_LCD_ERR,
      () => false
    )
  }
}

/**
 * 
 * @param {string} idLCD id of LCD need delete
 */
export const deleteLCD = (idLCD) => async dispatch => {
  const queryDataFormat = JSON.stringify({
    ID: idLCD
  })

  try {
    const res = await Axios.post(apiDeleteLCD, queryDataFormat);
    const {data} = res;

    dispatch({
      type: types.DELETE_LCD,
      data
    })
  }
  catch(err){
    catchErrorApi(
      err,
      dispatch,
      types.DELETE_LCD_ERR,
      () => false
    )
  }
}

/**
 * 
 * @param {string} idOffice id of office contain LCD
 * @param {string} idLCD id of LCD need generate key
 */
export const generateKeyConnectLCD = (idOffice, idLCD) => async dispatch => {
  const formatQueryData = JSON.stringify({
    OfficeID: idOffice,
    LcdID: idLCD
  })

  try {
    const res = await Axios.post(apiGenKeyLCD, formatQueryData);
    const { data } = res;

    dispatch({
      type: types.GENERATE_KEY_LCD,
      data
    })
  }
  catch (err) {
    catchErrorApi(
      err,
      dispatch,
      types.GENERATE_KEY_LCD_ERR,
      () => false
    )
  }
}

export const clearKeyGenLcd = (lcdId, LcdName, key) => ({
  type: types.CLEAR_KEY_GEN_LCD,
  payload: {
    lcdId,
    LcdName,
    key,
  },
})