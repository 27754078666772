import React, { useState, useEffect } from 'react'

// styles
import { WrapperHeader } from './index.styles'

// images
import ic_close from '../../../../../images/ic_close_gray.svg';
import ic_upload_blue from '../../../../../images/ic_upload_blue.svg';

// constant
import { STEP_CONFIRM_SEARCH_USERS, STEP_SEARCH_USER, STEP_SETUP_USER } from '..'

// const SEARCH_TITLE = "Tìm kiếm tài khoản Vietinbank"
const SEARCH_TITLE = <>
    <div>Tìm kiếm User</div>
    <div>(Điều kiện: User đã được phân quyền trên CoreBanking)</div>
</>
const CONFIG_TITLE = "Phân quyền"
const UPLOAD_TITLE = <>
    <div>Thông tin User được tải lên</div>
    <div>(Điều kiện: User đã được phân quyền trên CoreBanking)</div>
</>

const Header = ({ dataSetup, step, outPopup, handleUploadFile }) => {
    const [title, setTitle] = useState(SEARCH_TITLE)

    useEffect(() => {
        if (step === STEP_SEARCH_USER) {
            setTitle(SEARCH_TITLE)
            return;
        }

        if (step === STEP_SETUP_USER) {
            setTitle(CONFIG_TITLE)
            return;
        }

        setTitle(UPLOAD_TITLE)

    }, [step])

    const stepSearchSingle = [STEP_SEARCH_USER, STEP_SETUP_USER]

    const infosUser = (() => {
        if (!dataSetup) return null
        const infosUserBank = dataSetup[0]
        const {
            UserName,
            Name,
            Role,
            Branch,
            BranchCode
        } = infosUserBank
        return `${UserName} / ${Name} / ${Role} / ${BranchCode} - ${Branch}`
    })()

    return (
        <WrapperHeader>
            <div className="content">
                <div className="title">Thêm mới User</div>
                <div className='row infos-user'>
                    <div className='main-content'>{title}</div>
                    {
                        step === STEP_CONFIRM_SEARCH_USERS &&
                        <div className='btn-upload-file' onClick={handleUploadFile}>
                            <img src={ic_upload_blue} alt="upload" />
                            Chọn lại file EXCEL
                        </div>
                    }
                    {
                        stepSearchSingle.includes(step) &&
                        <div className="user" title={infosUser}>{infosUser}</div>
                    }
                </div>
            </div>
            <img
                className="ic-close"
                onClick={outPopup}
                src={ic_close}
                alt="close"
            />
        </WrapperHeader>
    )
}

export default Header
