import styled, { css } from 'styled-components'

const lstPxs = [115, 290, 410, 197, 200, 240, 185, 262, 196, 374]
const gridArr = lstPxs.map((item) =>
    typeof item === 'number' ? `${item}px` : item
)

const extendStylesTable = css`
    .header, .row {
        grid-template-columns: ${gridArr.join(" ")}
    }
`;

export {
    extendStylesTable
}