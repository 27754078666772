import * as types from '../../actions/queryAction/types'

const initialState = {
    duplBioDatas: null,
    error: '',
    countChangeRoute: 0
}

const duplicateBioReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_DUPL_BIO_DETAILS: {
            return {
                ...state,
                duplBioDatas: action.dupl_bio_details,
                error: ''
            }
        }
        case types.GET_DUPL_BIO_DETAILS_ERR: {
            return {
                ...state,
                duplBioDatas: null,
                error: action.payload.error
            }
        }
        case types.CHANGE_COUNT_ROUTE: {
            return {
                ...state,
                countChangeRoute: state.countChangeRoute + 1
            }
        }
        default:
            return state
    }
}

export default duplicateBioReducer