import Axios from 'axios'

import * as types from './types'
import { host } from '../host'
// import { resetStore } from './userAction';
// import { getInfoImageOrVideo } from '../tools';
import { addLoading, removeLoading } from './commonAction'
import catchErrorApi from '../helper/catchErrorApi'

// api
const apiGetAds = host + 'admin/ads/get-and-filter'
const apiCreateAds = host + 'admin/ads/create'
const apiUpdateAds = host + 'admin/ads/update'
const apiDeleteAds = host + 'admin/ads/delete'
const apiSetStatusAds = host + 'admin/ads/active'
const apiGetFile = host + 'admin/ads/file'

/**
 * @param {"GDV" \| "KIOSK" \| "LCD"} typeAds
 * @param {number} pageSize
 * @param {number} pageNumber
 * @param {string=undefined} SearchText
 */
export const getAds = (
    typeAds,
    pageSize,
    pageNumber,
    SearchText = undefined
) => async (dispatch) => {
    dispatch(addLoading())
    const dataFormat = {
        TypeAds: typeAds,
        PageSize: pageSize,
        PageNumber: pageNumber,
    }

    if (SearchText) dataFormat['SearchText'] = SearchText

    const querydataFormat = JSON.stringify({
        ...dataFormat,
    })

    try {
        const res = await Axios.post(apiGetAds, querydataFormat)
        const data = res.data

        if (!data.ListAds) {
            dispatch({
                type: types.GET_ADS,
                listAds: [],
                totalAds: data.TotalAds,
                pageNumber,
            })
            dispatch(removeLoading())
            return
        }

        const mapImageToListAds = data.ListAds.map((ads) => {
            return Axios.get(apiGetFile + '/' + ads.FileID, {
                responseType: 'blob',
            })
        })

        Promise.all(mapImageToListAds).then((dta) => {
            dispatch({
                type: types.GET_ADS,
                listAds: dta.map((item, i) => ({
                    ...data.ListAds[i],
                    fileSrc: item.request.responseURL,
                    fileSize: item.data.size,
                    fileType: item.data.type.split('/')[1],
                })),
                totalAds: data.TotalAds,
                pageNumber,
            })
            dispatch(removeLoading())
        })

    } catch (err) {
        dispatch(removeLoading())
        catchErrorApi(err, dispatch, types.GET_ADS_ERR, () => false)
    }
}

/**
 * @param {string} title
 * @param {string} desc
 * @param {string($binary)} upload
 * @param {"KIOSK" \| "GDV" \| "lCD"} typeAds
 * @param {boolean} active
 * @param {array=undefined} applyOffice
 */
export const createAds = (
    title,
    desc,
    upload,
    typeAds,
    active,
    applyOffice = undefined
) => async (dispatch) => {
    const bodyFormData = new FormData()

    bodyFormData.set('title', title)
    bodyFormData.set('desc', desc)
    bodyFormData.append('upload', upload)
    bodyFormData.set('type_ads', typeAds)
    bodyFormData.set('active', active)
    applyOffice &&
        applyOffice.map((item) => bodyFormData.append('apply_at_office', item))

    try {
        const res = await Axios.post(apiCreateAds, bodyFormData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        })

        const { data } = res
        dispatch({
            type: types.CREATE_NEW_ADS,
            success: Boolean(data.ID),
        })
    } catch (err) {
        catchErrorApi(err, dispatch, types.CREATE_NEW_ADS_ERR, () => false)
    }
}

/**
 * @param {string} id
 * @param {{
 * title: string,
 * type: "KIOSK" \| "GDV" \| "LCD",
 * desc: string,
 * upload: string($binary),
 * applyAtOffice: array,
 * status: boolean
 * }} dataChange
 */
export const updateAds = (id, dataChange) => async (dispatch) => {
    const updateFormData = new FormData()

    updateFormData.set('id', id)
    dataChange.title && updateFormData.set('title', dataChange.title)
    dataChange.type && updateFormData.set('type', dataChange.type)
    dataChange.desc && updateFormData.set('desc', dataChange.desc)
    dataChange.upload && updateFormData.append('upload', dataChange.upload)
    dataChange.applyAtOffice &&
        dataChange.applyAtOffice.map((item) =>
            updateFormData.append('apply_at_office', item)
        )
    try {
        const querydataFormat = JSON.stringify({
            ID: id,
            Active: dataChange.status,
        })
        const [updateData, updateStatus] = await Promise.all([
            Axios.post(apiUpdateAds, updateFormData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            }),
            Axios.post(apiSetStatusAds, querydataFormat),
        ])
        dispatch({
            type: types.UPDATE_ADS,
            success: Boolean(updateData.data) && Boolean(updateStatus.data),
        })
    } catch (err) {
        catchErrorApi(err, dispatch, types.UPDATE_ADS_ERR, () => false)
    }
}

/**
 * @param {string} id
 */
export const deleteAds = (id) => async (dispatch) => {
    const querydataFormat = JSON.stringify({
        ID: id,
    })

    try {
        await Axios.post(apiDeleteAds, querydataFormat)

        dispatch({
            type: types.DELETE_ADS,
            success: true,
        })
    } catch (err) {
        catchErrorApi(err, dispatch, types.DELETE_ADS_ERR, () => false)
    }
}

/**
 * @param {string} id
 * @param {boolean} status
 */
export const setStatusAds = (id, status) => async (dispatch) => {
    const querydataFormat = JSON.stringify({
        ID: id,
        Active: status,
    })

    try {
        const res = await Axios.post(apiSetStatusAds, querydataFormat)

        const { data } = res
        dispatch({
            type: types.SET_STATUS_ADS,
            success: Boolean(data),
        })
    } catch (err) {
        catchErrorApi(err, dispatch, types.SET_STATUS_ADS_ERR, () => false)
    }
}

/**
 * @param {string} idFile: id of file need get
 */
export const getFileAds = (idFile) => async (dispatch) => {
    try {
        const getImg = await Axios.get(apiGetFile, {
            params: {
                id: idFile,
            },
            responseType: 'blob',
        })

        return {
            fileSrc: getImg.request.responseURL,
            fileSize: getImg.data.size,
            fileType: getImg.data.type.split('/')[1],
        }
    } catch (err) {
        // console.log(err);
        // if (err.response && err.response.status === 403) {
        //   dispatch(alertLogout())
        //   return;
        // }
        // dispatch({
        //   type: types.GET_FILE_ADS_ERR,
        //   err
        // })

        const handleError400 = () => {
            dispatch({
                type: types.GET_FILE_ADS_ERR,
                err,
            })
            return true
        }

        catchErrorApi(err, dispatch, types.GET_FILE_ADS_ERR, handleError400)
    }
}

export const alertAds = () => {
    return {
        type: types.ALERT_CONTENT,
    }
}
export const resetAdsReducer = () => (dispatch) => {
    //console.log("reload")
    dispatch({
        type: types.RESET_ADS_REDUCER,
    })
}

export const saveTextSearchAds = (text) => ({
    type: types.SAVE_TEXT_ADS,
    textSearch: text,
})

export const clearTextSearchAds = () => ({
    type: types.CLEAR_TEXT_ADS,
})
