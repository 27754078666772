import styled from 'styled-components'
const Bound = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: ${props => props.widthButton ? props.widthButton : "103px"}; */
    height: ${props => props.heightButton ? props.heightButton : "31px"};
    background: #005993;
    border-radius: 18px;
    cursor: pointer;
    box-sizing: border-box;
    padding: 11px 8px;
    p{
        font-family: 'SVN-Gilroy';
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        text-transform: uppercase;
        color: #FFFFFF;
        margin-left: 1px;
    }
`
export { Bound }