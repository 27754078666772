import moment from 'moment';

export const formatTime = {
    HHmmss: 'HH : mm : ss',
    HHmm: 'HH : mm',
    HHmmNoSpace: 'HH:mm',
    // HH mm with text
    HHmmText: `HH giờ mm `,
    HHmmUpperHour: `H Giờ mm `,
    HH: 'HH',
    mm: 'mm',
    ss: 'ss'
}

export const formatDate = {
    DDMMYYYY: 'DD/MM/YYYY',
    MMMD: 'MMM D'
}

export const formatDateTime = {
    DDMMYYHHmm: 'DD/MM/YYYY HH:mm',
    DDMMYYYYmmss: 'DD/MM/YYYY HH:mm:ss',
    HHmmDDMMYY: 'HH:mm DD/MM/YYYY'
}

/**
 * @param { Date \| moment} dateTime date time or moment
 * @param { string } typeFormat follow formatTime, formatDate, formatDateTime object 
 */

export const formatDateTimeToSpecificFormat = (dateTime, typeFormat) => 
    moment(dateTime).format(typeFormat)
