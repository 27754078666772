import styled from 'styled-components'

const widthDataBefore = [115, 290, 170, 260, 170, 170, 170, 170, 170, 170, 210]
const totalWidthDataBefore = widthDataBefore.reduce((total, curr) => (total + curr))
const widthDataAfter = [115, 290, 170, 260, 170, 170, 170, 170, 210]
const totalWidthDataAfter = widthDataAfter.reduce((total, curr) => (total + curr))

const WrapTable = styled.div`
    margin-top: 25px;
    min-width: max-content;
`
const Header = styled.div`
    display: grid;
    grid-template-columns: 170px ${totalWidthDataBefore}px ${totalWidthDataAfter}px;
    align-items: center;
    background: #0059930d;
    padding: 0 25px;
    box-shadow: inset 0px -0.5px 0px rgba(0, 0, 0, 0.15);

    & > *:not(:last-child) {
        border-right: 1px solid rgba(0, 0, 0, 0.12);
    }

    .header-item {
        font-family: SVN-Gilroy;
        font-style: normal;
        font-weight: bold;
        font-size: 10px;
        line-height: 126%;
        text-transform: uppercase;
        color: #005993;
        align-self: center;
        height: 100%;
        display: flex;
        align-items: center;
    }
`

const WrapInfos = styled.div`
    display: grid;
    grid-template-rows: 40px 40px;
    align-items: center;
    justify-items: center;

    &:last-child {
        & > *:first-child {
            padding-right: 48px;
        }
    }

    & > *:first-child {
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        width: 100%;
        justify-content: center;
    }

    .row-title {
        display: grid;
        height: 100%;

        &.data-before {
            grid-template-columns: ${widthDataBefore.map(item => `${item}px`).join(" ")};
        }

        &.data-after {
            grid-template-columns: ${widthDataAfter.map(item => `${item}px`).join(" ")};
        }

        & > *:not(:last-child) {
            border-right: 1px solid rgba(0, 0, 0, 0.12);
        }

        & > * {
            box-sizing: border-box;
            padding: 0 10px;
        }
    }
`

const HEIGHT_ROW = 48
const Body = styled.div`
    min-width: min-content;
    position: relative;
    overflow-y: auto;
    overflow-y: overlay;
    height: ${(props) => props.numOfRow * HEIGHT_ROW}px;

    .row {
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 170px ${widthDataBefore.map(item => `${item}px`).join(" ")} ${widthDataAfter.map(item => `${item}px`).join(" ")};
        min-height: ${HEIGHT_ROW}px;
        padding: 0 25px;
        background: #efefef;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);

        & > * {
            box-sizing: border-box;
        }

        & > *:not(:first-child) {
            padding-left: 10px;
        }

        & > *:not(:last-child) {
            padding-right: 10px;
            border-right: 1px solid rgba(0, 0, 0, 0.12);
        }

        & > * {
            background: #efefef;
            font-family: SVN-Gilroy;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 128%;
            color: #222222;
            align-self: center;
            max-width: 100%;
            /* white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis; */
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            box-sizing: border-box;
            padding-top: 10px;
            padding-bottom: 10px;
            min-width: 0;

            & > * {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }
`

export { WrapTable, Header, WrapInfos, Body }
