import * as types from '../../../actions/reportsAction/detailsReport/types';

const initialState = {
    report: {},
    condFilter: {},
    listData: []
}

const detailsReport = (state=initialState, action) => {
    switch(true){
        case action.type === types.CLEAR_REPORT:
            return initialState

        case Object.keys(types).includes(action.type): {
            return {
                ...state,
                ...action.payload
            }
        }

        default:
            return state;
    }
}

export default detailsReport