import React from 'react'
import ReactDOM from 'react-dom'
import ModalPrompt from './ModalPrompt'

const styleContainer = [
    'position: fixed',
    'top: 0',
    'left: 0',
    'display: flex',
    'justify-content: center',
    'align-items: center',
    'width: 100vw',
    'height: 100vh',
    'background: #000000d9',
    'z-index: 999999'
    // 'min-width: 1440px',
]

const PromtLeave = (message, callback) => {
    const container = document.createElement('div')
    container.style = styleContainer.join('; ')
    container.setAttribute('id', 'custom-confirmation-navigation')
    document.body.appendChild(container)

    const closeModal = (callbackState) => {
        ReactDOM.unmountComponentAtNode(container)
        const divContainer = document.getElementById(
            'custom-confirmation-navigation'
        )
        document.body.removeChild(divContainer)
        callback(callbackState)
    }

    const configPopup = JSON.parse(message)

    ReactDOM.render(
        <ModalPrompt
            cancelLabel={configPopup.cancelText}
            confirmLabel={configPopup.confirmText}
            onCancel={() => closeModal(false)}
            onConfirm={() => closeModal(true)}
            title={configPopup.title}
            note={configPopup.note}
        />,
        container
    )
}
export default PromtLeave
