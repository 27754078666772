import * as types from "../../actions/types";

const initialState = {
  err: '',
  success: false
}

export default function deleteOfficeReducer(state = initialState, action) {
  switch (action.type) {
    case types.DELETE_OFFICE:
      return {
        ...state,
        success: action.success
      }
    case types.DELETE_OFFICE_ERR:
      return {
        ...state,
        err: action.err
      }
    case types.CLEAR_ERR_DELETE_OFFICE:
      return {
        ...state,
        err: ''
      }
    default:
      return state
  }
}