import React, { Component } from 'react'
import styled from "styled-components";
// import { connect } from 'react-redux';

//container
import BlockNameControl from "./BlockNameControl";
import AddMore from "./AddMore";
import SearchControl from './SearchControl';
import SortControl from './SortControl';

const Bound = styled.div`
    display:flex;
    margin-bottom:7px;
    height:70px;
    flex-direction:row;
    justify-content:space-between;
    .block-left{
        display:flex;
        align-items:center;
        ${'' /* .data-type{
            display: flex;
            flex-direction: row;
            font-family: 'SVN-Gilroy';
            font-size: 14px;
            font-weight: 500;
            div{
                font-weight: bold;
                color:#005993;
                margin: 0 20px 0 5px;
            }
        } */}
    }
    .counter-remain{
        margin-right: 30px;
        font-family: SVN-Gilroy;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.3;
        span{
            font-size: 14px;
            font-weight: bold;
            color: #d71249;
        }
    }
    .add{
        margin-right: 7px;
    }
`

export default class SubHeaderControl extends Component {

    onSubmit = () => {
        this.props.onAddMoreSubmit();
    }

    onReset = () => {
        this.props.onReset()
    }
    render() {
        const { data, isActives } = this.props;
        return (
            <Bound>
                <BlockNameControl
                    mainname={data.mainTitle}
                    subname={data.subTitle}
                />
                <div className="block-left">
                    {/* {
                        data.buttonArr.dataType ?
                        <div className='data-type'>
                            <p>Loại dữ liệu:</p>
                            <div>{data.buttonArr.dataType.content}</div>
                        </div>
                        :
                        null                        
                    } */}
                    {
                        data.buttonArr.search &&
                        <SearchControl
                            textSearch={this.props.textSearch}
                            typeSearch = {this.props.typeSearch}
                            total={this.props.total}
                            ref={ref => this.search = ref}
                            searchFilter={this.props.searchFilter}
                            inputname={data.buttonArr.search.placeHolder} />
                    }
                    {
                        Object.keys(this.props).includes('isShowFilter')
                            ? this.props.isShowFilter
                                ? <SortControl
                                    refSearch={this.search}
                                    toggleFilter={() => { this.props.toggleFilter(this.search) }}
                                />
                                : null
                            : data.buttonArr.filter &&
                            <SortControl
                                refSearch={this.search}
                                toggleFilter={() => { this.props.toggleFilter(this.search) }}
                            />
                    }
                    {
                        Object.keys(this.props).includes("counterRemain") ?
                            <div className="counter-remain">
                                Số quầy còn lại: <span>{`0${this.props.counterRemain}`.slice(-2)}</span>
                            </div>
                            : null
                    }
                    {
                        //!isActives && data.buttonArr.search &&
                        data.buttonArr.search &&
                        <AddMore
                            namebtn={data.resetButton}
                            isActive= {true}
                            onSubmit = {this.onReset}/>
                    }

                    {
                        this.props.isAdd === true ?
                            null
                            :
                            data.buttonArr.addMore &&
                            <AddMore
                                namebtn={data.buttonArr.addMore.title}
                                onSubmit={this.onSubmit} />
                    }
                    
                </div>
            </Bound>
        )
    }

}