import * as types from '../../actions/types'

const initialState = {
    kioskInfo: {}, //data reponse when create kiosk
    dataUpdate: {}, //data reponse when update kiosk
    listKiosk: [], //data reponse when get all kiosk
    total: 0, //data reponse when get all kiosk
    dataConnect: {}, //data reponse when connect kiosk
    dataGenKey: {}, //data reponse when generate key connect
    resDel: {}, //data reponse when delete kiosk
    err: '',
    currentPage: 1,

    type: '',
}

export default function kioskReducer(state = initialState, action) {
    switch (action.type) {
        case types.DEVICE_CONNECTED: {
            const { deviceType, parentId } = action.payload;
            if(deviceType !== 'KIOSK') return state;
            return {
                ...state,
                listKiosk: state.listKiosk.map(item => item.ID === parentId ? { ...item, IsConnected: true } : item)
            }
        }
        case types.CREATE_KIOSK:
            return {
                ...state,
                kioskInfo: action.data,
                err: '',
                type: action.type,
            }
        case types.CREATE_KIOSK_ERR:
            return {
                ...state,
                err: action.err,
                type: action.type,
            }

        case types.UPDATE_KIOSK:
            const { infosUpdate } = action
            return {
                ...state,
                dataUpdate: action.data,
                listKiosk: state.listKiosk.map(item => item.ID === infosUpdate.ID? {...item, ...infosUpdate} : item),
                err: '',
                type: action.type,
            }
        case types.UPDATE_KIOSK_ERR:
            return {
                ...state,
                err: action.err,
                type: action.type,
            }

        case types.GET_ALL_KIOSK:
            return {
                ...state,
                listKiosk: action.listKiosk,
                total: action.total,
                currentPage: action.pageNumber,
                err: '',
                type: action.type,
            }
        case types.GET_ALL_KIOSK_ERR:
            return {
                ...state,
                err: action.err,
                type: action.type,
            }

        case types.CONNECT_KIOSK:
            return {
                ...state,
                dataConnect: action.data,
                err: '',
                type: action.type,
            }
        case types.CONNECT_KIOSK_ERR:
            return {
                ...state,
                err: action.err,
                type: action.type,
            }

        case types.DISCONNECT_KIOSK:
            return {
                ...state,
                err: '',
                type: action.type,
            }
        case types.DISCONNECT_KIOSK_ERR:
            return {
                ...state,
                err: action.err,
                type: action.type,
            }

        case types.GENERATE_KEY_KIOSK:
            const newData = state.listKiosk.map((item) => {
                return {
                    ...item,
                    dataGenKey:
                        `KIOSK${item.Name}` === action.data.ConnectID
                            ? action.data
                            : item.dataGenKey,
                }
            })
            return {
                ...state,
                dataGenKey: action.data,
                listKiosk: newData,
                err: '',
                type: action.type,
            }
        case types.GENERATE_KEY_KIOSK_ERR:
            return {
                ...state,
                err: action.err,
                type: action.type,
            }

        case types.DELETE_KIOSK:
            return {
                ...state,
                resDel: action.data,
                err: '',
                type: action.type,
            }

        case types.DELETE_KIOSK_ERR:
            return {
                ...state,
                err: action.err,
                type: action.type,
            }

        case types.CLEAR_KEY_GEN_KIOSK:
            const { kioskId, kioskName, key } = action.payload
            return {
                ...state,
                listKiosk: state.listKiosk.map((item) =>
                    item.ID === kioskId ? { ...item, [key]: undefined } : item
                ),
                [key]:
                    state[key].ConnectID === `KIOSK${kioskName}`
                        ? {}
                        : state[key],
            }
        // //// Update E-form
        // case types.UPDATE_EFORM:
        //     console.log(action.data)

        //     return {
        //         ...state,
        //         listKiosk: action.data,
        //         type: action.type,
        //     }

        default:
            return state
    }
}
