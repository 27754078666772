import React from 'react'

// styles
import {
    extendStylesSelect,
    extendStylesDropdown,
    DropDownStyles,
} from './index.styles'

// component
import DropDownSearchObjV2 from '../../../../../Control/DropDownSearchObjV2'

const DropDownV2 = ({ extendStyles, maxNumShowDropdown, label, ...props }) => {
    return (
        <>
            <div className="label-tools-item">{label}</div>
            <DropDownStyles className="tools-item" extendStyles={extendStyles}>
                <DropDownSearchObjV2
                    {...props}
                    extendStylesSelect={extendStylesSelect}
                    extendStylesDropdown={extendStylesDropdown}
                />
            </DropDownStyles>
        </>
    )
}

export default DropDownV2
