import styled, { css } from 'styled-components'

// const lstPxs = [60, 200, 120, 170, 150, 140, 170, 100, 400, 100]
// const sumPx = lstPxs.reduce((total, curr) => total + curr)
// const gridArr = lstPxs.map(
//     (item) => `minmax(${item}px, ${Math.round((item / sumPx) * 100)}fr)`
// )

const lstPxs = [115, 290, 170, 260, 170, 170, 170, 200, 170, 500, 170]
const gridArr = lstPxs.map((item) =>
    typeof item === 'number' ? `${item}px` : item
)

const extendStylesTable = css`
    .header,
    .row {
        grid-template-columns: ${gridArr.join(' ')};
    }
`

const extendStylesTeller = css`
    width: 220px;
`

export { extendStylesTable, extendStylesTeller }
