import styled, { css } from 'styled-components'

const lstPxs = [115, 290, 400, 275, 150]
// const sumPx = lstPxs.reduce((total, curr) => total + curr)
// const gridArr = lstPxs.map(
//     (item) => `minmax(${item}px, ${Math.round((item / sumPx) * 100)}fr)`
// )
const gridArr = lstPxs.map(item => `${item}px`)

const extendStylesTable = css`
    .header,
    .row {
        grid-template-columns: ${gridArr.join(' ')};
    }
`

const extendWrapToolsCustom = css``

const extendStylesOffice = css`
    width: 268px;
`

export { extendStylesTable, extendWrapToolsCustom, extendStylesOffice }
