import React, { Component } from 'react'
import moment from "moment";
import { connect } from 'react-redux'

// component
import LoadingControl from "../../MainScreen/Control/LoadingControl"

// action
import { logoutUser } from '../../../../actions/userAction'

// styles
import { PopupTimeoutModalStyles } from "./index.styles"

const WAIT_TIME_IN_SECONDS = 30;

class PopupTimeoutModal extends Component {
    state = {
        time: ""
    }

    _handleLogout = () => {
        const { dataLogin } = this.props.userReducer;
        const { UID } = dataLogin;
        this.props.logoutUser(UID)
    }

    render() {
        return (
            <PopupTimeoutModalStyles>
                <div className="title">Phiên làm việc sắp kết thúc.</div>
                <div className="title last">Vui lòng xác nhận tiếp tục</div>
                <div className="content">
                    <LoadingControl size="36px" loadingPage={false} />
                    <div className="time-count">
                        Thời gian chờ: <span>{` 00:${this.state.time}`}</span>
                    </div>
                </div>
                <button className="continue" onClick={this.props.onAccept}>
                    Tiếp tục
                </button>
            </PopupTimeoutModalStyles>
        )
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.time !== this.state.time && this.state.time === "00") {
            this._handleLogout();
            // this.props.onAccept();
        }
        // if(this.props.counterReducer.listCurrentTicket !== prevProps.counterReducer.listCurrentTicket){
        //     // debugger;
        //     // const currentTicket = this.props.counterReducer.listCurrentTicket.find(
        //     //     item => item.TicketID === this.props.ticketId
        //     // )
        //     // if(currentTicket && currentTicket.isFinishWaitingEvalueate)
        //     this.props.closePopup();
        // }
    }


    componentDidMount() {
        const nowTime = moment();
        const timeCall = moment();
        const duration = moment.duration(nowTime.diff(timeCall));
        const hrs = duration.hours();
        const mins = duration.minutes();
        const secs = duration.seconds();
        let range = WAIT_TIME_IN_SECONDS - secs;
        if (hrs > 0 || mins > 0 || range <= 0) {
            this.setState({ time: "00" })
            return;
        }
        this.setState({ time: ("0" + range).slice(-2) })
        const setTimeOutCall = this.setTimeOutCall = setInterval(() => {
            const range = this.state.time - 1;
            if (range <= 0) {
                this.setState({ time: "00" })
                clearInterval(setTimeOutCall);
                return;
            }
            this.setState({ time: ("0" + range).slice(-2) })
        }, 1000)
    }

    componentWillUnmount() {
        clearInterval(this.setTimeOutCall);
    }

}

const mapStateToProps = state => ({
    userReducer: state.userReducer
})

const mapDispatchToProps = dispatch => ({
    logoutUser: (userId) => 
        dispatch(logoutUser(userId))
})

export default connect(mapStateToProps, mapDispatchToProps)(PopupTimeoutModal)
