import { combineReducers } from 'redux'
import { isUndefined } from 'lodash'

import listReports from './listReports'
import detailsReport from './detailsReport'

const reports = combineReducers({
    listReports,
    detailsReport
})

export default reports