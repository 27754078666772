import React, { Component } from 'react'
import styled from "styled-components";
import parse from 'html-react-parser';

// import image
import icClose from "../../../../images/ic_close_gray.svg";
import ic_previous from '../../../../images/ic_previous.svg';
import ic_next from '../../../../images/ic_next.svg';
// import bgFinger from "../../../../images/bg_finger.svg";
import face_err from '../../.././../images/face_err.svg';
import bg_finger_update from '../../../../images/bg_finger_update.svg';
import ic_zoom from '../../../../images/ic_zoom.svg';
import on_form from '../../../../images/on_form_white.svg';

//Images
import finger_white from '../../../../images/finger_white.svg';
import finger_delete_red from '../../../../images/finger_delete_red.svg';
import finger_register_green from '../../../../images/finger_register_green.svg';
import finger_update_blue from '../../../../images/finger_update_blue.svg';
import finger_authen_trans from '../../../../images/finger_authen_trans.svg';
import finger_authen_blue from '../.././../../images/finger_authen_blue.svg';
import finger_authen_red from '../.././../../images/finger_authen_red.svg';
import finger_authen_white from '../.././../../images/finger_authen_white.svg';

import { dataFunction, biometrictFingerDataUpdate } from '../../../../data/data';
import FingerPrint from './FingerPrint';
import { FACE, REGISTER, UPDATE, DELETE, THUMB_LEFT, dataFingerPrint, THUMB_RIGHT, INDEX_LEFT, INDEX_RIGHT, MIDDLE_LEFT, MIDDLE_RIGHT, RING_LEFT, RING_RIGHT, PINKY_LEFT, PINKY_RIGHT, EXCUST, customType, INCUST, BioInfosType, ACTIVE, ISACTIVE, HANG, NR, fullNameVi, fullName_mobie, mobileFone, NOT_CHANGE_BIOMETRIC, FINGER, BioType, FACE_FINGER, REJECT, fullName_mobie_face, fullName_mobie_face_finger, fullName_mobie_finger, NOT_REGISTER_BIOMETRIC, mobie_finger, fullName_finger, mobie_face_finger, fullName_face_finger, mobie_face, fullName_face, statusN, statusS, statusU, titleCloseActiveFace, titleUFace, NR_FINGER, NOT_REGISTER_FINGER } from '../../../../data/infosType';
import { formatDateTimeToSpecificFormat, formatDateTime, formatDate } from '../../../../helper/formatTime';
import PopupShowFingerInfoCustomer from './PopupShowFingerInfoCustomer';
import { apiFaceHistory } from '../../../../actions/supervisorAction/biometricAction';
import PopupShowFaceImage from './PopupShowFaceImage';
import PopupNote from './PopupNote';

const StylePopupShowBiometricHistory = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 690px;
  /* height: 340px; */
  z-index: 999999;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  white-space: nowrap;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px #0000008a;
  font-family: 'SVN-Gilroy';
  & > * {
    width: 100%;
  }
  
  & > img {
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .popup-header{
    cursor: move;
    box-sizing: border-box;
    background: #005993;
    font-family: 'SVN-Gilroy';
    font-size: 14px;
    font-style: normal;
    color: #ffffff;
    display: flex;
    padding: 11px 10px 9px 20px;
    justify-content: space-between;
    align-items: center;
    z-index: 0;
    line-height: 128%;
    text-align: right;
    img {
      z-index: 1;
      cursor: pointer;
    }
  }

  .popup-main {
    position: relative;
    flex-grow: 1;

    &-item {
      position: absolute;
      font-family: 'SVN-Gilroy';
      font-size: 12px;
      font-weight: 500;
      font-style: normal;
      line-height: 1.3;
      color: #ffffff;
      /* opacity: 0.5; */
    }

    .last-modify {
      color: #979797;
      top: 6px;
      left: 20px;
      span {
        color: #ffffff;
      }
    }

    .left-hand {
      bottom: 12px;
      left: 143px;
      opacity: 0.5;
    }

    .right-hand {
      bottom: 12px;
      right: 20px;
      opacity: 0.5;
    }
  }
  .biometric-finger{
    font-family: SVN-Gilroy;
    flex: 4;
    position: relative;
    height: 352px;
    .show-finger{
      .finger{
        position: absolute;
        bottom: 12px;
        left: 12px;
        display: flex;
        justify-content: space-between;
        width: 100%;
        p{
          font-family: SVN-Gilroy;
          font-size: 12px;
          font-weight: 500;
          line-height: 1.3;
          opacity: 0.5;
          color: #ffffff;
        }
        p:nth-child(2){
          padding-right: 31px;
        }
      }
    }
  }

  .inform{
    /* height: 629px; */
    /* height: ${props => props.height === NR ? '300px' : '571px'}; */
    /* height: 573px; */
    /* padding-bottom: 10px; */
    background: white;
    display: flex;
    flex-direction:column;
    .slider{
      position: relative;
      img{
        position: fixed;
        /* top: 5px; */
        cursor: pointer;
        z-index: 9;
        &:first-child{
          left: 11.45px;
        }
        &:last-child{
          right: 11.45px;
        }
      }
    }
    .title_1 {
      font-size: 11px;
      font-weight: bold;
      line-height: 1.26;
      color: #005993;
      text-transform:uppercase;
      padding-left: 30px;
    }

    .title_1.pt-17 {
      margin-bottom: 15px;
    }

    .userName {
      font-size: 24px;
      font-weight: bold;
      line-height: 1.08;
      color: #222222;
      margin: 14px 0 0 0;
    }
    .block_info {
      /*margin: 16px 0 0 0;*/
      .name_type {
        .content {
          font-size: 14px;
          font-weight: 500;
          line-height: 1.25;
          color: #979797;
          margin: 14px 0 0 0;
          white-space: pre-line;
          /* width: 232px; */
          span {
            color: #222222;
          }
        }
      }
    /*.name_type.block_col_2{
      .row.content:first-child{
        color: rgba(215,18,73,1) !important;
        font-weight: bold;
      }
      .row.content:last-child{
        color: rgba(215,18,73,1) !important;
        font-weight: bold;
      }
    }*/
    }
  }

  .bio-data {
    position: relative;
  }

  .pb-51{
    padding: 0 16px 22px 30px;
  }
  .pt-17{
    padding-top: 17px;
  }
  .padding{
    padding: 0 16px 0 30px;
  }
  .face-id{
    .img-border{
      position: absolute;
      bottom: 160px;
      left: 20px;
      /* width: 120px; */
      height: 120px;
      border: 0.3px solid #D6D6D6;
      box-sizing: border-box;
      border-radius: 5px;
      img{
        /* width: 120px; */
        /* height: 120px; */
        height: 100%;
        object-fit: contain;
        /* position: absolute; */
        bottom: 160px;
        left: 20px;
        background: #222222;
        opacity: 0.5;
        border-radius: 5px;
      }
    }
    p{
      font-family: SVN-Gilroy;
      font-size: 12px;
      line-height: 130%;
      color: #FFFFFF;
      opacity: 0.5;
      position: absolute;
      bottom: 136px;
      left: 20px;
    }
    img.ic_zoom{
      width: 14px;
      height: 14px;
      cursor: pointer;
      position: absolute;
      left: 29px;
      bottom: 169px;
    }
  }
  img.bg_finger{
    width: 690px;
    height: 300px;
    background-color: #303030;
    display: block;
  }
  .status{
    display: flex;
    position: absolute;
    bottom: 274px;
    right: 45px;
    width: 200px;
    .update{
      display: flex;
      align-items: center;
      &:first-child{
        display: none;
      }
      &:not(:nth-child(1)){
        padding-left: 10px
      }
    }
    .circle{
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }
    p{
      padding-left: 4px;
      font-size: 12px;
      font-weight: 500;
      line-height: 1.3;
      color: #ffffff;
    }
  }

  .text-ellipst {
    width: 275px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    pointer-events:none;
    }
    .text-ellipst:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 20px;
        z-index: 1;
        pointer-events: initial;
    }

    .text-gdv{
      color: #222222;
    }
    .text-word{
      /* padding-left: 5px; */
      word-break: break-all;
    }
    
    .w-149{
      width: 149px;
    }

    .cover{
      position: relative;
      width: 100%;
      display: flex;
    }
    #tooltip_ {
      position: absolute;
      white-space: pre-line;
      /* width: 55%; */
      left: 40%;
      top: -66%;
      z-index: 999;
      visibility: hidden;
      background-color: #fff;
      padding: 11px;
      -webkit-box-shadow: 0 0 50px 0 rgba(0,0,0,0.3);
      opacity: 0;
      transition: opacity 0.5s ease;
    }

    .text-ellipst:hover + #tooltip_{
      visibility: visible;
      transition: opacity 0.5s ease;
      opacity: 1;
    }
  .text_error{
    img{
      position: absolute;
      bottom: 133px;
      left: 120px;
      opacity: 0.7;
      cursor: pointer;
    }
    .img_hover{
        &:hover {
        p{
          display: block;
          visibility: visible;
          opacity: 1;
          bottom: 107px;
          left: 130px;
        }
      }
      p{
        visibility: hidden;
        position: absolute;
        z-index: 1;
        bottom: 125%;
        left: 50%;
        width: 60px;
        height: 20px;
        background-color: rgba(0,0,0,0.6);
        color: #fff;
        text-align: center;
        padding: 5px 0;
        border-radius: 6px;
        margin-left: -60px;
        /* Fade in tooltip - takes 1 second to go from 0% to 100% opac: */
        opacity: 0;
        transition: opacity 1s;
        display: flex !important;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .scrollbar{
    width: 100%;
    /* height: 228px; */
    /* overflow: auto; */
    /* overflow-x: hidden; */
    /* margin-top: 10px; */
    ${props =>
    ![NR, NR_FINGER].includes(props.dataInform.BioInfosType) ?
      `max-height: 250px;
      overflow-y: auto;`
      :
      ""
  }
    /* padding: 14px 0; */
    padding-bottom: 14px;
    ::-webkit-scrollbar {
      width: 3px;
    }
    ::-webkit-scrollbar-thumb {
      background: #C4C4C4;
      border-radius: 4px;
    }
  }

  .r-margin{
    margin: 0 !important;
  }
`
var _ = require('lodash');
class PopupShowBiometricHistory extends Component {
  state = {
    isShowPopup: false,
    isShowPopupFace: false,
    isShowPopupNote: false,
    dataInformFinger: {},
    dataInformFace: {},
    dataInformNote: {},
    imageLoadErr: true,
    dataInformCustom: this.props.dataInformCustom
  }

  onNextHistory = () => {
    this.props.onNextHistory()
  }

  onPreviousHistory = () => {
    this.props.onPreviousHistory()
  }

  showFingerInfoCustomer = (biotype) => {
    this.setState({
      isShowPopup: true
    });
    let dataRemoveNote, RemoveNote, elmConfirmFinger, clConfirmFinger, TextFingerHistory, IsTextFingerHistory, Face, BioType, CreateAt, ConfirmData, EnrollData, IsActive, IsConfirmFinger, BioActionType, dataEnroll, dataFace, dataCreate, dataBioType, dataConfirm, dataBioActionType, dataIsActive, dataIsConfirmFinger
    // const {data} = this.props
    let { dataInformFinger } = this.state

    let dataCurrentBio = this.props.dataInformCustom.BioDatas.map(item => item)

    dataFace = dataCurrentBio.find(item => item.BioType === FACE)

    dataBioType = dataCurrentBio.find(item => item.BioType === biotype)
    dataConfirm = dataCurrentBio.find(item => item.BioType === biotype && item.ConfirmData)
    dataEnroll = dataCurrentBio.find(item => item.BioType === biotype && item.EnrollData)
    dataBioActionType = dataCurrentBio.find(item => item.BioType === biotype && item.BioActionType)
    dataCreate = dataCurrentBio.find(item => item.BioType === biotype && item.CreateAt)
    dataCurrentBio.forEach(item => {
      if (item.BioType === biotype) {
        dataIsConfirmFinger = item.IsConfirmFinger
        dataIsActive = item.IsActive
        dataRemoveNote = item.RemoveNote
      }
    })

    // CurrentBioData.forEach((bio) => {
    // if(bio.BioType!== FACE){
    Face = dataFace && dataFace.EnrollData
    BioType = dataBioType && dataBioType.BioType
    ConfirmData = dataConfirm && dataConfirm.ConfirmData
    EnrollData = dataEnroll ? (dataEnroll.EnrollData !== null ? dataEnroll.EnrollData : null) : null
    IsActive = dataIsActive
    IsConfirmFinger = dataIsConfirmFinger
    CreateAt = dataCreate && dataCreate.CreateAt
    BioActionType = dataBioActionType && dataBioActionType.BioActionType
    TextFingerHistory = 'FingerHistory'
    RemoveNote = dataRemoveNote && dataRemoveNote

    // }
    // })

    if (BioType === THUMB_LEFT) {
      BioType = dataFingerPrint[THUMB_LEFT].label
    } else if (BioType === THUMB_RIGHT) {
      BioType = dataFingerPrint[THUMB_RIGHT].label
    } else if (BioType === INDEX_LEFT) {
      BioType = dataFingerPrint[INDEX_LEFT].label
    } else if (BioType === INDEX_RIGHT) {
      BioType = dataFingerPrint[INDEX_RIGHT].label
    } else if (BioType === MIDDLE_LEFT) {
      BioType = dataFingerPrint[MIDDLE_LEFT].label
    } else if (BioType === MIDDLE_RIGHT) {
      BioType = dataFingerPrint[MIDDLE_RIGHT].label
    } else if (BioType === RING_LEFT) {
      BioType = dataFingerPrint[RING_LEFT].label
    } else if (BioType === RING_RIGHT) {
      BioType = dataFingerPrint[RING_RIGHT].label
    } else if (BioType === PINKY_LEFT) {
      BioType = dataFingerPrint[PINKY_LEFT].label
    } else if (BioType === PINKY_RIGHT) {
      BioType = dataFingerPrint[PINKY_RIGHT].label
    }


    let elmBioActionFinger
    let index = 0
    if (dataBioType.BioType !== FACE) {
      if (BioActionType === REGISTER) {
        elmBioActionFinger = BioInfosType[REGISTER].content
      }
      else if (BioActionType === DELETE) {
        elmBioActionFinger = BioInfosType[DELETE].dlFinger
      }
      else if (BioActionType === UPDATE) {
        // check close, active finger
        if (dataIsActive) {
          if (dataIsConfirmFinger) {
            elmBioActionFinger = customType[ACTIVE].vlFinger
          } else {
            elmBioActionFinger = customType[ISACTIVE].vlFinger
          }
        } else {
          // check hang finger'
          if (BioActionType === UPDATE) {
            index = 1
            if (index === 1 && dataIsConfirmFinger === false) {
              elmBioActionFinger = customType[ISACTIVE].vlFinger
            } else {
              elmBioActionFinger = customType[HANG].vlFinger
            }
          }
        }
      }
    }
    dataInformFinger.Face = Face
    dataInformFinger.BioActionType = BioActionType
    dataInformFinger.BioType = BioType
    dataInformFinger.CreateAt = CreateAt
    dataInformFinger.FromChannel = dataBioType?.FromChannel
    dataInformFinger.ConfirmData = ConfirmData
    dataInformFinger.EnrollData = EnrollData
    dataInformFinger.IsActiveCurrent = IsActive
    dataInformFinger.IsConfirmFingerCurrent = IsConfirmFinger
    dataInformFinger.IsTextFingerHistory = TextFingerHistory
    dataInformFinger.BioActionFinger = elmBioActionFinger
    dataInformFinger.RemoveNote = dataRemoveNote
  }

  showFaceImage = (biotype) => {
    // this.props.showFaceImage()
    // const { dataInformFace } = this.state

    const faceBio = this.props.dataInformCustom.BioDatas.find(item => item.BioType === FACE)
    const currentBioData = this.props.dataInformCustom.CurrentBioData?.find(item => item.BioType === FACE)
    if (!faceBio) return;

    let title;
    let textStatus;

    if (faceBio.BioActionType === REGISTER) {
      title = titleCloseActiveFace
    }
    else if (faceBio.BioActionType === UPDATE) {
      textStatus = 'Trạng thái khuôn mặt sau cập nhật'
      if (faceBio.EnrollData) {
        title = 'Cập nhật hình ảnh khuôn mặt'
      }
      else {
        title = 'Cập nhật trạng thái khuôn mặt'
      }
    }

    this.setState(
      {
        dataInformFace: {
          Face: faceBio.EnrollData || currentBioData?.EnrollData || undefined,
          IsActive: faceBio.IsActive,
          IsConfirmFinger: faceBio.IsConfirmFinger,
          IsTextFaceHistory: 'FaceHistory',
          FromChannel: faceBio.FromChannel,
          Title: title,
          TextStatus: textStatus
        }
      },
      () => this.setState({ isShowPopupFace: true })
    )

    // this.setState({
    //   isShowPopupFace: true
    // })
    // let Face, IsActive, IsConfirmFinger, dataFace, dataIsActive, dataIsConfirmFinger,TextFaceHistory, title, textStatus
    // let {dataInformFace} = this.state

    // let dataCurrentBio =  this.props.dataInformCustom.BioDatas.map(item => item)
    // dataFace = dataCurrentBio.find(item => item.BioType === FACE)
    // let dataFaceCurrent =  this.props.dataInformCustom.CurrentBioData && this.props.dataInformCustom.CurrentBioData.map(item => item)
    // dataFaceCurrent = dataFaceCurrent && dataFaceCurrent.find(item => item.BioType === FACE)

    // dataCurrentBio.forEach(item => {
    //   if(item.BioType === biotype){
    //     dataIsConfirmFinger = item.IsConfirmFinger
    //     dataIsActive = item.IsActive
    //   }
    // })

    // dataCurrentBio.forEach((bio) => {
    //   // if(bio.BioType!== FACE){
    //     Face =  dataFace && (dataFace.EnrollData !== null ? dataFace.EnrollData : dataFaceCurrent.EnrollData)
    //     IsActive = dataIsActive
    //     IsConfirmFinger = dataIsConfirmFinger
    //     TextFaceHistory = 'FaceHistory'
    //   // }

    //   if(bio.BioActionType === REGISTER) {
    //       title = titleCloseActiveFace
    //   }else if(bio.BioActionType === UPDATE) {
    //     if(bio.EnrollData){
    //       title = 'Cập nhật hình ảnh khuôn mặt'
    //     }else{
    //       title = 'Cập nhật trạng thái khuôn mặt'
    //       textStatus = 'Trạng thái khuôn mặt sau cập nhật'
    //     }
    //   }

    //   // if(bio.BioActionType === REGISTER) {
    //   //   title = titleCloseActiveFace
    //   //   textStatus = statusN
    //   // }else if(bio.BioActionType === UPDATE) {
    //   //   if(bio.EnrollData){
    //   //     title = titleUFace
    //   //     textStatus = statusU
    //   //   }else{
    //   //     title = titleCloseActiveFace
    //   //     textStatus = statusS
    //   //   }
    //   // }
    // })

    // dataInformFace.Face = Face
    // dataInformFace.IsActive = IsActive
    // dataInformFace.IsConfirmFinger = IsConfirmFinger
    // dataInformFace.IsTextFaceHistory = TextFaceHistory
    // dataInformFace.Title = title
    // dataInformFace.TextStatus = textStatus

  }

  showFaceNote = (biotype) => {
    this.setState({
      isShowPopupNote: true
    })
    let dataRemoveNote
    let { dataInformNote } = this.state

    let dataCurrentBio = this.props.dataInformCustom.BioDatas.map(item => item)

    dataCurrentBio.forEach(item => {
      if (item.BioType === biotype) {
        dataRemoveNote = item.RemoveNote
      }
    })
    dataInformNote.RemoveNote = dataRemoveNote
  }

  onCloseNoteHistory = () => {
    this.setState({
      isShowPopup: false
    })
  }
  onCloseFaceHistory = () => {
    this.setState({
      isShowPopupFace: false
    })
  }

  onCloseFaceNote = () => {
    this.setState({
      isShowPopupNote: false
    })
  }
  addDefaultSrc(e) {
    if (this.state.imageLoadErr) {
      this.setState({
        imageLoadErr: false
      })
    }
    e.target.src = face_err
  }

  funcMergeData = (bioDatas, currentBioDatas, dataStatus) => {
    // if(!bioDatas) return {
    //       sameArr: [],
    //       diffArr: currentBioDatas.map(item => item.BioType) || []
    //   }
    //   if(!currentBioDatas) return {
    //       sameArr: [],
    //       diffArr: bioDatas.map(item => item.BioType) || []
    //   }


    currentBioDatas = currentBioDatas ? currentBioDatas : []
    bioDatas = bioDatas ? bioDatas : []

    const bioDatasTypes = dataStatus === REJECT ? [] : bioDatas.map(item => item.BioType);
    const currentBioDatasTypes = currentBioDatas.map(item => item.BioType);

    const sameArr = bioDatasTypes.filter(item => currentBioDatasTypes.includes(item));//["FACE"]

    const diffArrBioDatas = bioDatasTypes.filter(item => !currentBioDatasTypes.includes(item))
    let diffArrCurrentBioDatas = currentBioDatasTypes.filter(item => !bioDatasTypes.includes(item))

    // console.log(bioDatasTypes, currentBioDatasTypes, diffArrCurrentBioDatas)
    let isSame = 0, isMatch = 0
    const sameArrGetData = sameArr.map(item => {
      let itemSameInBioDatas = bioDatas.find(data => data.BioType === item)//{ID: , BioType: "FACE"}
      let itemSameInCurrentBioDatas = currentBioDatas.find(data => data.BioType === item)//{ID: , BioType: "FACE"}

      if ((itemSameInBioDatas.BioActionType === UPDATE || itemSameInBioDatas.BioActionType === DELETE) && (itemSameInCurrentBioDatas.BioActionType === "" || itemSameInCurrentBioDatas.BioActionType === UPDATE || itemSameInCurrentBioDatas.BioActionType === REGISTER)) {
        isSame = 3
      }
      if (isSame === 3) {
        if ((!itemSameInBioDatas.IsConfirmFinger && !itemSameInCurrentBioDatas.IsConfirmFinger) || (itemSameInBioDatas.IsConfirmFinger && itemSameInCurrentBioDatas.IsConfirmFinger)) {
          isSame = 1;
        }

        if (((!itemSameInBioDatas.IsActive && !itemSameInCurrentBioDatas.IsActive) || (itemSameInBioDatas.IsActive && itemSameInCurrentBioDatas.IsActive)) && isSame === 1) {
          isSame = 2;
        }
        if (isSame === 2) {
          if ((itemSameInBioDatas.BioActionType === UPDATE && (itemSameInCurrentBioDatas.BioActionType === "" || itemSameInCurrentBioDatas.BioActionType === UPDATE || itemSameInCurrentBioDatas.BioActionType === REGISTER)) &&
            ((((itemSameInBioDatas.EnrollData === null || itemSameInBioDatas.EnrollData !== null) && itemSameInCurrentBioDatas.EnrollData !== null) || (itemSameInBioDatas.EnrollData === itemSameInCurrentBioDatas.EnrollData)))) {
            // itemSameInBioDatas = itemSameInCurrentBioDatas
            if (itemSameInBioDatas.BioType === FACE && (itemSameInBioDatas.EnrollData === itemSameInCurrentBioDatas.EnrollData)) {
              isMatch = 1
            } else if (itemSameInBioDatas.BioType === FACE && (itemSameInBioDatas.EnrollData !== itemSameInCurrentBioDatas.EnrollData) && itemSameInBioDatas.EnrollData !== null) {
              isMatch = 2
            } else {
              isMatch = 1
            }
            itemSameInBioDatas = bioDatas.find(data => data.BioType === item)
          } else {
            itemSameInBioDatas = bioDatas.find(data => data.BioType === item)
          }
          if (isMatch === 1) {
            isMatch = 0
            // itemSameInBioDatas.["key3"] = "value3"
            itemSameInBioDatas.matchDataType = false
          }
        }
      }
      return {
        itemSameInBioDatas,
        itemSameInCurrentBioDatas,
      }
    })

    // console.log('same', sameArrGetData, isMatch)
    const diffArrBioDatasGetData = diffArrBioDatas.map(item => {
      const itemDiffInBioDatas = bioDatas.filter(items => items.BioType === item)
      return {
        itemDiffInBioDatas
      }
    })

    const diffArrCurrentBioDatasGetData = diffArrCurrentBioDatas.map(item => {
      const itemDiffInCurrentDatas = currentBioDatas.filter(items => items.BioType === item)
      return {
        itemDiffInCurrentDatas
      }
    })

    const obj = {
      sameArr,
      diffArrBioDatas,
      sameArrGetData,
      diffArrBioDatasGetData,
      diffArrCurrentBioDatas,
      diffArrCurrentBioDatasGetData
    }
    return obj
  }

  render() {
    let { dataInformCustom } = this.props
    // JSON.parse(JSON.stringify(dataInformCustom))
    let elmCustomerType
    switch (dataInformCustom.CustomerType) {
      case EXCUST:
        elmCustomerType = customType[EXCUST].value
        break;
      default:
        elmCustomerType = customType[INCUST].value
        break;
    }

    // let elmTypeData, colorTypeData
    // switch (dataInformCustom.BioInfosType) {
    //   case REGISTER:
    //     elmTypeData = BioInfosType[REGISTER].value
    //     colorTypeData = BioInfosType[REGISTER].color
    //     break;
    //   case UPDATE:
    //     elmTypeData = BioInfosType[UPDATE].value
    //     colorTypeData = BioInfosType[UPDATE].colors
    //     break;
    //   case NR:
    //     elmTypeData = BioInfosType[NR].value
    //     colorTypeData = BioInfosType[NR].color
    //     break;
    //   default:
    //     break;
    // }

    const resultArrFinger = []
    // const {data } = this.props
    let bioTypeFinger = ![NR, NR_FINGER].includes(dataInformCustom.BioInfosType) && dataInformCustom.BioDatas.filter(item => item.BioType !== FACE)

    let bioFace = ![NR, NR_FINGER].includes(dataInformCustom.BioInfosType) && dataInformCustom.BioDatas.find(item => item.BioType === FACE)
    let bioFaceCurrent = ![NR, NR_FINGER].includes(dataInformCustom.BioInfosType) && dataInformCustom.CurrentBioData !== null && dataInformCustom.CurrentBioData.find(item => item.BioType === FACE)

    bioTypeFinger = ![NR, NR_FINGER].includes(dataInformCustom.BioInfosType) && bioTypeFinger.map(item => {
      if (item.BioActionType === REGISTER) {
        if (item.IsConfirmFinger)
          return { ...item, img_finger: finger_authen_trans }
        return { ...item, img_finger: finger_register_green }
      } else if (item.BioActionType === UPDATE) {
        if (item.IsConfirmFinger)
          return { ...item, img_finger: finger_authen_blue }
        return { ...item, img_finger: finger_update_blue }
      } else if (item.BioActionType === DELETE) {
        if (item.IsConfirmFinger)
          return { ...item, img_finger: finger_authen_red }
        return { ...item, img_finger: finger_delete_red }
      } else {
        if (item.IsConfirmFinger)
          return { ...item, img_finger: finger_authen_white }
        return { ...item, img_finger: finger_white }
      }
    })

    let biometrictFinger = biometrictFingerDataUpdate.map(item => item)
    ![NR, NR_FINGER].includes(dataInformCustom.BioInfosType) && bioTypeFinger.forEach((item) => {
      biometrictFinger.forEach(typeFingers => {
        if (typeFingers.typeFinger.indexOf(item.BioType) > -1) {
          resultArrFinger.push({ ...typeFingers, img_finger: item.img_finger })
        }
      })
    })

    const { sameArr, diffArrBioDatas, sameArrGetData, diffArrBioDatasGetData, diffArrCurrentBioDatas, diffArrCurrentBioDatasGetData } = this.funcMergeData(dataInformCustom.BioDatas, dataInformCustom.CurrentBioData, dataInformCustom.Status)
    // console.log(sameArr, diffArrBioDatas, sameArrGetData, diffArrBioDatasGetData, diffArrCurrentBioDatasGetData, dataInformCustom.MobileNumber)  
    let elmBioInfosType, colorsInfosType, elmTextDays, typeData
    let index = 0, indexStatus = 0, indexDiffBio = 0, isSame = 0, isMissCase = 0

    let indexx = 0
    // if(dataUser.BioInfosType !== NR && dataUser.BioDatas.length === 0){
    if (![NR, NR_FINGER].includes(dataInformCustom.BioInfosType)) {
      if (dataInformCustom.MobileNumber !== '') {
        elmBioInfosType = BioType[NOT_CHANGE_BIOMETRIC].label
        colorsInfosType = BioInfosType[REGISTER].color
        typeData = mobileFone
        indexx = 1
        indexDiffBio = 10
        isMissCase = 1
      }
      if (!!dataInformCustom.FullNameVi) {
        if (indexx === 1) {
          indexx = 2
        } else {
          elmBioInfosType = BioType[NOT_CHANGE_BIOMETRIC].label
          colorsInfosType = BioInfosType[REGISTER].color
          typeData = fullNameVi
          indexx = 3
          indexDiffBio = 11
          isMissCase = 2
        }
      }
      if (indexx === 2) {
        elmBioInfosType = BioType[NOT_CHANGE_BIOMETRIC].label
        colorsInfosType = BioInfosType[REGISTER].color
        typeData = fullName_mobie
        indexDiffBio = 7
        isMissCase = 3
      }
    }
    // indexx = 6: finger, indexx = 7: face, indexx = 8: face_finger
    if (sameArr.includes(FACE) || sameArr.some(item => item !== FACE)) {
      let findFace = sameArr.find(item => item === FACE)
      let findFinger = sameArr.find(item => item !== FACE)

      if (findFace) {
        index = 1
        sameArrGetData.forEach(item => {
          if (item.itemSameInBioDatas.BioActionType === UPDATE && item.itemSameInBioDatas.BioType === FACE) {
            elmBioInfosType = BioInfosType[UPDATE].vlType
            colorsInfosType = BioInfosType[REGISTER].color
            typeData = BioType[FACE].label
            indexStatus = 1
            indexx = 7
            if (indexx === 7) {
              if (indexDiffBio === 10) {
                elmBioInfosType = BioType[NOT_CHANGE_BIOMETRIC].labelC_B
                colorsInfosType = BioInfosType[REGISTER].color
                typeData = mobie_face
              } else if (indexDiffBio === 11) {
                elmBioInfosType = BioType[NOT_CHANGE_BIOMETRIC].labelC_B
                colorsInfosType = BioInfosType[REGISTER].color
                typeData = fullName_face
              }
            }
            if (indexStatus === 1) {
              if (!item.itemSameInBioDatas.matchDataType && item.itemSameInBioDatas.matchDataType !== undefined) {
                elmBioInfosType = BioType[NOT_CHANGE_BIOMETRIC].namelabel
                colorsInfosType = BioType[NOT_CHANGE_BIOMETRIC].color
                typeData = ""
                isSame = 1
              }
            }
          }
          diffArrBioDatasGetData.forEach(item => {
            item.itemDiffInBioDatas.forEach(items => {
              if (indexStatus === 1) {
                if (items.BioActionType === REGISTER) {
                  elmBioInfosType = BioInfosType[UPDATE].vlType
                  colorsInfosType = BioInfosType[REGISTER].color
                  typeData = BioType[FACE_FINGER].label
                  indexx = 8
                  indexDiffBio = 9
                }
              }
            })
          })

          if (item.itemSameInBioDatas.BioActionType === REGISTER && item.itemSameInBioDatas.BioType === FACE) {
            elmBioInfosType = BioInfosType[REGISTER].vlType
            colorsInfosType = BioInfosType[REGISTER].color
            typeData = BioType[FACE].label
            indexStatus = 8
            indexx = 7
          }
          if (indexStatus === 8) {
            if (!item.itemSameInBioDatas.matchDataType && item.itemSameInBioDatas.matchDataType !== undefined) {
              elmBioInfosType = BioType[NOT_CHANGE_BIOMETRIC].namelabel
              colorsInfosType = BioType[NOT_CHANGE_BIOMETRIC].color
              typeData = ""
            }
          }

          if (item.itemSameInBioDatas.BioActionType === DELETE && item.itemSameInBioDatas.BioType === FACE) {
            elmBioInfosType = BioInfosType[UPDATE].vlType
            colorsInfosType = BioInfosType[REGISTER].color
            typeData = BioType[FACE].label
            indexx = 7
          }

          if (indexx === 7) {
            if (indexDiffBio === 10) {
              elmBioInfosType = BioType[NOT_CHANGE_BIOMETRIC].labelC_B
              colorsInfosType = BioInfosType[REGISTER].color
              typeData = mobie_face
            } else if (indexDiffBio === 11) {
              elmBioInfosType = BioType[NOT_CHANGE_BIOMETRIC].labelC_B
              colorsInfosType = BioInfosType[REGISTER].color
              typeData = fullName_face
            }
          }

          if (indexDiffBio === 7 || isMissCase === 3) {
            if (indexx === 7) {
              elmBioInfosType = BioType[NOT_CHANGE_BIOMETRIC].labelC_B
              colorsInfosType = BioInfosType[REGISTER].color
              typeData = fullName_mobie_face
            } else if (indexx === 8) {
              elmBioInfosType = BioType[NOT_CHANGE_BIOMETRIC].labelC_B
              colorsInfosType = BioInfosType[REGISTER].color
              typeData = fullName_mobie_face_finger
            }
          }
        })
      }
      if (findFinger) {
        if (index === 1 || indexStatus === 1) {
          sameArrGetData.forEach(item => {
            if (item.itemSameInBioDatas.BioActionType === UPDATE) {
              elmBioInfosType = BioInfosType[UPDATE].vlType
              colorsInfosType = BioInfosType[REGISTER].color
              typeData = BioType[FACE_FINGER].label
              indexx = 8
              if (indexx === 8) {
                if (indexDiffBio === 10 || isMissCase === 1) {
                  elmBioInfosType = BioType[NOT_CHANGE_BIOMETRIC].labelC_B
                  colorsInfosType = BioInfosType[REGISTER].color
                  typeData = mobie_face_finger
                } else if (indexDiffBio === 11 || isMissCase === 2) {
                  elmBioInfosType = BioType[NOT_CHANGE_BIOMETRIC].labelC_B
                  colorsInfosType = BioInfosType[REGISTER].color
                  typeData = fullName_face_finger
                }
              }
              if (!item.itemSameInBioDatas.matchDataType && item.itemSameInBioDatas.matchDataType !== undefined) {
                if (isSame === 1) {
                  if (indexStatus === 10) {
                    if (item.itemSameInBioDatas.BioType === FACE && !item.itemSameInBioDatas.matchDataType) {
                      elmBioInfosType = BioInfosType[UPDATE].vlType
                      colorsInfosType = BioInfosType[REGISTER].color
                      typeData = BioType[FINGER].label
                      indexx = 6
                    } else if (item.itemSameInBioDatas.BioType !== FACE && !item.itemSameInBioDatas.matchDataType === undefined) {
                      elmBioInfosType = BioInfosType[UPDATE].vlType
                      colorsInfosType = BioInfosType[REGISTER].color
                      typeData = BioType[FACE].label
                      indexx = 7
                    }
                  } else {
                    elmBioInfosType = BioType[NOT_CHANGE_BIOMETRIC].namelabel
                    colorsInfosType = BioType[NOT_CHANGE_BIOMETRIC].color
                    typeData = ""
                    indexStatus = 11
                  }
                  diffArrBioDatasGetData.forEach(diff => {
                    diff.itemDiffInBioDatas.forEach(check => {
                      if (check.BioActionType === REGISTER) {
                        if (isSame === 1 || indexStatus === 11) {
                          elmBioInfosType = BioInfosType[UPDATE].content
                          colorsInfosType = BioInfosType[REGISTER].color
                          typeData = BioType[FINGER].label
                          indexx = 6
                        }
                      }
                    })
                  })
                } else {
                  elmBioInfosType = BioInfosType[UPDATE].vlType
                  colorsInfosType = BioInfosType[REGISTER].color
                  typeData = BioType[FACE_FINGER].label
                  indexStatus = 9
                  if (item.itemSameInBioDatas.BioType !== FACE && !item.itemSameInBioDatas.matchDataType && item.itemSameInBioDatas.matchDataType !== undefined) {
                    elmBioInfosType = BioInfosType[UPDATE].vlType
                    colorsInfosType = BioInfosType[REGISTER].color
                    typeData = BioType[FACE].label
                    indexx = 7
                  }
                  if (indexStatus === 1) {
                    if (item.itemSameInBioDatas.BioType !== FACE && indexStatus === 11) {
                      elmBioInfosType = BioInfosType[UPDATE].vlType
                      colorsInfosType = BioInfosType[REGISTER].color
                      typeData = BioType[FINGER].label
                      indexx = 6
                    }
                  }
                }
              } else {
                if (isSame === 1) {
                  if (item.itemSameInBioDatas.BioType !== FACE && item.itemSameInBioDatas.matchDataType === undefined) {
                    elmBioInfosType = BioInfosType[UPDATE].vlType
                    colorsInfosType = BioInfosType[REGISTER].color
                    typeData = BioType[FINGER].label
                    indexStatus = 10
                    indexx = 6
                  }
                }
              }

              if (indexStatus === 9 && item.itemSameInBioDatas.BioType === FACE) {
                elmBioInfosType = BioInfosType[UPDATE].vlType
                colorsInfosType = BioInfosType[REGISTER].color
                typeData = BioType[FACE].label
                indexx = 7
              }
            }
            else if (item.itemSameInBioDatas.BioActionType === REGISTER) {
              if (index === 3) {
                elmBioInfosType = BioInfosType[UPDATE].vlType
                colorsInfosType = BioInfosType[REGISTER].color
                typeData = BioType[FACE_FINGER].label
                indexx = 8
              } else {
                elmBioInfosType = BioInfosType[REGISTER].vlType
                colorsInfosType = BioInfosType[REGISTER].color
                typeData = BioType[FACE_FINGER].label
                index = 7
                indexx = 8
              }
            } else if (item.itemSameInBioDatas.BioActionType === DELETE) {
              elmBioInfosType = BioInfosType[UPDATE].vlType
              colorsInfosType = BioInfosType[REGISTER].color
              typeData = BioType[FACE_FINGER].label
              indexx = 8
              if (indexx === 8) {
                if (indexDiffBio === 10 || isMissCase === 1) {
                  elmBioInfosType = BioType[NOT_CHANGE_BIOMETRIC].labelC_B
                  colorsInfosType = BioInfosType[REGISTER].color
                  typeData = mobie_face_finger
                } else if (indexDiffBio === 11 || isMissCase === 2) {
                  elmBioInfosType = BioType[NOT_CHANGE_BIOMETRIC].labelC_B
                  colorsInfosType = BioInfosType[REGISTER].color
                  typeData = fullName_face_finger
                }
              }
              indexStatus = 2
              if (indexStatus === 2) {
                if (isSame === 1) {
                  elmBioInfosType = BioInfosType[UPDATE].vlType
                  colorsInfosType = BioInfosType[REGISTER].color
                  typeData = BioType[FACE_FINGER].label
                  indexx = 8
                }
              }
            }
          })

          if (indexDiffBio === 7 || isMissCase === 3) {
            if (indexx === 6) {
              elmBioInfosType = BioType[NOT_CHANGE_BIOMETRIC].labelC_B
              colorsInfosType = BioInfosType[REGISTER].color
              typeData = fullName_mobie_finger
            } else if (indexx === 7 || isMissCase === 1) {
              elmBioInfosType = BioType[NOT_CHANGE_BIOMETRIC].labelC_B
              colorsInfosType = BioInfosType[REGISTER].color
              typeData = fullName_mobie_face
            } else if (indexx === 8 || isMissCase === 2) {
              elmBioInfosType = BioType[NOT_CHANGE_BIOMETRIC].labelC_B
              colorsInfosType = BioInfosType[REGISTER].color
              typeData = fullName_mobie_face_finger
            }
          }
        } else {
          index = 2
          sameArrGetData.forEach(item => {
            if (item.itemSameInBioDatas.BioActionType === REGISTER) {
              elmBioInfosType = BioInfosType[REGISTER].vlType
              colorsInfosType = BioInfosType[REGISTER].color
              typeData = BioType[FINGER].label
              indexx = 6
            }
            if (item.itemSameInBioDatas.BioActionType === UPDATE) {
              elmBioInfosType = BioInfosType[UPDATE].vlType
              colorsInfosType = BioInfosType[REGISTER].color
              typeData = BioType[FINGER].label
              indexStatus = 7
              indexx = 6
              if (indexStatus === 7) {
                if (!item.itemSameInBioDatas.matchDataType && item.itemSameInBioDatas.matchDataType !== undefined) {
                  if (index === 3) {
                    elmBioInfosType = BioInfosType[UPDATE].vlType
                    colorsInfosType = BioInfosType[REGISTER].color
                    typeData = BioType[FINGER].label
                  } else {
                    elmBioInfosType = BioType[NOT_CHANGE_BIOMETRIC].namelabel
                    colorsInfosType = BioType[NOT_CHANGE_BIOMETRIC].color
                    typeData = ""
                  }
                }
              }
            }

            if (item.itemSameInBioDatas.BioActionType === DELETE) {
              elmBioInfosType = BioInfosType[UPDATE].vlType
              colorsInfosType = BioInfosType[REGISTER].color
              typeData = BioType[FINGER].label
              index = 3
              indexx = 6
            }
            diffArrBioDatasGetData.forEach(diff => {
              diff.itemDiffInBioDatas.forEach(check => {
                if (check.BioActionType === REGISTER) {
                  if (index === 7 || indexStatus === 7) {
                    elmBioInfosType = BioInfosType[UPDATE].content
                    colorsInfosType = BioInfosType[REGISTER].color
                    typeData = BioType[FINGER].label
                    indexx = 6
                  }
                }
              })
            })
            // if(indexDiffBio === 7 && indexx === 6){
            //   elmBioInfosType = BioType[NOT_CHANGE_BIOMETRIC].labelC_B
            //   colorsInfosType = BioInfosType[REGISTER].color
            //   typeData = fullName_mobie_finger
            // }

            if (indexx === 6) {
              if (indexDiffBio === 7) {
                elmBioInfosType = BioType[NOT_CHANGE_BIOMETRIC].labelC_B
                colorsInfosType = BioInfosType[REGISTER].color
                typeData = fullName_mobie_finger
              } else if (indexDiffBio === 10 || isMissCase === 1) {
                elmBioInfosType = BioType[NOT_CHANGE_BIOMETRIC].labelC_B
                colorsInfosType = BioInfosType[REGISTER].color
                typeData = mobie_finger
              } else if (indexDiffBio === 11 || isMissCase === 2) {
                elmBioInfosType = BioType[NOT_CHANGE_BIOMETRIC].labelC_B
                colorsInfosType = BioInfosType[REGISTER].color
                typeData = fullName_finger
              }
            }
          })
        }
      }
    } else if (!sameArr.includes(FACE)) {
      const haveFingerInSameArr = sameArr.find(item => item !== FACE)
      const haveFingerInDiffArrBioDatas = diffArrBioDatasGetData.find(item => item !== FACE)
      const haveFingerInDiffArrCurrentBioDatas = diffArrCurrentBioDatasGetData && diffArrCurrentBioDatasGetData.find(item => item && item.itemDiffInCurrentDatas[0].BioType === FACE)

      if (haveFingerInSameArr && haveFingerInDiffArrBioDatas) {
        //cập nhật và đăng ký bổ sung vân tay
      } else if (haveFingerInSameArr) {
        // cập nhật vân tay
      } else if (haveFingerInDiffArrBioDatas && haveFingerInDiffArrCurrentBioDatas !== undefined) {
        // đăng ký bổ sung vân tay
        if (haveFingerInDiffArrCurrentBioDatas.itemDiffInCurrentDatas[0].BioType === FACE) {
          elmBioInfosType = BioInfosType[UPDATE].vlType
          colorsInfosType = BioInfosType[UPDATE].color
          typeData = BioType[FINGER].label
          indexx = 6
          if (indexx === 6) {
            if (indexDiffBio === 7) {
              elmBioInfosType = BioType[NOT_CHANGE_BIOMETRIC].labelC_B
              colorsInfosType = BioInfosType[REGISTER].color
              typeData = fullName_mobie_finger
            } else if (indexDiffBio === 10 || isMissCase === 1) {
              elmBioInfosType = BioType[NOT_CHANGE_BIOMETRIC].labelC_B
              colorsInfosType = BioInfosType[REGISTER].color
              typeData = mobie_finger
            } else if (indexDiffBio === 11 || isMissCase === 2) {
              elmBioInfosType = BioType[NOT_CHANGE_BIOMETRIC].labelC_B
              colorsInfosType = BioInfosType[REGISTER].color
              typeData = fullName_finger
            }
          }
        }
      } else if (haveFingerInDiffArrBioDatas && haveFingerInDiffArrCurrentBioDatas === undefined) {
      }
    }

    if (diffArrBioDatas.includes(FACE) && diffArrBioDatas.some(item => item === FACE)) {
      let findFace = diffArrBioDatas.find(item => item === FACE)
      let findFinger = diffArrBioDatas.find(item => item !== FACE)
      if (findFace) {
        indexDiffBio = 1
        diffArrBioDatasGetData.forEach(item => {
          item.itemDiffInBioDatas.forEach(items => {
            if (items.BioActionType === REGISTER) {
              elmBioInfosType = BioInfosType[REGISTER].vlType
              colorsInfosType = BioInfosType[REGISTER].color
              typeData = BioType[FACE].label
            }
          })
        })
      }
      if (findFinger) {
        if (indexDiffBio === 1) {
          diffArrBioDatasGetData.forEach(item => {
            item.itemDiffInBioDatas.forEach(items => {
              if (items.BioActionType === REGISTER) {
                indexDiffBio = 3
                elmBioInfosType = BioInfosType[REGISTER].vlType
                colorsInfosType = BioInfosType[REGISTER].color
                typeData = BioType[FACE_FINGER].label
              } else if (items.BioActionType === DELETE) {
                elmBioInfosType = BioInfosType[UPDATE].vlType
                colorsInfosType = BioInfosType[UPDATE].color
                typeData = BioType[FACE_FINGER].label
              }
            })
          })
        } else {
          indexDiffBio = 2
          diffArrBioDatasGetData.forEach(item => {
            // console.log(item.itemDiffInBioDatas)
            item.itemDiffInBioDatas.forEach(items => {
              if (items.BioActionType === REGISTER) {
                elmBioInfosType = BioInfosType[REGISTER].vlType
                colorsInfosType = BioInfosType[REGISTER].color
                typeData = BioType[FINGER].label
              }
            })
          })
        }
      }
    } else if (!diffArrBioDatas.includes(FACE)) {
    }

    if (dataInformCustom.Status === REJECT) {
      if (dataInformCustom.CurrentBioData) {
        if (diffArrCurrentBioDatas.includes(FACE) && diffArrCurrentBioDatas.some(item => item === FACE)) {
          let findFace = diffArrCurrentBioDatas.find(item => item === FACE)
          let findFinger = diffArrCurrentBioDatas.find(item => item !== FACE)
          if (findFace) {
            indexDiffBio = 4
            elmBioInfosType = BioInfosType[UPDATE].vlType
            colorsInfosType = BioInfosType[UPDATE].color
            typeData = BioType[FACE].label
            indexx = 7
          }
          if (findFinger) {
            if (indexDiffBio === 4) {
              elmBioInfosType = BioInfosType[UPDATE].vlType
              colorsInfosType = BioInfosType[UPDATE].color
              typeData = BioType[FACE_FINGER].label
              indexDiffBio = 5
              indexx = 8
            } else {
              elmBioInfosType = BioInfosType[UPDATE].vlType
              colorsInfosType = BioInfosType[UPDATE].color
              typeData = BioType[FINGER].label
              indexDiffBio = 6
              indexx = 6
            }
          }
          if (indexDiffBio === 7 || isMissCase === 3) {
            if (indexx === 6) {
              elmBioInfosType = BioType[NOT_CHANGE_BIOMETRIC].labelC_B
              colorsInfosType = BioInfosType[REGISTER].color
              typeData = fullName_mobie_finger
            } else if (indexx === 7) {
              elmBioInfosType = BioType[NOT_CHANGE_BIOMETRIC].labelC_B
              colorsInfosType = BioInfosType[REGISTER].color
              typeData = fullName_mobie_face
            } else if (indexx === 8) {
              elmBioInfosType = BioType[NOT_CHANGE_BIOMETRIC].labelC_B
              colorsInfosType = BioInfosType[REGISTER].color
              typeData = fullName_mobie_face_finger
            }
          }
        } else if (!diffArrCurrentBioDatas.includes(FACE)) {
        }
      } else {
      }
    }

    if (dataInformCustom.BioInfosType === NR) {
      elmBioInfosType = BioType[NOT_REGISTER_BIOMETRIC].namelabel
      colorsInfosType = BioType[NOT_CHANGE_BIOMETRIC].colorNR
    }
    if (dataInformCustom.BioInfosType === NR_FINGER) {
      elmBioInfosType = BioType[NOT_REGISTER_FINGER].namelabel
      colorsInfosType = BioType[NOT_CHANGE_BIOMETRIC].colorNR
    }

    if (dataInformCustom.CurrentBioData === null && ![NR, NR_FINGER].includes(dataInformCustom.BioInfosType)) {
      const bioDatasRMU = dataInformCustom.BioDatas.map(item => item.BioType);
      if (bioDatasRMU.includes(FACE) && bioDatasRMU.some(item => item === FACE)) {
        let findFace = bioDatasRMU.find(item => item === FACE)
        let findFinger = bioDatasRMU.find(item => item !== FACE)
        if (findFace) {
          dataInformCustom.BioDatas.forEach(item => {
            indexDiffBio = 8
            if (item.BioActionType === REGISTER && item.BioType === FACE) {
              elmBioInfosType = BioInfosType[REGISTER].vlType
              colorsInfosType = BioInfosType[REGISTER].color
              typeData = BioType[FACE].label
              indexx = 7
              indexStatus = 1
              if (indexStatus === 1) {
                if (!item.matchDataType && item.matchDataType !== undefined) {
                  elmBioInfosType = BioType[NOT_CHANGE_BIOMETRIC].namelabel
                  colorsInfosType = BioType[NOT_CHANGE_BIOMETRIC].color
                  typeData = ""
                  isSame = 1
                }
              }
            }
            if (indexDiffBio === 7) {
              if (indexx === 7) {
                elmBioInfosType = BioType[NOT_CHANGE_BIOMETRIC].labelC_B
                colorsInfosType = BioInfosType[REGISTER].color
                typeData = fullName_mobie_face
              }
            }
          })
        }
        if (findFinger) {
          if (indexDiffBio === 8) {
            dataInformCustom.BioDatas.forEach(item => {
              if (item.BioActionType === REGISTER && item.BioType !== FACE) {
                elmBioInfosType = BioInfosType[REGISTER].vlType
                colorsInfosType = BioInfosType[REGISTER].color
                typeData = BioType[FACE_FINGER].label
                indexx = 9
              }
            })
          } else {
            dataInformCustom.BioDatas.forEach(item => {
              if (item.BioActionType === REGISTER && item.BioType !== FACE) {
                elmBioInfosType = BioInfosType[REGISTER].vlType
                colorsInfosType = BioInfosType[REGISTER].color
                typeData = BioType[FINGER].label
                indexx = 6
              }
            })
          }
          if (indexDiffBio === 7 || isMissCase === 3) {
            if (indexx === 6 || isMissCase === 2) {
              elmBioInfosType = BioType[NOT_CHANGE_BIOMETRIC].labelC_B
              colorsInfosType = BioInfosType[REGISTER].color
              typeData = fullName_mobie_finger
            } else if (indexx === 8) {
              elmBioInfosType = BioType[NOT_CHANGE_BIOMETRIC].labelC_B
              colorsInfosType = BioInfosType[REGISTER].color
              typeData = fullName_mobie_face_finger
            }
          }
        }
      }
    }
    let textReason
    switch (dataInformCustom.BioInfosType) {
      case REGISTER:
        elmTextDays = 'Ngày đăng ký:'
        break;
      case UPDATE:
        elmTextDays = 'Ngày cập nhật:'
        break;
      default:
        elmTextDays = 'Ngày ghi nhận:'
        break;
    }

    if (dataInformCustom.ReasonNR !== '') {
      textReason = dataInformCustom.ReasonNR
      indexx = 4
    }
    if (dataInformCustom.ReasonNRFinger !== '') {
      if (indexx === 4) {
        indexx = 5
      } else {
        textReason = dataInformCustom.ReasonNRFinger
      }
    }
    if (indexx === 5) {
      textReason = dataInformCustom.ReasonNR + '. ' + dataInformCustom.ReasonNRFinger
    }
    textReason = parse(`${textReason}`)

    let { dataSlice } = this.props
    let cloneDataSlicePop = _.cloneDeep(dataSlice)
    let cloneDataSliceShift = _.cloneDeep(dataSlice)
    let findIndexShift = cloneDataSliceShift.shift()
    let findIndexLast = cloneDataSlicePop.slice(-1).pop()
    // let findIndexLast = cloneDataSlice[cloneDataSlice.length - 1]
    // console.log(findIndexShift.ID, findIndexLast.ID, dataInformCustom.ID, dataSlice.length, lstBioHistoryList.length)
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          zIndex: 999
        }}>
        <StylePopupShowBiometricHistory ref={ref => this.popupShowBiometricHistory = ref}
          arrNext={this.props.arrNext}
          height={dataInformCustom.BioInfosType} updateCif={dataInformCustom.BioDatas} elmBioInfos={elmBioInfosType} dataInform={dataInformCustom}>
          <div className="popup-header">
            <div className="popup-header-title">Chi tiết lịch sử dữ liệu</div>
            <img
              onClick={() => this.props.onClosePopup()}
              src={icClose} alt="close-popup" />
          </div>
          <div className='inform'>
            <p className='title_1 pt-17'>thông tin khách hàng</p>
            <div className='scrollbar'>
              <div className="container block_info">
                <div className="row pb-51">
                  <div className="col-7 name_type">
                    <div className="row content r-margin">
                      <div className='cover' style={{ cursor: (dataInformCustom.FullNameVi || dataInformCustom.CustomerInfos.FullNameVi || dataInformCustom.CustomerInfos.Name) !== '' && 'pointer' }}>
                        <p>Họ tên KH: &nbsp;</p>
                        <div className='text-gdv text-ellipst'>{dataInformCustom.FullNameVi ? dataInformCustom.FullNameVi : (dataInformCustom.CustomerInfos.FullNameVi ? dataInformCustom.CustomerInfos.FullNameVi : dataInformCustom.CustomerInfos.Name)}</div>
                        {
                          (dataInformCustom.FullNameVi || dataInformCustom.CustomerInfos.FullNameVi || dataInformCustom.CustomerInfos.Name) !== '' && <span id='tooltip_'>{dataInformCustom.FullNameVi ? dataInformCustom.FullNameVi : (dataInformCustom.CustomerInfos.FullNameVi ? dataInformCustom.CustomerInfos.FullNameVi : dataInformCustom.CustomerInfos.Name)}</span>
                        }
                      </div>
                    </div>
                    <div className="row content">
                      <p>Loại khách hàng:&nbsp;
                        <span>{elmCustomerType && elmCustomerType}</span>
                      </p>
                    </div>
                    {
                      dataInformCustom.CustomerType === INCUST && !!dataInformCustom.RegiserAt &&
                      <div className="row content r-margin">
                        <p>Ngày bắt đầu là CBNH:&nbsp;
                          <span>{dataInformCustom.RegiserAt && formatDateTimeToSpecificFormat(dataInformCustom.RegiserAt, formatDateTime.HHmmDDMMYY)}</span>
                        </p>
                      </div>
                    }
                  </div>
                  <div className="col-5 name_type block_col_2">
                    <div className="row content r-margin">
                      <p>Số CIF:&nbsp;
                        <span>{dataInformCustom && dataInformCustom.CustomerInfos.CustomerNumber}</span>
                      </p>
                    </div>
                    {/* <div className="row content" style={{visibility: 'hidden'}}>
                      <p>Ngày đăng ký khách hàng nội bộ &nbsp;
                        <span>{dataInformCustom && formatDateTimeToSpecificFormat(dataInformCustom.CreateAt, formatDateTime.HHmmDDMMYY)}</span>
                      </p>
                    </div> */}
                    <div className="row content">
                      <p>SĐT di động: &nbsp;
                        <span>{dataInformCustom.MobileNumber ? dataInformCustom.MobileNumber : dataInformCustom.CustomerInfos.MobileNumber}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='slider'>
                <img src={ic_previous} alt='ic_previous'
                  onClick={
                    findIndexShift &&
                      ((findIndexShift.ID !== findIndexLast.ID && findIndexShift.ID === dataInformCustom.ID && dataSlice.length !== 0)
                        || (findIndexShift.ID === findIndexLast.ID && findIndexShift.ID === dataInformCustom.ID && dataSlice.length === 1))
                      ? () => { } : this.onPreviousHistory
                  }
                  style={{
                    opacity: findIndexShift &&
                      ((findIndexShift.ID !== findIndexLast.ID && findIndexShift.ID === dataInformCustom.ID && dataSlice.length !== 0))
                      ? '0.4' : (findIndexShift.ID === findIndexLast.ID && findIndexShift.ID === dataInformCustom.ID && dataSlice.length === 1 ? '0' : '1')
                  }} />
                <img src={ic_next} alt='ic_next'
                  onClick={findIndexLast && findIndexLast.ID === dataInformCustom.ID ? () => { } : this.onNextHistory}
                  style={{ opacity: findIndexLast && findIndexLast.ID === dataInformCustom.ID ? (findIndexShift.ID === findIndexLast.ID && findIndexShift.ID === dataInformCustom.ID && dataSlice.length === 1 ? '0' : '0.4') : '1' }} />
              </div>
              <p className='title_1'>Thông tin dữ liệu</p>
              <div className="container block_info">
                <div className="row padding">
                  <div className="col-7 name_type">
                    <div className="row content">
                      <p style={{ paddingRight: '10px' }}>Loại tác nghiệp: &nbsp;
                      {/* <span style={{color: colorTypeData}}>{elmTypeData}</span> */}
                        <span style={{ color: colorsInfosType }}>{elmBioInfosType}</span>
                      </p>
                    </div>
                    <div className="row content">
                      <p>Ngày tác nghiệp: &nbsp;
                      <span>{formatDateTimeToSpecificFormat(dataInformCustom.RequestAt, formatDateTime.HHmmDDMMYY)} </span>
                      </p>
                    </div>
                    <div className="row content">
                      <p>Ngày phê duyệt: &nbsp;
                      <span>{formatDateTimeToSpecificFormat(dataInformCustom.AcceptAt, formatDateTime.HHmmDDMMYY)} </span>
                      </p>
                    </div>
                  </div>
                  <div className="col-5 name_type block_col_2">
                    <div className="row content">
                      <div className='cover' style={{ cursor: !!dataInformCustom.OfficeName && 'pointer' }}>
                        <p>CN/ Phòng GD: &nbsp;</p>
                        {/* <span>{dataInformCustom.OfficeName }</span> */}
                        <div className='text-gdv text-ellipst w-149'>{dataInformCustom.OfficeName}</div>
                        {
                          !!dataInformCustom.OfficeName && <span id='tooltip_'>{dataInformCustom.OfficeName}</span>
                        }
                      </div>
                    </div>
                    <div className="row content">
                      <div className='cover' style={{ cursor: !!dataInformCustom.TellerInfos.UserName && 'pointer' }}>
                        <p>Giao dịch viên: &nbsp;</p>
                        <div className='text-gdv text-ellipst w-149'>{dataInformCustom.TellerInfos.UserName}</div>
                        {
                          !!dataInformCustom.TellerInfos.UserName && <span id='tooltip_'>{dataInformCustom.TellerInfos.UserName}</span>
                        }
                      </div>
                    </div>
                    <div className="row content">
                      <div className='cover' style={{ cursor: !!dataInformCustom.SupervisorInfos?.UserName && 'pointer' }}>
                        <p>Kiểm soát viên: &nbsp;</p>
                        {
                          dataInformCustom.SkipReview ?
                            <div className='text-gdv text-ellipst w-149' style={{ color: "#0fb11f" }}>Auto</div>
                            :
                            <div className='text-gdv text-ellipst w-149'>{dataInformCustom.SupervisorInfos?.UserName}</div>
                        }
                        {
                          !!dataInformCustom.SupervisorInfos?.UserName && <span id='tooltip_'>{dataInformCustom.SupervisorInfos?.UserName}</span>
                        }
                      </div>
                    </div>
                  </div>
                  {
                    !!dataInformCustom.DuplicateFaceNote &&
                    <div className="col-12 name_type block_col_2">
                      <div className="row content">
                        <div className='cover'>
                          <p>Nội dung ghi chú trùng khuôn mặt:&nbsp;
                          <span className='text-gdv text-word' style={{ display: 'block' }}>{parse(`${dataInformCustom.DuplicateFaceNote}`)}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  }
                  {
                    [NR, NR_FINGER].includes(dataInformCustom.BioInfosType) &&
                    <div className="col-12 name_type block_col_2">
                      <div className="row content">
                        <div className='cover' style={{ flexDirection: 'column' }}>
                          <p>{dataInformCustom.ReasonNRFinger ? 'Lý do không đăng ký STH vân tay:' : 'Lý do không đăng ký STH:'} &nbsp;
                            {
                              !!dataInformCustom.ReasonNR
                              ? <span className='text-gdv text-word' style={{ display: 'block' }}>{parse(`${dataInformCustom.ReasonNR + '. ' + dataInformCustom.ReasonNRFinger}`)}</span>
                              : <span className='text-gdv text-word' style={{ display: 'block' }}>{parse(`${dataInformCustom.ReasonNRFinger}`)}</span>
                            }
                          </p>
                        </div>
                      </div>
                    </div>
                  }
                  {
                    (!!dataInformCustom.ReasonNR || !!dataInformCustom.ReasonNRFinger) && (![NR, NR_FINGER].includes(dataInformCustom.BioInfosType) || dataInformCustom.CustomerType === INCUST) &&
                    <div className="col-12 name_type block_col_2">
                      <div className="row content">
                        <div className='cover'>
                          <p>{dataInformCustom.ReasonNRFinger ? 'Lý do không đăng ký STH vân tay:' : 'Lý do không đăng ký STH:'} &nbsp;
                          <span className='text-gdv text-word' style={{ display: 'block' }}>{textReason}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  }
                  {
                    dataInformCustom.CustomerType === INCUST && !!dataInformCustom.RejectRegisterNote &&
                    <div className="col-12 name_type block_col_2">
                      <div className="row content">
                        <div className='cover' style={{ flexDirection: 'column' }}>
                          <p>Lý do không đăng ký đủ 10 vân tay: &nbsp;
                          <span className='text-gdv text-word' style={{ display: 'block' }}>{parse(`${dataInformCustom.RejectRegisterNote}`)}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
          {
            ![NR, NR_FINGER].includes(dataInformCustom.BioInfosType) &&
            <div className="bio-data">
              {
                (elmBioInfosType === BioType[NOT_CHANGE_BIOMETRIC].label || elmBioInfosType === BioType[NOT_CHANGE_BIOMETRIC].namelabel) ? null : <img src={bg_finger_update} alt="finger" className='bg_finger' />
              }
              {
                elmBioInfosType === BioType[NOT_CHANGE_BIOMETRIC].namelabel ? null :
                  bioFace !== undefined &&
                  <div className='face-id'
                    onClick={bioFace !== undefined && bioFace.BioType === FACE && this.state.imageLoadErr && bioFace.BioActionType !== DELETE ? () => this.showFaceImage(bioFace.BioType) : null}>
                    <div className='img-border'>
                      {
                        bioFace !== undefined &&
                        bioFace.BioType === FACE &&
                        <img
                          src={apiFaceHistory(bioFace.EnrollData !== null ? bioFace.EnrollData : bioFaceCurrent.EnrollData)}
                          onError={(e) => this.addDefaultSrc(e)}
                          alt='face'
                        />
                      }
                    </div>
                    <div className='text_error' >
                      {
                        bioFace.BioActionType === DELETE &&
                        <div className='img_hover' onClick={bioFace.BioActionType === DELETE ? () => this.showFaceNote(bioFace.BioType) : null}>
                          <img src={on_form} alt='on_form' />
                          <p>Lý do</p>
                        </div>
                      }
                      <p style={{ color: bioFace.BioActionType === DELETE && 'rgb(243, 25, 25)' }}>{bioFace.BioActionType === DELETE ? 'Xóa khuôn mặt' : 'Khuôn mặt'}</p>
                    </div>
                    {
                      this.state.imageLoadErr && bioFace.BioActionType !== DELETE ?
                        <img src={ic_zoom} alt='ic_zoom' className='ic_zoom'
                          onClick={bioFace !== undefined && bioFace.BioType === FACE ? () => this.showFaceImage(bioFace.BioType) : null} /> : null
                    }

                  </div>
              }
              <div className="popup-main">
                <div className="popup-main-item last-modify">
                  Lần thay đổi gần nhất: <span>{formatDateTimeToSpecificFormat(this.props.data.CreateAt, formatDateTime.HHmmDDMMYY)}</span>
                </div>
                <div className="popup-main-item left-hand">Tay trái</div>
                <div className="popup-main-item right-hand">Tay phải</div>
              </div>

              {
                elmBioInfosType === BioType[NOT_CHANGE_BIOMETRIC].namelabel ? null :
                  <div className='handle-finger'>
                    <div className='fingerprint'>
                      {
                        resultArrFinger.map((item, i) => {
                          return (
                            <FingerPrint
                              key={i}
                              img_finger={item.img_finger}
                              alt='finger'
                              showFingerInfoCustomer={this.showFingerInfoCustomer}
                              {...item}
                            />
                          )
                        })
                      }
                    </div>
                  </div>
              }
              {
                (elmBioInfosType === BioType[NOT_CHANGE_BIOMETRIC].label || elmBioInfosType === BioType[NOT_CHANGE_BIOMETRIC].namelabel) ? null :
                  <div className='status'>
                    {
                      dataFunction.map((functon, i) => {
                        return (
                          <div className='update' key={i}>
                            <div className='circle'
                              style={
                                (() => {
                                  if (functon.type === 1) {
                                    return { backgroundColor: '#1477ec' }
                                  } else if (functon.type === 2) {
                                    return { backgroundColor: '#f31919' }
                                  } else if (functon.type === 3) {
                                    return { backgroundColor: '#0fb11f' }
                                  }
                                })()
                              }
                            ></div>
                            <p>{functon.content}</p>
                          </div>
                        )
                      })
                    }
                  </div>
              }
            </div>
          }
          {
            this.state.isShowPopup &&
            <PopupShowFingerInfoCustomer
              data={this.state.dataInformFinger}
              type='fingerHistory'
              onclose={this.onCloseNoteHistory}
            />
          }
        </StylePopupShowBiometricHistory>
        {
          this.state.isShowPopupFace &&
          <PopupShowFaceImage
            data={this.state.dataInformFace}
            onCloseFaceHistory={this.onCloseFaceHistory}
            type='CloseFaceHistory'
          />
        }
        {
          this.state.isShowPopupNote &&
          <PopupNote
            data={this.state.dataInformNote}
            onCloseFaceNote={this.onCloseFaceNote}
            type='noteRemoveFace'
            title='Nội dung xóa'
          />
        }
      </div>
    )
  }

  componentDidMount() {
    // console.dir(this.popupShowBiometricHistory)
    this.dragElement(this.popupShowBiometricHistory, ".popup-header")
  }

  dragElement = (elmnt, headerClass) => {
    // console.log('header', headerClass)
    var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
    const header = elmnt.querySelector(headerClass)
    if (header) {
      /* if present, the header is where you move the DIV from:*/
      header.onmousedown = dragMouseDown;
    } else {
      /* otherwise, move the DIV from anywhere inside the DIV:*/
      elmnt.onmousedown = dragMouseDown;
    }

    function dragMouseDown(e) {
      e = e || window.event;
      e.preventDefault();
      // get the mouse cursor position at startup:
      pos3 = e.clientX;
      pos4 = e.clientY;
      document.onmouseup = closeDragElement;
      // call a function whenever the cursor moves:
      document.onmousemove = elementDrag;
    }
    const that = this;
    function elementDrag(e) {
      e = e || window.event;
      e.preventDefault();
      // calculate the new cursor position:
      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;
      pos3 = e.clientX;
      pos4 = e.clientY;
      // set the element's new position:
      const tmpTop = (elmnt.offsetTop - pos2);
      const tmpLeft = (elmnt.offsetLeft - pos1);
      const { scrollHeight, scrollWidth, clientHeight, clientWidth } = that.popupShowBiometricHistory;
      const { innerHeight, innerWidth } = window;

      if (tmpTop < scrollHeight / 2 && pos2 > 0) {
        elmnt.style.top = scrollHeight / 2 + "px";
      }
      else if (tmpTop > scrollHeight / 2 + innerHeight - clientHeight) {
        elmnt.style.top = scrollHeight / 2 + innerHeight - clientHeight + "px";
      }
      else {
        elmnt.style.top = tmpTop + "px";
      }

      if (tmpLeft < scrollWidth / 2 && pos1 > 0) {
        elmnt.style.left = scrollWidth / 2 + "px";
      }
      else if (tmpLeft > scrollWidth / 2 + innerWidth - clientWidth) {
        elmnt.style.left = scrollWidth / 2 + innerWidth - clientWidth + "px";
      }
      else {
        elmnt.style.left = tmpLeft + "px";
      }
    }

    function closeDragElement() {
      /* stop moving when mouse button is released:*/
      document.onmouseup = null;
      document.onmousemove = null;
    }
  }
}

export default PopupShowBiometricHistory;
