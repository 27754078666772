// import Axios from 'axios'

// constant
import * as types from './types'
// import { host } from '../../../host'

// // helper
// import catchErrorApi from '../../../helper/catchErrorApi'
// import genGetRequest from '../../../helper/genGetRequest'


export const selectReport = (report) => ({
    type: types.SELECT_REPORT,
    payload: {
        report
    }
})

export const clearDetailsReport = () => ({
    type: types.CLEAR_REPORT
})
