const fromChannels = {
    IPAY: 'IPAY',
    SDB: 'SDB'
}

const fromChannelsDesc = {
    [fromChannels.IPAY]: {
        key: fromChannels.IPAY,
        text: 'IPAY'
    },
    [fromChannels.SDB]: {
        key: fromChannels.SDB,
        text: 'SDB'
    },
}

export {
    fromChannelsDesc
}
export default fromChannels