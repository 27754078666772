import React, {
    useReducer,
    useState,
    useCallback,
    useEffect,
    useMemo,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

// styles
import { extendStylesTable } from './index.styles'
import {
    GridColTools,
    GridBtnTools,
    GridSplitHeadTools,
    WrapGridToolsCustom,
} from '../../controls/Tools/index.styles'

// component
import Header from '../../controls/Header'
import ExportExcel from '../../controls/Tools/ExportExcel'
import ButtonFilter from '../../controls/Tools/ButtonFilter'
import Table from '../../controls/Table'
import SelectStartDate from '../../controls/Tools/SelectStartDate'
import SelectEndDate from '../../controls/Tools/SelectEndDate'
import SelectOfficeV2 from '../../controls/Tools/SelectOfficeV2'
import SelectTransV2 from '../../controls/Tools/SelectTransV2'

// action
import {
    exportListSelfServeByOfficeAndTrans,
    getListSelfServeByOfficeAndTrans,
} from '../../../../../../../actions/reportsAction/detailsReport/numOfTransaction/selfServeReport'

// hooks
import useDidMount from '../../../../../../../helper/Hooks/useDidMount'

// constant
import { ADMIN_ROLE } from '../../../../../../../data/userRole'

const lstTitleCols = [
    'mã CN',
    'Tên CN',
    'số lượng GD tự thực hiện',
    'Số lượng GD KH có điền eForm',
    'Số lượng GD chỉ lấy số vào quầy',
    'Tổng số giao dịch',
]

const lstTrans = [
    {
        key: 'DEPINT',
        value: 'Nộp tiền trong VietinBank',
    },
    {
        key: 'DEPEXT',
        value: 'Nộp tiền ngoài VietinBank',
    },
    {
        key: 'WITHDRAW',
        value: 'Rút tiền',
    },
    {
        key: 'TRANSINT',
        value: 'Chuyển khoản trong VietinBank',
    },
    {
        key: 'TRANSEXT',
        value: 'Chuyển khoản ngoài VietinBank thông thường',
    },
    {
        key: 'TRANSEXTACC',
        value: 'Chuyển khoản nhanh ngoài VietinBank đến số tài khoản (≤300trđ)',
    },
    {
        key: 'TRANSEXTCARD',
        value: 'Chuyển khoản nhanh ngoài VietinBank đến số thẻ (≤300trđ)',
    },
    {
        key: 'SAVINGDEP',
        value: 'Gửi tiết kiệm',
    },
    {
        key: 'WDSVG',
        value: 'Rút tiết kiệm',
    },
    {
        key: 'FCY',
        value: 'Ngoại tệ',
    },
    {
        key: 'CARD',
        value: 'Thẻ',
    },
    {
        key: 'CORPCUST',
        value: 'Khách hàng doanh nghiệp',
    },
    {
        key: 'OTHER',
        value: 'Khác',
    },
    // {
    //     key: 'DIRECTCALL',
    //     value: 'Trống (Vé load CIF)'
    // },
]

// const DEFAULT_FROM_DATE = moment().startOf('days')
// const DEFAULT_TO_DATE = moment().endOf('days')

const ReportSelfServeByOfficeAndTrans = ({ title, codeName, ...props }) => {

    const DEFAULT_FROM_DATE = useMemo(() => moment().startOf('days'), [])
    const DEFAULT_TO_DATE = useMemo(() => moment().endOf('days'), [])

    const dispatch = useDispatch()
    const { detailsReport, userReducer } = useSelector((state) => ({
        detailsReport: state.reports.detailsReport,
        userReducer: state.userReducer,
    }))

    const [stateFilter, dispatchFilter] = useReducer(
        (state, action) => ({ ...state, ...action }),
        {
            fromDate: DEFAULT_FROM_DATE.toDate(),
            toDate: DEFAULT_TO_DATE.toDate(),
            office: null,
            transaction: null,
            service: null,
        }
    )

    const [dataTable, setDataTable] = useState([])

    const [BranchIDs, BranchName, BranchCodes] = useMemo(() => {
        if (!stateFilter.office) return [null, null, null]
        const lenListOffice = stateFilter.office.length
        if (lenListOffice === 0) {
            return [null, null, null]
        }
        if (lenListOffice === 1) {
            const { key, value, BranchCode } = stateFilter.office[0]
            if (Array.isArray(key))
                return [key, value, BranchCode ? [BranchCode] : ['ALL']]
            return [[key], value, BranchCode ? [BranchCode] : ['ALL']]
        }

        const lstBranchId = [],
            lstBranchName = [],
            lstBranchCode = []

        const lstOfficeSort = [...stateFilter.office].sort((a, b) =>
            Number(a.BranchCode) - Number(b.BranchCode)
        )

        lstOfficeSort.forEach((element) => {
            lstBranchId.push(element.key)
            lstBranchName.push(element.value)
            lstBranchCode.push(element.BranchCode)
        })
        return [lstBranchId, lstBranchName.join(', '), lstBranchCode]
    }, [stateFilter.office])

    const ServiceCode = useMemo(() => {
        if (!stateFilter.transaction) return null;
        const lenListTransaction = stateFilter.transaction.length

        if (lenListTransaction === 0) {
            return null
        }
        if (lenListTransaction === 1) {
            const { key, value } = stateFilter.transaction[0]
            // all
            if (Array.isArray(key))
                return key
            return [key]
        }

        const lstSeriveCodes = []

        stateFilter.transaction.forEach((element) => {
            lstSeriveCodes.push(element.key)
        })
        return lstSeriveCodes
    }, [stateFilter.transaction])

    const _handleSubmit = useCallback(() => {
        const { fromDate, toDate, transaction, office } = stateFilter
        dispatch(
            getListSelfServeByOfficeAndTrans({
                FromDate: fromDate.toISOString(),
                ToDate: toDate.toISOString(),
                ServiceCode,
                ReportCodeName: codeName,
                BranchName,
                BranchIDs,
            })
        )
    }, [stateFilter, BranchName, BranchIDs, ServiceCode])

    const _handleExportExcel = useCallback(() => {
        const { fromDate, toDate, transaction, office } = stateFilter
        dispatch(
            exportListSelfServeByOfficeAndTrans({
                FromDate: fromDate.toISOString(),
                ToDate: toDate.toISOString(),
                ServiceCode,
                ReportCodeName: codeName,
                BranchName,
                BranchIDs,
                BranchCodes,
            })
        )
    }, [stateFilter, BranchName, BranchIDs, BranchCodes, ServiceCode])

    useDidMount(() => {
        if (!userReducer.dataLogin) return false
        const { Roles } = userReducer.dataLogin
        if (Roles.includes(ADMIN_ROLE)) return true

        const { fromDate, toDate, transaction, office } = stateFilter
        if (!transaction) return false
        if (!BranchName || !BranchIDs) return false
        dispatch(
            getListSelfServeByOfficeAndTrans({
                FromDate: fromDate.toISOString(),
                ToDate: toDate.toISOString(),
                ServiceCode,
                ReportCodeName: codeName,
                BranchName,
                BranchIDs,
            })
        )
        return true
    }, [userReducer.dataLogin, BranchName, BranchIDs, ServiceCode])

    useEffect(() => {
        // const dataTable = detailsReport.listData.map(item => [
        //     item.BranchCode,
        //     item.BranchName,
        //     item.TotalSelfServ,
        //     item.TotalFillEform,
        //     item.TotalGoToCounter,
        // ])
        setDataTable(detailsReport.listData)
    }, [detailsReport.listData])

    return (
        <div>
            <Header title={title} />
            <WrapGridToolsCustom templateCol="auto 1fr auto" gap="20px">
                <GridSplitHeadTools
                    template={['auto 1.4fr 0.6fr 1fr', 'auto 1fr']}
                >
                    <div>
                        <SelectOfficeV2
                            defaultValue={stateFilter.office}
                            onChange={(office) => dispatchFilter({ office })}
                        />
                        <SelectStartDate
                            defaultValue={stateFilter.fromDate}
                            onChange={(fromDate) =>
                                dispatchFilter({ fromDate })
                            }
                        />
                    </div>
                    <div>
                        {/* <SelectTrans
                            defaultValue={stateFilter.transaction}
                            onChange={(transaction) =>
                                dispatchFilter({ transaction })
                            }
                            lstTrans={lstTrans}
                        /> */}
                        <SelectTransV2
                            onChange={(transaction) =>
                                dispatchFilter({ transaction })
                            }
                            // defaultValue={stateFilter.transaction} 
                            lstTrans={lstTrans}
                        />
                    </div>
                </GridSplitHeadTools>
                <GridColTools>
                    <SelectEndDate
                        defaultValue={stateFilter.toDate}
                        onChange={(toDate) => dispatchFilter({ toDate })}
                    />
                </GridColTools>
                <GridBtnTools>
                    <ButtonFilter
                        data={stateFilter}
                        onClick={_handleSubmit}
                        isDisable={!BranchIDs}
                    />
                    <ExportExcel
                        onClick={_handleExportExcel}
                        isDisable={!BranchIDs}
                    />
                </GridBtnTools>
            </WrapGridToolsCustom>
            <Table
                lstTitleCols={lstTitleCols}
                lstData={dataTable}
                extendStyles={extendStylesTable}
            />
        </div>
    )
}

export default ReportSelfServeByOfficeAndTrans
