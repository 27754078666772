import createOfficeReducer from "./createOfficeReducer";
import filterOfficeReducer from './filterOfficeReducer';
import updateOfficeReducer from './updateOfficeReducer';
import deleteOfficeReducer from './deleteOfficeReducer';

export const officeReducer = {
  createOfficeReducer,
  filterOfficeReducer,
  updateOfficeReducer,
  deleteOfficeReducer
}
