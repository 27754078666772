import React, { Component } from 'react'
import moment from 'moment';
import styled from "styled-components"

const StyledCountDownTimeControl = styled.div`
  font-family: "SVN-Gilroy";
  font-size: 14px;
  font-weight: 500;
  line-height: 1.68;
  color: #222222;
`;

const MAX_HOURS_WAIT = 0;
const MAX_MINUTES_WAIT = 10;

export default class CountDownTimeControl extends Component {
  constructor(props) {
    super(props)

    this.state = {
      time: ""
    }

    this.objInterval = {}
    this._isMounted = false;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data !== this.props.data){
      clearInterval(this.objInterval);
      this.countTime.style.color = "#222222"
      this.getTimeCountDown(nextProps.data)
    }
  }

  setTimeCount = (getStartTime) => {
    const currentTime = moment()

    // years days months hours minutes secons miliseconds
    const duration = moment.duration(currentTime.diff(getStartTime))
    let hrs = duration.hours();
    let mins = duration.minutes();
    const secs = duration.seconds();

    this.setState(
      { time: `${hrs}:${mins}:${secs}` },
      () => {
        const time = this.state.time.split(":")
        // const [hours, minutes, second] = time;

        const [hours, minutes] = time;
        if (Number(hours) > MAX_HOURS_WAIT) {
          this.countTime.style.color = "#d71249"
        }
        if(Number(minutes) >= MAX_MINUTES_WAIT){
          this.countTime.style.color = "#d71249"
        }
      }
    )
  }

  getTimeCountDown = (startTime) => {
    const getStartTime = moment(startTime)
    // getStartTime.subtract(2, 'seconds')
    // console.log(getStartTime)
    this.setTimeCount(getStartTime)

    this.objInterval = setInterval(() => {
      this.setTimeCount(getStartTime)
    }, 1000);
  }

  render() {
    return (
      <StyledCountDownTimeControl ref={ref => this.countTime = ref}>
        {this.state.time}
      </StyledCountDownTimeControl>
    )
  }

  // componentDidUpdate(prevProps, prevState) {
    
  // }


  componentDidMount() {
    this._isMounted = true;
    this._isMounted && this.getTimeCountDown(this.props.data)
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.objInterval)
  }


}
