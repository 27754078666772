import styled from 'styled-components'

export const Popup = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.6);
    z-index: 97;
    overflow: auto;
`
