import * as types from "../../actions/types";

const initialState = {
  listUser: [],
  total: 0,
  success: false,
  dataLogin: {},
  err: '',
  userInfoFromBank: {},
  lstUserFromBank: [],
  alert: '',
  lastConditionFilterUser: {},
  textSearch: '',
  saveUser: {}
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case types.CLEAR_ERR: {
      if (action.reducerTarget === "userReducer") {
        if (!state.err) return state;
        return {
          ...state,
          err: ''
        }
      }
      return state;
    }
    case types.ALERT_CONTENT:
      return {
        ...state,
        alert: action.alert
      }
    case types.ADD_USER:
      return {
        ...state,
        success: action.success,
        err: ''
      }
    case types.ADD_USER_ERR:
      return {
        ...state,
        err: {
          ...action.err
        }
      }
    case types.UPDATE_USER:
      return {
        ...state,
        err: ''
      }
    case types.UPDATE_USER_ERR:
      return {
        ...state,
        err: {
          ...action.err
        }
      }
    case types.DELETE_USER:
      return {
        ...state,
        success: action.success,
        err: ''
      }
    case types.DELETE_USER_ERR:
      return {
        ...state,
        err: action.err
      }
    case types.FORCE_SIGN_OUT_USER_ERR:
      return {
        ...state,
        err: action.err
      }
    case types.FILTER_USER:
      return {
        ...state,
        listUser: action.listUser,
        total: action.total,
        lastConditionFilterUser: action.lastConditionFilterUser,
        err: ''
      }
    case types.FILTER_USER_ERR:
      return {
        ...state,
        err: action.err
      }
    case types.LOGIN_USER:
      return {
        ...state,
        dataLogin: action.data,
        err: ''
      }
    case types.LOGIN_USER_ERR:
      return {
        ...state,
        err: {
          ...state.err,
          ...action.err
        }
      }
    case types.LOGOUT_USER:
      return {
        ...state,
        dataLogin: {},
        err: ''
      }
    case types.LOGOUT_USER_ERR:
      return {
        ...state,
        err: action.err
      }
    case types.SEARCH_USER_TO_ADD_BY_EMAIL:
      return {
        ...state,
        userInfoFromBank: action.userInfoFromBank,
        lstUserFromBank: [action.userInfoFromBank],
        err: ''
      }
    case types.SEARCH_USER_TO_ADD_BY_EMAIL_ERR:
      return {
        ...state,
        err: action.err
      }
    case types.SAVE_TEXT_SEARCH:
      return {
        ...state,
        textSearch: action.textSearch
      }
    case types.CLEAR_TEXT_SEARCH:
      return {
        ...state,
        textSearch: ''
      }
    case types.SAVE_USER_PASS:
      return {
        ...state,
        saveUser: action.dataSave
      }
    case types.SAVE_USER_PASS_ERR:
      return {
        ...state,
        saveUser: ''
      }

    default:
      return state
  }
}