import React, { useReducer, useCallback } from 'react'
import moment from 'moment'

// component
import SelectDateTime from '../SelectDateTime'

const SelectStartDate = ({
    defaultValue: defaultStart,
    onChange: onChangeStart,
    showTimeSelect = true,
    dateFormat = 'dd/MM/yyyy HH:mm',
}) => {
    const [state, dispatch] = useReducer(
        (state, action) => ({ ...state, ...action }),
        {
            startTime: defaultStart || new Date(),
        }
    )

    const _handleOnChangeStart = useCallback((startTime) => {
        dispatch({ startTime })
        onChangeStart(startTime)
    }, [])

    return (
        <SelectDateTime
            defaultValue={state.startTime}
            onChange={_handleOnChangeStart}
            maxDate={moment().toDate()}
            showTimeSelect={showTimeSelect}
            dateFormat={dateFormat}
            label="Từ ngày"
        />
    )
}

export default SelectStartDate
