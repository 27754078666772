import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// styles
import { WrapBioErrorExecute } from './index.styles'

// images
import ic_search_gray from '../../../../images/ic_search_gray.png'

// action
import { getListErrorAcceptBios } from '../../../../actions/bioErrorAction'

// component
import TableBl from './Table/indexBl'

const BioErrorExecute = ({ showPopupCheckBiometric }) => {
    const dispatch = useDispatch()
    const { listBioErrors, error } = useSelector(state => ({
        listBioErrors: state.bioErrorReducer.listBioErrors,
        error: state.bioErrorReducer.error,
    }))
    const [inputSearch, setInputSearch] = useState('');

    const _handleSearch = () => {
        dispatch(getListErrorAcceptBios(inputSearch))
    }

    return (
        <WrapBioErrorExecute>
            <div className='search-bio'>
                <div className="wrap-search">
                    <div className="wrapper-input">
                        <img src={ic_search_gray} alt="search" />
                        <input
                            onKeyPress={(e) => {
                                if (e.charCode !== 13) return true
                                _handleSearch()
                                return false
                            }}
                            value={inputSearch}
                            onChange={(e) => setInputSearch(e.target.value)}
                            placeholder="Nhập số CIF"
                            type="text"
                            autoFocus="autofocus"
                        />
                    </div>
                    <button
                        className={`btn-search${!inputSearch ? ' disabled' : ''}`}
                        onClick={_handleSearch}
                        disabled={!inputSearch}
                    >Tìm kiếm</button>
                </div>
                <div className="error">{error}</div>
            </div>
            {
                listBioErrors.length > 0 &&
                <div className="wrap-table">
                    <TableBl
                        showPopupCheckBiometric={showPopupCheckBiometric}
                    />
                </div>
            }
        </WrapBioErrorExecute>
    )
}

export default BioErrorExecute
