import styled from 'styled-components'

const Bound = styled.div`
    height: calc((9 * 48px) + (9 * 4px));
    margin: 15px 0 20px 0;
    overflow-x: auto;
    overflow-y: hidden;

    .block {
        display: grid;
        grid-template-columns:
            minmax(112px, 1fr)
            minmax(105px, 0.8fr)
            minmax(186px, 1.4fr)
            minmax(124px, 1fr)
            minmax(105px, 0.8fr)
            minmax(124px, 0.9fr)
            minmax(124px, 1fr)
            minmax(124px, 1fr)
            minmax(81px, 0.5fr)
            minmax(124px, 1fr)
            minmax(124px, 1fr)
            minmax(124px, 1fr)
            minmax(108px, 0.78fr);
        align-items: center;
        grid-column-gap: 5px;
        width: max-content;
    }
    .item-row {
        height: 48px;
        background: #ffffff;
        border-radius: 4px;
        margin-bottom: 4px;
        cursor: pointer;
    }
    .date__color {
        color: #c8c8c8;
    }
    .bio-head {
        height: 48px;
        font-family: SVN-Gilroy;
        font-size: 10px;
        font-weight: bold;
        line-height: 1.26;
        color: #005993;
    }
    .bio-body {

        font-family: SVN-Gilroy;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.3;
        color: #222222;
        overflow-y: auto;
        overflow-x: hidden;
        width: max-content;
        height: calc(100% - 48px);

    }

    .first {
        padding-left: 20px;
    }
    .last {
        text-align: right;
    }
    .item-last {
        text-align: right;
        margin-right: 14px;
    }
    .name-custom {
        font-weight: bold;
    }

    .text-ellipst {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        pointer-events:none;
    }
    .w-100 {
        width: 124px;
    }
    .w-118 {
        width: 124px;
    }
    .w-147 {
        width: 186px;
    }
    .w-95 {
        width: 95px;
    }
    .w-72 {
        width: 105px;
    }
    .w-50 {
        width: 112px;
    }
    .text-ellipst:after {
        content: '';
        display: block;
        position: absolute;
        top: -15px;
        right: 0;
        width: 100%;
        height: 48px;
        z-index: 1;
        pointer-events: initial;
    }

    .cover {
        position: relative;
    }

    .self-approval {
        color: rgb(15, 177, 31);
        font-weight: bold;
    }
`

export {
    Bound
}