import styled from 'styled-components'

const WrapApplyLocation = styled.div`
    font-family: 'SVN-Gilroy';
    font-size: 14px;

    .wrap-search {
        margin: 12px 25px 12px 25px;
        display: flex;
        align-items: center;
        height: 40px;
        box-sizing: border-box;

        input {
            flex-grow: 1;
            height: 100%;
            box-sizing: border-box;
            border: 0.5px solid #ededed;
            outline: none;
            color: #222222;
            padding: 11px 12px;
            font-family: SVN-Gilroy;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 128%;
        }

        button {
            outline: none;
            border: none;
            background: #005993;
            width: 40px;
            height: 100%;
            border-radius: 0 4px 4px 0;

            &:hover {
                cursor: pointer;
            }
        }
    }

    .wrapper {
        border: solid 1px #c8c8c8;
        background-color: #fafafa;
        margin: 25px;
        height: 264px;
        overflow: auto;

        .wrap-check {
            display: flex;
            align-items: center;
            min-height: 32px;

            label {
                display: flex;
                align-items: center;
                cursor: pointer;
                font-size: 14px;
                
                .wrap-checkbox {
                    position: relative;

                    input[type=checkbox] { 
                        visibility: hidden; 
                        height: 16px;
                        width: 16px;
                    }

                    input:checked ~ .geek-mark:after { 
                        display: block; 
                    }

                    .geek-mark { 
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 16px;
                        width: 16px;
                        background: #fff;
                        border: solid 0.8px #c8c8c8;
                        box-sizing: border-box;

                        &:after { 
                            content: ""; 
                            position: absolute; 
                            display: none; 
                            left: 4px;
                            bottom: 3px;
                            width: 4px;
                            height: 10px;
                            border: solid #d71249;
                            border-width: 0 3px 3px 0;
                            transform: rotate(45deg); 
                        }
                    } 
                }

                .checkbox-name {
                    margin-left: 10px;
                }

                &.main-check {
                    margin-left: 10px;
                    .checkbox-name {
                        font-weight: bold;
                        line-height: 128%;
                        color: #005993;
                    }
                }

                &.sub-check {
                    margin-left: 20px;
                    .checkbox-name {
                        line-height: 128%;
                        color: #000000;
                    }
                }
            }

            .wrap-btn-show-children {
                margin-left: 5px;
                padding: 5px;
                .checky {
                    width: 6px;
                    height: 10px;
                    background-color: #005993;
                    clip-path: polygon(0 0, 0% 100%, 100% 50%);
                    
                    &.show {
                        transform: rotate(90deg);
                    }
                }

                &:hover {
                    cursor: pointer;
                }
            }

        }
    }

    & > button{
        float: right;
        margin: 0 40px 24px 0;
    }
`;

export {
    WrapApplyLocation
}