import React, { Component } from 'react'
import styled from 'styled-components'
const Bound = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-family: 'SVN-Gilroy';
    font-size: 14px;
    font-weight: 500;
    color: #222222;
    margin-top: 20px;
    p{
        margin-right: 12px;
    }    
    .switch {
        position: relative;
        display: inline-block;
        width: 50px;
        height: 26px;
    }
    .switch input { 
        opacity: 0;
        width: 0;
        height: 0;
    }
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #e9e9e9;        
    }
    .slider:before {
        position: absolute;
        content: "";
        height: 22px;
        width: 22px;
        left: 2px;
        bottom: 2px;
        background-color: #ffffff;        
    }
    input:checked + .slider {
        background-color: #1477ec;
    }    
    input:checked + .slider:before {
        -webkit-transform: translateX(24px);
        -ms-transform: translateX(24px);
        transform: translateX(24px);
    }
    .slider.round {
        border-radius: 16px;
    }
    .slider.round:before {
        border-radius: 50%;
    }    
`
export default class CheckOnOffControl extends Component {
    state = {
        checked: this.props.defaulVal? this.props.defaulVal: false
    }
    handleChange = (e) => {
        // console.log(e.target.checked)
        this.setState({
            checked: !this.state.checked
        }, () => this.props.getStatus(this.state.checked));
    }
    render() {
        return (
            <Bound>
                <p>Bật/tắt quảng cảo</p>                              
                <label className="switch">
                    <input 
                        type="checkbox" 
                        defaultChecked={this.state.checked}
                        onChange={this.handleChange}
                    />
                    <span className="slider round"></span>
                </label>      
            </Bound>
        )
    }
}

