import styled from 'styled-components'

const WrapPopupStyles = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 1024px;
    overflow: auto;
`;

export {
    WrapPopupStyles
}