import React, { Component } from 'react';
import styled from 'styled-components';

import ButtonConfirmPopup from '../MainScreen/Control/ButtonConfirmPopup';
import { color } from '../../../data/store';
import ic_question from '../../../images/Icons_Notification_Question.svg'

const Bound = styled.div`
    padding: 20px 32px 15px 32px;
    background-color: #fafafa;
    width: ${props => props.width || '256.02px'};
    height: auto;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-40%, -50%);
    font-family: 'SVN-Gilroy';
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    z-index: 999;
    box-shadow: 0px 0px 10px 0px #0000008a;
    border-radius: ${props => props.borderRadius || '10px'};
    animation: fadeInConfirm .6s;
    @keyframes fadeInConfirm {
        0%   { opacity:.7; top: 25% }
        100% { opacity:1; top: 40% }
    }
    .row_question{
        display:flex;
        align-items: center;
        justify-content: center;
        height:53px;
        .img_question{
            background-image: url(${ic_question});
            background-position:center center;
            background-repeat: no-repeat;
            background-size:cover;
            width: 53px;
            height: 53px;
            object-fit: contain;
        }
    }
    .row_title{
        display:flex;
        align-items: center;
        justify-content: center;
        margin: 20px 0 0 0;
        flex-direction:column;
        font-size: 16px;
        .title{
            line-height: 1.13;
            color: #222222;
            text-align: center;
        }
        b {
            width: 100%;
            overflow:hidden;
            display: flex;
            justify-content: center;
            text-align: center;
            margin: 10px 0 0 0;
        }
        .contentBlue {
            margin: 12px 0 0 0;
            font-size: 14px;
            font-weight: 500;
            line-height: 1.25;
            color: #005993;
            text-align: center;
            width: 248px;
        }
        .contentAlert{
            font-size: 14px;
            font-weight: 500;
            line-height: 1.2;
            color: ${props => props.colorContent || '#f31919'};
            text-align: center;
            margin: 12px 0 0 0;
            text-align: center;
    }
    }
    .row_btn_confirm{
        display:flex;
        align-items: center;
        justify-content: center;
        margin: 25px 0 0 0;
        .btn_exit{
            margin: 0 20px 0 0;
        }
    }
`
export default class PopupConfirm extends Component {
    render() {
        return (
            <div
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    zIndex: 999
                }}>
                <Bound
                    colorContent={this.props.colorContent}
                    width={this.props.width}>
                    <div className="row_question">
                        <span className='img_question'></span>
                    </div>
                    <div className="row_title">
                        <p className='title'>{this.props.title}</p>
                        <b>
                            {
                                this.props.nameData ?
                                    `"${this.props.nameData}"`
                                    : this.props.content
                                // this.props.nameData ? 
                                // `"${this.props.nameData}"`:
                                // (this.props.nameKiosk?
                                // `"${this.props.nameKiosk}"`:
                                // `"${this.props.nameLCD}"`
                                // )
                            }
                        </b>
                        {
                            this.props.contentBlue &&
                            <p className='contentBlue'>{this.props.contentBlue}</p>
                        }
                        <p className='contentAlert'>
                            {this.props.contentDelete}
                        </p>
                    </div>
                    <p></p>
                    <div className="row_btn_confirm">
                        <div className="btn_exit">
                            <ButtonConfirmPopup
                                titleConfirm='HỦY BỎ'
                                bg_color={this.props.bg_color_close ? this.props.bg_color_close : 'rgb(215, 18, 73)'}
                                onClick={() => this.props.cancelAction()}
                            />
                        </div>
                        <div className="btn_accept">
                            <ButtonConfirmPopup
                                titleConfirm='XÁC NHẬN'
                                bg_color={color.blue93}
                                onClick={() => { this.props.acceptAction(this.props.dataNeedDelete) }}
                            />
                        </div>
                    </div>
                </Bound>
            </div>
        )
    }
}

