import React, {
    useReducer,
    useState,
    useCallback,
    useEffect,
    useMemo,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

// component
import Header from '../../controls/Header'
import ExportExcel from '../../controls/Tools/ExportExcel'
import ButtonFilter from '../../controls/Tools/ButtonFilter'
import Table from '../../controls/Table'
import SelectStartDate from '../../controls/Tools/SelectStartDate'
import SelectEndDate from '../../controls/Tools/SelectEndDate'
import SelectOfficeV2 from '../../controls/Tools/SelectOfficeV2'

// styles
import { extendStylesTable } from './index.styles'
import {
    WrapGridTools,
    GridColTools,
    GridBtnTools,
} from '../../controls/Tools/index.styles'

// action
import {
    getListNumOfSelfServeAuthNotSuccess,
    exportListNumOfSelfServeAuthNotSuccess,
} from '../../../../../../../actions/reportsAction/detailsReport/numOfTransaction/kioskTransactionFailed'

// hooks
import useDidMount from '../../../../../../../helper/Hooks/useDidMount'

// constant
import { ADMIN_ROLE } from '../../../../../../../data/userRole'

const lstTitleCols = [
    'mã CN',
    'Tên CN',
    'Số lượng GD tự thực hiện -  xác thực vân tay không thành công',
]

// const DEFAULT_FROM_DATE = moment().startOf('days')
// const DEFAULT_TO_DATE = moment().endOf('days')

const ReportNumOfSelfServeNotSuccess = ({ title, codeName, ...props }) => {
    const DEFAULT_FROM_DATE = useMemo(() => moment().startOf('days'), [])
    const DEFAULT_TO_DATE = useMemo(() => moment().endOf('days'), [])

    const dispatch = useDispatch()
    const { detailsReport, userReducer } = useSelector((state) => ({
        detailsReport: state.reports.detailsReport,
        userReducer: state.userReducer,
    }))

    const [stateFilter, dispatchFilter] = useReducer(
        (state, action) => ({ ...state, ...action }),
        {
            fromDate: DEFAULT_FROM_DATE.toDate(),
            toDate: DEFAULT_TO_DATE.toDate(),
            office: null,
            transaction: null,
            service: null,
        }
    )

    const [dataTable, setDataTable] = useState([])

    const [BranchIDs, BranchName, BranchCodes] = useMemo(() => {
        if (!stateFilter.office) return [null, null, null]
        const lenListOffice = stateFilter.office.length
        if (lenListOffice === 0) {
            return [null, null, null]
        }
        if (lenListOffice === 1) {
            const { key, value, BranchCode } = stateFilter.office[0]
            if (Array.isArray(key))
                return [key, value, BranchCode ? [BranchCode] : ['ALL']]
            return [[key], value, BranchCode ? [BranchCode] : ['ALL']]
        }

        const lstBranchId = [],
            lstBranchName = [],
            lstBranchCode = []

        const lstOfficeSort = [...stateFilter.office].sort((a, b) =>
            Number(a.BranchCode) - Number(b.BranchCode)
        )

        lstOfficeSort.forEach((element) => {
            lstBranchId.push(element.key)
            lstBranchName.push(element.value)
            lstBranchCode.push(element.BranchCode)
        })
        return [lstBranchId, lstBranchName.join(', '), lstBranchCode]
    }, [stateFilter.office])

    const _handleSubmit = useCallback(() => {
        const { fromDate, toDate, transaction, office } = stateFilter
        dispatch(
            getListNumOfSelfServeAuthNotSuccess({
                FromDate: fromDate.toISOString(),
                ToDate: toDate.toISOString(),
                ReportCodeName: codeName,
                BranchName,
                BranchIDs,
            })
        )
    }, [stateFilter, BranchName, BranchIDs])

    const _handleExportExcel = useCallback(() => {
        const { fromDate, toDate, office } = stateFilter
        dispatch(
            exportListNumOfSelfServeAuthNotSuccess({
                FromDate: fromDate.toISOString(),
                ToDate: toDate.toISOString(),
                ReportCodeName: codeName,
                BranchName,
                BranchIDs,
                BranchCodes,
            })
        )
    }, [stateFilter, BranchName, BranchIDs, BranchCodes])

    useDidMount(() => {
        if (!userReducer.dataLogin) return false
        const { Roles } = userReducer.dataLogin
        if (Roles.includes(ADMIN_ROLE)) return true

        if (!BranchName || !BranchIDs) return false
        dispatch(
            getListNumOfSelfServeAuthNotSuccess({
                FromDate: DEFAULT_FROM_DATE.toISOString(),
                ToDate: DEFAULT_TO_DATE.toISOString(),
                ReportCodeName: codeName,
                BranchName,
                BranchIDs,
            })
        )
        return true
    }, [userReducer.dataLogin, BranchName, BranchIDs])

    useEffect(() => {
        // const dataTable = detailsReport.listData.map(item => [
        //     item.BranchCode,
        //     item.BranchName,
        //     item.TotalUnsuccess,
        // ])
        setDataTable(detailsReport.listData)
    }, [detailsReport.listData])

    return (
        <div>
            <Header title={title} />
            <WrapGridTools numOfCol={5}>
                <GridColTools templateRow="none">
                    <SelectOfficeV2
                        defaultValue={stateFilter.office}
                        onChange={(office) => dispatchFilter({ office })}
                    />
                </GridColTools>
                <GridColTools templateRow="none">
                    <SelectStartDate
                        defaultValue={stateFilter.fromDate}
                        onChange={(fromDate) => dispatchFilter({ fromDate })}
                    />
                </GridColTools>
                <GridColTools templateRow="none">
                    <SelectEndDate
                        defaultValue={stateFilter.toDate}
                        onChange={(toDate) => dispatchFilter({ toDate })}
                    />
                </GridColTools>
                <GridColTools templateRow="none">
                    <ButtonFilter
                        data={stateFilter}
                        onClick={_handleSubmit}
                        isDisable={!BranchIDs}
                    />
                    <ExportExcel
                        onClick={_handleExportExcel}
                        isDisable={!BranchIDs}
                    />
                </GridColTools>
            </WrapGridTools>
            <Table
                lstTitleCols={lstTitleCols}
                lstData={dataTable}
                extendStyles={extendStylesTable}
                numOfRow={9}
            />
        </div>
    )
}

export default ReportNumOfSelfServeNotSuccess
