import styled from 'styled-components'

const Button = styled.div`
    border: none;
    outline: none;
    padding: 8px 12px;
    text-transform: uppercase;
    font-family: SVN-Gilroy;
    font-style: normal;
    font-weight: bold;
    font-size: 12.5px;
    line-height: 120%;
    letter-spacing: 0.25px;
    color: #ffffff;
    background: #005993;
    border-radius: 18px;
    height: 32px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    white-space: nowrap;
    pointer-events: ${(props) => (props.isDisable ? 'none' : 'auto')};
    opacity: ${(props) => (props.isDisable ? '0.4' : '1')};

    &:hover {
        cursor: ${(props) => (props.isDisable ? 'auto' : 'pointer')};
    }
`

export { Button }
