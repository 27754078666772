import styled from 'styled-components'
const Bound = styled.div`
    height: 48px;
    display: grid;
    grid-template-columns: 170px 190px 220px 1fr;
    grid-gap: 10px;
    background: #FFFFFF;
    border-radius: 4px;
    margin: 0 0 4px 0;
    align-items: center;    
    .name, .role, .branch {
        font-family: 'SVN-Gilroy';
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 128%;
        color: #222222;
    }
    .name{
        padding-left: 20px;
    }
    .icon-check{
        display: flex;
        justify-content: flex-end;
        padding-right: 15px;
        img {        
            :hover {
                cursor: pointer;
            }
        }
    }
`
export { Bound }
