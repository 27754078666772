import styled from 'styled-components'

const Wrapper = styled.div`
    width: 760px;
    height: 560px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    font-family: 'SVN-Gilroy';
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    background-color: #fafafa;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
`;

export {
    Wrapper
}
