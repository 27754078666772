import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// styles
import { WrapOverlay, PopupConfirmResetWrapDiv } from './index.styles'

// component
import ButtonConfirmPopup from '../../Control/ButtonConfirmPopup'
import PopupAlert from '../../../Popup/PopupAlert'
import Modal from '../../../../../helper/Modal'

// action
import { removeCounterLink, getCounterByOffice } from '../../../../../actions/counterAction'

// image
import ic_close from '../../../../../images/ic_close.svg'

const PopupConfirmReset = ({
    counterInfos,
    setIsShowConfirmReset,
    currentPage,
}) => {
    const dispatch = useDispatch()

    const { userReducer } = useSelector((state) => ({
        userReducer: state.userReducer,
    }))

    const [isShowErrorReset, setIsShowErrorReset] = useState(false)
    const [dataError, setDataError] = useState({})

    useEffect(() => {
        document.body.style.overflowX = 'hidden'
        return () => {
            document.body.style.overflowX = 'visible'
        }
    }, [])


    const _callbackResetSuccess = useCallback(() => {
        dispatch(
            getCounterByOffice(userReducer.dataLogin.OfficeID, 8, currentPage)
        )
        setIsShowConfirmReset(false)
    }, [])

    const _callbackResetError = useCallback((code) => {
        switch(code) {
            case 21:
                setDataError({
                    content:
                        'Không thể thoát quầy vì quầy đang phục vụ khách hàng',
                    iconData: ic_close,
                })
                break;
            case 22:
                setDataError({
                    content:
                        'Không thể thoát quầy vì quầy đang ở trạng thái giao dịch',
                    iconData: ic_close,
                })
                break;
            default:
                break;
        }
        setIsShowErrorReset(true)
    }, [])

    const _handleResetCounter = () => {
        dispatch(
            removeCounterLink(
                counterInfos.ID,
                _callbackResetSuccess,
                _callbackResetError
            )
        )
    }

    return (
        <WrapOverlay>
            {
                !isShowErrorReset &&
                <PopupConfirmResetWrapDiv>
                    <div className="row_question">
                        <span className="img_question"></span>
                    </div>
                    <div className="row_title">
                        <p className="title">Xác nhận thoát quầy giao dịch</p>
                        <p className="contentAlert">
                            <span>Quầy sẽ ngắt kết nối với các thiết bị</span>
                            <span>đã liên kết</span>
                        </p>
                    </div>
                    <p></p>
                    <div className="row_btn_confirm">
                        <div className="btn_exit">
                            <ButtonConfirmPopup
                                titleConfirm="HỦY BỎ"
                                bg_color="rgb(215, 18, 73)"
                                onClick={() => setIsShowConfirmReset(false)}
                            />
                        </div>
                        <div className="btn_accept">
                            <ButtonConfirmPopup
                                titleConfirm="XÁC NHẬN"
                                bg_color="var(--peacock-blue)"
                                onClick={_handleResetCounter}
                            />
                        </div>
                    </div>
                </PopupConfirmResetWrapDiv>
            }
            <Modal isShow={isShowErrorReset}>
                <PopupAlert
                    data={dataError}
                    bgColor="rgba(0,0,0,0.6)"
                    outPopup={() => {
                        setIsShowErrorReset(false)
                        setIsShowConfirmReset(false)
                    }}
                />
            </Modal>
        </WrapOverlay>
    )
}

export default PopupConfirmReset
