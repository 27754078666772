import * as types from "../../actions/types";

const initialState = {
  success: false,
  err: ''
}

export default function createOfficeReducer(state = initialState, action) {
  switch (action.type) {
    case types.CREATE_NEW_OFFICE:
      return {
        ...state,
        success: action.success,
        err: ''
      }
    case types.CREATE_NEW_OFFICE_ERR:
      return {
        ...state,
        err: action.err
      }
    case types.RESET_STATE_OFFICE: 
      return initialState
    default:
      return state
  }
}