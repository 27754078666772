import React, { useState, useEffect, useCallback } from 'react'

// styles
import { extendStylesDropdownTrans } from './index.styles'

// component
import DropDownV2 from '../DropDownV2'

const itemAllTrans = {
    key: 'ALL',
    value: 'Tất cả giao dịch',
}

const SelectTransV2 = ({
    defaultValue,
    onChange,
    isNotSelectAll,
    lstTrans = [],
}) => {

    const [lstServices, setLstServices] = useState([])
    const [servicesSelected, setServicesSelected] = useState(
        defaultValue || [itemAllTrans]
    )

    const _handleOnChange = useCallback((arr) => {
        const isAllInArr = arr.some((item) => item.key === itemAllTrans.key)
        if (isAllInArr) {
            const allTransSubmit = {
                key: [],
                value: 'Tất cả giao dịch',
            }
            setServicesSelected([itemAllTrans])
            onChange([allTransSubmit])
            return
        }

        setServicesSelected(arr)
        onChange(arr)
    }, [])

    useEffect(() => {
        setLstServices(lstTrans)
        if (isNotSelectAll && !servicesSelected) {
            setServicesSelected([lstTrans[0]])
            return
        }
    }, [lstTrans])


    return (
        <DropDownV2
            lstItemFixed={isNotSelectAll ? [] : [itemAllTrans]}
            lstItems={lstServices}
            defaultValue={servicesSelected}
            onChange={_handleOnChange}
            haveSearch={false}
            extendStyles={extendStylesDropdownTrans}
            label="Loại GD"
            placeHolderSelect="Chọn giao dịch"
            isSelectMultiple={true}
            textMultipleSelect={(lstSelected) => 'Nhiều loại giao dịch'}
        />
    )
}

export default SelectTransV2
