import { alertLogout, showErrConnection, showErrorTimeoutApi } from "../actions/commonAction"


const  catchErrorApi = (err, dispatch, typeAction, callBackHandleError400) => {
    
    // UNAUTHORIZED
    if (err.response && err.response.status === 403) {
        dispatch(alertLogout())
        return
    }

    // TIMEOUT
    if (typeof(err) === 'object' && err.code === "ECONNABORTED"){
        dispatch(showErrorTimeoutApi())
        return;
    }

    // BAD REQUEST
    if (err.response && err.response.status === 400 && err.response.data) {
        const isCatchedError400 = callBackHandleError400()
        if (isCatchedError400) return;
    }

    // OTHER ERROR
    console.dir(err)
    dispatch(showErrConnection())
}

export default catchErrorApi;