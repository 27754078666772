import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { css } from 'styled-components'

// styles
import { extendStylesDropdownChannel } from './index.styles'

// component
import DropDown from '../DropDown'

// action
import { getListChannel } from '../../../../../../../../actions/commonAction';

// helper
import getChannelName from '../../../../../../../../helper/getChannelName';

// data
// import { fromChannelsDesc } from '../../../../../../../../data/fromChannels';

const itemAllChannel = {
    key: undefined,
    value: 'Tất cả',
}

// const lstChannel = Object.values(fromChannelsDesc).map(({ key, text }) => ({ key, value: text }))


const labels = {
    KENH_GIAO_DICH: 'KENH_GIAO_DICH',
    KENH_GIAO_DICH_TRUOC: 'KENH_GIAO_DICH_TRUOC',
    KENH_GIAO_DICH_SAU: 'KENH_GIAO_DICH_SAU',
    KENH_GIAO_DICH_FACE: 'KENH_GIAO_DICH_FACE',
    KENH_GIAO_DICH_FACE_TRUOC: 'KENH_GIAO_DICH_FACE_TRUOC',
    KENH_GIAO_DICH_FACE_SAU: 'KENH_GIAO_DICH_FACE_SAU',
}

const labelsDesc = {
    [labels.KENH_GIAO_DICH]: {
        title: 'Kênh tác nghiệp'
    },
    [labels.KENH_GIAO_DICH_TRUOC]: {
        title: <>
            <div>Kênh tác nghiệp vân tay</div>
            <div>(KH đăng ký trước)</div>
        </>
    },
    [labels.KENH_GIAO_DICH_SAU]: {
        title: <>
            <div>Kênh tác nghiệp vân tay</div>
            <div>(KH đăng ký sau)</div>
        </>
    },
    [labels.KENH_GIAO_DICH_FACE]: {
        title: 'Kênh đăng ký khuôn mặt'
    },
    [labels.KENH_GIAO_DICH_FACE_TRUOC]: {
        title: <>
            <div>Kênh tác nghiệp khuôn mặt</div>
            <div>(KH đăng ký trước)</div>
        </>
    },
    [labels.KENH_GIAO_DICH_FACE_SAU]: {
        title: <>
            <div>Kênh tác nghiệp khuôn mặt</div>
            <div>(KH đăng ký sau)</div>
        </>
    },
}

const SelectChannel = ({ defaultValue, onChange, label = labels.KENH_GIAO_DICH, extendStyles }) => {
    const dispatch = useDispatch()

    const { listChannel } = useSelector(state => ({
        listChannel: state.commonReducer.listChannel
    }))

    const [lstItems, setLstItems] = useState([])
    const [value, setValue] = useState(defaultValue || itemAllChannel)

    const _handleOnChange = useCallback((obj) => {
        setValue(obj)
        onChange(obj)
    }, [])

    useEffect(() => {
        dispatch(getListChannel())
    }, []);

    useEffect(() => {
        const lstItems = listChannel.map(({ Channel }) => ({ key: Channel, value: getChannelName(Channel) }))
        // setLstItems([itemAllChannel, ...lstChannel])
        setLstItems([itemAllChannel, ...lstItems])
    }, [listChannel])

    return (
        <DropDown
            lstItemFixed={[]}
            lstItems={lstItems}
            defaultValue={value}
            onChange={_handleOnChange}
            haveSearch={false}
            extendStyles={css`${extendStylesDropdownChannel}${extendStyles}`}
            maxNumShowDropdown={lstItems.length}
            label={labelsDesc[label]?.title}
        />
    )
}

export default SelectChannel
