import styled from 'styled-components'

const PopupTimeoutModalStyles = styled.div`
    z-index: 12;
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -20%);
    background: #fafafa;
    border-radius: 10px;
    /* box-shadow: 0 0 20px #000; */
    padding: 0 63px;
    text-align: center;
    box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.23);

    & * {
        text-align: center;
        font-family: SVN-Gilroy;
    }

    .title {
        /* margin-top: 28px; */
        /* margin-bottom: 32px; */
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 21px;
        color: #005993;
        white-space: nowrap;
        /* width: 19ch; */
    }

    .title:first-child {
        margin-top: 28px;
    }

    .title.last {
        margin-bottom: 32px;
    }

    .content {
        margin-bottom: 39px;

        .time-count {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 130%;
            color: gray;

            span {
                color: #222222;
            }
        }
    }

    button.continue {
        border: none;
        background: var(--peacock-blue);
        margin-bottom: 22px;
        padding: 7px 16px;
        border-radius: 18px;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        color: #fff;
        text-transform: uppercase;
        outline: none;

        &:hover {
            cursor: pointer;
        }
    }
`

export { PopupTimeoutModalStyles }
