import styled from 'styled-components'

const WrapperEmpty = styled.div`
    font-family: SVN-Gilroy;
    font-style: normal;
    color: #BEBEBE;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .title {
        font-weight: bold;
        line-height: 22px;
        font-size: 20px;
    }

    .details {
        font-weight: 500;
        font-size: 14px;
        line-height: 128%;
    }
`;

export { WrapperEmpty }