import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { css } from 'styled-components'

// component
import DropDownV2 from '../DropDownV2'

// styles
import { extendStylesDropdownOfficeV2 } from './index.styles'

// action
import {
    getAllOffice,
    filterOffice,
} from '../../../../../../../../actions/officeAction'

// hooks
import useDidMount from '../../../../../../../../helper/Hooks/useDidMount'

// constant
import { ADMIN_ROLE } from '../../../../../../../../data/userRole'
import { MAIN_OFFICE_BRANCH_CODE } from '../../../../../../../../data/commonConstant'

// {
//     key: null,
//     value: "Chọn chi nhánh"
// }
// {
//     key: [],
//     value: "Tất cả chi nhánh"
// }
// {
//     key: [{ID: "acasd2323232323", Name: "Hoàn kiếm"}, {ID: "asdsadsae2342asd", Name: "Hà nội"}]
//     value: "Nhiều chi nhánh",
// }
// {
//     key: [{ID: "acasd2323232323", Name: "Hoàn kiếm"}]
//     value: "Hoàn kiếm",
// }

const noneSelect = {
    key: null,
    value: 'Chọn chi nhánh',
}

const allOffice = {
    key: 'ALL',
    value: 'Tất cả chi nhánh',
}

const SelectOfficeV2 = ({ defaultValue, onChange, extendStyles }) => {
    const dispatch = useDispatch()

    const { userReducer, filterOfficeReducer } = useSelector((state) => ({
        userReducer: state.userReducer,
        filterOfficeReducer: state.filterOfficeReducer,
    }))

    const [lstOffices, setLstOffices] = useState([])
    const [officeSelected, setOfficeSelected] = useState(defaultValue || [])

    const _handleOnChange = useCallback((arr) => {
        const isAllInArr = arr.some((item) => item.key === allOffice.key)
        if (isAllInArr) {
            const allOfficeSubmit = {
                key: [],
                value: 'Tất cả',
            }
            setOfficeSelected([allOfficeSubmit])
            onChange([allOfficeSubmit])
            return
        }

        setOfficeSelected(arr)
        onChange(arr)
    }, [])

    const _mapOfficeOfUserNotAdminTSC = useCallback(() => {
        const { OfficeName, OfficeID, BranchCode } = userReducer.dataLogin
        if (!OfficeName || !OfficeID) return

        const office = {
            key: OfficeID,
            value: `${BranchCode} - ${OfficeName}`,
            BranchCode,
        }
        setLstOffices([office])
        setOfficeSelected([office])
    }, [userReducer.dataLogin, setLstOffices, setOfficeSelected])

    const _mapOfficeOfUserAdminTSC = useCallback(() => {
        const lstOffices = filterOfficeReducer.listOffice.map((item) => ({
            ...item,
            key: item.ID,
            value: `${item.BranchCode} - ${item.Name}`,
        }))

        setLstOffices([...lstOffices])
        setOfficeSelected(null)
    }, [filterOfficeReducer.listOffice, setLstOffices, setOfficeSelected])

    const _handleSearch = useCallback((keySearch) => {
        dispatch(filterOffice({ name: keySearch }, 1000000, 1))
    }, [])

    useDidMount(() => {
        if (!userReducer.dataLogin) return false

        const { Roles, BranchCode } = userReducer.dataLogin
        // if user is not ADMIN TSC and branch is not main office => do not need run code below
        // if (!Roles.includes(ADMIN_ROLE)) return true
        if (!Roles.includes(ADMIN_ROLE) && BranchCode !== MAIN_OFFICE_BRANCH_CODE) return true

        dispatch(getAllOffice())
    }, [userReducer.dataLogin])

    useEffect(() => {
        if (!userReducer.dataLogin) return
        const { Roles, BranchCode } = userReducer.dataLogin
        if (!Roles.includes(ADMIN_ROLE) && BranchCode !== MAIN_OFFICE_BRANCH_CODE) {
            _mapOfficeOfUserNotAdminTSC()
            return
        }

        _mapOfficeOfUserAdminTSC()
    }, [
        userReducer.dataLogin,
        _mapOfficeOfUserNotAdminTSC,
        _mapOfficeOfUserAdminTSC,
    ])

    const isAdminTSC = useMemo(
        () => userReducer.dataLogin?.Roles?.includes(ADMIN_ROLE),
        [userReducer.dataLogin]
    )

    const isMainOffice = userReducer.dataLogin?.BranchCode === MAIN_OFFICE_BRANCH_CODE

    return (
        <DropDownV2
            lstItemFixed={isAdminTSC || isMainOffice ? [allOffice] : []}
            lstItems={lstOffices}
            defaultValue={isAdminTSC || isMainOffice ? undefined : officeSelected}
            onChange={_handleOnChange}
            onSearch={_handleSearch}
            haveSearch={isAdminTSC || isMainOffice ? true : false}
            extendStyles={css`${extendStylesDropdownOfficeV2}${extendStyles}`}
            label="Chi nhánh"
            placeHolder="Nhập mã, tên chi nhánh"
            placeHolderSelect="Chọn chi nhánh"
            isSelectMultiple={isAdminTSC || isMainOffice}
            textMultipleSelect={(lstSelected) => 'Nhiều chi nhánh'}
        />
    )
}

export default SelectOfficeV2
