const hostConfigs = {
    // dev: 'http://localhost:7000/api/',
    dev: 'http://172.16.15.168:7000/api/',
    // dev: 'https://io-dev.wee.vn/api/',
    ppe: 'https://prekioskadmins.vietinbank.vn/api/',
    product: 'https://sdbadmin.vietinbank.vn/api/',
    wee: 'https://vtb-admin.wee.vn/api/'
}

const webSocketUrlConfigs = {
    dev: 'wss://io-dev.wee.vn/ws/connection/websocket',
    ppe: 'wss://prekioskadmins.vietinbank.vn/ws/connection/websocket',
    product: 'wss://sdbadmin.vietinbank.vn/ws/connection/websocket',
    wee: 'wss://vtb-admin.wee.vn/ws/connection/websocket'
}

const host = process.env.REACT_APP_BUILD_TYPE
    ? hostConfigs[process.env.REACT_APP_BUILD_TYPE]
    : hostConfigs.dev
const webSocketUrl = process.env.REACT_APP_BUILD_TYPE
    ? webSocketUrlConfigs[process.env.REACT_APP_BUILD_TYPE]
    : webSocketUrlConfigs.dev

export { host, webSocketUrl }
