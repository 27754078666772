import React, { Component } from 'react';
import styled from 'styled-components';

const Bound =styled.div`
    cursor: pointer;
    position: relative;
    display: inline-block;
    &:not(:last-child){
        img{
            margin-right: 23px;
        }
    }
    img {
        width: 24px;
        height: 24px;
    }
    /* Tooltip text */
    .tooltiptext {
        font-family: SVN-Gilroy;
        font-size: 14px;
        visibility: hidden;
        width: 120px;
        background-color: rgba(0,0,0,0.6);
        color: #fff;
        text-align: center;
        padding: 5px 0;
        border-radius: 6px;
        /* Position the tooltip text */
        position: absolute;
        z-index: 1;
        bottom: 125%;
        left: 50%;
        margin-left: -60px;
        /* Fade in tooltip */
        opacity: 0;
        transition: opacity 0.3s;
    }
    /* Tooltip arrow */
    .tooltiptext::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: #555 transparent transparent transparent;
    }
    /* Show the tooltip text when you mouse over the tooltip container */
    &:hover .tooltiptext {
        visibility: visible;
        opacity: 1;
    }
`
export default class ActionControlCheckBio extends Component {
    render() {
        return (
            <Bound>
                <img 
                onClick={()=>this.props.onSubmit()}
                src={this.props.icon} alt='icon'/>
                {
                    this.props.isTootltip && <span className="tooltiptext">Lịch sử</span>
                }
            </Bound>
        )
    }
}
