import * as userRole from './userRole';
export const location = {
    city: ['Hồ Chí Minh', 'Hà Nội', 'Hải Phòng', 'Đà Nẵng', 'Thái Nguyên', 'Bình Dương', 'Huế'],
    district: ['Tân Bình', 'Quận 10', 'Quận 1', 'Hoàng Mai', 'Đống Đa', 'Lê Chân'],
    ward: ['1', '2', '3', '4', '5', '6', '7'],
    office: ['Chi nhánh', 'Phòng giao dịch', 'Gửi tiết kiệm']
}

export const setPermissionSingleChoise = {
    rank: [
        {
            type: userRole.ADMIN_OFFICE_ROLE,
            // name: 'Quản trị viên PGD',
            name: 'Quản trị HT tại CN',
            permissions: [userRole.ADMIN_ROLE],
            notCombined: []
        },
        {
            type: userRole.TELLER,
            // name: 'Giao dịch viên',
            name: 'GDV CN',
            permissions: [userRole.ADMIN_ROLE],
            notCombined: [userRole.OFFEXSUPERVISOR, userRole.INBIOCOLLECTOR]
        },
        {
            type: userRole.OFFINSUPERVISOR,
            // name: 'Giám sát viên - Nội bộ',
            name: 'Ban giám đốc CN',
            permissions: [userRole.ADMIN_ROLE],
            notCombined: [userRole.INBIOCOLLECTOR]
        },
        {
            type: userRole.OFFEXSUPERVISOR,
            // name: 'Giám sát viên - Khách hàng',
            name: 'KSV CN',
            permissions: [userRole.ADMIN_ROLE],
            notCombined: [userRole.TELLER]
        },
        {
            type: userRole.INBIOCOLLECTOR,
            // name: 'Điện toán (Quản trị dữ liệu nội bộ)',
            name: ' Đăng ký STH cho cán bộ CN',
            permissions: [userRole.ADMIN_ROLE],
            notCombined: [userRole.OFFINSUPERVISOR, userRole.TELLER]
        },
        {
            type: userRole.RPW,
            name: 'Xem báo cáo',
            permissions: [userRole.ADMIN_ROLE],
            notCombined: []
        },
        {
            type: userRole.IFW,
            name: 'Vấn tin',
            permissions: [userRole.ADMIN_ROLE],
            notCombined: [],
            children: [
                {
                    type: userRole.IFW_DUP,
                    name: 'Vấn tin trùng STH',
                    permissions: [userRole.ADMIN_ROLE],
                    notCombined: [],
                },
                {
                    type: userRole.IFW_SID,
                    name: 'Vấn tin theo giấy tờ tùy thân',
                    permissions: [userRole.ADMIN_ROLE],
                    notCombined: [],
                },
                {
                    type: userRole.IFW_SC,
                    name: 'Vấn tin theo số CIF',
                    permissions: [userRole.ADMIN_ROLE],
                    notCombined: [],
                },
                {
                    type: userRole.IFW_SBB,
                    name: 'Vấn tin theo hình ảnh STH',
                    permissions: [userRole.ADMIN_ROLE],
                    notCombined: [],
                },
            ]
        },
    ],
    // rank:[
    //     'Giao dịch viên', 'Quản trị viên PGD'
    // ],
    roomTrans: ['PGD Hồ Chí Minh', 'PGD Hà Nội', 'PGD Hải Phòng', 'PGD Đà Nẵng', 'PGD Huế', 'PGD Bình Dương']
}

export const setSupervisorSingleChoise = {
    status: [
        {
            type: 'PENDING',
            name: 'Chờ phê duyệt',
        },
        {
            type: 'ACCEPT',
            name: 'Đồng ý'
        },
        {
            type: 'REJECT',
            name: 'Từ chối'
        }
    ],
    permissionVerify: [
        {
            Role: 'TPP_KETOAN'
        }
    ],
    dataStatus: [
        {
            type: 'Pending',
            // name: 'Chờ phê duyệt'
            name: 'Chưa xem'
        },
        {
            type: 'Seen',
            name: 'Đã xem'
        }
    ],
    segment: [
        'CN1',
        'CN2',
        'CN3',
        'CN4',
        'CN5',
        'CN6',
        // {
        //     type: 'CN1',
        //     name: 'CN1'
        // },
        // {
        //     type: 'CN2',
        //     name: 'CN2'
        // },
        // {
        //     type: 'CN3',
        //     name: 'CN3'
        // },
        // {
        //     type: 'CN4',
        //     name: 'CN4'
        // },
        // {
        //     type: 'CN5',
        //     name: 'CN5'
        // },
        // {
        //     type: 'CN6',
        //     name: 'CN6'
        // }
    ],
    verifyStatus: [
        {
            type: 'VerifyUnsuccessfully',
            name: 'Không thành công'
        },
        {
            type: 'VerifySuccessfully',
            name: 'Thành công'
        },
        {
            type: 'NotVerify',
            name: 'Không xác thực'
        }
    ],
    bioInfosType: [
        {
            type: 'ALL',
            name: 'Tất cả'
        },
        {
            type: 'REGISTER',
            name: 'Đăng ký mới'
        },
        {
            type: 'UPDATE',
            name: 'Cập nhật'
        },
        {
            type: 'NOTREGISTER',
            name: 'Không đăng ký',
        },

        // {
        //     type: 'DELETE',
        //     name: 'Xóa dữ liệu'
        // },
        // {
        //     type: 'BLOCK',
        //     name: 'Khóa dữ liệu'
        // },
        // {
        //     type: 'UNBLOCK',
        //     name: 'Mở khóa dữ liệu'
        // }
    ]
}