import Axios from 'axios'

// constant
import * as types from '../types'
import { host } from '../../../../host'

// helper
import catchErrorApi from '../../../../helper/catchErrorApi'
import autoDownloadFromBlob from '../../../../helper/autoDownloadFromBlob'

// action
import { addLoading, removeLoading } from '../../../commonAction'
import sortListStringNum from '../../../../helper/sortLIstStringNum'

const apiGetListDetailsOfBioNotReview = `${host}admin/exportJSON/bioNotReview`

export const getListDetailsOfBioNotReview = ({
    FromDate,
    ToDate,
    BranchIDs,
    ReportCodeName,
    BranchName,
    TellerName,
    Channel
}) => async (dispatch) => {
    dispatch(addLoading())
    const dataRequest = JSON.stringify({
        FromDate,
        ToDate,
        BranchIDs,
        ReportCodeName,
        BranchName,
        TellerName,
        Channel
    })

    try {
        const res = await Axios.post(
            apiGetListDetailsOfBioNotReview,
            dataRequest
        )
        const { data } = res
        dispatch({
            type: types.GET_LIST_DETAILS_OF_BIO_NOT_REVIEW,
            payload: {
                condFilter: {
                    FromDate,
                    ToDate,
                    BranchIDs,
                    ReportCodeName,
                    BranchName,
                    TellerName,
                    Channel
                },
                listData: data.Data || [],
            },
        })
        dispatch(removeLoading())
    } catch (err) {
        dispatch(removeLoading())
        catchErrorApi(
            err,
            dispatch,
            types.GET_LIST_DETAILS_OF_BIO_NOT_REVIEW_ERROR,
            () => false
        )
    }
}

export const exportListDetailsOfBioNotReview = ({
    FromDate,
    ToDate,
    BranchIDs,
    ReportCodeName,
    BranchName,
    BranchCodes,
    TellerName,
    Channel
}) => async (dispatch) => {
    if (!BranchCodes) return

    dispatch(addLoading())
    const dataRequest = JSON.stringify({
        FromDate,
        ToDate,
        BranchIDs,
        ExportExcel: true,
        ReportCodeName,
        BranchName,
        TellerName,
        Channel
    })

    try {
        const res = await Axios.post(
            apiGetListDetailsOfBioNotReview,
            dataRequest,
            {
                responseType: 'blob',
            }
        )
        dispatch(removeLoading())
        const { data } = res
        if (BranchCodes.length === 1) {
            autoDownloadFromBlob(data, `${ReportCodeName}-${BranchCodes[0]}`)
        } else {
            autoDownloadFromBlob(
                data,
                `${ReportCodeName}-${sortListStringNum(BranchCodes).join('-')}`
            )
        }
    } catch (err) {
        dispatch(removeLoading())
        console.log(err)
    }
}
