import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux';

import ic_close from '../../../images/ic_close.svg'
// import { getBioInfos } from '../../../actions/supervisorAction/biometricAction'
// import { OFFINSUPERVISOR, OFFEXSUPERVISOR } from "../../../data/userRole";

const Bound = styled.div`
    z-index:99999;
    padding: 20px 45px;
    box-shadow: 1px 3px 15px #000000b0;
    width: ${props => props.setWidth || "210px"};
    background-color: #fafafa;
    display:flex;
    align-items: center;
    flex-direction:column;
    font-family: 'SVN-Gilroy';
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    position: absolute;
    top: ${props => props.isCenter ? "50%" : 0};
    left: 50%;
    transform: translate(-50%,${props => props.isCenter ? "-50%" : 0});
    animation-name: example;
    animation-duration: 0.4s;
    @keyframes example {
        0%   { top:${props => props.isCenter ? "40%" : "-168px"};}
        100%  { top:${props => props.isCenter ? "50%" : 0};}
    }
    .ic_close{
        width: 52px;
        height: 52px;
        object-fit: contain;
    }
    .content {
        font-size: 16px;
        font-weight: ${props => props.mixTitle ? 500 : "bold"};
        line-height: 1.19;
        color: #222222;
        margin:22px 0 0 0;
        text-align: center;

        span {
            font-weight: bold;
        }
    }
    .info {
        margin-top: 16px;
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        line-height: 1.3;
        color: var(--peacock-blue);
        text-align: center;
    }
    .note {
        margin-top: 20px;
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        line-height: 1.3;
        color: var(--vermillion);
        text-align: center;
    }
    .btn_ok {
        margin-top: 28px;
        width: 100px;
        height: 36px;
        border-radius: 18px;
        background-color: #005993;
        font-size: 13px;
        font-weight: bold;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 22px 0 0 0;
        cursor: pointer;
    }
`

class PopupAlert extends Component {
        
    componentDidMount() {
        window.addEventListener("keyup", event => {
            if(event.keyCode === 13){
                this.outPopup()
            }
        })

        document.body.style.overflow = 'hidden'
    }

    componentWillUnmount() {
        document.body.style.overflow = 'auto'
    }
    

    outPopup = () => {
        this.props.outPopup()
    }
    render() {
        const {icon} = this.props
        const { iconData, content, width } = this.props.data || {};
        return (
            <div
                style={{
                    width: "100%",
                    minHeight: "100vh",
                    backgroundColor: this.props.bgColor ? "rgba(0,0,0,0.6)" : "transparent",
                    position: "absolute",
                    top: "0",
                    left: "0",
                    zIndex: "9999",
                }}
            >
                <Bound
                    setWidth={this.props.width || width}
                    isCenter={this.props.isCenter}
                    mixTitle={this.props.title && typeof (this.props.title) === "object"}
                >
                    <div className="ic_close">
                        <img src={iconData || icon || ic_close} alt="ic_close" />
                    </div>
                    <div className="content">
                        {
                            this.props.title ?
                                typeof (this.props.title) === "string" ?
                                    this.props.title
                                    :
                                    (<React.Fragment>{`${this.props.title.sub} `}<span>{this.props.title.main}</span></React.Fragment>)
                                :
                                content
                        }
                    </div>
                    {
                        this.props.info &&
                        <div className="info">{this.props.info}</div>
                    }
                    {
                        this.props.note &&
                        <div className="note">{this.props.note}</div>
                    }
                    <div className="btn_ok"
                        id="btn_apply"
                        onClick={this.outPopup}>
                        XÁC NHẬN
                </div>
                </Bound>
            </div>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        userReducer: state.userReducer,
        // biometricReducer: state.biometricReducer,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        // getBioInfos: (dataQuery) => { dispatch(getBioInfos(dataQuery))}
    }
}
export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(PopupAlert)