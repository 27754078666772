import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import Axios from 'axios'

// styles
import { WrapQueries } from './index.styles'

// component
import Modal from '../../../../helper/Modal'
import MainQueries from './MainQueries'
import GTTTQueries from './GTTTQueries'
import QueryByBioImage from './QueryByBioImage'
import DuplicateBio from './DuplicateBio'

// constant
import { ADMIN_OFFICE_ROLE, ADMIN_ROLE, IFW, IFW_SID, IFW_SC, IFW_DUP, OFFEXSUPERVISOR, OFFINSUPERVISOR, RPW, IFW_SBB } from '../../../../data/userRole'

// action
import { clearDuplBioDetails, getDuplBioDetails } from '../../../../actions/queryAction/queryBioAction'

// api
import { apiGetCif } from '../../../../actions/queryAction/queryByCif'

const QUERIES_MAIN_STEP = 'QUERIES_MAIN_STEP'
const GTTT_STEP = 'GTTT_STEP'
const DUP_BIO_STEP = 'DUP_BIO_STEP'
const BIO_IMG_STEP = 'BIO_IMG_STEP'

const ERR_NOT_FOUND_USER = 'Không tìm thấy thông tin khách hàng'

const Queries = () => {
    const history = useHistory()
    const dispatch = useDispatch()

    const { userReducer, duplicateBioReducer } = useSelector((state) => ({
        userReducer: state.userReducer,
        duplicateBioReducer: state.queryReducer.duplicateBioReducer
    }))
    const [step, setStep] = useState(QUERIES_MAIN_STEP);
    const [data, setData] = useState(null);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [defaultDataQueryUser, setDefaultDataQueryUser] = useState(null);
    const [defaultQueryCode, setDefaultQueryCode] = useState("");
    const [defaultActiveQueryMethod, setDefaultActiveQueryMethod] = useState(null);
    const [showDialog, setShowDialog] = useState(true);

    const _handleCloseMainQueries = () => {
        setShowDialog(false)
        // if (history.length > 2) {
        //     history.goBack()
        //     return;
        // }
        // const { Roles } = userReducer.dataLogin
        // switch (true) {
        //     case Roles.includes(ADMIN_ROLE):
        //         history.push("/danh-sach-giao-dich")
        //         break;
        //     case Roles.includes(ADMIN_OFFICE_ROLE):
        //         history.push("/thiet-bi-kiosk")
        //         break;
        //     case Roles.includes(OFFEXSUPERVISOR):
        //     case Roles.includes(OFFINSUPERVISOR):
        //         history.push("/biometric-data")
        //         break;
        //     case Roles.includes(RPW):
        //         history.push("/reports")
        //         break;
        //     case Roles.includes(IFW):
        //         history.push("/van-tin")
        //         break;
        //     default:
        //         break;
        // }
    }

    const _handleQueryUserByGTTTorCif = async (custKey, queryType) => {
        setError('')
        setLoading(true)
        const dataRequest = JSON.stringify({
            CifsNum: custKey,
            Type: queryType
        })

        try {
            const res = await Axios.post(apiGetCif, dataRequest)
            const { data } = res;
            const isSuccess = data.Code === 0 && data.Cifs;
            if (isSuccess) {
                console.log('data:', data)
                setData(data)
                setStep(GTTT_STEP)
            }
            else {
                console.log('error:', data)
                if (data.Code === 0) {
                    setError(ERR_NOT_FOUND_USER)
                }
            }
        }
        catch (err) {
            console.dir(err)
        }
        setLoading(false)
    }

    const _handleQueryDuplicateBio = (queryCode) => {
        setError('')
        setLoading(true)
        dispatch(getDuplBioDetails(queryCode, () => setLoading(false)))
    }

    const _handleQueryByBioImage = () => {
        setStep(BIO_IMG_STEP)
    }

    const _handleCloseStep = () => {
        setStep(QUERIES_MAIN_STEP)
        setData(null)
    }

    const _handleCloseDuplicateBio = () => {
        _handleCloseStep()
        dispatch(clearDuplBioDetails())
    }

    useEffect(() => {
        const { duplBioDatas, error } = duplicateBioReducer
        if (error) {
            setError(error)
            return;
        }
        if (!duplBioDatas) return;
        setStep(DUP_BIO_STEP)
        setData(duplBioDatas)
    }, [JSON.stringify(duplicateBioReducer)]);

    useEffect(() => {
        setShowDialog(true)
    }, [duplicateBioReducer.countChangeRoute]);

    const userIsAdmin = userReducer.dataLogin.Roles.includes(ADMIN_ROLE)
    const userIsQueryRole = userReducer.dataLogin.Roles.includes(IFW)
    const queryByGTTT = userIsAdmin
        || (userIsQueryRole && userReducer.dataLogin.InfosPermission.includes(IFW_SID))
    const queryByCif = userIsAdmin
        || (userIsQueryRole && userReducer.dataLogin.InfosPermission.includes(IFW_SC))
    const queryByCodeDupl = userIsAdmin
        || (userIsQueryRole && userReducer.dataLogin.InfosPermission.includes(IFW_DUP))
    const queryByBioImage = userIsAdmin
        || (userIsQueryRole && userReducer.dataLogin.InfosPermission.includes(IFW_SBB))

    return (
        <WrapQueries>
            <Modal isShow={showDialog}>
                {
                    step === QUERIES_MAIN_STEP &&
                    <MainQueries
                        handleClose={_handleCloseMainQueries}
                        handleQueryUser={_handleQueryUserByGTTTorCif}
                        handleQueryDuplicateBio={_handleQueryDuplicateBio}
                        handleQueryByBioImage={_handleQueryByBioImage}
                        error={error}
                        loading={loading}
                        permissionQuery={{
                            queryByGTTT,
                            queryByCif,
                            queryByCodeDupl,
                            queryByBioImage
                        }}
                        defaultDataQueryUser={defaultDataQueryUser}
                        setDefaultDataQueryUser={setDefaultDataQueryUser}
                        defaultQueryCode={defaultQueryCode}
                        setDefaultQueryCode={setDefaultQueryCode}
                        defaultActiveQueryMethod={defaultActiveQueryMethod}
                        setDefaultActiveQueryMethod={setDefaultActiveQueryMethod}
                    />
                }
                {
                    step === GTTT_STEP &&
                    <GTTTQueries
                        data={data}
                        handleClose={_handleCloseStep}
                    />
                }
                {
                    step === DUP_BIO_STEP &&
                    <DuplicateBio
                        data={data}
                        handleClose={_handleCloseDuplicateBio}
                    />
                }
                {
                    step === BIO_IMG_STEP &&
                    <QueryByBioImage
                        handleClose={_handleCloseStep}
                    />
                }
            </Modal>
        </WrapQueries>
    )
}

export default Queries
