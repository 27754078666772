import Axios from 'axios'
import * as types from './types';
import { host } from '../host';
import catchErrorApi from '../helper/catchErrorApi';

const apiGetDataNotViewYet = host + "supervisor/get/not-view-count";
const apiGetListOfUsername = host + "supervisor/get/list-username";
const apiGetListChannel = host + "admin/exportJSON/getListChannel";

export const addLoading = () => ({
	type: types.RUN_LOADING
})

export const removeLoading = () => ({
	type: types.REMOVE_LOADING
})

export const alertLogout = () => ({
	type: types.ALERT_LOGOUT
})

export const showErrConnection = () => ({
	type: types.ERR_CONNECTION
})

export const removeErrorConnection = () => ({
	type: types.REMOVE_ERR_CONNECTION
})

export const showErrorTimeoutApi = () => ({
	type: types.ERR_CONNECTION_TIMEOUT
})

export const removeErrorConnectionTimeoutApi = () => ({
	type: types.REMOVE_ERR_CONNECTION_TIMEOUT
})

export const showErrorBranchCode = () => ({
	type: types.ERR_MATCH_BRANCHCODE
})

export const removeErrorBranchCode = () => ({
	type: types.REMOVE_ERR_MATCH_BRANCHCODE
})


export const clearError = (typeErr, reducerTarget) => dispatch => {
	dispatch({
		type: types.CLEAR_ERR,
		typeErr,
		reducerTarget
	})
}

export const deviceConnected = (deviceType, mac, parentId) => ({
	type: types.DEVICE_CONNECTED,
	payload: {
		deviceType,
		mac,
		parentId
	}
})

export const getNumOfDataNotViewYet = () => async dispatch => {
	try {
		const res = await Axios.get(apiGetDataNotViewYet);
		const { data } = res;
		// console.log(data)
		if (data.Code === 0) {
			dispatch({
				type: types.GET_DATA_NOT_VIEW_YET,
				payload: {
					data
				}
			})
		}
	}
	catch (err) {
		catchErrorApi(
			err,
			dispatch,
			types.GET_DATA_NOT_VIEW_YET_ERR,
			() => false
		)
	}
}

export const getListOfUsername = (lstOffice=[]) => async dispatch => {
	dispatch(addLoading())
	const dataRequest = JSON.stringify({
		OfficeIDs: lstOffice
	})
	try {
		const res = await Axios.post(apiGetListOfUsername, dataRequest)
		const { data } = res;
		if (data.Code === 0) {
			dispatch({
				type: types.GET_LIST_OF_USERNAME,
				payload: {
					listTellers: data.ListTeller || [],
					listInbioCollectors: data.ListInbioCollector || [],
					listInSupervisors: data.ListInSupervisor || [],
					listExSupervisors: data.ListExSupervisor || [],
				}
			})
		}
		dispatch(removeLoading())
	}
	catch (err) {
		dispatch(removeLoading())
		catchErrorApi(
			err,
			dispatch,
			types.GET_LIST_OF_USERNAME_ERR,
			() => false
		)
	}
}

export const getListChannel = () => async dispatch => {
	try {
		const res = await Axios.post(apiGetListChannel)
		const { data } = res;
		console.log(data)
		const isSuccess = data.Code === 0
		if (isSuccess) {
			dispatch({
				type: types.GET_LIST_CHANNEL,
				payload: {
					listChannel: data.Channels || []
				}
			})
		}
	}
	catch (err) {
		console.log('err')
		catchErrorApi(
			err,
			dispatch,
			types.GET_LIST_CHANNEL_ERR,
			() => false
		)
	}
}