import React, { useEffect, /* useCallback */ } from 'react'
// import { useDispatch, useSelector } from 'react-redux'

// action
// import { removeCounterLink, getCounterByOffice } from '../../../../../actions/counterAction';

// styles
import { WrapOverlay, OptionListDiv } from './index.styles'

const PopupOptionListCounterItem = ({
    offsetPopup,
    currentPage,
    counterInfos,
    funcCall: { onShowPopupEdit, onShowPopupDelete, onShowPopupReset },
}) => {
    // const dispatch = useDispatch()
    // const { userReducer } = useSelector((state) => ({
    //     userReducer: state.userReducer,
    // }))

    useEffect(() => {
        document.body.style.overflowX = 'hidden'
        return () => {
            document.body.style.overflowX = 'visible'
        }
    }, [])

    const _handleModify = (e) => {
        // e.stopPropagation()
        onShowPopupEdit({
            ...counterInfos,
            currentPage,
        })
    }

    // const _callbackResetSuccess = useCallback(() => {
    //     dispatch(
    //         getCounterByOffice(userReducer.dataLogin.OfficeID, 8, currentPage)
    //     )
    // })

    const _handleResetCounter = (e) => {
        // e.stopPropagation()
        // dispatch(removeCounterLink(counterInfos.ID, _callbackResetSuccess))
        onShowPopupReset()
    }

    const _handleRemove = (e) => {
        // e.stopPropagation()
        onShowPopupDelete({
            ...counterInfos,
            currentPage,
        })
    }

    return (
        <WrapOverlay>
            <OptionListDiv offset={offsetPopup}>
                <div className="modify" onClick={_handleModify}>
                    Tùy chỉnh
                </div>
                {
                    (!!counterInfos.TabletDevice || !!counterInfos.DesktopDevice) &&
                    <div className="reset-counter" onClick={_handleResetCounter}>
                        Thoát quầy
                    </div>
                }
                <div className="remove" onClick={_handleRemove}>
                    Xóa quầy
                </div>
            </OptionListDiv>
        </WrapOverlay>
    )
}

export default PopupOptionListCounterItem
