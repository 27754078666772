import React from 'react'
import { useSelector } from 'react-redux'

// component
import Table from '.'
import { BioInfosType as BioInfosTypes, ACCEPT, NR, PENDING, REGISTER, REJECT, Status as StatusDetails, UPDATE } from '../../../../../data/infosType'

const TableBl = ({ showPopupCheckBiometric }) => {
    const { listBioErrors } = useSelector(state => ({
        listBioErrors: state.bioErrorReducer.listBioErrors
    }))

    const list = listBioErrors.map(bioError => {
        const { BioInfosType, Status } = bioError
        const elmBioInfosType = (() => {
            switch (BioInfosType) {
                case REGISTER:
                    return BioInfosTypes[REGISTER].label
                case UPDATE:
                    return BioInfosTypes[UPDATE].contentLabel
                case NR:
                    return BioInfosTypes[NR].label
                default:
                    return ''
            }
        })()
        const colorsInfosType = (() => {
            switch (BioInfosType) {
                case REGISTER:
                    return BioInfosTypes[REGISTER].color
                case UPDATE:
                    return BioInfosTypes[UPDATE].color
                case NR:
                    return BioInfosTypes[NR].color
                default:
                    return 'initial'
            }
        })()
        const colorStatus = StatusDetails[PENDING].color

        const valueStatus = (() => {
            switch (Status) {
                case ACCEPT:
                    return StatusDetails[ACCEPT].label
                case PENDING:
                    return StatusDetails[PENDING].value
                default:
                    return StatusDetails[REJECT].label
            }
        })()

        const clsStatus = (() => {
            switch (Status) {
                case ACCEPT:
                    return StatusDetails[ACCEPT].colorA
                case PENDING:
                    return StatusDetails[PENDING].colorP
                default:
                    return StatusDetails[REJECT].colorR
            }
        })()

        return {
            ...bioError,
            elmBioInfosType,
            colorsInfosType,
            colorStatus,
            valueStatus,
            clsStatus
        }
    })

    return (
        <Table
            list={list}
            showPopupCheckBiometric={showPopupCheckBiometric}
        />
    )
}

export default TableBl
