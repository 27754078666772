import * as types from '../../../actions/reportsAction/listReports/types'


const initialState = {
    listParents: {}, 
    list: {},
    condFilter: {
        ParentCode: '',
        CodeName: '',
        Searchtext: ''
    },
    condFilterParents: {
        ParentCode: '',
        CodeName: '',
        Searchtext: ''
    }
}

const listReports = (state=initialState, action) => {
    switch(action.type) {
        case types.SET_PARENT_CONDITION_FILTER:
        case types.SET_CONDITION_FILTER:
        case types.GET_LIST_PARENT_REPORTS: 
        case types.GET_LIST_REPORTS: {
            return {
                ...state,
                ...action.payload
            }
        }
        case types.CLEAR_LIST_REPORT: {
            return initialState
        }
        default:
            return state;
    }
}

export default listReports