import React from 'react'
import { useDispatch } from 'react-redux'
import * as style from './index.style'
import { withPopupConfirm } from '../index'

//image
import ic_confirm from '../../../../../../images/Icons_Notification_Question.svg'
import { updateKiosk } from '../../../../../../actions/kioskAction'

const PopupConfirmUsePortal = ({ closePopup, data }) => {
    const dispatch = useDispatch()

    // const { OnOffEform, Name } = data
    const { EformStatus, Name, ID } = data
    const _handleAccept = () => {
        // dispatch(updateEForm(data.ID, data.status))
        const newEformStatus = EformStatus === 'E' ? 'D' : 'E';
        const dataUpdate = {
            EformStatus: newEformStatus,
        }
        dispatch(updateKiosk(ID, Name, dataUpdate))
        closePopup()
    }
    return (
        <style.Bound>
            <img src={ic_confirm} alt="confirm" />
            <p className="content">{`${
                // OnOffEform ? 'Bật' : 'Tắt'
                EformStatus === 'D' ? 'Bật' : 'Tắt'
            } chức năng E-form của Kiosk`}</p>
            <p className="name">{`"${Name}"`}</p>
            <div className="block_btn">
                <div className="close" onClick={closePopup}>
                    Hủy Bỏ
                </div>
                <div className="accept" onClick={_handleAccept}>
                    Xác nhận
                </div>
            </div>
        </style.Bound>
    )
}

export default React.memo(withPopupConfirm(PopupConfirmUsePortal))
