import * as types from "../../actions/types";

const initialState = {
  success: false,
  err: '',
}

export default function updateAdsReducer(state = initialState, action) {
  switch (action.type) {
    case types.UPDATE_ADS:
      return {
        ...state,
        succes: action.succes
      }
    case types.UPDATE_ADS_ERR:
      return {
        ...state,
        err: action.err
      }
    default:
      return state
  }
}