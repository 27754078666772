import styled from 'styled-components'

const WrapperSearchReport = styled.div`
    display: flex;
    /* flex: 1 0; */
    justify-content: space-between;
    margin: 18px 0;
    padding: 0 25px;
    box-sizing: border-box;
    align-items: center;

    .title-report-list {
        font-family: SVN-Gilroy;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 22px;
        color: #222222;
    }

    .wrap-search {
        display: flex;
        height: 36px;
        align-items: center;
        border: 0.5px solid #D6D6D6;
        box-sizing: border-box;
        border-radius: 4px;
        background: #FFFFFF;
        padding: 0 11px 0 13px;

        input {
            flex-grow: 1;
            flex-shrink: 0;
            font-family: SVN-Gilroy;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            /* line-height: 128%; */
            color: #222222;
            outline: none;
            border: none;
            margin: 0 6px;
        }

        .wrap-btn-clear {
            min-width: 14px;
            min-height: 14px;

            img {
                display: block;
            }
        }
    }
`

export {
    WrapperSearchReport
}