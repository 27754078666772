import Axios from 'axios'

// constant
import * as types from '../types'
import { host } from '../../../../host'

// helper
import catchErrorApi from '../../../../helper/catchErrorApi'
import autoDownloadFromBlob from '../../../../helper/autoDownloadFromBlob'

// action
import { addLoading, removeLoading } from '../../../commonAction'

const apiGetHistoryCusChangeRegBio = `${host}admin/exportJSON/customerBioHistoriesDetails`

export const getHistoryCusChangeRegBio = ({
    FromDate,
    ToDate,
    BranchIDs,
    ReportCodeName,
    BranchName,
    CustomerNumber,
}) => async (dispatch) => {
    dispatch(addLoading())
    const dataRequest = JSON.stringify({
        FromDate,
        ToDate,
        BranchIDs,
        ReportCodeName,
        BranchName,
        CustomerNumber,
    })

    try {
        const res = await Axios.post(apiGetHistoryCusChangeRegBio, dataRequest)
        const { data } = res
        dispatch({
            type: types.GET_HISTORY_CUS_CHANGE_REG_BIO,
            payload: {
                condFilter: {
                    FromDate,
                    ToDate,
                    BranchIDs,
                    ReportCodeName,
                    BranchName,
                    CustomerNumber,
                },
                listData: data.Data || [],
            },
        })
        dispatch(removeLoading())
    } catch (err) {
        dispatch(removeLoading())
        catchErrorApi(
            err,
            dispatch,
            types.GET_HISTORY_CUS_CHANGE_REG_BIO_ERROR,
            () => false
        )
    }
}

export const exportHistoryCusChangeRegBio = ({
    FromDate,
    ToDate,
    BranchIDs,
    ReportCodeName,
    BranchName,
    CustomerNumber,
}) => async (dispatch) => {
    dispatch(addLoading())
    const dataRequest = JSON.stringify({
        FromDate,
        ToDate,
        BranchIDs,
        ExportExcel: true,
        ReportCodeName,
        BranchName,
        CustomerNumber,
    })

    try {
        const res = await Axios.post(
            apiGetHistoryCusChangeRegBio,
            dataRequest,
            {
                responseType: 'blob',
            }
        )
        dispatch(removeLoading())
        const { data } = res

        autoDownloadFromBlob(
            data,
            CustomerNumber
                ? `${ReportCodeName}-${CustomerNumber}`
                : `${ReportCodeName}-ALL`
        )
    } catch (err) {
        dispatch(removeLoading())
        console.log(err)
    }
}
