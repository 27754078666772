import { combineReducers } from 'redux'
import { adsReducer } from './adsReducer';
import { officeReducer } from './officeReducer';
import userReducer from './userReducer/userReducer';
import counterReducer from './counterReducer/counterReducer';
import kioskReducer from './kioskReducer/kioskReducer';
import LCDReducer from "./LCDReducer/LCDReducer";
import { supervisorReducer } from "./supervisorReducer";
import commonReducer from "./commonReducer";
import reports from './reports';
import queryReducer from './queryReducer';
import bioErrorReducer from './bioErrorReducer';
import biometricExecutionReducer from './biometricExecutionReducer';
import * as types from '../actions/types'

const appReducer = combineReducers({
  ...adsReducer,
  ...officeReducer,
  userReducer,
  counterReducer,
  kioskReducer,
  LCDReducer,
  ...supervisorReducer,
  commonReducer,
  reports,
  queryReducer,
  bioErrorReducer,
  biometricExecutionReducer
})


const rootReducer = (state, action) => {
  // console.log(appReducer)
  if (action.type === types.LOGOUT_USER) {
    localStorage.removeItem("dataLogin")
    state = undefined;
    // console.log("logout")
  }
  return appReducer(state, action);
}



export default rootReducer