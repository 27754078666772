import React, { useCallback, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { css } from 'styled-components'

// styles
import { extendStylesDropdownTeller } from './index.styles'

// component
import DropDownTeller from './DropdownTeller'

// action
import { getListOfUsername } from '../../../../../../../../actions/commonAction'

const labels = {
    GDV: 'GDV',
    DIEN_TOAN: 'DIEN_TOAN',
    GDV_DIEN_TOAN: 'GDV_DIEN_TOAN',
    GDV_DIEN_TOAN_TRUOC: 'GDV_DIEN_TOAN_TRUOC',
    GDV_DIEN_TOAN_SAU: 'GDV_DIEN_TOAN_SAU'
}

const labelsDesc = {
    [labels.GDV]: {
        title: 'GDV'
    },
    [labels.DIEN_TOAN]: {
        title: 'Điện toán'
    },
    [labels.GDV_DIEN_TOAN]: {
        title: 'GDV/ Điện toán'
    },
    [labels.GDV_DIEN_TOAN_TRUOC]: {
        title: <>
            <div>GDV/ Điện toán</div>
            <div>(KH đăng ký trước)</div>
        </>
    },
    [labels.GDV_DIEN_TOAN_SAU]: {
        title: <>
            <div>GDV/ Điện toán</div>
            <div>(KH đăng ký sau)</div>
        </>
    },
}

const ALL_TELLER = 'Tất cả'

const SelectTeller = ({ defaultValue, onChange, label = labels.GDV, extendStyles }) => {
    const dispatch = useDispatch()

    const commonReducer = useSelector((state) => state.commonReducer)

    const [lstItems, setLstItems] = useState([])
    const [value, setValue] = useState(defaultValue || ALL_TELLER)

    const _handleOnChange = useCallback((val) => {
        setValue(val)
        if (ALL_TELLER === val) {
            onChange(undefined)
            return
        }
        onChange(val)
    }, [])

    // useEffect(() => {
    //     dispatch(getListOfUsername())
    // }, [])

    useEffect(() => {
        switch (label) {
            case labels.GDV:
                setLstItems([ALL_TELLER, ...commonReducer.listTellers])
                break;
            case labels.DIEN_TOAN:
                setLstItems([ALL_TELLER, ...commonReducer.listInbioCollectors])
                break;
            case labels.GDV_DIEN_TOAN:
            case labels.GDV_DIEN_TOAN_TRUOC:
            case labels.GDV_DIEN_TOAN_SAU:
                {
                    const lstUsers =
                        [...commonReducer.listTellers, ...commonReducer.listInbioCollectors]
                            .reduce((lst, item) => lst.includes(item) ? lst : lst.concat(item), [])
                    lstUsers.sort((a, b) => a.localeCompare(b))
                    setLstItems([ALL_TELLER, ...lstUsers])
                    break;
                }
            default:
                break;
        }
    }, [
        label,
        commonReducer.listTellers,
        commonReducer.listInbioCollectors,
    ])

    useEffect(() => {
        setValue(ALL_TELLER)
    }, [
        commonReducer.listTellers,
        commonReducer.listInbioCollectors
    ])

    return (
        <DropDownTeller
            extendStyles={css`${extendStylesDropdownTeller}${extendStyles}`}
            label={labelsDesc[label]?.title}
            lstItemFixed={[]}
            lstItems={lstItems}
            onChange={_handleOnChange}
            defaultValue={value}
            placeHolder="Nhập User"
        />
    )
}

export default SelectTeller
