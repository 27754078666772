import styled from 'styled-components'

const WrapperEditUser = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fafafa;
    width: 760px;
    height: 560px;
    display: flex;
    flex-direction: column;

    .footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 26px 40px;
    }
`;

export { WrapperEditUser }