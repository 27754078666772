import React, { Component } from 'react'
import styled from 'styled-components'

//Img
import ic_more_gray from '../../../../images/ic_more_gray.svg'
import SortControl from '../Control/PagningControl';
import { formatNumber } from '../../../../tools';
// import { ADMIN_ROLE } from '../../../../data/userRole';

// data
import { setPermissionSingleChoise } from "../../../../data/singleChoiseData";
import { ADMIN_OFFICE_ROLE } from '../../../../data/userRole';

const Bound = styled.div`
    display:flex;
    flex:1;
    font-family: SVN-Gilroy;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    overflow:auto;
    &::-webkit-scrollbar {
      width: 0px;
    }
    table{
        /*display:flex;*/
        /*flex:1;*/
        /*flex-direction:column;*/
        border-collapse: collapse; 
        table-layout: fixed;
        width: 100%;
        white-space: nowrap;
        padding-bottom:40px;
        & * {
            box-sizing: border-box;
        }
        ::-webkit-scrollbar { 
          display: none; 
        }
        thead{
            display:flex;
            tr{
                display:flex;
                width:100%;
                align-items:center;
                height:48px;
                margin:2px 0;
                th{
                    display:flex;
                    ${'' /* flex:1; */}
                    height:100%;
                    align-items:center;
                    text-align:left;
                    font-size: 11px;
                    font-weight: bold;
                    line-height: 1.26;
                    color: #005993;
                    text-transform:uppercase;
                    :first-child{
                        margin-left:20px;
                        flex-basis: 100px;
                    }
                    :nth-child(2), :nth-child(4), :nth-child(5), :nth-child(6){
                        flex: 1;
                    }
                    :nth-child(3){
                        flex: 1;
                    }
                }
                .button-control{
                    display:flex;
                    flex:unset;
                    /*width:50px;*/
                    height:100%;
                }
            }
        }
        tbody{
            display:flex;
            flex-direction:column;
            flex:1;
            border-collapse:separate; 
            border-spacing:0 15px;
            tr{
                display:flex;
                align-items:center;
                width:100%;
                height:48px;
                margin:2px 0;
                background-color:#ffffff;
                border-radius:4px;
                position:relative;
                &:hover {
                    cursor: pointer;
                }
                td{
                    display:flex;
                    ${'' /* flex:1; */}
                    height:100%;
                    align-items:center;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 1.68;
                    color: #222222;
                    overflow:hidden;
                    :first-child{
                        margin-left:20px;
                        flex-basis: 100px;
                    }
                    :nth-child(2), :nth-child(4), :nth-child(5), :nth-child(6){
                        flex: 1;
                    }
                    :nth-child(2) {
                        span {
                            /*max-width: 150px;*/
                            white-space: nowrap; 
                            overflow: hidden;
                            text-overflow: ellipsis;
                            padding-right: 13px;
                        }
                    }
                    :nth-child(3){
                        flex: 1;
                        span {
                            /*max-width: 300px;*/
                            white-space: nowrap; 
                            overflow: hidden;
                            text-overflow: ellipsis;
                            padding-right: 13px;
                        }
                    }
                    :nth-child(4){
                        span {
                            /*max-width: 150px;*/
                            white-space: nowrap; 
                            overflow: hidden;
                            text-overflow: ellipsis;
                            padding-right: 13px;
                        }
                    }
                    :nth-child(5){
                        span{
                            /*width: 102px;*/
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            padding-right: 13px;
                        }
                    }
                    :nth-child(6){
                        color: #979797;
                    }
                }
                .button-control{
                    display:flex;
                    flex:unset;
                    width:108px;
                    height:100%;
                    .more-button{
                        position:absolute;
                        top:50%;
                        right:8.5px;
                        transform:translate(0,-50%);
                        width:24px;
                        height:24px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
`
const limitPerPage = 10
export default class TableControl extends Component {
    state = {
        data: this.props.data,
        currentPage: 1,
        startIndex: 0,
        endIndex: 10,
        isActive: false
    }

    onPageChange = (page) => {
        let currentPage = page
        this.setState({ currentPage })
        const { lastConditionFilterUser } = this.props.filterUserReducer;
        this.props.filterUser(lastConditionFilterUser, 10, page)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps !== this.props) {
            this.setState({
                data: nextProps.data
            })
        }
        if (
            (JSON.stringify(nextProps.filterUserReducer.lastConditionFilterUser) !==
                JSON.stringify(this.props.filterUserReducer.lastConditionFilterUser)) ||
            (this.props.filterUserReducer.total !== nextProps.filterUserReducer.total)
        ) {
            // debugger;
            this.setState({ currentPage: 1 })
        }
    }

    // componentDidUpdate(prevProps, prevState) {
    //     if(prevProps.filterUserReducer.total < this.props.filterUserReducer.total){
    //         debugger;
    //         this.setState({currentPage: 1})
    //     }
    // }

    renderTitleContent() {
        return this.state.data.title.map((item, i) => {
            return (
                <th key={i}>
                    {item}
                </th>
            )
        })
    }
    rendeRowTable() {
        const { Roles } = this.props.dataLogin;

        return this.state.data.content.map((data, i) => {
            return (
                <tr
                    key={i}
                    className='table-body'
                    onDoubleClick={() =>
                        this.props.onShowPopupModify({
                            ...data,
                            isReadOnly: Roles.includes(ADMIN_OFFICE_ROLE),
                            currentPage: this.state.currentPage
                        })
                    }
                >
                    {
                        this.renderRowContent(data, i)
                    }
                    {
                        Roles.includes(ADMIN_OFFICE_ROLE)
                            ? <td className='button-control'></td>
                            : <td className='button-control'>
                                <img src={ic_more_gray} className='more-button' id={'more_button_id_' + i} alt='ic_more'
                                    onClick={() => { this.onShowControl(i, data) }} />
                            </td>
                    }
                </tr>
            )
        })
    }
    onShowControl(index, data) {
        let moreBtn = document.getElementById('more_button_id_' + index);
        if (moreBtn) {
            let bound = moreBtn.getBoundingClientRect()
            let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            let topBound = bound.top + scrollTop
            let leftBound = bound.left + scrollLeft
            let offsetPopup = {
                top: topBound + bound.height / 2,
                left: leftBound - 78 + bound.width / 2
            }
            data = { ...data, currentPage: this.state.currentPage }
            this.props.onShowControl(offsetPopup, 'Permission', data)

        }
    }
    renderRowContent(rowData, keyVal) {
        // debugger;
        // const roleInVietNamese = setPermissionSingleChoise.rank.find(item => item.type === rowData[3]);

        const detailOfListRoles = setPermissionSingleChoise.rank.filter(item => rowData[3].includes(item.type))

        // if(roleInVietNamese){
        // rowData[3] = roleInVietNamese.name
        // rowData[3] = detailOfListRoles.map(item => item.name).join(", ")
        // }



        return rowData.map((data, i) => {
            if (i === 3) {
                const rolesText = detailOfListRoles.map(item => item.name).join(", ")
                return <td key={i} title={rolesText}>
                    <span>{rolesText}</span>
                </td>
            }
            return (
                <td key={i} title={i === 0 ? "" : data}>
                    <span>{i === 0 ? formatNumber(keyVal + 1 + (this.state.currentPage - 1) * 10, 5) : data}</span>
                </td>
            )
        })
    }

    render() {

        const total = this.props.filterUserReducer.total
        return (
            <Bound>
                <table cellSpacing="0" cellPadding="0" className='table'>
                    <thead>
                        <tr className='table-head'>
                            {this.renderTitleContent()}
                            <th className='button-control'>
                                {
                                    total > 10 ?
                                        <SortControl
                                            currentPage={this.state.currentPage}
                                            totalItem={total}
                                            limitPerPage={limitPerPage}
                                            onChangePage={this.onPageChange}
                                            isActive='visible'
                                        />
                                        :
                                        <SortControl
                                            currentPage={this.state.currentPage}
                                            totalItem={total}
                                            limitPerPage={limitPerPage}
                                            onChangePage={this.onPageChange}
                                            isActive='hidden'
                                        />
                                }
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.rendeRowTable()}
                    </tbody>
                </table>
            </Bound>
        )
    }
}