import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
// Component
import SubHeaderControl from '../Control/SubHeaderControl'
import PagingControl from '../Control/PagningControl'
import EmptyControl from '../Control/EmptyControl'
import DeviceKiosItem from './DeviceKiosItem'
import DeviceLCDItem from './DeviceLCDItem'
// Data
import { TrandingRoomTabArr, emptyData } from '../../../../data/data'
import {
    getAllKiosk,
    generateKeyConnectKiosk,
    updateEForm,
    clearKeyGenKiosk,
} from '../../../../actions/kioskAction'
import { getAllLCD, generateKeyConnectLCD, clearKeyGenLcd } from '../../../../actions/LCDAction'
// import { DataCity } from "../../../../data/VietNamData/DataCity";
import { dataCity } from '../../../../data/VietNamData/DataCity'
import { dataDistrict } from '../../../../data/VietNamData/DataDistrict'
import { dataWard } from '../../../../data/VietNamData/DataWard'

const Bound = styled.div`
    font-family: 'SVN-Gilroy';
    font-size: 14px;
    font-weight: bold;
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
    /*min-width:1260px;*/
    .title {
        background-color: #fafafa;
        height: 48px;
        display: flex;
        flex-direction: row;
        text-align: center;
        align-items: flex-start;
        .choise {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 120px;
            height: calc(100% - 3px);
            cursor: pointer;
        }
    }
    .ad-container {
        display: flex;
        flex: 1;
        overflow: hidden;
        padding: 0 40px;
        flex-direction: column;
        .title-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            p {
                color: #005993;
                font-size: 11px;
                text-transform: uppercase;
                margin: 26px 0 17px 0;
            }
        }
        .item {
            display: flex;
            flex-direction: column;
            overflow: hidden;
            & > p {
                text-transform: uppercase;
            }
            .scroll {
                overflow: auto;
                &::-webkit-scrollbar {
                    width: 0px;
                }
                .item-child {
                    display: grid;
                    width: 100%;
                    grid-template-columns: 20% 20% 20% 20%;
                    /*justify-content: space-evenly;*/
                    justify-content: space-between;
                    grid-gap: 13px 11px;

                    ::-webkit-scrollbar {
                        display: none;
                    }
                }
                @media screen and (min-width: 1024px) {
                    .item-child {
                        grid-template-columns: repeat(4, 280px);
                        grid-gap: 13px 11px;
                    }
                }
            }
        }
    }
`
const limitPerPage = 8
class TrandingRoomDevice extends Component {
    _isMounted = false
    state = {
        tradingTab: [],
        typeSelected: TrandingRoomTabArr[0].key,
        subHeaderData: TrandingRoomTabArr[0].subHeaderData,
        data: this.props.data,
        currentPage: 1,
        startIndex: 0,
        endIndex: 10,
        // totalAds: 8,
        alertContent: '',
    }

    componentWillMount() {
        let tradingTab = JSON.parse(JSON.stringify(TrandingRoomTabArr))
        const { dataLogin } = this.props.userReducer
        tradingTab = tradingTab.map((item) => ({
            ...item,
            subHeaderData: {
                ...item.subHeaderData,
                mainTitle: dataLogin.OfficeName,
                subTitle: [
                    dataLogin.OfficeAddress,
                    dataWard[dataLogin.OfficeWard].name,
                    dataDistrict[dataLogin.OfficeDistrict].name,
                    dataCity[dataLogin.OfficeProvince].name
                ].join(", "),
            },
        }))
        this.setState({
            tradingTab,
            typeSelected: tradingTab[0].key,
            subHeaderData: tradingTab[0].subHeaderData,
        })
    }

    componentDidMount() {
        this._isMounted = true
        this._isMounted && this.pagnationPage()
        if (this._isMounted) {
            this._isMounted &&
                this.props.getAllKiosk(
                    this.props.userReducer.dataLogin.OfficeID,
                    8,
                    1
                )
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (
            prevProps.kioskReducer.kioskInfo !==
            this.props.kioskReducer.kioskInfo
        ) {
            this.setState({ currentPage: 1 })
        } else if (
            prevProps.LCDReducer.LCDInfo !== this.props.LCDReducer.LCDInfo
        ) {
            this.setState({ currentPage: 1 })
        }
    }

    setTypeSelected = async (tab) => {
        if (tab) {
            const { typeSelected } = this.state
            this.setState(
                {
                    typeSelected: tab.key,
                    subHeaderData: tab.subHeaderData,
                    currentPage: 1,
                },
                async () => {
                    if (typeSelected === TrandingRoomTabArr[0].key) {
                        ;(await this._isMounted) &&
                            this.props.getAllLCD(
                                this.props.userReducer.dataLogin.OfficeID,
                                8,
                                1
                            )
                    } else {
                        ;(await this._isMounted) &&
                            this.props.getAllKiosk(
                                this.props.userReducer.dataLogin.OfficeID,
                                8,
                                1
                            )
                    }
                }
            )
        }
    }
    pagnationPage() {
        let endIndex = this.state.currentPage * limitPerPage
        let startIndex = endIndex - limitPerPage
        this.setState({ startIndex, endIndex })
    }
    onPageChange = (e) => {
        let currentPage = e
        let endIndex = currentPage * limitPerPage
        let startIndex = endIndex - limitPerPage
        this.setState({ currentPage, startIndex, endIndex })
        if (this.state.typeSelected === TrandingRoomTabArr[0].key) {
            this._isMounted &&
                this.props.getAllKiosk(
                    this.props.userReducer.dataLogin.OfficeID,
                    8,
                    currentPage
                )
        } else {
            this._isMounted &&
                this.props.getAllLCD(
                    this.props.userReducer.dataLogin.OfficeID,
                    8,
                    currentPage
                )
        }
    }
    showPopupAddAdvert = () => {
        const { typeSelected } = this.state
        switch (typeSelected) {
            case TrandingRoomTabArr[0].key: {
                this.props.showAddTrandingRoomKioskPopup(this.state.currentPage)
                break
            }
            case TrandingRoomTabArr[1].key: {
                this.props.showAddTrandingRoomLCDPopup()
                break
            }
            default:
                break
        }
    }
    renderTab() {
        return this.state.tradingTab.map((tab, i) => {
            return (
                <div
                    className="choise"
                    key={i}
                    style={
                        this.state.typeSelected === tab.key
                            ? {
                                  color: tab.activeColor,
                                  borderBottom: tab.avtiveBorderBottom,
                              }
                            : { color: tab.disActiveColor }
                    }
                    onClick={() => this.setTypeSelected(tab)}
                >
                    {tab.label}
                </div>
            )
        })
    }

    renderTitleList = () => {
        return TrandingRoomTabArr.find(
            (tab) => tab.key === this.state.typeSelected
        ).title
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    render() {
        let { subHeaderData, typeSelected /*, currentPage*/ } = this.state
        // let dataSlice = this.state.data.slice(startIndex, endIndex)
        // console.log(dataSlice);
        const { listKiosk, total, currentPage } = this.props.kioskReducer
        const {
            listLCD,
            total: totalLcd,
            currentPage: currentPageLcd,
        } = this.props.LCDReducer

        let totalPage
        if (this.state.typeSelected === TrandingRoomTabArr[0].key) {
            totalPage = total
        } else {
            totalPage = totalLcd
        }

        return (
            <Bound>
                <div className="title">{this.renderTab()}</div>
                <div className="ad-container">
                    <SubHeaderControl
                        data={subHeaderData}
                        onAddMoreSubmit={this.showPopupAddAdvert}
                    />
                    {this._isMounted && totalPage > 0 ? (
                        <div className="item">
                            <div className="title-wrapper">
                                <p>{this.renderTitleList()}</p>
                                {totalPage > limitPerPage ? (
                                    <PagingControl
                                        currentPage={
                                            typeSelected ===
                                            TrandingRoomTabArr[0].key
                                                ? currentPage
                                                : currentPageLcd
                                        }
                                        totalItem={totalPage}
                                        limitPerPage={limitPerPage}
                                        onChangePage={(page) =>
                                            this.onPageChange(page)
                                        }
                                    />
                                ) : null}
                            </div>
                            <div className="scroll">
                                <div className="item-child">
                                    {
                                        // this._isMounted && dataSlice.map((item, i) => {
                                        this._isMounted &&
                                            listKiosk.map((item, i) => {
                                                switch (typeSelected) {
                                                    case TrandingRoomTabArr[0]
                                                        .key: {
                                                        return (
                                                            <DeviceKiosItem
                                                                clearKeyGenKiosk={
                                                                    this.props
                                                                        .clearKeyGenKiosk
                                                                }
                                                                currentPage={
                                                                    currentPage
                                                                }
                                                                key={i}
                                                                keyValue={i}
                                                                data={item}
                                                                dataGenkey={
                                                                    item.dataGenKey ? item.dataGenKey : null
                                                                }
                                                                onShowControl={(
                                                                    offset,
                                                                    from,
                                                                    data
                                                                ) => {
                                                                    this.props.onShowControl(
                                                                        offset,
                                                                        from,
                                                                        data
                                                                    )
                                                                }}
                                                                onShowPopupEditKiosk={(
                                                                    data
                                                                ) =>
                                                                    this.props.onShowPopupEditKiosk(
                                                                        data
                                                                    )
                                                                }
                                                                generateKeyConnectKiosk={
                                                                    this.props
                                                                        .generateKeyConnectKiosk
                                                                }
                                                            />
                                                        )
                                                    }
                                                    default:
                                                        break
                                                }
                                                return null
                                            })
                                    }
                                    {(() => {
                                        return (
                                            this._isMounted &&
                                            listLCD.map((item, i) => {
                                                switch (typeSelected) {
                                                    case TrandingRoomTabArr[1]
                                                        .key: {
                                                        return (
                                                            <DeviceLCDItem
                                                                clearKeyGenLcd={this.props.clearKeyGenLcd}
                                                                currentPage={
                                                                    currentPage
                                                                }
                                                                key={i}
                                                                keyValue={i}
                                                                data={item}
                                                                dataGenkey={
                                                                    this.props
                                                                        .LCDReducer
                                                                        .dataGenKey
                                                                }
                                                                onShowPopupEditLCD={(
                                                                    data
                                                                ) =>
                                                                    this.props.onShowPopupEditLCD(
                                                                        data
                                                                    )
                                                                }
                                                                onShowControl={(
                                                                    offset,
                                                                    from,
                                                                    data
                                                                ) => {
                                                                    this.props.onShowControl(
                                                                        offset,
                                                                        from,
                                                                        data,
                                                                        this
                                                                            .state
                                                                            .currentPage
                                                                    )
                                                                }}
                                                                generateKeyConnectLCD={
                                                                    this.props
                                                                        .generateKeyConnectLCD
                                                                }
                                                            />
                                                        )
                                                    }
                                                    default:
                                                        break
                                                }
                                                return null
                                            })
                                        )
                                    })()}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <EmptyControl
                            alertContent={this.state.alertContent}
                            widthInput={400}
                            content={emptyData.deviceKiosk}
                        />
                    )}
                </div>
            </Bound>
        )
    }
}

const mapStateToProps = (state) => ({
    kioskReducer: state.kioskReducer,
    userReducer: state.userReducer,
    LCDReducer: state.LCDReducer,
})

const mapDispatchToProps = (dispatch) => ({
    getAllKiosk: (idOffice, pageSize, pageNumber) =>
        dispatch(getAllKiosk(idOffice, pageSize, pageNumber)),
    getAllLCD: (idOffice, pageSize, pageNumber) =>
        dispatch(getAllLCD(idOffice, pageSize, pageNumber)),
    generateKeyConnectKiosk: (idOffice, idKiosk) =>
        dispatch(generateKeyConnectKiosk(idOffice, idKiosk)),
    generateKeyConnectLCD: (idOffice, idLCD) =>
        dispatch(generateKeyConnectLCD(idOffice, idLCD)),

    clearKeyGenKiosk: (kioskId, kioskName, key) =>
        dispatch(clearKeyGenKiosk(kioskId, kioskName, key)),
    clearKeyGenLcd: (lcdId, lcdName, key) =>
        dispatch(clearKeyGenLcd(lcdId, lcdName, key)),
})

export default connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true,
})(TrandingRoomDevice)
