import React, { useState, useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

// styles
import { WrapperSearchReport } from './index.styles'

// image
import ic_search_gray from '../../../../../../images/ic_search_gray.png'
import ic_close_black from '../../../../../../images/ic_close_black.svg'

// hook
import useDebounce from '../../../../../../helper/Hooks/useDebouce'

// action
import { getListReports } from '../../../../../../actions/reportsAction/listReports/listReports'

const SearchReport = () => {
    const dispatch = useDispatch()

    const { listParents, condFilter } = useSelector(state => state.reports.listReports)

    const [reportSelected, setReportSelected] = useState(null)
    const [keySearch, setKeySearch] = useState('')
    const keySearchDebounce = useDebounce(keySearch, 1000)

    const _handleOnChange = useCallback(
        (e) => {
          const { value } = e.target;
          setKeySearch(value)
        },
        [],
    )

    useEffect(() => {
        if(!condFilter.ParentCode) {
            return;
        }

        setReportSelected(listParents[condFilter.ParentCode])
    }, [condFilter, listParents])

    useEffect(() => {
        const { Searchtext } = condFilter
        const keySearchStrip = keySearchDebounce.trim().replace(/\s+/, ' ')
        if(Searchtext === keySearchStrip) return;
        dispatch(getListReports({
            ...condFilter,
            Searchtext: keySearch
        }))
    }, [keySearchDebounce, condFilter])

    return (
        <WrapperSearchReport>
            <div className="title-report-list">{reportSelected?.Name}</div>
            <div className="wrap-search">
                <img 
                    src={ic_search_gray}
                    alt="search" 
                    className="search"
                />
                <input 
                    type="text" 
                    placeholder="Nhập tên, mã báo cáo"
                    value={keySearch}
                    onChange={_handleOnChange}
                />
                <div className="wrap-btn-clear">
                {
                    keySearch.length > 0 &&
                    <img 
                        src={ic_close_black} 
                        alt="clear" 
                        onClick={() => setKeySearch('')}
                    />
                }
                </div>
            </div>
        </WrapperSearchReport>
    )
}

export default SearchReport
