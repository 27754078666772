import styled from "styled-components";

const WrapTableBio = styled.div`
    .header-table{
        display: grid;
        grid-template-columns: minmax(70px,100px) minmax(70px,100px) minmax(150px,1fr) minmax(70px,150px) minmax(100px,150px) minmax(70px,150px) minmax(50px,70px);
        height: 48px;
        font-family: SVN-Gilroy;
        font-size: 10px;
        font-weight: bold;
        line-height: 1.26;
        color: #005993;
        padding-left: 20px;
        .item-table{
            display: flex;
            align-items: center;
        }
    }
    .content-table{
        display: grid;
        grid-template-columns:  minmax(70px,100px) minmax(70px,100px) minmax(150px,1fr) minmax(70px,150px) minmax(100px,150px) minmax(70px,150px) minmax(50px,70px);
        font-family: SVN-Gilroy;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.3;
        height: 60px;
        color: #191919;
        background-color: #ffffff;
        border-radius: 10px;
        padding-left: 20px;
        align-items: center;
        cursor: pointer;
        .item-table{
            &.item-name{
                text-transform: uppercase;
                font-weight: 700;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                padding-right: 10px;
                
            }
            &.details{
                color: #005993;
                cursor: pointer;
                text-decoration: underline;
            }
        }
    }

`

export {
    WrapTableBio
}