import React, { useState } from 'react'

// styles
import { WrapBioInfos } from './index.styles'

// images
import bg_finger from '../../../../../../images/bg_finger.svg'
import icDefaultUser from '../../../../../../images/default_user.svg'
import fingerAuthenTrans from '../../../../../../images/finger_authen_trans.svg'
import fingerClosedUpdate from '../../../../../../images/finger_closed_update.svg'
import fingerHangUpdate from '../../../../../../images/finger_hang_update.svg'
import icZoom from '../../../../../../images/ic_zoom.svg'

// constant
import { FACE } from '../../../../../../data/infosType'

// api
import { apiFaceHistory } from '../../../../../../actions/supervisorAction/biometricAction'

// component
import Modal from '../../../../../../helper/Modal'
import PopupShowFace from './PopupShowFace'
import PopupShowFinger from './PopupShowFinger'

// helper
import getFromChannel from '../../../../../../helper/getFromChannel'
import getChannelName from '../../../../../../helper/getChannelName'

const MOCK_IMG = 'https://picsum.photos/200/300'

const getStatusBio = (bioType, isActive, isConfirmFinger) => {
    if (bioType === FACE) {
        switch (true) {
            case isActive && isConfirmFinger:
                return {
                    class: 'active',
                    text: 'Hoạt động'
                }
            case !isActive && isConfirmFinger:
                return {
                    class: 'hang',
                    text: 'Đóng'
                }
            default:
                return {
                    class: '',
                    text: ''
                }
        }
    }
    switch (true) {
        case isActive && isConfirmFinger:
            return {
                class: 'active',
                text: 'Hoạt động',
                imgUrl: fingerAuthenTrans
            }
        case !isActive && !isConfirmFinger:
            return {
                class: 'close',
                text: 'Đóng',
                imgUrl: fingerClosedUpdate
            }
        case !isActive && isConfirmFinger:
            return {
                class: 'hang',
                text: 'Treo',
                imgUrl: fingerHangUpdate
            }
        default:
            return {
                class: '',
                text: ''
            }
    }
}

const biometrictFingerData = [
    {
        typeFinger: 'THUMB_LEFT',
        bottom: '86',
        left: '24',
    },
    {
        typeFinger: 'INDEX_LEFT',
        bottom: '209',
        left: '57',
    },
    {
        typeFinger: 'MIDDLE_LEFT',
        bottom: '236',
        left: '91',
    },
    {
        typeFinger: 'RING_LEFT',
        bottom: '234',
        left: '145',
    },
    {
        typeFinger: 'PINKY_LEFT',
        bottom: '211',
        left: '200',
    },
    {
        typeFinger: 'THUMB_RIGHT',
        bottom: '86',
        right: '34',
    },
    {
        typeFinger: 'INDEX_RIGHT',
        bottom: '208',
        right: '64',
    },
    {
        typeFinger: 'MIDDLE_RIGHT',
        bottom: '235',
        right: '99',
    },
    {
        typeFinger: 'RING_RIGHT',
        bottom: '233',
        right: '152',
    },
    {
        typeFinger: 'PINKY_RIGHT',
        bottom: '210',
        right: '208',
    },
]


const BioInfos = ({
    bios: {
        BioDatas
    }
}) => {

    const [showPopupFace, setShowPopupFace] = useState(false);
    const [showFinger, setShowFinger] = useState(null);

    const bioFace = BioDatas && BioDatas.find(bio => bio.BioType === FACE)
    const statusFace = !!bioFace ? getStatusBio(FACE, bioFace.IsActive, bioFace.IsConfirmFinger) : null

    const bioFinger = BioDatas && BioDatas.filter(bio => bio.BioType !== FACE)

    const statusFinger = (bioFinger || [])
        .map((finger) => ({
            ...finger,
            fromChannel: getFromChannel(finger.FromChannel),
            ...getStatusBio(finger.BioType, finger.IsActive, finger.IsConfirmFinger)
        }))
        .reduce((prev, curr) => ({ ...prev, [curr.BioType]: curr }), {})

    const fromChannel = getFromChannel(bioFace?.FromChannel)

    return (
        <WrapBioInfos>
            <div className="first-col">
                <div className="wrap-face">
                    <div className="wrap-inner-face" onClick={() => !!bioFace ? setShowPopupFace(true) : null}>
                        <img
                            src={!!bioFace ? apiFaceHistory(bioFace.EnrollData[0]) : icDefaultUser}
                            alt="face"
                            className="face"
                        />
                        {
                            !!bioFace &&
                            <img className="ic-zoom" src={icZoom} alt="zoom" />
                        }
                    </div>
                    <Modal isShow={showPopupFace}>
                        {
                            !!bioFace
                                ? <PopupShowFace
                                    url={apiFaceHistory(bioFace.EnrollData[0])}
                                    fromChannel={fromChannel}
                                    isActive={bioFace.IsActive}
                                    isConfirmFinger={bioFace.IsConfirmFinger}
                                    handleClose={() => setShowPopupFace(false)}
                                />
                                : null
                        }
                    </Modal>
                </div>
                {
                    !!statusFace &&
                    <div className="status">Trạng thái: <span className={statusFace.class}>{statusFace.text}</span></div>
                }
                <div className="channel">Kênh tác nghiệp: {getChannelName(fromChannel)}</div>
            </div>
            <div className="last-col">
                <div className="status-note">
                    <div className="status-note-item"><span className="delete"></span>Xóa</div>
                    <div className="status-note-item"><span className="active"></span>Hoạt động</div>
                    <div className="status-note-item"><span className="close"></span>Đóng</div>
                    <div className="status-note-item"><span className="hang"></span>Treo</div>
                </div>
                <img src={bg_finger} alt="hands" />
                <div className="hand-title left">Bàn tay trái</div>
                <div className="hand-title right">Bàn tay phải</div>
                {
                    biometrictFingerData.map(finger => {
                        const statusOfFingerItem = statusFinger[finger.typeFinger]
                        if (statusOfFingerItem)
                            return (
                                <img
                                    key={finger.typeFinger}
                                    onClick={() => setShowFinger(finger.typeFinger)}
                                    src={statusOfFingerItem.imgUrl}
                                    alt="finger"
                                    style={{
                                        top: finger.top ? `${finger.top}px` : undefined,
                                        left: finger.left ? `${finger.left}px` : undefined,
                                        bottom: finger.bottom ? `${finger.bottom}px` : undefined,
                                        right: finger.right ? `${finger.right}px` : undefined,
                                    }}
                                    className="finger"
                                />
                            )
                        return null
                    })
                }
                <Modal isShow={showFinger !== null}>
                    <PopupShowFinger
                        dataFinger={statusFinger[showFinger]}
                        handleClose={() => setShowFinger(null)}
                    />
                </Modal>
            </div>
        </WrapBioInfos>
    )
}

export default BioInfos
