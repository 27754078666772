import React from 'react'

// styles
import {
    extendStylesSelect,
    extendStylesDropdown,
    DropDownStyles,
} from './index.styles'

// component
import DropDownSearchObj from '../../../../../Control/DropDownSearchObj'

const DropDown = ({ extendStyles, maxNumShowDropdown, label, ...props }) => {
    return (
        <>
            <div className="label-tools-item">{label}</div>
            <DropDownStyles className="tools-item" extendStyles={extendStyles}>
                <DropDownSearchObj
                    {...props}
                    extendStylesSelect={extendStylesSelect}
                    extendStylesDropdown={extendStylesDropdown}
                    maxNumShowDropdown={maxNumShowDropdown}
                />
            </DropDownStyles>
        </>
    )
}

export default DropDown
