import React, { useState, useEffect, useReducer, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// styles
import { WrapperSetup } from './index.styles'

// component
import CheckBoxItem from './CheckBoxItem'
import DropDownSearchObjV2 from '../../../MainScreen/Control/DropDownSearchObjV2'
import SubGroupReport from '../../PopupAccountPermissionV2/Main/SubGroupReport'

// constant
import { setPermissionSingleChoise } from '../../../../../data/singleChoiseData'
import { RPW, IFW, ADMIN_OFFICE_ROLE, TELLER, INBIOCOLLECTOR, OFFINSUPERVISOR, OFFEXSUPERVISOR } from '../../../../../data/userRole'
import { MAIN_OFFICE_BRANCH_CODE } from '../../../../../data/commonConstant'

// action
import { filterOffice, getAllOffice } from '../../../../../actions/officeAction'

const SetupUser = ({
    stateConfig,
    dispatchConfig,
    listReports,
    objListReports,
    listParentReports
}) => {

    const dispatch = useDispatch()

    const { filterOfficeReducer } = useSelector((state) => ({
        filterOfficeReducer: state.filterOfficeReducer
    }))

    const [listOffice, setListOffice] = useState([])
    const [isExpandSubRoleReports, setIsExpandSubRoleReports] = useState(true);
    const [isExpandSubRoleQueries, setIsExpandSubRoleQueries] = useState(true);


    const _handleCheck = useCallback(
        (type) => {
            if (stateConfig.lstRoles.includes(type)) {
                const newLstRoles = stateConfig.lstRoles.filter(item => item !== type)
                if (type === RPW) {
                    dispatchConfig({
                        lstRoles: newLstRoles,
                        reportPermission: []
                    })
                    return;
                }
                if (type === IFW) {
                    dispatchConfig({
                        lstRoles: newLstRoles,
                        infosPermission: []
                    })
                    return;
                }

                dispatchConfig({
                    lstRoles: newLstRoles
                })
                return;
            }

            const newLstRoles = [...stateConfig.lstRoles, type]
            if (type === RPW) {
                dispatchConfig({
                    lstRoles: newLstRoles,
                    reportPermission: listReports.map(item => item.CodeName)
                })
                return;
            }
            if (type === IFW) {
                const childrenRoleInIFW = setPermissionSingleChoise.rank.find(item => item.type === IFW)
                dispatchConfig({
                    lstRoles: newLstRoles,
                    infosPermission: childrenRoleInIFW.children.map(item => item.type)
                })
                return;
            }

            dispatchConfig({
                lstRoles: newLstRoles
            })
        },
        [stateConfig.lstRoles],
    )

    const _handleCheckInfos = (type) => {
        if (stateConfig.infosPermission.includes(type)) {
            const newInfosPermission = stateConfig.infosPermission.filter(item => item !== type)
            if (newInfosPermission.length === 0) {
                dispatchConfig({
                    lstRoles: stateConfig.lstRoles.filter(item => item !== IFW),
                    infosPermission: newInfosPermission
                })
                return;
            }
            dispatchConfig({
                infosPermission: newInfosPermission
            })
            return;
        }

        if (stateConfig.infosPermission.length === 0) {
            dispatchConfig({
                lstRoles: [...stateConfig.lstRoles, IFW],
                infosPermission: [type]
            })
            return;
        }
        dispatchConfig({
            infosPermission: [...stateConfig.infosPermission, type]
        })
    }

    const _handleToggleListSubRole = (setIsExpand) => {
        setIsExpand(prev => !prev)
    }

    const _handleChange = (arr) => {
        if (!arr.length) return;
        const atOffice = arr[0]
        dispatchConfig({ atOffice })
        if (atOffice.BranchCode === MAIN_OFFICE_BRANCH_CODE) {
            const { lstRoles } = stateConfig
            const rolesValidInMainOffice = [ADMIN_OFFICE_ROLE, TELLER, INBIOCOLLECTOR, OFFINSUPERVISOR, OFFEXSUPERVISOR]
            const newLstRoles = lstRoles.filter(role => !rolesValidInMainOffice.includes(role))
            dispatchConfig({ lstRoles: newLstRoles })
        }
    }

    const _handleSearch = (keySearch) => {
        dispatch(filterOffice({ name: keySearch }, 1000000, 1))
    }

    useEffect(() => {
        dispatch(getAllOffice())
    }, [])

    useEffect(() => {
        const listOffice = filterOfficeReducer.listOffice.map(({ ID, Name, BranchCode }) => ({
            // id: ID,
            // name: Name,

            key: ID,
            value: `${BranchCode} - ${Name}`,
            BranchCode
        }))
        setListOffice(listOffice)
    }, [filterOfficeReducer])

    return (
        <WrapperSetup>
            <div className="title">CN/PGD</div>
            <div className="wrap-dropdown">
                <DropDownSearchObjV2
                    lstItemFixed={[]}
                    lstItems={listOffice}
                    onChange={_handleChange}
                    onSearch={_handleSearch}
                    defaultValue={!!stateConfig.atOffice && [stateConfig.atOffice]}
                    haveSearch={true}
                    placeHolder="Nhập mã, tên chi nhánh"
                    placeHolderSelect="Chọn CN/PGD"
                />
            </div>
            <div className="title">Vai trò</div>
            <div className="wrap-roles">
                {
                    setPermissionSingleChoise.rank.map((item, key) => {
                        if (item.type === RPW) {
                            return (
                                <div className="grid-full-col" key={key}>
                                    <div className="wrap-main-role">
                                        <CheckBoxItem
                                            onClick={() => _handleCheck(item.type)}
                                            isChecked={listReports.length > 0 && stateConfig.reportPermission.length === listReports.length}
                                            isCheckedSome={stateConfig.reportPermission.length > 0}
                                            isDisabled={listReports.length === 0 || item.notCombined.some(role => stateConfig.lstRoles.includes(role))}
                                        >
                                            {item.name}
                                        </CheckBoxItem>
                                        {
                                            // stateConfig.reportPermission.length > 0 &&
                                            <div className="sum-of-sub-role-selected" onClick={() => _handleToggleListSubRole(setIsExpandSubRoleReports)}>
                                                {stateConfig.reportPermission.length ? ("0" + stateConfig.reportPermission.length).slice(-2) : 0} tùy chỉnh
                                                <span className="down-arrow" />
                                            </div>
                                        }
                                    </div>
                                    {
                                        isExpandSubRoleReports &&
                                        <div className="wrap-list-children">
                                            {
                                                listParentReports.map((parentReport) => (
                                                    <SubGroupReport
                                                        key={parentReport.ID}
                                                        parentReport={parentReport}
                                                        objListReports={objListReports}
                                                        stateConfig={stateConfig}
                                                        dispatchConfig={dispatchConfig}
                                                    />
                                                ))
                                            }
                                        </div>
                                    }
                                </div>
                            )
                        }

                        if (item.children) {
                            return (
                                <div className="grid-full-col" key={key}>
                                    <div className="wrap-main-role">
                                        <CheckBoxItem
                                            onClick={() => _handleCheck(item.type)}
                                            isChecked={item.children.length > 0 && stateConfig.infosPermission.length === item.children.length}
                                            isCheckedSome={stateConfig.infosPermission.length > 0}
                                            isDisabled={item.children.length === 0 || item.notCombined.some(role => stateConfig.lstRoles.includes(role))}
                                        >
                                            {item.name}
                                        </CheckBoxItem>
                                        {
                                            // stateConfig.infosPermission.length > 0 &&
                                            <div className="sum-of-sub-role-selected" onClick={() => _handleToggleListSubRole(setIsExpandSubRoleQueries)}>
                                                {stateConfig.infosPermission.length ? ("0" + stateConfig.infosPermission.length).slice(-2) : 0} tùy chỉnh
                                                <span className="down-arrow" />
                                            </div>
                                        }
                                    </div>
                                    {
                                        isExpandSubRoleQueries &&
                                        <div className="wrap-list-children">
                                            {item.children.map(({ type, name }) => (
                                                <CheckBoxItem
                                                    key={type}
                                                    onClick={() => _handleCheckInfos(type)}
                                                    isChecked={stateConfig.infosPermission.includes(type)}
                                                >
                                                    {name}
                                                </CheckBoxItem>
                                            ))}
                                        </div>
                                    }
                                </div>
                            )
                        }

                        return (
                            <CheckBoxItem
                                key={key}
                                onClick={() => _handleCheck(item.type)}
                                isChecked={stateConfig.lstRoles.includes(item.type)}
                                isDisabled={
                                    stateConfig.atOffice?.BranchCode === MAIN_OFFICE_BRANCH_CODE
                                    || item.notCombined.some(item => stateConfig.lstRoles.includes(item))
                                }
                            >
                                {item.name}
                            </CheckBoxItem>
                        )
                    })
                }
                <div></div>
            </div>
        </WrapperSetup>
    )
}

export default SetupUser
