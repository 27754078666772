import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import moment from 'moment'
import { Prompt } from 'react-router'

//Images
import ic_close from '../../../images/ic_close_gray.svg'
//Component
import LoadingControl from '../MainScreen/Control/LoadingControl'
//Data
import {
    dataLoginVerifyBiometric,
    loginVerifyBiometric,
} from '../../../data/biometricRole'
import { OFFINSUPERVISOR, OFFEXSUPERVISOR } from '../../../data/userRole'
//Action
import {
    acceptBioInfos,
    getBioInfos,
    rejectBioInfos,
} from '../../../actions/supervisorAction/biometricAction'
import { getNumOfDataNotViewYet } from '../../../actions/commonAction'

const NonOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
`
const Bound = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    width: 338px;
    /* height: 375px; */
    border-radius: 5px;
    box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.08);
    background-color: #ffffff;
    padding-bottom: 24px;
    img {
        padding: 10.1px 10.2px 0 0;
        float: right;
        cursor: pointer;
    }
    h3 {
        padding-top: 21.9px;
        padding: 32px 10.2px 0 25px;
        font-family: SVN-Gilroy;
        font-size: 16px;
        font-weight: bold;
        line-height: 1.25;
        color: #222222;
        text-align: center;
    }
    .verify-inform {
        padding: 25px 25px 13px 25px;
        &--content {
            margin-bottom: 13px;
            label {
                font-family: SVN-Gilroy;
                text-transform: uppercase;
                font-size: 11px;
                font-weight: bold;
                line-height: 1.26;
                color: #005993;
            }
            input {
                width: 93%;
                height: 43px;
                border-radius: 4px;
                border: solid 0.5px #d6d6d6;
                background-color: #ffffff;
                margin-top: 5px;
                padding: 0 9px;
                outline: none;
                font-family: SVN-Gilroy;
                &::placeholder {
                    font-family: SVN-Gilroy;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 1.25;
                    color: #222222;
                    opacity: 0.25;
                }
            }
        }
        &--permission {
            display: flex;
            padding-top: 8px;
            label {
                font-family: SVN-Gilroy;
                font-size: 14px;
                line-height: 17.5px;
                color: #222222;
                font-weight: 500;
                padding-right: 3px;
            }
            p {
                font-family: SVN-Gilroy;
                font-size: 14px;
                font-weight: bold;
                line-height: 1.25;
                color: #005993;
            }
        }
    }
    .error {
        /* height: 17px; */
        margin: 0 0 14px 0;
        text-align: center;
        font-family: SVN-Gilroy;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.25;
        color: #f31919;
    }
    .not-match {
        margin: 0 31px 14px 11px;
    }
    .loading {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
        span {
            /* margin-top: 3px; */
            font-family: SVN-Gilroy;
            font-size: 14px;
            font-weight: 500;
            line-height: 1.25;
            color: #979797;
        }
    }
    .btn-apply {
        width: 88px;
        height: 36px;
        border-radius: 18px;
        background-color: #005993;
        font-family: SVN-Gilroy;
        font-size: 13px;
        font-weight: bold;
        color: #ffffff;
        text-transform: uppercase;
        /* margin: 14px auto 0 auto; */
        margin: 24px auto 0 auto;
        cursor: pointer;
    }
`

// const permissions = 'Giám sát dữ liệu nội bộ'
const textPermission = {
    OFFEXSUPERVISOR: 'Giám sát dữ liệu khách hàng',
    OFFINSUPERVISOR: 'Giám sát dữ liệu nội bộ',
    EXCUST: 'Giám sát dữ liệu khách hàng',
    INCUST: 'Giám sát dữ liệu nội bộ'
}
const digit = '000000000000000000000000'
class PopupVerifyApproverV2 extends Component {
    state = {
        // userName: '',
        userName: this.props.userReducer.dataLogin.Email,
        passWord: '',
        err: '',
        isLoading: false,
        shouldBlockNavigation: true,
        permissions: '',
    }

    handleChange = (e) => {
        let name = e.target.name
        let value = e.target.value
        this.setState({
            err: '',
            [name]: value,
        })
    }

    onKeyUp = (e) => {
        if (e.keyCode === 13) {
            this.onSubmit()
        }
    }

    onSubmit = async () => {
        this.setState({ isLoading: true })
        const { data, isShowAlert } = this.props
        let { userName, passWord } = this.state
        // const { Role } = this.props.userReducer.dataLogin
        // const emlRole = loginVerifyBiometric[Role].Role
        const { CustomerType } = data
        const emlRole = loginVerifyBiometric[CustomerType].Role
        const elmEmail = this.props.userReducer.dataLogin.Email
        let elmTextErr

        if (!userName.trim() || !passWord.trim()) {
            this.setState({ isLoading: false })
            this.setState({ err: 'Vui lòng nhập thông tin' })
            return
        }

        if (data.ID === '' || data.ID === digit) {
            this.setState({ isLoading: false })
            this.setState({ err: 'Lỗi dữ liệu. Vui lòng thử lại.' })
            return
        }

        // const m = moment().utcOffset(0)
        // m.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        // m.toISOString()

        !!this.props.verifyAdmin &&
            (await this.props.rejectBioInfos(
                data.ID,
                userName,
                passWord,
                emlRole,
                this.props.noteReject
            ))

        this.props.typeCheckBiometric === 'approveDataBiometric' &&
            (await this.props.acceptBioInfos(
                data.ID,
                userName,
                passWord,
                emlRole
            ))
        if (this.props.biometricReducer.errs !== '')
            switch (this.props.biometricReducer.errs) {
                case 1:
                    elmTextErr =
                        'Không thể phê duyệt dữ liệu sinh trắc học, dữ liệu sinh trắc học không hợp lệ'
                    break
                case 2:
                    elmTextErr =
                        'Không thể phê duyệt dữ liệu sinh trắc học, không có thông tin chờ phê duyệt'
                    break
                case 3:
                    elmTextErr =
                        'Không thể phê duyệt dữ liệu sinh trắc học vào lúc này. Trạng thái sinh trắc học không ở trạng thái chờ'
                    break
                case 4:
                    elmTextErr =
                        'Không được phép phê duyệt dữ liệu sinh trắc học của CN/PGD khác'
                    break
                case 5:
                    elmTextErr =
                        'Lỗi cập nhật trạng thái hoàn thành dữ liệu sinh trắc học'
                    break
                case 6:
                    elmTextErr =
                        'Không tìm thấy thông tin CN/PGD của dữ liệu sinh trắc học chờ phê duyệt'
                    break
                case 8:
                    elmTextErr =
                        'Lỗi lưu lịch sử thay đổi sinh trắc học của khách hàng'
                    break
                case 9:
                    elmTextErr =
                        'Không thể kết nối tới Core để phê duyệt sinh trắc học'
                    break
                case 10:
                    elmTextErr =
                        'Không thể phê duyệt dữ liệu sinh trắc học vào lúc này. Vui lòng thử lại sau'
                    break
                case 11:
                    elmTextErr =
                        'Không thể kết nối tới Core để kiểm tra quyền phê duyệt của KSV'
                    break
                case 12:
                case 503:
                    elmTextErr =
                        'Bạn không có quyền phê duyệt thông tin sinh trắc học'
                    break
                case 20:
                    elmTextErr =
                        'Lỗi cập nhật từ chối sinh trắc học của khách hàng'
                    break
                case 21:
                    elmTextErr =
                        'Lỗi cập nhật lại dữ liệu sinh trắc học cho GDV tại quầy'
                    break
                case 22:
                    elmTextErr =
                        'Không thể phê duyệt dữ liệu sinh trắc học vì không đúng loại dữ liệu khách hàng'
                    break
                case 23:
                    elmTextErr =
                        'Không thể phê duyệt dữ liệu sinh trắc học vì không đúng loại dữ liệu khách hàng'
                    break
                case 30:
                    elmTextErr = 'Không tìm thấy thông tin tài khoản'
                    break
                case 31:
                    elmTextErr = 'Không tìm thấy thông tin tài khoản'
                    break
                case 32:
                    elmTextErr =
                        'Tài khoản đăng nhập và tài khoản phê duyệt không hợp lệ'
                    break
                case 35:
                    elmTextErr =
                        'Lỗi lấy trạng thái sinh trắc học của khách hàng'
                    break
                case 36:
                    elmTextErr =
                        'Không thể cập nhật trạng thái sinh trắc học của khách hàng đang giao dịch'
                    break
                case 41:
                    elmTextErr =
                        'Lỗi lấy thông tin lịch sử cập nhật sinh trắc học của khách hàng'
                    break
                case 101:
                    elmTextErr =
                        'Giá trị gửi lên không đúng. Số CIF không có giá trị'
                    break
                case 102:
                    elmTextErr =
                        'Thông tin CIF đã tồn tại trong hệ thống sinh trắc học. Hoặc thông tin khuôn mặt chưa được xác thực'
                    break
                case 103:
                    elmTextErr =
                        'Không thể lưu trữ dữ liệu sinh trắc học vào lúc này'
                    break
                case 119:
                    elmTextErr = 'Khuôn mặt đã tồn tại trong hệ thống'
                    break
                case 131:
                    elmTextErr =
                        'Không thể xử lý sinh trắc học vân tay vào lúc này'
                    break
                case 501:
                    elmTextErr =
                        'Mật khẩu không chính xác. Vui lòng kiểm tra lại'
                    break
                case 502:
                    elmTextErr =
                        'User phê duyệt không tồn tại trên hệ thống. Vui lòng kiểm tra lại'
                    break
                default:
                    elmTextErr = 'Phê duyệt thông tin thất bại'
                    // this.props.biometricReducer.Message +
                    // ' - Code: ' +
                    // this.props.biometricReducer.errs
                    break
            }
        this.setState({ isLoading: false })
        // if (!elmTextErr) {
        if (this.props.biometricReducer.codes === 0) {
            this.props.getNumOfDataNotViewYet()
            this.props.outPopup()
            this.props.onClosePopupInBiometric()
            this.props.onCloseCheck()
            !!this.props.verifyAdmin &&
                this.props.alertPopup('Từ chối dữ liệu Biometric thành công')
            this.props.typeCheckBiometric === 'approveDataBiometric' &&
                this.props.alertPopup('Xác thực dữ liệu Biometric thành công')
            const { lastConditionFilterUser } = this.props.biometricReducer
            // this.props.getBioInfos({
            //     OfficeID: this.props.userReducer.dataLogin.OfficeID,
            //     PageSize: 10,
            //     PageNumber: 1,
            //     FromCreateAt: m.format(),
            // })
            this.props.getBioInfos({ ...lastConditionFilterUser })
            return
        }
        this.setState({
            err: elmTextErr,
        })
    }

    handleChange = (e) => {
        const value = e.target.value
        const name = e.target.name
        this.setState({
            [name]: value,
            err: '',
        })
    }

    onClose = () => {
        if (this.state.isLoading) return
        this.props.outPopup()
        this.props.onClosePopupInBiometric()
    }

    render() {
        const { title } = this.props
        return (
            <NonOverlay>
                <Prompt
                    when={this.state.shouldBlockNavigation}
                    message={() =>
                        JSON.stringify({
                            title:
                                'Đang thực hiện phê duyệt, bạn không thể thực hiện load lại trạng thái này',
                            cancelText: 'Xác nhận',
                        })
                    }
                />
                <Bound>
                    <img src={ic_close} onClick={this.onClose} alt="ic_close" />
                    <h3>{title}</h3>
                    <div className="verify-inform">
                        <div className="verify-inform--content">
                            <label>username</label>
                            <input
                                type="text"
                                placeholder="Nhập username"
                                name="userName"
                                value={this.state.userName || ''}
                                onChange={this.handleChange}
                                onKeyUp={(e) => this.onKeyUp(e)}
                            />
                        </div>
                        <div className="verify-inform--content">
                            <label>password</label>
                            <input
                                type="password"
                                placeholder="Password"
                                name="passWord"
                                onChange={this.handleChange}
                                onKeyUp={(e) => this.onKeyUp(e)}
                                value={this.state.passWord || ''}
                            />
                        </div>
                        <div className="verify-inform--permission">
                            <label>Phân quyền:</label>
                            <p>{this.state.permissions}</p>
                        </div>
                    </div>
                    <p className="error not-match">{this.state.err}</p>
                    {this.state.isLoading ? (
                        <div className="loading">
                            <LoadingControl size={20} loadingPage={false} />
                            <span>Đang xử lý phê duyệt...</span>
                        </div>
                    ) : (
                        <div
                            className="btn-apply d-center"
                            style={{
                                opacity: this.state.isLoading ? 'unset' : '1',
                            }}
                            onClick={this.onSubmit}
                        >
                            xác nhận
                        </div>
                    )}
                </Bound>
            </NonOverlay>
        )
    }

    handleBlockNavigation = () => {
        if (this.state.shouldBlockNavigation) {
            window.onbeforeunload = () => true
        } else {
            window.onbeforeunload = undefined
        }
    }

    handleReloadPage = (e) => {
        // e.preventDefault();
        const dialogText =
            'Reload có thể làm cho dữ liệu bị lỗi, bạn vẫn muốn thực hiện?'
        e.returnValue = dialogText
        return dialogText
    }

    componentDidUpdate(prevProps, prevState) {
        // if (this.state.shouldBlockNavigation !== prevState.shouldBlockNavigation) {
        //     this.handleBlockNavigation()
        //     return
        // }
    }

    componentDidMount() {
        // this.handleBlockNavigation()
        // const {
        //     dataLogin: { Role },
        // } = this.props.userReducer

        // this.setState({ permissions: textPermission[Role] })

        const { data } = this.props;
        const { CustomerType } = data;
        this.setState({ permissions: textPermission[CustomerType] })


        window.addEventListener('beforeunload', this.handleReloadPage, false)
    }

    componentWillUnmount() {
        // window.onbeforeunload = undefined
        window.removeEventListener('beforeunload', this.handleReloadPage, false)
    }
}

const mapStateToProps = (state) => {
    return {
        userReducer: state.userReducer,
        biometricReducer: state.biometricReducer,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        acceptBioInfos: (idBio, approverName, approverPwd, role) =>
            dispatch(acceptBioInfos(idBio, approverName, approverPwd, role)),
        rejectBioInfos: (idBio, rejectName, rejectPwd, role, note) =>
            dispatch(rejectBioInfos(idBio, rejectName, rejectPwd, role, note)),
        getBioInfos: (dataQuery) => {
            dispatch(getBioInfos(dataQuery))
        },
        getNumOfDataNotViewYet: () => dispatch(getNumOfDataNotViewYet())
    }
}
export default connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true,
})(PopupVerifyApproverV2)
