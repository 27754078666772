import * as userRole from "../data/userRole"

const teller = ["Code", "CounterID", "CounterNumber", "CsrfToken", "Email", "ExpireAt", "FullName", "Message", "OfficeAddress", "OfficeDistrict", "OfficeName", "OfficeProvince", "OfficeWard", "ServAllService", "Token", "UID"]
const tellerVip = [...teller, "IsVIP"].sort();
const adminRoot = ["Code", "CsrfToken", "Email", "ExpireAt", "FullName", "Message", "Role", "UID"]
const adminDiffRoot = ["Code", "CsrfToken", "Email", "ExpireAt", "FullName", "Message", "OfficeAddress", "OfficeDistrict", "OfficeID", "OfficeName", "OfficeProvince", "OfficeWard", "Role", "UID"]

export const isMatchDataLoginFormatResponse = (data, roleNeedMatch) => {
  const keysData = Object.keys(data).sort();
  // console.log(keysData, adminDiffRoot)
  switch (roleNeedMatch) {
    case userRole.ADMIN_ROLE:
      return JSON.stringify(adminRoot) === JSON.stringify(keysData) || JSON.stringify(adminDiffRoot) === JSON.stringify(keysData)
    case userRole.ADMIN_OFFICE_ROLE:
    case userRole.OFFEXSUPERVISOR:
    case userRole.OFFINSUPERVISOR:
      return JSON.stringify(adminDiffRoot) === JSON.stringify(keysData)
    case userRole.TELLER:
      return JSON.stringify(teller) === JSON.stringify(keysData) || JSON.stringify(tellerVip) === JSON.stringify(keysData)
    default:
      return false;
  }

}