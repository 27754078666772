import styled, { css } from 'styled-components'

const WrapSubGroupReport = styled.div`
    
`;

const extendStylesText = css`
    font-weight: bold;
    font-size: 11px;
    line-height: 150%;
    color: #005993;
`;

export {
    WrapSubGroupReport,
    extendStylesText
}