import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

//Image
import ic_close from '../../../images/ic_close_gray.svg';

//Control
import SingleChoiseDropDownControl from '../MainScreen/Control/SingleChoiseDropDownControl';
// Data
import { location } from '../../../data/singleChoiseData';

//Tool
import { filterOffice, alertFillterOffice } from '../../../actions/officeAction';
import { getArrayCity, getDistrictArrayByCityCode, getWardArrayByDistrictCode } from '../../../tools';
import LoadingControl from '../MainScreen/Control/LoadingControl';

const Bound = styled.div`
    width:100%;
    min-height:100vh;
    position:absolute;
    top:0;
    left:0;
    z-index:10;
    font-family: 'SVN-Gilroy';
    .loading{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 111px 248px 0 0;
        p{
            font-family: SVN-Gilroy;
            font-size: 14px;
            font-weight: 500;
            line-height: 1.25;
            color: #787878;
            padding-left: 6px;
        }
    }
    .popup-filter-bound{
        display:flex;
        flex-direction:column;
        width:200px;
        padding: 30px 20px 0 20px;
        height: calc(100vh - 80px - 80px - 30px);
        background-color:white;
        position:absolute;
        bottom:0;
        right:-240px;
        transition:right 0.5s;
        align-items:center;
        .div_check{
            width:200px;
            margin: 0 0 20px 0;
        }
        .div_header{
            width:100%;
            margin: 0 0 20px 0;
            display:flex;
            justify-content:space-between;
            align-items: center;
        .title{
            font-size: 14px;
            font-weight: bold;
            color: #000000;
            width: 64px;
            height: 36px;
            border-radius: 18px;
            background-color: #005993;
            display:flex;
            justify-content: center;
            align-items: center;
            color:#ffffff;
            cursor: pointer;
        }
        .close-btn{
            width:20px;
            height:20px;
            cursor: pointer;
        }
        }
    }
`

class PopupBranchFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: location,
      dataCity: [],
      dataDistrict: [],
      dataWard: [],

      idShow: "",
      typeTrans: '',
      city: '',
      district: '',
      ward: '',
      isLoading: false,
    }
  }

  isMouseEnter = false;

  setIdShow = (id) => {
    this.setState({ idShow: id })
  }

  onSubmit = () => {
    let { city, district, ward, typeTrans } = this.state;
    let dataFilter = {}
    if(city) dataFilter["province"] = city.code
    if(district) dataFilter["district"] = district.code
    if(ward) dataFilter["ward"] = ward.code
    // if(typeTrans) dataFilter["officeType"] = typeTrans;
    if(typeTrans){
      if (typeTrans === 'Phòng giao dịch') {
        dataFilter.officeType = 'TRADING'
      } else if (typeTrans === 'Chi nhánh') {
        dataFilter.officeType = 'BRANCH'
      } else {
        dataFilter.officeType = 'SAVING'
      }
    }
    if(this.props.searchRef){
      // debugger;
      const text = this.props.searchRef.state.txtText;
      if(text){
        dataFilter.name = text;
      }
    }
    if (Object.keys(dataFilter).length !== 0) {
      this
        .props
        .filterOffice(dataFilter, 10, 1)
        this.props.alertContent('');
      this
        .props
        .onClose()
      this.setState({isLoading: true});
    } 
  else {
      this.props.alertPopup('Vui lòng điền đầy đủ thông tin')
      return
    }
    this.props.outPopupAlert();
  }
  componentDidMount() {
    this.setState({
      dataCity: getArrayCity()
    });
  }

  getText = (type) => text => {
    if(type === 'city') {
      this.setState({
        dataDistrict: getDistrictArrayByCityCode(text.code)
      })
    }else if(type === 'district') {
      this.setState({
        dataWard: getWardArrayByDistrictCode(text.code)
      })
    }
    // debugger;
    this.setState({
      [type]: text
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.city && this.state.city !== prevState.city){
      // debugger;
      if(prevState.city && prevState.city.code === this.state.city.code) return;
      this.setState({
        dataDistrict: getDistrictArrayByCityCode(this.state.city.code),
        district: "",
        ward: ""
      })
      return;
    }
    if(this.state.district && this.state.district !== prevState.district){
      // debugger;
      if(prevState.district && prevState.district.code === this.state.district.code) return;
      this.setState({
        dataWard: getWardArrayByDistrictCode(this.state.district.code),
        ward: ""
      })
      return;
    }
  }

  render() {
    const officeTrans = this.getText("typeTrans");
    const cityTrans = this.getText("city");
    const districtTrans = this.getText("district");
    const wardTrans = this.getText("ward");
    return (
      <Bound
        onMouseDown={() => {
          if (!this.isMouseEnter) {
            this
              .props
              .onClose()
          }
        }}>
        {
          this.state.isLoading &&
          <div className = 'loading'>
            <LoadingControl size = '16px' loadingPage = {false} />
            <p>Đang tải...</p>
          </div>
        }
        <div
          className='popup-filter-bound'
          id='filter_branch_id'
          onMouseEnter={() => {
            this.isMouseEnter = true
          }}
          onMouseLeave={() => {
            this.isMouseEnter = false
          }}>
          <div className="div_header">
            <img
              src={ic_close}
              alt='close'
              className='close-btn'
              onClick={() => {
                this
                  .props
                  .onClose()
              }} />
            <div onClick={() => this.onSubmit()} className='title'>FILTER</div>
          </div>
          <div className="div_check">
            <SingleChoiseDropDownControl
              id="selectOffice"
              getText={officeTrans}
              data={this.state.data.office}
              titleChoise='Chọn loại VP'
              title=' loại vp giao dịch'
              idShow={this.state.idShow}
              setIdShow={this.setIdShow} />
          </div>
          <div className="div_check">
              <div className = 'city'>
                <SingleChoiseDropDownControl
                  id="selectCity"
                  getText={cityTrans}
                  data={this.state.dataCity}
                  titleChoise='Chọn Tỉnh/TP'
                  title='tỉnh/tp'
                  idShow={this.state.idShow}
                  setIdShow={this.setIdShow} />
              </div>
          </div>
          <div className="div_check">
            <div className = 'district'>
              <SingleChoiseDropDownControl
                id="selectDistrict"
                getText={districtTrans}
                data={this.state.city ? this.state.dataDistrict : null}
                titleChoise='Chọn quận/ huyện'
                title='quận / huyện'
                idShow={this.state.idShow}
                setIdShow={this.setIdShow} 
                />
            </div>
          </div>
          <div className="div_check">
            <div className = 'ward'>
              <SingleChoiseDropDownControl
                id="selectTown"
                getText={wardTrans}
                data={this.state.district ? this.state.dataWard : null}
                titleChoise='Chọn phường/ xã'
                title='phường / xã'
                idShow={this.state.idShow}
                setIdShow={this.setIdShow}
              />
              </div>
          </div>
        </div>
      </Bound>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  filterOffice: (dataFilter, pageSize, pageNumber) => dispatch(filterOffice(dataFilter, pageSize, pageNumber)),
  alertContent: (data) => dispatch(alertFillterOffice(data))
})

export default connect(null, mapDispatchToProps, null, { forwardRef: true })(PopupBranchFilter)