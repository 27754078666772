import * as types from "../actions/types";

const initialState = {
  isLoading: false,
  bioDataNotReview: 0,
  verifyNotSeen: 0,
  listTellers: [],
  listInbioCollectors: [],
  listInSupervisors: [],
  listExSupervisors: [],
  listSupervisors: [],
  listChannel: []
}

export default function commonReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_LIST_CHANNEL: {
      return {
        ...state,
        ...action.payload
      }
    }
    case types.GET_LIST_OF_USERNAME: {
      return {
        ...state,
        ...action.payload
      }
    }
    case types.GET_DATA_NOT_VIEW_YET: {
      const { data } = action.payload;
      return {
        ...state,
        bioDataNotReview: data.BioDataNotReview,
        verifyNotSeen: data.VerifyNotSeen
      }
    }
    case types.RUN_LOADING:
      return {
        ...state,
        isLoading: true
      }
    case types.REMOVE_LOADING:
      return {
        ...state,
        isLoading: false
      }
    case types.ALERT_LOGOUT:
      return {
        ...state,
        isLogout: true
      }
    case types.ERR_CONNECTION:
      return {
        ...state,
        showErrorConnection: true
      }
    case types.REMOVE_ERR_CONNECTION:
      return {
        ...state,
        showErrorConnection: false
      }
    case types.ERR_CONNECTION_TIMEOUT:
      return {
        ...state,
        errorConnectionTimeoutApi: true
      }
    case types.REMOVE_ERR_CONNECTION_TIMEOUT:
      return {
        ...state,
        errorConnectionTimeoutApi: false
      }
    case types.ERR_MATCH_BRANCHCODE:
      return {
        ...state,
        showErrorBranchCode: true
      }
    case types.REMOVE_ERR_MATCH_BRANCHCODE:
      return {
        ...state,
        showErrorBranchCode: false
      }
    default:
      return state
  }
}