import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

// route
import reportPaths from './routesConfig'



const ReportDetails = () => {
    // debugger
    // console.log(reportPaths)
    return (
        <Switch>
            {
                reportPaths.map((item, key) => (
                    <Route 
                        // exact 
                        path={`/reports${item.path}`} 
                        key={key}
                        render={props => (
                            <item.component 
                                title={item.title} 
                                codeName={item.codeName}
                                {...props} 
                            />
                        )}
                    />
                ))
            }
            <Redirect to='/reports' />
        </Switch>
    )
}

export default ReportDetails
