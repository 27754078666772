import styled, { css } from 'styled-components'

const lstPxs = [60, 200, 140, 160, 160, 220]
const sumPx = lstPxs.reduce((total, curr) => total + curr)
const gridArr = lstPxs.map(item => `minmax(${item}px, ${Math.round((item/sumPx)*100)}fr)`)

const extendStylesTable = css`
    .header, .row {
        grid-template-columns: ${gridArr.join(" ")}
    }
`;

export {
    extendStylesTable
}