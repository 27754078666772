import React, { useEffect, useState } from 'react'
import Portal from '../../Control/Portal'
import GTTTQueries from '../GTTTQueries'
import { WrapTableBio } from './index.styles'

const dataHeader = [
    'MÃ SỐ CIF',
    'GTTT',
    'TÊN KHÁCH HÀNG',
    'SỐ ĐIỆN THOẠI',
    'HẠNG KHÁCH HÀNG',
    'LOẠI KHÁCH HÀNG',
]

const CustomerTypes = {
    EXCUST: 'Thông thường',
    INCUST: 'CBNH',
}

export default function TableBio({data}) {
    const [isShowPopup, setIsShowPopup] = useState(false)
    const [info, setInfo] = useState([])

    const handleClickDetail = () => {
        setIsShowPopup(true)
    }

    useEffect(()=>{
        if (!data?.Cifs?.length) return
        const {CustomerNumber, IdNumber, Name, MobileNumber, SegmentDesc, CustomerType } = data?.Cifs[0]
        let arrInfo = [CustomerNumber, IdNumber,Name, MobileNumber, SegmentDesc, CustomerTypes[CustomerType]]
        setInfo(arrInfo)
    },[data])

    return (
        <>
            <WrapTableBio>
                <div className="header-table">
                    {dataHeader.map((item, index) => (
                        <div key={index} className="item-table">
                            {item}
                        </div>
                    ))}
                </div>
                <div
                    className="content-table"
                    onDoubleClick={handleClickDetail}
                >
                    {info?.map((item, index) => (
                        <div key={index} className={index === 2 ? "item-table item-name" : "item-table" } title={index === 2 ? item : ""}>
                            {item}
                        </div>
                    ))}
                    <div
                        className="item-table details"
                        onClick={handleClickDetail}
                    >
                        Chi tiết
                    </div>
                </div>
            </WrapTableBio>
            {isShowPopup && (
                <Portal>
                    <GTTTQueries
                        handleClose={()=>setIsShowPopup(false)}
                        data={data}
                    />
                </Portal>
            )}
        </>
    )
}
