import React, { Component } from 'react';

import DataInfoCheckBiometric from './DataInfoCheckBiometric';
import DataInfoCheckCustomer from '../PopupCheckCustomer/DataInfoCheckCustomer';

// styles
import { Bound } from './UserInformation.styles';
// import { VERIFYUNSUCCESSFULLY } from '../../../../data/dataVerifyCustomer';
// import { OFFINSUPERVISOR, OFFEXSUPERVISOR } from '../../../../data/userRole';


class UserInformation extends Component {
  render() {
    const { dataUser } = this.props
    return (
        <Bound>
            <p className="title_1">thông tin khách hàng</p>
            <h3 className="userName">
                {dataUser?.CustomerInfos.Name}
            </h3>
            <div className="container block_info">
                <div className="row">
                    <div className="col-7 name_type">
                        <div className="row content">
                            <p>
                                Giới tính: &nbsp;
                                <span>
                                    {dataUser?.CustomerInfos.Gender}
                                </span>
                            </p>
                        </div>
                        <div className="row content">
                            <p>
                                CMND/CCCD/HC: &nbsp;
                                <span>
                                    {dataUser?.CustomerInfos.IdNumber}
                                </span>
                            </p>
                        </div>
                        {/* {
                (dataUser.VerifyStatus === VERIFYUNSUCCESSFULLY  || this.props.role === OFFINSUPERVISOR || this.props.role === OFFEXSUPERVISOR) &&  */}
                        <div className="row content">
                            <p>
                                Ngày cấp: &nbsp;
                                <span>
                                    {dataUser?.CustomerInfos.IdIssueDate}
                                </span>
                            </p>
                        </div>
                        {/* }
              {
                (dataUser.VerifyStatus === VERIFYUNSUCCESSFULLY || this.props.role === OFFINSUPERVISOR || this.props.role === OFFEXSUPERVISOR) &&  */}
                        <div className="row content">
                            <p>
                                Nơi cấp: &nbsp;
                                <span>
                                    {dataUser?.CustomerInfos.IdIssuePlace}
                                </span>
                            </p>
                        </div>
                        {/* } */}
                    </div>
                    <div className="col-5 name_type block_col_2">
                        <div className="row content">
                            {/* <p>Tên KH có dấu: &nbsp;
                  <span>{dataUser && (dataUser.FullNameVi ? dataUser.FullNameVi : dataUser.CustomerInfos.FullNameVi)}</span>
                  <span>{dataUser && (dataUser.CustomerInfos.FullNameVi && dataUser.CustomerInfos.FullNameVi)}</span>
                  <span>{dataUser && (dataUser.FullNameVi ? dataUser.FullNameVi : dataUser.CustomerInfos.Name)}</span>
                </p> */}
                            <div
                                className="cover"
                                style={{
                                    cursor:
                                        (
                                            !!dataUser.FullNameVi ||
                                            !!dataUser.CustomerInfos.FullNameVi ||
                                            !!dataUser.CustomerInfos.Name
                                        ) &&
                                        'pointer',
                                }}
                            >
                                <p>Tên KH có dấu: &nbsp;</p>
                                {/* <span>{dataInformCustom.OfficeName }</span> */}
                                <div className="text-gdv text-ellipst w-90">
                                    {dataUser.FullNameVi
                                        ? dataUser.FullNameVi
                                        : dataUser.CustomerInfos.FullNameVi
                                        ? dataUser.CustomerInfos.FullNameVi
                                        : dataUser.CustomerInfos.Name}
                                </div>
                                {(!!dataUser.FullNameVi ||
                                    !!dataUser.CustomerInfos.FullNameVi ||
                                    !!dataUser.CustomerInfos.Name) && (
                                    <span id="tooltip_">
                                        {dataUser.FullNameVi
                                            ? dataUser.FullNameVi
                                            : dataUser.CustomerInfos.FullNameVi
                                            ? dataUser.CustomerInfos.FullNameVi
                                            : dataUser.CustomerInfos.Name}
                                    </span>
                                )}
                            </div>
                        </div>
                        <div className="row content">
                            {/* <p>Hạng khách hàng: &nbsp;
                  <span>{dataUser && dataUser.CustomerInfos.SegmentDesc}</span>
                </p> */}
                            <div
                                className="cover"
                                style={{
                                    cursor:
                                        !!dataUser.CustomerInfos.SegmentDesc &&
                                        'pointer',
                                }}
                            >
                                <p>Hạng khách hàng: &nbsp;</p>
                                <div className="text-gdv text-ellipst w-90">
                                    {' '}
                                    &nbsp;
                                    {dataUser?.CustomerInfos.SegmentDesc}
                                </div>
                                {!!dataUser.CustomerInfos.SegmentDesc && (
                                    <span id="tooltip_">
                                        {dataUser?.CustomerInfos.SegmentDesc}
                                    </span>
                                )}
                            </div>
                        </div>

                        <div className="row content">
                            {/* <p>Số điện thoại di động:&nbsp;
                                <span>{dataUser && (dataUser.MobileNumber ? dataUser.MobileNumber : dataUser.CustomerInfos.MobileNumber)}</span>
                              </p> */}
                            <div
                                className="cover"
                                style={{
                                    cursor:
                                        (!!dataUser.MobileNumber ||
                                            !!dataUser.CustomerInfos.MobileNumber) &&
                                        'pointer',
                                }}
                            >
                                <p>SĐT di động: &nbsp;</p>
                                <div className="text-gdv text-ellipst w-90">
                                    {dataUser &&
                                        (dataUser.MobileNumber
                                            ? dataUser.MobileNumber
                                            : dataUser.CustomerInfos.MobileNumber)}
                                </div>
                                {(!!dataUser.MobileNumber ||
                                    !!dataUser.CustomerInfos.MobileNumber) && (
                                    <span id="tooltip_">
                                        {dataUser &&
                                            (dataUser.MobileNumber
                                                ? dataUser.MobileNumber
                                                : dataUser.CustomerInfos.MobileNumber)}
                                    </span>
                                )}
                            </div>
                        </div>

                        <div className="row content">
                            <p>
                                Email: &nbsp;
                                <span>
                                    {dataUser?.CustomerInfos.Email}
                                </span>
                            </p>
                        </div>
                    </div>
                    <div className="col-12 name_type address">
                        <div className="row content address">
                            <p>
                                Địa chỉ: &nbsp;
                                <span>
                                    {dataUser?.CustomerInfos.Address}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {this.props.typeInternal === 'gdv' ? (
                <DataInfoCheckBiometric dataUser={this.props.dataUser} />
            ) : (
                this.props.typeInternal === 'ksv' && (
                    <DataInfoCheckCustomer dataUser={dataUser} />
                )
            )}
        </Bound>
    )
  }
}

export default UserInformation