import styled from 'styled-components'

const WrapperHeader = styled.div`
    padding: 13px 20px 8px;
    box-shadow: 0px 3px 0px -1px #EFEFEF;
    background: #FFFFFF;

    .content {
        flex-grow: 1;

        .title {
            font-family: SVN-Gilroy;
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            line-height: 22px;
            color: #000000;
        }

        .row {
            margin-top: 16px;
            justify-content: space-between;
            font-family: SVN-Gilroy;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 128%;
            color: #222222;

            .main-content {
                color: #005993;
            }

            & > *:not(.main-content) {
                /* flex-basis: 30%; */
                max-width: 20ch;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis
            }
        }
    }

    img {
        position: relative;
        bottom: 16px;

        :hover {
            cursor: pointer;
        }
    }
`;

export { WrapperHeader }