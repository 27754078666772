import React, { useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

// image
import ic_left_arrow_blue from '../../../../../../../images/left-arrow-blue.svg'

// styles
import { WraperHeader } from './index.styles'

const Header = ({ title }) => {
    const history = useHistory()
    const location = useLocation()

    const _handleBack = useCallback(() => {
        // console.log(history)
        history.push('/reports', location.state)
    }, [history, location])

    return (
        <WraperHeader>
            <img src={ic_left_arrow_blue} alt="back" onClick={_handleBack} />
            <div className='title'>{title}</div>
        </WraperHeader>
    )
}

export default Header
