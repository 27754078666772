import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import moment from 'moment'

// images
import icon_search from './../../../../images/ic_search_gray.png'

//Data
import { subHeaderData } from '../../../../data/data'
import { dataDistrict } from '../../../../data/VietNamData/DataDistrict'
import { dataCity } from '../../../../data/VietNamData/DataCity'
import { dataWard } from '../../../../data/VietNamData/DataWard'

//component
import CustomerTable from './CustomerTable'
import EmptyControl from '../Control/EmptyControl'
import CustomerFilter from './CustomerFilter'

//Action
import {
    getListVerifyFingerHistory,
    saveTextVerifyCustomer,
} from '../../../../actions/supervisorAction/verifyCustomerAction'
import { getListOfUsername } from '../../../../actions/commonAction'


const Bound = styled.div`
    height: 100%;
    font-family: 'SVN-Gilroy';
    display: flex;
    flex: 1;
    justify-content: space-between;
    overflow: hidden;

    .sub-header {
        display: flex;
        flex: 1;
        width: 900px;
        overflow: hidden;
        padding: 0 0 0 40px;
        flex-direction: column;
        .header {
            display: flex;
            justify-content: space-between;
            margin: 27px 16px 0 0;
            &-address {
                .main {
                    color: #005993;
                    font-size: 26px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 580px;
                    /* padding: 5px 0 0 0; */
                    text-transform: uppercase;
                    font-weight: bold;
                }
                .sub {
                    font-weight: 500;
                    font-size: 12px;
                    color: #222222;
                }
            }
            &-search {
                position: relative;
                input {
                    width: 300px;
                    height: 36px;
                    background: #ffffff;
                    border-radius: 4px;
                    border: none;
                    outline: none;
                    padding: 0 30px 0 10px;
                }
                img {
                    position: absolute;
                    top: 40%;
                    right: 10px;
                    transform: translate(0, -50%);
                    cursor: pointer;
                }
            }
        }
    }

    .block-filter {
        /* height: 100%; */
        width: 240px;
        /* overflow: auto; */
        background: #ffffff;
        box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.06);
    }
`

class CustomerVertifyContainer extends Component {
    state = {
        subHeaderData: subHeaderData.CustomerVertify,

        valueCustNumOrName: '',
        callBackSearch: 0,
    }

    componentWillMount() {
        let { subHeaderData } = this.state
        const { dataLogin } = this.props.userReducer
        subHeaderData = {
            ...subHeaderData,
            mainTitle: dataLogin.OfficeName,
            subTitle: [
                dataLogin.OfficeAddress,
                dataWard[dataLogin.OfficeWard].name,
                dataDistrict[dataLogin.OfficeDistrict].name,
                dataCity[dataLogin.OfficeProvince].name
            ].join(", "),
        }
        this.setState({ subHeaderData })
    }

    render() {
        const { valueCustNumOrName, callBackSearch } = this.state
        return (
            <>
                <Bound>
                    <div className="sub-header">
                        <div className="header">
                            <div className="header-address">
                                <p className="main">
                                    {this.state.subHeaderData.mainTitle}
                                </p>
                                <p className="sub">
                                    {this.state.subHeaderData.subTitle}
                                </p>
                            </div>

                            <div className="header-search">
                                <input
                                    type="text"
                                    placeholder="Nhập mã số CIF, GTTT, Tên khách hàng"
                                    value={valueCustNumOrName}
                                    onChange={(e) =>
                                        this.setState({
                                            valueCustNumOrName: e.target.value,
                                        })
                                    }
                                    onKeyDown={(e) => {
                                        if (e.keyCode === 13) {
                                            this.setState({
                                                callBackSearch:
                                                    callBackSearch + 1,
                                            })
                                        }
                                    }}
                                />
                                <img
                                    src={icon_search}
                                    alt="search"
                                    onClick={() =>
                                        this.setState({
                                            callBackSearch:
                                                this.state.callBackSearch + 1,
                                        })
                                    }
                                />
                            </div>
                        </div>

                        {this.props.verifyCustomerReducer
                            .listVerifyCustomerHistory &&
                        this.props.verifyCustomerReducer
                            .listVerifyCustomerHistory.length > 0 ? (
                            <CustomerTable
                                showPopupCheckCustomer={
                                    this.props.showPopupCheckCustomer
                                }
                            />
                        ) : (
                            <EmptyControl
                                text_title="Chưa có dữ liệu khách hàng"
                                text="Hệ thống sẽ tự động cập nhật khi có dữ liệu"
                            />
                        )}
                    </div>

                    <div className="block-filter">
                        <CustomerFilter
                            callBackGetSearch={callBackSearch}
                            valueCustNumOrName={this.state.valueCustNumOrName}
                            callBackReset={() =>
                                this.setState({ valueCustNumOrName: '' })
                            }
                        />
                    </div>
                </Bound>
            </>
        )
    }

    componentDidMount() {
        // let m = moment().utcOffset(0)
        // m.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        const timeStart = moment().startOf('day').toISOString()
        const timeEnd = moment().endOf('day').toISOString()
        this.props.getListVerifyFingerHistory({
            PageSize: 10,
            PageNumber: 1,
            DataStatus: 'Pending',
            // FromCreateAt: m.format(),
            FromCreateAt: timeStart,
            ToCreateAt: timeEnd,
            // VerifyStatus: this.props.verifyCustomerReducer.lastConditionFilterVerifyCustomer.VerifyStatus
        })
        this.props.getListOfUsername([this.props.userReducer.dataLogin.OfficeID])
    }
}

const mapStateToProps = (state) => {
    return {
        verifyCustomerReducer: state.verifyCustomerReducer,
        userReducer: state.userReducer,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getListVerifyFingerHistory: (dataQuery) =>
            dispatch(getListVerifyFingerHistory(dataQuery)),
        saveTextVerifyCustomer: (text) =>
            dispatch(saveTextVerifyCustomer(text)),
        getListOfUsername: (lstOffice) =>
            dispatch(getListOfUsername(lstOffice))
    }
}
export default connect(mapStateToProps, mapDispatchToProps, null, {
    forwarRef: true,
})(CustomerVertifyContainer)
