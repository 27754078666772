import React, { useCallback, useEffect, useState } from 'react'
import { WrapperFooter, Button, Step } from './index.styles'
import { STEP_RESULT_ADD_USERS, STEP_SEARCH_USER, STEP_SETUP_USER } from '..'

const NEXT_BTN = 'Tiếp theo'
const CONFIRM_BTN = 'Hoàn tất'


const Footer = ({
    step,
    setStep,
    stateSearch,
    stateConfig,
    isEnableSubmit,
    handleSubmit
}) => {

    const [titleBtn, setTitleBtn] = useState(NEXT_BTN)

    useEffect(() => {
        if (step === STEP_SETUP_USER || step === STEP_RESULT_ADD_USERS) {
            setTitleBtn(CONFIRM_BTN)
            return;
        }
        setTitleBtn(NEXT_BTN)
    }, [step])

    const _changeStep = useCallback(
        (step) => {
            const { error } = stateSearch
            const { lstUserSelect } = stateConfig
            if (!lstUserSelect.length || error) return
            setStep(step)
        },
        [setStep, stateSearch, stateConfig],
    )

    const stepSearchSingle = [STEP_SEARCH_USER, STEP_SETUP_USER]

    return (
        <WrapperFooter className="row">
            <div className="row">
                {
                    stepSearchSingle.includes(step) &&
                    <>
                        <Step
                            isSelect={step === STEP_SEARCH_USER}
                            onClick={() => _changeStep(STEP_SEARCH_USER)}
                        >1</Step>
                        <Step
                            isSelect={step === STEP_SETUP_USER}
                            onClick={() => _changeStep(STEP_SETUP_USER)}
                        >2</Step>
                    </>
                }
            </div>
            <Button
                onClick={handleSubmit}
                isEnable={isEnableSubmit}
            >{titleBtn}</Button>
        </WrapperFooter>
    )
}

export default Footer
