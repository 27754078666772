import * as types from "../../actions/types";

const initialState = {
  Total: 0,
  TotalHistoryList: 0,
  dataBiometric: [],
  lastConditionFilterUser:{},
  textSearch: '',
  codes: {},
  Message: '',
  // dataUpdate: {}, //data reponse when update Bio
  // dataAccept: {}, //data reponse when accept Bio
  // dataReject: {}, //data reponse when reject Bio
  err: '',
  errs: '',
  lstBio: [],
  lstBioHistoryList: [],
  lstBioHistoryInfo: [],
  lstCurrent: []
}

export default function biometricReducer(state = initialState, action) {
  switch (action.type) {
    case types.CLEAR_ERR_APPROVE:
        return {
          ...state,
          codes: {},
          errs: ''
        }
    case types.GET_BIO_INFOS:
        return {
          ...state,
          Total: action.Total,
          dataBiometric: action.listBio,
          lstCurrent: action.lstCurrent, 
          lastConditionFilterUser: action.lastConditionFilterUser,
          err: '',
        }
    case types.GET_BIO_INFOS_ERR :
        return {
          ...state,
          err: action.err
        }
    case types.UPDATE_BIO_INFOS_NOTE:
      return {
        ...state,
        // dataUpdate: action.data,
        err: ''
      }
    case types.UPDATE_BIO_INFOS_NOTE_ERR:
      return {
        ...state,
        err: action.err
      }
    case types.ACCEPT_BIO_INFOS:
      return {
        ...state,
        codes: action.Code,
        // Message: action.message,
        errs: ''
      }
    case types.ACCEPT_BIO_INFOS_ERR:
      return {
        ...state,
        Message: action.message,
        errs: action.err
      }
    case types.REJECT_BIO_INFOS: 
      return {
        ...state,
        codes: action.Code,
        // dataReject: action.data,
        err: ''
      }
    case types.REJECT_BIO_INFOS_ERR:
      return {
        ...state,
        Message: action.message,
        errs: action.err
      }
    case types.SAVE_TEXT_DATA_BIOMETRIC:
      return {
        ...state,
        textSearch: action.textSearch
      }
    case types.CLEAR_TEXT_DATA_BIOMETRIC:
      return {
        ...state,
        textSearch: ''
      }
      case types.SOCKET_GET_BIOS_INFO: {
        if(action.data.Status === state.lastConditionFilterUser.Status || state.lastConditionFilterUser.Status === undefined || action.data !== null) 
          return {
            ...state,
            dataBiometric: [action.data,...state.dataBiometric],
            lastConditionFilterUser: state.lastConditionFilterUser,
            lstCurrent: [action.data,...state.lstCurrent], 
            Total: state.Total + 1
          }
          return state  
      }
      case types.GET_BIO_HISTORY_LIST:
        return {
          ...state,
          TotalHistoryList: action.TotalHistoryList,
          lstBioHistoryList: action.listBioHistoryList,
          err: ''
        }
      case types.GET_BIO_HISTORY_LIST_ERR: 
        return {
          ...state,
          err: action.err
        }
      case types.GET_BIO_HISTORY_INFO:
        return {
          ...state,
          lstBioHistoryInfo: action.listBioHistoryInfo,
          err: ''
        }
      case types.GET_BIO_HISTORY_INFO_ERR: 
      return {
        ...state,
        err: action.err
      }
  	default:
  		return state
  }
}