import React, { Component } from 'react'
import styled from 'styled-components'
const Bound = styled.div`
    display: flex;
    /* flex: 1; */
    margin: 24px 0 0 0;
    .core-name {
        h2 {
            font-family: 'SVN-Gilroy';
            font-size: 24px;
            font-weight: bold;
            line-height: 1.08;
            color: #005993;
        }
        p {
            font-family: 'SVN-Gilroy';
            font-size: 14px;
            font-weight: 500;
            line-height: 1.42;
            color: #222222;
            margin-top: 5px;
            text-transform: inherit;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2; /* number of lines to show */
            -webkit-box-orient: vertical;
        }
    }
`

export default class BlockNameControl extends Component {
    render() {
        return (
            <Bound>
                <div className="core-name">
                    <h2>{this.props.mainname}</h2>
                    <p title={this.props.subname}>{this.props.subname}</p>
                </div>
            </Bound>
        )
    }
}
