import React, {
    useReducer,
    useState,
    useCallback,
    useEffect,
    useMemo,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

// component
import Header from '../../controls/Header'
import ExportExcel from '../../controls/Tools/ExportExcel'
import ButtonFilter from '../../controls/Tools/ButtonFilter'
import Table from '../../controls/Table'
import SelectEndDate from '../../controls/Tools/SelectEndDate'
import SelectStartDate from '../../controls/Tools/SelectStartDate'
import SelectOfficeV2 from '../../controls/Tools/SelectOfficeV2'
import SelectTeller from '../../controls/Tools/SelectTeller'
import SelectSupervisor from '../../controls/Tools/SelectSupervisor'
import SelectChannel from '../../controls/Tools/SelectChannel'

// style
import { extendStylesTable, extendStylesTeller } from './index.styles'
import {
    GridBtnTools,
    WrapGridTools,
    GridColTools,
    GridBtnToolsEnd,
} from '../../controls/Tools/index.styles'

// action
import {
    exportListDetailsOfNotRegisterBio,
    getListDetailsOfNotRegisterBio,
} from '../../../../../../../actions/reportsAction/detailsReport/biometrics/notRegister'
import { getListOfUsername } from '../../../../../../../actions/commonAction'

// hooks
import useDidMount from '../../../../../../../helper/Hooks/useDidMount'

// constant
import { ADMIN_ROLE } from '../../../../../../../data/userRole'

// helper
import getChannelName from '../../../../../../../helper/getChannelName'


const lstTitleCols = [
    'mã CN', //115
    'Tên CN', //290
    'số CIF', //170
    'Tên KH', //260
    'Loại KH', //170
    'Ngày giao dịch', //170
    'Kênh tác nghiệp', //170
    'Thông tin không đăng ký', //200
    'GDV phục vụ KH', // 170
    'Lý do không đăng ký', //500
    'KSV phê duyệt', //170
]

const INDEX_KENH_TAC_NGHIEP = 6
const INDEX_LY_DO_KHONG_DK = 9
const VALUE_BREAK_LINE = '<br>'

// const DEFAULT_FROM_DATE = moment().startOf('days')
// const DEFAULT_TO_DATE = moment().endOf('days')

const ReportDetailsOfCusNotRegBio = ({ title, codeName, ...props }) => {
    const DEFAULT_FROM_DATE = useMemo(() => moment().startOf('days'), [])
    const DEFAULT_TO_DATE = useMemo(() => moment().endOf('days'), [])

    const dispatch = useDispatch()
    const { detailsReport, userReducer } = useSelector((state) => ({
        detailsReport: state.reports.detailsReport,
        userReducer: state.userReducer,
    }))

    const [stateFilter, dispatchFilter] = useReducer(
        (state, action) => ({ ...state, ...action }),
        {
            fromDate: DEFAULT_FROM_DATE.toDate(),
            toDate: DEFAULT_TO_DATE.toDate(),
            office: null,
            transaction: null,
            service: null,
            teller: null,
            supervisor: null,
            channel: null
        }
    )

    const [dataTable, setDataTable] = useState([])

    const [BranchIDs, BranchName, BranchCodes] = useMemo(() => {
        if (!stateFilter.office) return [null, null, null]
        const lenListOffice = stateFilter.office.length
        if (lenListOffice === 0) {
            return [null, null, null]
        }
        if (lenListOffice === 1) {
            const { key, value, BranchCode } = stateFilter.office[0]
            if (Array.isArray(key))
                return [key, value, BranchCode ? [BranchCode] : ['ALL']]
            return [[key], value, BranchCode ? [BranchCode] : ['ALL']]
        }

        const lstBranchId = [],
            lstBranchName = [],
            lstBranchCode = []

        const lstOfficeSort = [...stateFilter.office].sort((a, b) =>
            Number(a.BranchCode) - Number(b.BranchCode)
        )

        lstOfficeSort.forEach((element) => {
            lstBranchId.push(element.key)
            lstBranchName.push(element.value)
            lstBranchCode.push(element.BranchCode)
        })
        return [lstBranchId, lstBranchName.join(', '), lstBranchCode]
    }, [stateFilter.office])

    const _handleSubmit = useCallback(() => {
        const { fromDate, toDate, teller, supervisor, channel, transaction, office } = stateFilter
        dispatch(
            getListDetailsOfNotRegisterBio({
                FromDate: fromDate.toISOString(),
                ToDate: toDate.toISOString(),
                ReportCodeName: codeName,
                TellerName: teller,
                SupervisorName: supervisor,
                Channel: channel?.key,
                BranchName,
                BranchIDs,
            })
        )
    }, [stateFilter, BranchName, BranchIDs])

    const _handleExportExcel = useCallback(() => {
        const { fromDate, toDate, teller, supervisor, channel, office } = stateFilter
        dispatch(
            exportListDetailsOfNotRegisterBio({
                FromDate: fromDate.toISOString(),
                ToDate: toDate.toISOString(),
                ReportCodeName: codeName,
                TellerName: teller,
                SupervisorName: supervisor,
                Channel: channel?.key,
                BranchName,
                BranchIDs,
                BranchCodes,
            })
        )
    }, [stateFilter, BranchName, BranchIDs, BranchCodes])

    useDidMount(() => {
        if (!userReducer.dataLogin) return false
        const { Roles } = userReducer.dataLogin
        if (Roles.includes(ADMIN_ROLE)) return true

        if (!BranchName || !BranchIDs) return false
        dispatch(
            getListDetailsOfNotRegisterBio({
                FromDate: DEFAULT_FROM_DATE.toISOString(),
                ToDate: DEFAULT_TO_DATE.toISOString(),
                ReportCodeName: codeName,
                BranchName,
                BranchIDs,
            })
        )
        return true
    }, [userReducer.dataLogin, BranchName, BranchIDs])

    useEffect(() => {
        const dataTable = detailsReport.listData.map((item) => {
            
            const channel = item[INDEX_KENH_TAC_NGHIEP]
            item[INDEX_KENH_TAC_NGHIEP] = getChannelName(channel)

            const transInfos = item[INDEX_LY_DO_KHONG_DK]
            if (!transInfos) return item
            item[INDEX_LY_DO_KHONG_DK] = (
                <>
                    {transInfos.split(VALUE_BREAK_LINE).map((line, key) => (
                        <div key={key} title={line} className="limit-length">
                            {line}
                        </div>
                    ))}
                </>
            )
            return item
        })
        setDataTable(dataTable)
    }, [detailsReport.listData])

    useEffect(() => {
        if(!BranchIDs){
            dispatch(getListOfUsername())
            return;
        }
        dispatch(getListOfUsername(BranchIDs))
    }, [BranchIDs])

    return (
        <div>
            <Header title={title} />
            <WrapGridTools numOfCol={4}>
                <GridColTools>
                    <SelectOfficeV2
                        defaultValue={stateFilter.office}
                        onChange={(office) => dispatchFilter({ office })}
                    />
                    <SelectTeller
                        defaultValue={stateFilter.teller}
                        onChange={(teller) => dispatchFilter({ teller })}
                        extendStyles={extendStylesTeller}
                        label="GDV_DIEN_TOAN"
                    />
                </GridColTools>
                <GridColTools>
                    <SelectStartDate
                        defaultValue={stateFilter.fromDate}
                        onChange={(fromDate) => dispatchFilter({ fromDate })}
                        showTimeSelect={false}
                        dateFormat="dd/MM/yyyy"
                    />
                    <SelectSupervisor
                        defaultValue={stateFilter.supervisor}
                        onChange={(supervisor) => dispatchFilter({ supervisor })}
                        extendStyles={extendStylesTeller}
                        label="KSV_BGD"
                    />
                </GridColTools>
                <GridColTools>
                    <SelectEndDate
                        defaultValue={stateFilter.toDate}
                        onChange={(toDate) => dispatchFilter({ toDate })}
                        showTimeSelect={false}
                        dateFormat="dd/MM/yyyy"
                    />
                    <SelectChannel
                        defaultValue={stateFilter.channel}
                        onChange={(channel) => dispatchFilter({ channel })}
                    />
                </GridColTools>
                <GridBtnToolsEnd>
                    <ButtonFilter
                        data={stateFilter}
                        onClick={_handleSubmit}
                        isDisable={!BranchIDs}
                    />
                    <ExportExcel
                        onClick={_handleExportExcel}
                        isDisable={!BranchIDs}
                    />
                </GridBtnToolsEnd>
            </WrapGridTools>
            <Table
                lstTitleCols={lstTitleCols}
                lstData={dataTable}
                extendStyles={extendStylesTable}
                numOfRow={8}
            />
        </div>
    )
}

export default ReportDetailsOfCusNotRegBio
