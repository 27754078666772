import styled from 'styled-components'

const WrapGTTTQueries = styled.div`
    max-width: 811px;
    min-width: 705px;
    min-height: 516px;
    font-family: 'SVN-Gilroy';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    background: #FAFAFA;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    .btn-delete{
        background: #005993;
        border-radius: 20px;
        cursor: pointer;
        box-sizing: border-box;
        padding: 11px 8px;
        font-family: 'SVN-Gilroy';
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        text-transform: uppercase;
        color: #FFFFFF;
        width: 110px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 20px;
        right: 20px;
    }
    .wrap-header {
        background: #ffffff;
        padding: 0 20px 0 20px;
        box-sizing: border-box;
        border-radius: 10px 10px 0 0;
        .title {
            padding-top: 18px;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 22px;
            color: #222222;

            :hover {
                cursor: move;
            }
        }
    
        .ic-close {
            position: absolute;
            top: 8px;
            right: 8px;

            &:hover {
                cursor: pointer;
            }
        }
    
        .wrap-tab {
            margin-top: 6px;
            display: flex;
            justify-content: space-between;
    
            .wrap-inner-tab {
                display: flex;
                label {
                    display: block;
                    user-select: none;
                    input {
                        appearance: none;
    
                        &:checked + span {
                            color: #222222;
                            box-shadow: inset 0px -3px 0px #005993;
                        }
                    }
    
                    span {
                        padding: 10px 12px;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 14px;
                        line-height: 128%;
                        color: #AAAAAA;
                        display: inline-block;
                    }
    
                    &:hover {
                        cursor: pointer;
                    }
                }
            }
    
            .query-code {
                font-style: normal;
                font-weight: bold;
                font-size: 14px;
                line-height: 128%;
                color: #222222;
                padding: 10px 12px;
                span {
                    font-weight: normal;
                    color: #005993;
                }
            }
        }

        & + * {
            flex-grow: 1;
        }
    }

    .footer {
        height: 80px;
        display: flex;
        align-items: center;
        padding: 20px 19px;
        box-sizing: border-box;
        background: #ffffff;
        border-radius: 0 0 10px 10px;

        .ic-history {
            &:hover {
                cursor: pointer;
            }
        }
    }
`;

export {
    WrapGTTTQueries
}