import React, { useState, useEffect } from 'react'
import * as styles from './index.styles'
//component
import { CheckBox } from '../../SetupUser/CheckBoxItem/index.styles'
//image
import ic_warning from '../../../../../../images/ic_warning.svg'
import checked_red from '../../../../../../images/checked_red.svg'

const ERR_USER_EXISTED = 'username đã tồn tại trong hệ thống'
const ERR_USER_NOT_FOUND = 'Tài khoản không đúng hoặc không tồn tại.'


const RowListResult = ({ data, stateConfig, dispatchConfig }) => {

    const [isChecked, setIsChecked] = useState(false)

    const _onClickCheckBox = () => {
        const newCheck = !isChecked
        // setIsChecked(newCheck)
        const { lstUserSelect } = stateConfig
        if (newCheck) {
            dispatchConfig({ lstUserSelect: [...lstUserSelect, data] })
            return;
        }
        const newLstUserSelect = lstUserSelect.filter(item => item.UserName !== data.UserName)
        dispatchConfig({ lstUserSelect: newLstUserSelect })
    }

    useEffect(() => {
        if (data.IsExist || data.IsNotFound) return;
        const { lstUserSelect } = stateConfig
        dispatchConfig({ lstUserSelect: [...lstUserSelect, data] })
    }, []);

    useEffect(() => {
        const { lstUserSelect } = stateConfig
        const isUserInLstSelect = lstUserSelect.some(item => item.UserName === data.UserName)
        setIsChecked(isUserInLstSelect)
    }, [JSON.stringify(stateConfig), JSON.stringify(data)]);

    return (
        <styles.Bound>

            <div className="name" title={data.Name}>{data.Name}</div>
            <div className="role" title={data.Role}>{data.Role}</div>
            <div className="branch" title={`${data.BranchCode} - ${data.Branch}`}>{`${data.BranchCode} - ${data.Branch}`}</div>
            <div className="icon-check">
                {
                    data.IsExist || data.IsNotFound ?
                        <img
                            title={
                                data.IsExist
                                    ? ERR_USER_EXISTED
                                    : ERR_USER_NOT_FOUND
                            }
                            src={ic_warning}
                            alt="error-match"
                        />
                        :
                        <>
                            <div></div>
                            {/* <CheckBox onClick={_onClickCheckBox}>
                                {isChecked && <img src={checked_red} alt="checked" />}
                            </CheckBox> */}
                        </>
                }
            </div>
        </styles.Bound>
    )
}

export default RowListResult
