import React, { Component } from "react";
import styled from "styled-components";
import { Route, Switch, Redirect } from "react-router-dom";
import moment from "moment";

// Component
import TransactionContainer from "./Transaction/TransactionContainer";
import Header from "./Header";
import AdvertisementContainerV2 from './Advertisement/AdvertisementContainerV2';
import PermissionContainer from "./Permission/PermissionContainer";
import BranchContainer from "./Branch/BranchContainer";
import AdvertisePGDContainer from "./AdvertisementPGD/AdvertisePGDContainer";
import TrandingRoomDevice from "./DeviceKioskPGD/TrandingRoomDevice";
import TransactionPGDContainer from "./TransactionPGD/TransactionPGDContainer";
import BiometricDataContainer from './Biometric/BiometricDataContainer'
import PopupContainer from "../Popup/PopupContainer";
import PopupAlert from '../Popup/PopupAlert';
import PopupBranchFilter from "../Popup/PopupBranchFilter";
import PopupPermissionFilter from "../Popup/PopupPermissionFilter";
import BioErrorExecute from "./BioErrorExecute";

//Data
import { users } from "../../../data/store";
import { branchs } from '../../../data/dataBranch'
import { dataTrandingRoom } from "../../../data/dataDeviceKioskTransactionBranch";
import { popupType, mainMenu } from "../../../data/data";
import * as userRole from "../../../data/userRole"
// import { advertisementTabArr } from '../../../data/data'
import CustomerVertifyContainer from "./CustomerVertify/CustomerVertifyContainer";
import HeaderControl from "./Control/HeaderControl";
import PopupBiometricFilter from "../Popup/PopupBiometricFilter";
import PopupNote from "../Popup/PopupCheckBiometric/PopupNote";
// import PopupAdvertise from "../Popup/PopupAdvertise";
import PopupShowBiometricHistory from '../Popup/PopupCheckBiometric/PopupShowBiometricHistory';
import PopupShowFingerInfoCustomer from '../Popup/PopupCheckBiometric/PopupShowFingerInfoCustomer';
import PopupConfirm from '../Popup/PopupConfirm';
// import PopupNoteBiometric from "../Popup/PopupNoteBiometric";
import PopupSuccess from "../Popup/PopupSuccess";
import PopupVerifyCustomerFilter from "../Popup/PopupVerifyCustomerFilter";
import PopupVerifyApprover from "../Popup/PopupVerifyApproverV2";
import PopupHistoryCustomer from '../Popup/PopupCheckBiometric/PopupHistoryCustomer';
import Reports from "./Reports";
import Queries from "./Queries";

//Images
import ic_notification_success from '../../../images/ic_notification_success.svg';
import PopupMatchFace from "../Popup/PopupCheckBiometric/PopupMatchFace";
import PopupShowFaceImage from "../Popup/PopupCheckBiometric/PopupShowFaceImage";
import FormRegisterPrint from "../Popup/PopupCheckCustomer/FormRegisterPrint";
import BiometricExecution from "./BiometricExecution";

const Bound = styled.div`
  display: flex;
  flex: 1;
  background-color: #e9e9e9;
  /* margin-left:48px; */
  .biometric-data{
    .right-header {
      .time{
          width: 48px;
          padding-left: 4px;
          padding-right: 10px;
      }
      button {
          display: none;
      }
    } 
  }
  .main-bound {
    display: flex;
    flex: 1;
    flex-direction: column;
    position: relative;
    .second-bar{
      display:flex;
      margin-bottom:15px;
      height:70px;
      .block-left{
          display:flex;
          align-items:center;
      }
    }
  }
`

export default class MainScreenContainer extends Component {
  constructor(props) {
    super(props);
    //fix type: -1 after test
    this.popupObj = {
      type: -1,
      data: {}
    };
    this.dataCheckCustomer = null;
    this.dataCurrent = null;
    this.dataCheck = null;
    this.popupAlert = {
      content: ''
    }
    this.state = {
      isShowPopup: false,
      isShowFilter: false,
      isShowPermissionFilter: false,
      isShowBiometricFilter: false,
      isShowVerifyCustomerFilter: false,
      isShowAlert: false,
      isShowPopupIndBiometric: false,
      typeCheckBiometric: "",
      verifyAdmin: false,
    };
    // this.permission = JSON.parse(localStorage.getItem("checkLogin")).permission;

    this.searchRef = null;
  }

  // dataCheckCustomer = (dataCheckCustomer) => {
  //   this.dataCheckCustomer = dataCheckCustomer
  //   // alert(123)
  // }

  checkMatchPermission = (keyCheck, propertyCheck) => {
    // return object contain key
    const checkIncludeKey = key => property => object =>
      object[property].includes(key);

    const getKey = checkIncludeKey(keyCheck);

    const getProperty = getKey(propertyCheck);

    const getMenuItem = mainMenu.find(getProperty);
    // console.log(getMenuItem);
    // console.log(this.props.dataLogin);


    // return getMenuItem.permissions.includes(this.props.dataLogin.Role);
    const { Roles } = this.props.dataLogin;
    return Roles.some(item => getMenuItem.permissions.includes(item))
  };

  showPopup(type, data) {
    // console.log('test',data);    
    this.popupObj.type = type;
    this.popupObj.data = data;
    // console.log(this.popupObj)
    this.setState({
      isShowPopup: true
    });
  }
  onClosePopup() {
    this.popupObj = {};
    this.setState({
      isShowPopup: false
    });
  }
  alertPopup = (content) => {
    if (typeof (content) === "string") {
      this.popupAlert.content = content
    } else {
      this.popupAlert = content;
    }

    this.setState({ isShowAlert: true })
  }
  onClosePopupAlert = () => {
    this.popupAlert = {}
    this.setState({ isShowAlert: false })
  }
  getData(data) {
    this.popupObj.type = data.type;
    Object.assign(this.popupObj.data, data.data)
    this.setState({
      data: this.popupObj
    })
    // console.log('this.popupObj',this.popupObj) 
  }

  toggleFilter(refSearch) {
    // console.log("filter");
    this.searchRef = refSearch;
    const { isShowFilter } = this.state;
    if (isShowFilter) {
      //hide filter
      let filter = document.getElementById("filter_branch_id");
      if (filter) {
        filter.style.right = "-240px";
      }
      setTimeout(() => {
        this.setState({ isShowFilter: !isShowFilter });
      }, 500);
    } else {
      //show filter
      this.setState({ isShowFilter: !isShowFilter }, () => {
        let filter = document.getElementById("filter_branch_id");
        if (filter) {
          setTimeout(() => {
            filter.style.right = "0px";
          }, 50);
        }
      });
    }
  }
  togglePermissionFilter(ref) {
    this.searchRef = ref;
    // console.log("filter");
    const { isShowPermissionFilter } = this.state;
    if (isShowPermissionFilter) {
      //hide filter
      let filter = document.getElementById("filter_permission_id");
      if (filter) {
        filter.style.right = "-240px";
      }
      setTimeout(() => {
        this.setState({ isShowPermissionFilter: !isShowPermissionFilter });
      }, 500);
    } else {
      //show filter
      this.setState({ isShowPermissionFilter: !isShowPermissionFilter }, () => {
        let filter = document.getElementById("filter_permission_id");
        if (filter) {
          setTimeout(() => {
            filter.style.right = "0px";
          }, 50);
        }
      });
    }
  }
  toggleBiometricFilter() {
    // console.log("filter");
    const { isShowBiometricFilter } = this.state;
    if (isShowBiometricFilter) {
      //hide filter
      let filter = document.getElementById("filter_biometric_id");
      if (filter) {
        filter.style.right = "-240px";
      }
      setTimeout(() => {
        this.setState({ isShowBiometricFilter: !isShowBiometricFilter });
      }, 500);
    } else {
      //show filter
      this.setState({ isShowBiometricFilter: !isShowBiometricFilter }, () => {
        let filter = document.getElementById("filter_biometric_id");
        if (filter) {
          setTimeout(() => {
            filter.style.right = "0px";
          }, 50);
        }
      });
    }
  }

  toggleVerifyCustomerFilter() {
    // console.log("filter");
    const { isShowVerifyCustomerFilter } = this.state;
    if (isShowVerifyCustomerFilter) {
      //hide filter
      let filter = document.getElementById("filter_verify_customer_id");
      if (filter) {
        filter.style.right = "-240px";
      }
      setTimeout(() => {
        this.setState({ isShowVerifyCustomerFilter: !isShowVerifyCustomerFilter });
      }, 500);
    } else {
      //show filter
      this.setState({ isShowVerifyCustomerFilter: !isShowVerifyCustomerFilter }, () => {
        let filter = document.getElementById("filter_verify_customer_id");
        if (filter) {
          setTimeout(() => {
            filter.style.right = "0px";
          }, 50);
        }
      });
    }
  }


  onModify = (from, dataNeedModify) => {
    // console.log(from)
    // console.log(dataNeedModify)
    if (from === "Branch") {
      this.showPopup(
        popupType.configTransaction,
        {
          title: 'Tùy chỉnh chi nhánh/PGD',
          titleConfirm: 'Cập nhật',
          dataNeedModify
        }
      )
    } else if (from === "Permission") {
      this.showPopup(
        popupType.configAccountPermission,
        {
          title: 'Thông tin tài khoản ',
          dataNeedModify
        }
      )
    }
    else if (from === "deviceKiosk") {
      this.showPopup(
        popupType.configAdvertiseKiosk,
        {
          title: 'Tùy chỉnh chi nhánh/PGD',
          titleConfirm: 'Cập nhật',
          dataNeedModify,
        }
      )
    } else if (from === "deviceTablet") {
      this.showPopup(
        popupType.configAdvertiseTable,
        {
          title: 'Tùy chỉnh chi nhánh/PGD',
          dataNeedModify,
        }
      )
    }
    else if (from === "showTicket") {
      this.showPopup(
        popupType.configAdvertiseKiosk,
        {
          title: 'Tùy chỉnh chi nhánh/PGD',
          titleConfirm: 'Cập nhật',
          dataNeedModify,
        }
      )
    }
    else if (from === "DeviceKioskPGD") {
      this.showPopup(
        popupType.configAddBranch,
        {
          titleHeader: "Tùy chỉnh thiết bị Kiosk",
          title: "Tên thiết bị kiosk",
          placeHolder: "Nhập tên thiết bị Kiosk",
          dataNeedModify,
          type: 'DeviceKiosk'
        }
      )
    }
    else if (from === "DeviceLCDPGD") {
      this.showPopup(
        popupType.configAddBranch,
        {
          titleHeader: "Tùy chỉnh màn hình LCD",
          title: "Tên màn hình LCD",
          placeHolder: "Nhập tên thiết bị LCD",
          dataNeedModify,
          type: 'DeviceLcd'
        }
      )
    }
    else if (from === 'CounterTrans') {
      this.showPopup(
        popupType.configAddCounter,
        {
          titleHeader: "Tùy chỉnh quầy giao dịch",
          // title:"Tên màn hình LCD", 
          // placeHolder:"Nhập tên thiết bị Kiosk",
          dataNeedModify,
        }
      )
    }
    else if (from === 'PopupVerify') {
      this.showPopup(
        popupType.configVerifyApprover,
        {
          dataNeedModify,
        }
      )
    }
    else {
      //console.log("from other")
    }
  }

  onRemoveConfirm = (from, dataNeedDelete) => {
    // console.log(from,dataNeedDelete)
    // console.log(dataNeedDelete)
    if (from === "Branch") {
      // if (dataNeedDelete.NumOfCounter > 0 || dataNeedDelete.NumOfKiosk > 0) {
      //   this.showPopup(
      //     popupType.configOutOfLimit,
      //     {
      //       title: {
      //         sub: "Bạn không thể xoá",
      //         main: dataNeedDelete.Name
      //       },
      //       info: `(${dataNeedDelete.NumOfCounter} tài khoản và ${dataNeedDelete.NumOfKiosk} thiết bị được liên kết)`,
      //       note: `Bạn vui lòng thay đổi liên kết cho tài khoản và thiết bị trước khi xóa`,
      //       width: "270px",
      //       isCenter: true
      //     }
      //   )
      //   return;
      // }
      this.showPopup(
        popupType.configConfirm,
        {
          title: 'Xác nhận xóa chi nhánh/PGD',
          widthPopup: '280px',
          // contentDelete: 'Điểm giao dịch bị xóa sẽ đứt kết nối đến các thiết bị đã cấu hình vào hệ thống',
          dataNeedDelete
        }
      )
    }
    else if (from === "Permission") {
      this.showPopup(
        popupType.configDeletePermission, {
        title: 'Xác nhận xóa tài khoản ',
        dataNeedDelete
      }
      )
    }
    else if (from === "deviceKiosk") {
      this.showPopup(
        popupType.configConfirm, {
        title: 'Xác nhận xóa quảng cáo ',
        dataNeedDelete
      }
      )
    }
    else if (from === "deviceTablet") {
      this.showPopup(
        popupType.configConfirm, {
        title: 'Xác nhận xóa quảng cáo ',
        dataNeedDelete
      }
      )
    }
    else if (from === "showTicket") {
      this.showPopup(
        popupType.configConfirm, {
        title: 'Xác nhận xóa quảng cáo ',
        dataNeedDelete
      }
      )
    }
    else if (from === "DeviceKioskPGD") {
      this.showPopup(
        popupType.configConfirm, {
        title: 'Xác nhận xóa thiết bị Kiosk',
        contentDelete: 'Mất kết nối với thiết bị Kiosk đã liên kết',
        dataNeedDelete: { ...dataNeedDelete, key: "deleteDevice" },
        widthPopup: '280px',
      }
      )
    }
    else if (from === "DeviceLCDPGD") {
      this.showPopup(
        popupType.configConfirm, {
        title: 'Xác nhận xoá màn hình LCD',
        contentDelete: 'Mất kết nối với màn hình LCD đã liên kết',
        dataNeedDelete: { ...dataNeedDelete, key: "deleteDevice" },
        widthPopup: '280px',
      }
      )
    }
    else if (from === 'CounterTrans') {
      // console.log(from,dataNeedDelete);
      // console.log(dataNeedDelete.DesktopDevice);
      let contentBlue = null;
      if (dataNeedDelete.DesktopDevice !== "") {
        contentBlue = 'Quầy hiện kết nối với thiết bị tại quầy & thiết bị hỗ trợ'
      }

      this.showPopup(
        popupType.configConfirm, {
        title: 'Xác nhận xóa quầy giao dịch',
        contentDelete: 'Quầy bị xóa sẽ đứt kết nối với các thiết bị đã liên kết',
        dataNeedDelete: { ...dataNeedDelete, type: "deleteCounter" },
        widthPopup: '280px',
        contentBlue,
      }
      )
    }
    else {
      //console.log("from other")
    }
  }

  // handleShowPopupEditAdvert = (typeAds, data) => {
  //   debugger;
  //   // switch(typeAds){
  //   //   case advertisementTabArr[0].key: 
  //   // }
  // }

  render() {
    // console.log(' data - render', this.state.data)
    // console.log({dataTrandingRoom});  
    // console.log(this.props.dataLogin.Role)
    const { typeCheckBiometric } = this.state;
    return (
      <Bound>
        <Switch>
          <Route
            exact
            path="/xy-ly-sth"
            render={() => {
              if (moment().isSameOrAfter(moment(this.props.dataLogin.ExpireAt))) {
                this.props.resetStore();
                return;
              }

              if (this.checkMatchPermission("/xy-ly-sth", "key"))
                return (
                  <div className="main-bound">
                    <Header title="Xử lý sinh trắc học" />
                    <BiometricExecution />
                  </div>
                );

              return <Redirect to="/" />;
            }}
          />
          <Route
            exact
            path="/danh-sach-giao-dich"
            render={() => {
              if (moment().isSameOrAfter(moment(this.props.dataLogin.ExpireAt))) {
                this.props.resetStore();
                return;
              }

              if (this.checkMatchPermission("/danh-sach-giao-dich", "key"))
                return (
                  <div className="main-bound">
                    <Header title="Danh sách giao dịch" />
                    <TransactionContainer />
                  </div>
                );

              return <Redirect to="/" />;
            }}
          />
          <Route
            exact
            path="/chi-nhanh-pgd"
            render={() => {
              if (moment().isSameOrAfter(moment(this.props.dataLogin.ExpireAt))) {
                this.props.resetStore();
                return;
              }

              if (this.checkMatchPermission("/chi-nhanh-pgd", "key"))
                return (
                  <div className="main-bound">
                    <Header title="Chi nhánh/PGD" />
                    <BranchContainer
                      // dataNewBranch={this.state.data}
                      currentPage={this.currentPage}
                      data={branchs}
                      showAddBranchPopup={() => {
                        this.showPopup(
                          popupType.configTransaction,
                          { title: 'Thêm mới chi nhánh/PGD' }
                        )
                      }}
                      // showAddTransactionRoomPopup={() => {
                      //   this.showPopup(popupType.configTransaction,{})
                      // }}
                      toggleFilter={(refSearch) => {
                        this.toggleFilter(refSearch);
                      }}
                      onShowPopupEdit={data => {
                        this.showPopup(
                          popupType.configTransaction,
                          {
                            title: 'Tùy chỉnh chi nhánh/PGD',
                            titleConfirm: 'Cập nhật',
                            dataNeedModify: data
                          }
                        )
                      }}
                      onShowControl={(offset, from, dataReceive) => {
                        this.showPopup(popupType.popupControlType, {
                          offsetPopup: offset,
                          from,
                          dataReceive
                        });
                      }}
                    />
                  </div>
                );
              return <Redirect to="/" />;
            }}
          />
          <Route
            exact
            path="/quang-cao"
            render={() => {
              if (moment().isSameOrAfter(moment(this.props.dataLogin.ExpireAt))) {
                this.props.resetStore();
                return;
              }

              if (this.checkMatchPermission("/quang-cao", "key"))
                return (
                  <div className="main-bound">
                    <Header title="Quảng cáo" />
                    <AdvertisementContainerV2
                      showAddAdvertisementKioskPopup={() => {
                        this.showPopup(popupType.configAdvertisementPopup, { typeAdvert: "KIOSK" })
                      }}
                      showAddAdvertisementTablePopup={() => {
                        this.showPopup(popupType.configAdvertisementPopup, { typeAdvert: "GDV" })
                      }}
                      showAddAdvertisementShowTicketPopup={() => {
                        this.showPopup(popupType.configAdvertisementPopup, { typeAdvert: "LCD" })
                      }}
                      onShowPopupEditKiosk={data => {
                        this.showPopup(
                          popupType.configAdvertiseKiosk,
                          {
                            title: 'Tùy chỉnh chi nhánh/PGD',
                            titleConfirm: 'Cập nhật',
                            dataNeedModify: data,
                          }
                        )
                      }}
                      onShowPopupEditLCD={data => {
                        this.showPopup(
                          popupType.configAdvertiseKiosk,
                          {
                            title: 'Tùy chỉnh chi nhánh/PGD',
                            titleConfirm: 'Cập nhật',
                            dataNeedModify: data,
                          }
                        )
                      }}
                      // showPopupEditAdvert={this.handleShowPopupEditAdvert}
                      onShowControl={(offset, from, dataReceive) => {
                        this.showPopup(popupType.popupControlType, {
                          offsetPopup: offset,
                          from,
                          dataReceive
                        });
                      }}
                    />
                  </div>
                );
              return <Redirect to="/" />;
            }}
          />
          <Route
            exact
            path="/phan-quyen"
            render={() => {
              if (moment().isSameOrAfter(moment(this.props.dataLogin.ExpireAt))) {
                this.props.resetStore();
                return;
              }

              if (this.checkMatchPermission("/phan-quyen", "key"))
                return (
                  <div className="main-bound">
                    <Header title="Phân quyền" />
                    <PermissionContainer
                      users={users}
                      // onShowAlert={(offset,data) => {
                      //   this.showPopup(popupType.popupControlType, {
                      //     offsetPopup: offset,
                      //     data
                      //   });
                      // }}
                      onShowPopupModify={(dataNeedModify) => {
                        this.showPopup(
                          popupType.configAccountPermission,
                          {
                            title: 'Thông tin tài khoản ',
                            dataNeedModify
                          }
                        )
                      }}
                      onShowControl={(offset, from, dataReceive) => {
                        this.showPopup(popupType.popupControlType, {
                          offsetPopup: offset,
                          from,
                          dataReceive
                        });
                      }}
                      togglePermissionFilter={(ref) => {
                        this.togglePermissionFilter(ref);
                      }}
                      showAddUserPopup={() => { this.showPopup(popupType.configAddPermission, {}) }}

                    />
                  </div>
                );
              //console.log("not permission");
              return <Redirect to="/" />;
            }}
          />
          <Route
            exact
            path="/van-tin"
            render={() => {
              if (moment().isSameOrAfter(moment(this.props.dataLogin.ExpireAt))) {
                this.props.resetStore();
                return;
              }

              if (this.checkMatchPermission("/van-tin", "key"))
                return (
                  <div className="main-bound">
                    <Header title="Vấn tin" />
                    {/* <InquiryContainer /> */}
                    <Queries />
                  </div>
                );
              //console.log("not permission");
              return <Redirect to="/" />;
            }}
          />
          <Route
            exact
            path="/thiet-bi-kiosk"
            render={() => {
              if (moment().isSameOrAfter(moment(this.props.dataLogin.ExpireAt))) {
                this.props.resetStore();
                return;
              }
              // debugger
              if (this.checkMatchPermission("/thiet-bi-kiosk", "key"))
                return (
                  <div className="main-bound">
                    <Header title="Thiết bị PGD" />
                    <TrandingRoomDevice
                      data={dataTrandingRoom}
                      showAddTrandingRoomKioskPopup={() => {
                        this.showPopup(popupType.configAddBranch, {
                          titleHeader: "Thêm mới thiết bị Kiosk",
                          title: "tên thiết bị kiosk",
                          placeHolder: "Nhập tên thiết bị Kiosk",
                          type: 'DeviceKiosk'
                        })
                      }}
                      showAddTrandingRoomLCDPopup={() => {
                        this.showPopup(popupType.configAddBranch, {
                          titleHeader: "Thêm mới màn hình LCD",
                          title: "tên màn hình LCD",
                          placeHolder: "Nhập tên thiết bị LCD",
                          type: 'DeviceLcd'
                        })
                      }}
                      onShowPopupEditKiosk={data => {
                        this.showPopup(
                          popupType.configAddBranch,
                          {
                            titleHeader: "Tùy chỉnh thiết bị Kiosk",
                            title: "Tên thiết bị kiosk",
                            placeHolder: "Nhập tên thiết bị Kiosk",
                            dataNeedModify: data,
                            type: 'DeviceKiosk'
                          }
                        )
                      }}
                      onShowPopupEditLCD={data => {
                        this.showPopup(
                          popupType.configAddBranch,
                          {
                            titleHeader: "Tùy chỉnh màn hình LCD",
                            title: "Tên màn hình LCD",
                            placeHolder: "Nhập tên thiết bị LCD",
                            dataNeedModify: data,
                            type: 'DeviceLcd'
                          }
                        )
                      }}
                      onShowControl={(offset, from, dataReceive) => {
                        this.showPopup(popupType.popupControlType, {
                          offsetPopup: offset,
                          from,
                          dataReceive
                        });
                      }} />
                  </div>
                );
              return <Redirect to="/" />;
            }}
          />
          <Route
            exact
            path="/quay-giao-dich"
            render={() => {
              if (moment().isSameOrAfter(moment(this.props.dataLogin.ExpireAt))) {
                this.props.resetStore();
                return;
              }

              if (this.checkMatchPermission("/quay-giao-dich", "key"))
                return (
                  <div className="main-bound">
                    <Header title="Quầy giao dịch" />
                    <TransactionPGDContainer
                      onShowOutOfLimit={() => {
                        this.showPopup(popupType.configOutOfLimit, {
                          title: 'Số quầy vượt quá số lượng cho phép',
                        })
                      }}
                      showPopupAddCounter={() => {
                        this.showPopup(popupType.configAddCounter, {
                          titleHeader: 'Thêm mới quầy giao dịch',
                          title: 'tên màn hình LCD',
                          placeHolder: 'Nhập tên thiết bị LCD',
                        })
                      }}
                      onShowPopupEdit={(data) => {
                        this.showPopup(popupType.configAddCounter, {
                          titleHeader: 'Tùy chỉnh quầy giao dịch',
                          // title:"Tên màn hình LCD",
                          // placeHolder:"Nhập tên thiết bị Kiosk",
                          dataNeedModify: data,
                        })
                      }}
                      onShowPopupDelete={(dataNeedDelete) => {
                        let contentBlue = null
                        if (dataNeedDelete.DesktopDevice !== '') {
                          contentBlue =
                            'Quầy hiện kết nối với thiết bị tại quầy & thiết bị hỗ trợ'
                        }

                        this.showPopup(popupType.configConfirm, {
                          title: 'Xác nhận xóa quầy giao dịch',
                          contentDelete:
                            'Quầy bị xóa sẽ đứt kết nối với các thiết bị đã liên kết',
                          dataNeedDelete: {
                            ...dataNeedDelete,
                            type: 'deleteCounter',
                          },
                          widthPopup: '280px',
                          contentBlue,
                        })
                      }}
                      onShowDisConnectCounter={(data) => {
                        data.type === 'disConnectCounterTeller'
                          ? this.showPopup(popupType.configConfirm, {
                            title:
                              'Ngưng kết nối với thiết bị quầy',
                            widthPopup: '280px',
                            // contentDelete: 'Điểm giao dịch bị xóa sẽ đứt kết nối đến các thiết bị đã cấu hình vào hệ thống',
                            nameData: data.DesktopDevice,
                            dataNeedDelete: data,
                          })
                          : data.type === 'disConnectCounterTablet' &&
                          this.showPopup(popupType.configConfirm, {
                            title:
                              'Ngưng kết nối với thiết bị hỗ trợ GDV',
                            widthPopup: '280px',
                            nameData: data.TabletDevice,
                            dataNeedDelete: data,
                          })
                      }}
                      onShowControl={(offset, from, dataReceive) => {
                        this.showPopup(popupType.popupControlType, {
                          offsetPopup: offset,
                          from,
                          dataReceive,
                        })
                      }}
                    />
                  </div>
                )
              return <Redirect to="/" />;
            }}
          />
          <Route
            exact
            path="/quang-cao-pgd"
            render={() => {
              if (moment().isSameOrAfter(moment(this.props.dataLogin.ExpireAt))) {
                this.props.resetStore();
                return;
              }

              if (this.checkMatchPermission("/quang-cao-pgd", "key"))
                return (
                  <div className="main-bound">
                    <Header title="Quảng cáo PGD" />
                    <AdvertisePGDContainer />
                  </div>
                );
              return <Redirect to="/" />;
            }}
          />
          <Route
            exact
            path="/biometric-data"
            render={() => {
              if (moment().isSameOrAfter(moment(this.props.dataLogin.ExpireAt))) {
                this.props.resetStore();
                return;
              }

              if (this.checkMatchPermission("/biometric-data", "key"))
                return (
                  <div className="main-bound biometric-data">
                    <HeaderControl title="Dữ liệu sinh trắc học" />
                    <BiometricDataContainer
                      dataCheckCustomer={() => this.dataCheckCustomer}
                      roles={this.props.dataLogin.Roles}

                      showPopupCheckBiometric={(dataUser, dataCurrent) => {
                        this.showPopup(popupType.configCheckBiometricCustomer, {
                          dataUser,
                          dataCurrent
                          // dataBiometric
                        })
                      }}
                      toggleBiometricFilter={() => {
                        this.toggleBiometricFilter();
                      }}
                    />
                  </div>
                );
              return <Redirect to="/" />;
            }}
          />
          <Route
            exact
            path="/customer-vertify"
            render={() => {
              if (moment().isSameOrAfter(moment(this.props.dataLogin.ExpireAt))) {
                this.props.resetStore();
                return;
              }

              if (this.checkMatchPermission("/customer-vertify", "key"))
                return (
                  <div className="main-bound customer-vertify">
                    <HeaderControl title="Xác thực khách hàng" />
                    <CustomerVertifyContainer
                      showPopupCheckCustomer={(dataUser) => this.showPopup(popupType.configCheckCustomer, {
                        dataUser
                      })}

                      toggleVerifyCustomerFilter={() => {
                        this.toggleVerifyCustomerFilter();
                      }}
                    />
                  </div>
                );
              return <Redirect to="/" />;
            }}
          />
          <Route
            // exact
            path="/reports"
            render={() => {
              if (moment().isSameOrAfter(moment(this.props.dataLogin.ExpireAt))) {
                this.props.resetStore();
                return;
              }

              if (this.checkMatchPermission("/reports", "key"))
                return (
                  <div className="main-bound">
                    <HeaderControl
                      isShrink={true}
                      title="Báo cáo"
                    />
                    <Reports />
                  </div>
                );
              return <Redirect to="/" />;
            }}
          />
          <Route
            exact
            path="/xy-ly-du-lieu"
            render={() => {
              if (moment().isSameOrAfter(moment(this.props.dataLogin.ExpireAt))) {
                this.props.resetStore();
                return;
              }

              if (this.checkMatchPermission("/xy-ly-du-lieu", "key"))
                return (
                  <div className="main-bound">
                    <HeaderControl
                      isShrink={true}
                      title="Xử lý dữ liệu sinh trắc học"
                    />
                    <BioErrorExecute
                      showPopupCheckBiometric={(dataUser, dataCurrent) => {
                        this.showPopup(popupType.configCheckBiometricCustomer, {
                          dataUser,
                          dataCurrent,
                          isFlowBioErrorExecute: true
                        })
                      }}
                    />
                  </div>
                );
              return <Redirect to="/" />;
            }}
          />

          {
            (() => {
              const { Roles } = this.props.dataLogin
              switch (true) {
                case Roles.includes(userRole.ADMIN_ROLE):
                  return <Redirect from="/" to="/danh-sach-giao-dich" />
                case Roles.includes(userRole.ADMIN_OFFICE_ROLE):
                  return <Redirect from="/" to="/thiet-bi-kiosk" />
                case Roles.includes(userRole.OFFEXSUPERVISOR):
                case Roles.includes(userRole.OFFINSUPERVISOR):
                  return <Redirect from="/" to="/biometric-data" />
                case Roles.includes(userRole.RPW):
                  return <Redirect to="/reports" />
                case Roles.includes(userRole.IFW):
                  return <Redirect to="/van-tin" />
                default:
                  break;
              }
            })()
          }
        </Switch>
        {
          this.state.isShowPopup && (
            <PopupContainer
              showPopupInBiometric={this.showPopupInBiometric}
              onClosePopupInBiometric={this.onClosePopupInBiometric}
              alertPopup={this.alertPopup}
              popupRef={this.popupObj}
              closePopup={() => {
                this.onClosePopup();
              }}
              onModify={(from, dataNeedModify) => {
                this.onClosePopup();
                // this.onModify(from, dataNeedModify);
                setTimeout(() => {
                  this.onModify(from, dataNeedModify)
                }, 300);
              }}
              onRemoveConfirm={(from, dataNeedDelete) => {
                this.onClosePopup();
                setTimeout(() => {
                  this.onRemoveConfirm(from, dataNeedDelete)
                }, 300);
              }}
              onShowRemoveBranchError={(dataShow) => {
                this.onClosePopup();
                // debugger;
                setTimeout(() => {
                  this.showPopup(popupType.configFlowCheckOffice, {
                    dataShow,
                    contentDelete: 'Bạn vui lòng thay đổi liên kết cho tài khoản, thiết bị và quầy trước khi xóa',
                  })
                }, 300);
              }}
              showPopupAddUserSuccess={() => this.showPopup(popupType.configSuccess, {})}
              getData={data => this.getData(data)}
            />
          )
        }
        {
          this.state.isShowFilter && (
            <PopupBranchFilter
              searchRef={this.searchRef}
              getPopupBranchFilter={this.getPopupBranchFilter}
              onClose={() => {
                this.toggleFilter();
              }}
              onSubmit={() => {
                this.toggleFilter();
              }}
              alertPopup={this.alertPopup}
              outPopupAlert={() => {
                this.onClosePopupAlert()
              }}
            />
          )
        }
        {
          this.state.isShowPermissionFilter && (
            <PopupPermissionFilter
              searchRef={this.searchRef}
              onClose={() => {
                this.togglePermissionFilter();
              }}
              onSubmit={() => {
                this.togglePermissionFilter();
              }}
              alertPopup={this.alertPopup}
              outPopupAlert={() => {
                this.onClosePopupAlert()
              }}
            />
          )
        }
        {
          this.state.isShowBiometricFilter && (
            <PopupBiometricFilter
              onClose={() => {
                this.toggleBiometricFilter();
              }}
              onSubmit={() => {
                this.toggleBiometricFilter();
              }}
              alertPopup={this.alertPopup}
              outPopupAlert={() => {
                this.onClosePopupAlert()
              }}
            />
          )
        }
        {
          this.state.isShowVerifyCustomerFilter && (
            <PopupVerifyCustomerFilter
              onClose={() => {
                this.toggleVerifyCustomerFilter();
              }}
              onSubmit={() => {
                this.toggleVerifyCustomerFilter();
              }}
              alertPopup={this.alertPopup}
              outPopupAlert={() => {
                this.onClosePopupAlert()
              }}
            />
          )
        }
        {
          this.state.isShowAlert &&
          <PopupAlert
            data={this.popupAlert}
            icon={ic_notification_success}
            outPopup={() => {
              setTimeout(() => {
                this.onClosePopupAlert()
              }, 300)
            }}
          />
        }
        {/*/////////////////////*/}
        {/* <Biometric Popup /> */}
        {/*/////////////////////*/}
        {
          this.state.isShowPopupIndBiometric && this.state.typeCheckBiometric === 'NoticeBiometric' ?
            <PopupNote
              data={this.dataCheckCustomer}
              onClosePopup={this.onClosePopupInBiometric}
            />
            : null
        }

        {
          this.state.isShowPopupIndBiometric && this.state.typeCheckBiometric === 'BiometricHistory' ?
            <PopupHistoryCustomer
              data={this.dataCheckCustomer}
              onClosePopup={this.onClosePopupInBiometric}
            />
            : null
        }

        {
          this.state.isShowPopupIndBiometric && this.state.typeCheckBiometric === 'FingerInfoCustomer' ?
            <PopupShowFingerInfoCustomer
              data={this.dataCheckCustomer}
              onClosePopup={this.onClosePopupInBiometric}
            />
            :
            null
        }

        {
          this.state.isShowPopupIndBiometric && this.state.typeCheckBiometric === 'MatchFace' ?
            <PopupMatchFace
              data={this.dataCheckCustomer}
              onClosePopup={this.onClosePopupInBiometric}
            />
            : null
        }

        {
          this.state.isShowPopupIndBiometric && this.state.typeCheckBiometric === 'ShowFaceImage' ?
            <PopupShowFaceImage
              data={this.dataCheckCustomer}
              dataCurrent={this.dataCurrent}
              onClosePopup={this.onClosePopupInBiometric}
            // onshowPopupNote = {this.onshowPopupVerify}
            />
            : null
        }

        {/* {
          this.state.isShowPopupIndBiometric && this.state.typeCheckBiometric === 'confirmCheckBio' ?
            <PopupConfirm
              data={this.dataCheckCustomer}
              acceptAction={() => { }}
              cancelAction={() => { this.onClosePopupInBiometric() }}
              bg_color_close='#d71249'
              content='Kiểm tra dữ liệu sinh trắc học?'
              contentDelete='(*) Truy xuất vân tay với dữ liệu Plưu trữ trong hệ thống'
              colorContent='#005993'
              width='250px'
            />
            : null
        } */}

        {
          this.state.isShowPopupIndBiometric && this.state.typeCheckBiometric === 'confirmCheckBio' ?
            <FormRegisterPrint
              data={this.dataCheckCustomer}
              acceptAction={() => { }}
              cancelAction={() => { this.onClosePopupInBiometric() }}
              bg_color_close='#d71249'
              content='Kiểm tra dữ liệu sinh trắc học?'
              contentDelete='(*) Truy xuất vân tay với dữ liệu Plưu trữ trong hệ thống'
              colorContent='#005993'
              width='100%'
            />
            : null
        }

        {/* PopupNoteBiometric */}
        {/* <PopupNoteBiometric
              alertPopup={this.alertPopup}
              data={this.dataCheckCustomer}
              typeCheckBiometric={this.state.typeCheckBiometric}
              acceptAction={() => { }}
              cancelAction={() => { this.onClosePopupInBiometric() }}
              onClosePopup={() => { this.onClosePopup() }}
              bg_color_close='#d71249'
              content='Xác nhận phê duyệt dữ liệu?'
              contentDelete='(*) Dữ liệu sau khi phê duyệt sẽ được lưu lại và không thể chỉnh sửa'
              colorContent='#f31919'
              width='274px'
              closePopup={() => {
                this.onClosePopup()
              }}
            /> */}
        {
          this.state.isShowPopupIndBiometric && this.state.typeCheckBiometric === 'approveDataBiometric' ?
            <PopupVerifyApprover
              data={this.dataCheckCustomer}
              typeCheckBiometric={typeCheckBiometric}
              alertPopup={this.alertPopup}
              isShowAlert={this.state.isShowAlert}
              outPopupAlert={() => {
                this.onClosePopupAlert()
              }}
              outPopup={() => {
                this.onClosePopupVerify()
              }}
              onClosePopupInBiometric={this.onClosePopupInBiometric}
              onCloseCheck={() => { this.onClosePopup() }}
              title='Vui lòng nhập thông tin tài khoản để xác thực phê duyệt' />
            : null
        }
        {
          this.state.isShowPopupIndBiometric && typeCheckBiometric === 'rejectDataBiometric' ?
            this.state.verifyAdmin ?
              <PopupVerifyApprover
                verifyAdmin={this.state.verifyAdmin}
                noteReject={this.state.noteReject}
                outPopup={() => {
                  this.onClosePopupVerify()
                }}
                onClosePopupInBiometric={this.onClosePopupInBiometric}
                onCloseCheck={() => { this.onClosePopup() }}
                data={this.dataCheckCustomer}
                alertPopup={this.alertPopup}
                outPopupAlert={() => {
                  this.onClosePopupAlert()
                }}
                title='Vui lòng nhập thông tin tài khoản để xác thực phê duyệt'
              />
              :
              <PopupNote
                data={this.dataCheckCustomer}
                onClosePopup={this.onClosePopupInBiometric}
                onshowPopupVerify={this.onshowPopupVerify}
                outPopup={() => {
                  this.onClosePopupVerify()
                }}
                type='noteVerify'
                title='Nội dung từ chối'
                placeholder='Nhập nội dung từ chối 200 ký tự'
              />
            : null
        }
        {
          this.state.isShowPopupIndBiometric && (
            typeCheckBiometric === 'PermissionSuccess' ||
            typeCheckBiometric === 'BranchSuccess' ||
            typeCheckBiometric === 'AdvertiseSuccess' ||
            typeCheckBiometric === 'AddKioskSuccess' ||
            typeCheckBiometric === 'AddLCDSuccess' ||
            typeCheckBiometric === 'AddTransactionCounterSuccess'
          )
            ?
            <PopupSuccess
              outPopup={this.onClosePopupInBiometric}
              acceptAction={this.onClosePopupInBiometric}
              titleHeader={typeCheckBiometric === 'PermissionSuccess' ? "Tạo tài khoản thành công" : typeCheckBiometric === 'BranchSuccess' ? "Tạo chi nhánh/PGD thành công" : typeCheckBiometric === 'AdvertiseSuccess' ? "Tạo quảng cáo thành công" :
                typeCheckBiometric === 'AddKioskSuccess' ? "Tạo thiết bị Kiosk thành công" : typeCheckBiometric === 'AddLCDSuccess' ? "Tạo màn hình LCD thành công" : typeCheckBiometric === 'AddTransactionCounterSuccess' ? "Tạo quầy giao dịch thành công" : null}
            />
            : null
        }
        {
          this.state.isShowPopupIndBiometric &&
            typeCheckBiometric === 'PermissionError' ?
            <PopupAlert
              title="Không thể thực hiên được, vì User không có quyền trên hệ thống VietinBank"
              outPopup={this.onClosePopupInBiometric}
            />
            :
            null
        }
      </Bound>
    );
  }

  showPopupInBiometric = (dataUser, type, dataCurrent) => {
    this.dataCheckCustomer = dataUser
    this.dataCurrent = dataCurrent
    this.setState({
      isShowPopupIndBiometric: true,
      typeCheckBiometric: type,
    });
  }

  onClosePopupInBiometric = () => {
    this.setState({
      isShowPopupIndBiometric: false,
      typeCheckBiometric: ''
    })
  }

  onshowPopupVerify = ({ Note }) => {
    // this.dataCheck = dataUser

    this.setState({
      verifyAdmin: true,
      noteReject: Note || ""
    });
  }

  onClosePopupVerify = () => {
    this.setState({
      verifyAdmin: false,
      noteReject: ""
    })
  }

}
