import Axios from 'axios'

import * as types from '../types'
import { host } from '../../host'
import { addLoading, removeLoading } from '../commonAction'

// helper
import catchErrorApi from '../../helper/catchErrorApi'

//api
const apiGetListVerifyFingerHistory =
    host + 'supervisor/get-verify-finger-history'
const apiUpdateDataStatus = host + 'supervisor/update-verify-finger-dataStatus'
/**
 * [description]
 * @param  {
 * {
 * PageNumber*: number,
 * PageSize*: number,
 * CustNumOrName*: string
 * TellerName*: string
 * SupervisorName*: string
 * FromCreateAt*: string,
 * ToCreateAt*: string,
 * DataStatus: string, [Peding, Seen]
 * CustomerSegment: string,
 * CustomerType: 'INCUST' \| 'EXCUST'
 * VerifyStatus: string
 * }
 * } dataQuery [description]
 * @return {[type]}           [description]
 */
export const getListVerifyFingerHistory = (dataQuery) => async (dispatch) => {
    dispatch(addLoading())

    const dataWithoutFalsyVal = Object.keys(dataQuery).reduce(
        (lst, curr) =>
            dataQuery[curr] ? { ...lst, [curr]: dataQuery[curr] } : lst,
        {}
    )

    const queryDataFormat = JSON.stringify({
        ...dataWithoutFalsyVal,
    })
    try {
        const res = await Axios.post(
            apiGetListVerifyFingerHistory,
            queryDataFormat
        )
        const { data } = res
        dispatch({
            type: types.GET_VERIFY_FINGER_HISTORY,
            listVerifyHistory: data.ListHistory ? data.ListHistory : [],
            Total: data.TotalPage,
            lastConditionFilterHistory: {
                ...dataQuery,
            },
        })
        dispatch(removeLoading())
    } catch (err) {
        dispatch(removeLoading())
        catchErrorApi(
            err,
            dispatch,
            types.GET_VERIFY_FINGER_HISTORY_ERR,
            () => false
        )
    }
}

export const updateVerifyFingerDataStatus = (id, successCallback) => async (
    dispatch
) => {
    const queryDataFormat = JSON.stringify({
        ID: id,
    })
    try {
        const res = await Axios.post(apiUpdateDataStatus, queryDataFormat)
        const { data } = res
        dispatch({
            type: types.UPDATE_VERIFY_FINGER,
            Code: data.Code,
        })
        successCallback && successCallback()
    } catch (err) {
        catchErrorApi(
            err,
            dispatch,
            types.UPDATE_VERIFY_FINGER_ERR,
            () => false
        )
    }
}

export const saveTextVerifyCustomer = (text) => ({
    type: types.SAVE_TEXT_VERIFY_CUSTOMER,
    textSearch: text,
})

export const clearTextVerifyCustomer = () => ({
    type: types.CLEAR_TEXT_VERIFY_CUSTOMER,
})

export const socketGetVerifyCustomer = (data) => (dispatch) =>
    dispatch({
        type: types.SOCKET_GET_VERIFY_CUSTOMER,
        data,
    })
