import React, { useState, useRef } from 'react'
import { useDispatch } from 'react-redux'

// styles
import { WrapPopupStyles } from '../WrapPopupStyles'
import { WrapGTTTQueries } from './index.styles'

// image
import icCloseGray from '../../../../../images/ic_close_gray.svg'
import icHistory from '../../../../../images/Icons_History.svg'

// component
import CifInfos from './CifInfos'
import BioInfos from './BioInfos'
import Modal from '../../../../../helper/Modal'
import PopupHistoryCustomer from '../../../Popup/PopupCheckBiometric/PopupHistoryCustomer'

// action
import { getBioHistoryList } from '../../../../../actions/supervisorAction/biometricAction'

// hooks
import useMoveElement from '../../../../../helper/Hooks/useMoveElement'

const CIF_TAB = 'CIF_TAB'
const BIO_TAB = 'BIO_TAB'
const pageSize = 100000000000000000;

const GTTTQueries = ({ data, handleClose }) => {
    const dispatch = useDispatch()
    const GTTTQueriesRef = useRef(null)

    const [activeTab, setActiveTab] = useState(CIF_TAB);
    const [showHistory, setShowHistory] = useState(false);
    useMoveElement(GTTTQueriesRef, ".wrap-header > .title")

    const _handleShowBioHistory = () => {
        dispatch(getBioHistoryList(data.Cifs[0].CustomerNumber, pageSize, 1))
        setShowHistory(true)
    }

    if (!data) return null

    return (
        <WrapPopupStyles>
            <WrapGTTTQueries ref={GTTTQueriesRef}>
                <div className="wrap-header">
                    <div className="title">Thông tin khách hàng</div>
                    <img className="ic-close" src={icCloseGray} alt="close" onClick={handleClose} />
                    <div className="wrap-tab">
                        <div className="wrap-inner-tab">
                            <label htmlFor="cif-tab">
                                <input
                                    type="radio"
                                    id="cif-tab"
                                    value={CIF_TAB}
                                    name="tab"
                                    defaultChecked={true}
                                    onClick={() => setActiveTab(CIF_TAB)}
                                />
                                <span>Thông tin CIF</span>
                            </label>
                            <label htmlFor="bio-tab">
                                <input
                                    type="radio"
                                    id="bio-tab"
                                    value={BIO_TAB}
                                    name="tab"
                                    onClick={() => setActiveTab(BIO_TAB)}
                                />
                                <span>Thông tin STH</span>
                            </label>
                        </div>
                        <div className="query-code"><span>CIF:</span> {data.Cifs[0]?.CustomerNumber}</div>
                    </div>
                </div>
                {
                    activeTab === CIF_TAB &&
                    <CifInfos
                        cifs={data.Cifs[0] || {}}
                        bios={data.BioInfos || {}}
                    />
                }
                {
                    activeTab === BIO_TAB &&
                    <BioInfos bios={data.BioInfos || {}} />
                }
                <div className='footer'>
                    <img className="ic-history" src={icHistory} alt="history" onClick={_handleShowBioHistory} />
                    <Modal isShow={showHistory}>
                        <PopupHistoryCustomer onClosePopup={() => setShowHistory(false)} />
                    </Modal>
                </div>
            </WrapGTTTQueries>
        </WrapPopupStyles>
    )
}

export default GTTTQueries
