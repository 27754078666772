import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { formatTimeCoundown } from '../../../../tools'

const Bound = styled.div`
    ${(props) => props.extendStyles}
`

const CountDownTimer = ({ condition, timer, extendStyles, callBackTime }) => {
    const timerRef = useRef(null)
    const [timeCount, setTimeCount] = useState(null)

    useEffect(() => {
        setTimeCount(timer)
        clearTimeout(timerRef.current)
    }, [condition, timer])

    useEffect(() => {
        if (timeCount === 0) {
            callBackTime()
            return
        }
        if (!timeCount) return
        timerRef.current = setTimeout(async () => {
            await setTimeCount(timeCount - 1)
        }, 1000)

        return () => {
            clearTimeout(timerRef.current)
        }
    }, [timeCount, condition])

    return (
        <Bound extendStyles={extendStyles}>
            {formatTimeCoundown(timeCount)}
        </Bound>
    )
}

export default React.memo(CountDownTimer)
