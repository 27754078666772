import Axios from "axios"

// constant
import { host } from "../../../host"
import * as types from './types'

// helper
import catchErrorApi from "../../../helper/catchErrorApi"

// action
import { removeLoading, addLoading } from "../../commonAction"

export const apiGetListPorts = `${host}admin/exportJSON/filterListReport`

const allReports = {
    CodeName: "ALL",
    ID: "ALL",
    Name: "Tất cả nhóm báo cáo",
    ParentCode: ""
}

export const getListReports = ({ ParentCode, CodeName = '', Searchtext = '' }) => async dispatch => {

    dispatch(addLoading())
    const dataRequest = JSON.stringify({
        ParentCode: ParentCode === allReports.CodeName ? null : ParentCode,
        CodeName,
        Searchtext
    })

    try {
        const res = await Axios.post(apiGetListPorts, dataRequest)
        const { data } = res;
        const list = data.ListReports || []
        const listToObj = list.reduce((obj, curr) => ({ ...obj, [curr.CodeName]: curr }), {})
        if (!ParentCode) {
            dispatch({
                type: types.GET_LIST_PARENT_REPORTS,
                payload: {
                    listParents: {
                        [allReports.CodeName]: { ...allReports },
                        ...listToObj
                    },
                    condFilterParents: {
                        ParentCode,
                        CodeName,
                        Searchtext
                    }
                }
            })
            dispatch(removeLoading())
            return;
        }
        dispatch({
            type: types.GET_LIST_REPORTS,
            payload: {
                list: listToObj,
                condFilter: {
                    ParentCode,
                    CodeName,
                    Searchtext
                }
            }
        })
        dispatch(removeLoading())
    }
    catch (err) {
        dispatch(removeLoading())

        catchErrorApi(
            err,
            dispatch,
            !ParentCode ? types.GET_LIST_PARENT_REPORTS_ERROR : types.GET_LIST_REPORTS_ERROR,
            () => false
        )
    }
}

export const setConditionFilter = ({ ParentCode = '', CodeName = '', Searchtext = '' }) => dispatch => {
    if (!ParentCode) {
        dispatch({
            type: types.SET_PARENT_CONDITION_FILTER,
            payload: {
                condFilterParents: {
                    ParentCode,
                    CodeName,
                    Searchtext
                }
            }
        })
        return
    }

    dispatch({
        type: types.SET_CONDITION_FILTER,
        payload: {
            condFilter: {
                ParentCode,
                CodeName,
                Searchtext
            }
        }
    })
}

export const clearListReport = () => ({
    type: types.CLEAR_LIST_REPORT
})