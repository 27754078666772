const strToStrNoAccent = (str) => {
    // remove accents
    return str
        .replace(/[àáãảạăằắẳẵặâầấẩẫậ]/gi, 'a')
        .replace(/[èéẻẽẹêềếểễệ]/gi, 'e')
        .replace(/đ/gi, 'd')
        .replace(/[ùúủũụưừứửữự]/gi, 'u')
        .replace(/[òóỏõọôồốổỗộơờớởỡợ]/gi, 'o')
        .replace(/[ìíỉĩị]/gi, 'i')
        .replace(/[ýỳỹỵỷ]/gi, 'y')
        .toLowerCase()
}

export default strToStrNoAccent