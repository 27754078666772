import { OFFEXSUPERVISOR, OFFINSUPERVISOR } from "./userRole";
import { EXCUST, INCUST } from "./infosType";

// ---------------- Status ---------------- //
export const APPROVED = 'Đồng ý';
export const REJECT = 'Từ chối';
export const PENDING_APPROVE = 'Chờ phê duyệt';

// ---------------- BioInfosType ---------------- //
// const REGISTER = 'Đăng ký mới';
// const NR = 'Không đăng ký';
// const DELETE = 'Xóa dữ liệu';
// const BLOCK = 'Khóa dữ liệu';
// const UNBLOCK = 'Mở khóa dữ liệu';
// const UPDATE = 'Cập nhật dữ liệu';

export let dataLoginVerifyBiometric = [
    {
        UserName: "zgiangbh",
        PassWord: "Ab123456",
        Role:     "TPP_KETOAN",
    }
]

export const loginVerifyBiometric = {
    [OFFEXSUPERVISOR]: {
        Role:     "TPP_KETOAN,TPP_PGD,KSV_KETOAN",
    },
    [OFFINSUPERVISOR]: {
        Role:     "BGD_CN",
    },
    [EXCUST]: {
        Role: "TPP_KETOAN,TPP_PGD,KSV_KETOAN",
    },
    [INCUST]: {
        Role: "BGD_CN",
    },
}