import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import moment from 'moment'

//Control
import PagningControl from '../Control/PagningControl'
import CountDownTimeControl from '../Control/CountDownTimeControl'
//Data
import {
    formatDateTimeToSpecificFormat,
    formatDateTime,
} from '../../../../helper/formatTime'
import {
    SEEN,
    PENDING,
    VerifyCustomer,
    VERIFYUNSUCCESSFULLY,
    VERIFYSUCCESSFULLY,
    NOTVERIFY,
} from '../../../../data/dataVerifyCustomer'
//Action
import { getListVerifyFingerHistory } from '../../../../actions/supervisorAction/verifyCustomerAction'
import { Status, ACCEPT } from '../../../../data/infosType'
const Styled = styled.div``
const Bound = styled.div`
    font-family: SVN-Gilroy;
    font-size: 14px;
    height: 100%;
    margin: 20px 0 20px 0;
    /* overflow: auto; */
    overflow-x: auto;
    overflow-y: hidden;

    .block {
        display: grid;
        ${'' /* grid-template-columns: 0.7fr 0.7fr 1fr 1fr 1fr 1fr 1fr 0.68fr 1fr 1fr; */}
        /* grid-template-columns: 0.9fr 0.7fr 1fr 1fr 0.8fr 1fr 1fr 0.68fr 0.6fr 0.7fr; */
        grid-template-columns: 
            minmax(114px,0.9fr) 
            minmax(100px, 0.7fr) 
            minmax(150px, 1fr) 
            minmax(128px, 1fr) 
            minmax(128px, 1fr) 
            minmax(128px, 0.8fr) 
            minmax(128px, 1fr) 
            minmax(128px, 1fr) 
            minmax(70px, 0.68fr)
            minmax(90px, 0.5fr)
            minmax(128px, 1fr)
            minmax(128px, 1fr)
            minmax(108px, 0.7fr);
        align-items: center;
        grid-column-gap: 6px;
    }
    .table-head {
        color: #005993;
        font-size: 10px;
        font-weight: bold;
        height: 48px;
        width: max-content;
    }
    .table-body {
        /* height: 100%; */
        /* overflow: auto; */
        font-family: SVN-Gilroy;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.3;
        color: #222222;
        overflow-x: hidden;
        overflow-y: auto;
        max-height: calc(100% - 48px);
        width: max-content;

        /* ::-webkit-scrollbar {
            width: 8px;
        }

        ::-webkit-scrollbar-thumb {
            border-radius: 5px;
            background: #888;
        } */
    }
    .item-row {
        height: 48px;
        border-radius: 4px;
        background-color: #ffffff;
        margin-bottom: 4px;
        width: max-content;
    }
    .first {
        padding-left: 20px;
    }
    .text-ellipst {
        ${'' /* width: 100px; */}
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        ${'' /* position: relative; */}
        pointer-events:none;
    }

    .w-107 {
        ${'' /* width: 107px; */}
        width: 128px;
    }
    .w-150 {
        ${'' /* width: 103px; */}
        width: 150px;
    }
    .w-70 {
        /* width: 70px; */
        width: 100px;
    }
    .w-50 {
        ${'' /* width: 50px; */}
        width: 114px;
    }
    .text-ellipst:after {
        content: '';
        display: block;
        position: absolute;
        top: -15px;
        right: 0;
        width: 100%;
        height: 48px;
        z-index: 1;
        pointer-events: initial;
    }

    ${'' /* .text-ellipst:hover:after{
        cursor: pointer;
    } */}

    .cover {
        position: relative;
    }
    #tooltip_ {
        position: absolute;
        top: -66%;
        left: 50%;
        z-index: 999;
        width: fit-content;
        visibility: hidden;
        background-color: #fff;
        padding: 11px;
        box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.3);
        opacity: 0;
        transition: opacity 0.5s ease;
    }

    .text-ellipst:hover + #tooltip_ {
        visibility: visible;
        transition: opacity 0.5s ease;
        opacity: 1;
    }

    .name-custom {
        font-weight: bold;
    }
    .isLookFalse {
        color: #005993;
        text-decoration: underline;
        cursor: pointer;
    }
    .isLookTrue {
        color: #c8c8c8;
    }
    .item-last {
        padding-right: 14px;
        text-align: right;
    }
    .last {
        text-align: right;
    }
    .time-approve {
        color: #c8c8c8;
    }
    .status-data {
        font-weight: bold;
    }
`
const limitPerPage = 10
const CustomerType = {
    EXCUST: 'Thông thường',
    INCUST: 'Nội bộ',
}
class CustomerTable extends Component {
    state = {
        currentPage: 1,
    }

    onPageChange = (page) => {
        let currentPage = page
        this.setState({ currentPage })
        let dataFilter = this.props.verifyCustomerReducer
            .lastConditionFilterVerifyCustomer
        this.props.getListVerifyFingerHistory({
            ...dataFilter,
            PageNumber: currentPage,
        })
    }

    componentWillReceiveProps(n) {
        if (
            JSON.stringify(
                n.verifyCustomerReducer.lastConditionFilterVerifyCustomer !==
                    JSON.stringify(
                        this.props.verifyCustomerReducer
                            .lastConditionFilterVerifyCustomer
                    )
            )
        ) {
            this.setState({
                currentPage:
                    n.verifyCustomerReducer.lastConditionFilterVerifyCustomer
                        .PageNumber,
            })
        }
    }

    showInfo = (item) => {
        this.props.showPopupCheckCustomer(item)
    }
    render() {
        let dataSlice = this.props.verifyCustomerReducer
            .listVerifyCustomerHistory
        const { Total } = this.props.verifyCustomerReducer
        // let now = moment()
        return (
            <Bound>
                <Styled>
                    <div className="table-head block">
                        <div className="cif-code first">MÃ SỐ CIF</div>
                        <div className="cif-code">GTTT</div>
                        <div className="name-head">TÊN KHÁCH HÀNG</div>
                        <div className="type-custom">HẠNG KHÁCH HÀNG</div>
                        <div className="type-custom">LOẠI KHÁCH HÀNG</div>
                        <div className="registor-gdv">GDV XÁC THỰC</div>
                        <div className="type-data">TRẠNG THÁI XÁC THỰC</div>
                        <div className="time-update">THỜI GIAN XÁC THỰC</div>
                        <div className="time-update">TRẠNG THÁI XEM</div>
                        <div className="time-pendding">THỜI GIAN CHỜ</div>
                        <div className="user-approve">NGƯỜI PHÊ DUYỆT</div>
                        <div>THỜI GIAN PHÊ DUYỆT</div>
                        <div className="control-page last">
                            {Total > limitPerPage && (
                                <PagningControl
                                    currentPage={
                                        this.state.currentPage
                                    }
                                    onChangePage={
                                        this.onPageChange
                                    }
                                    limitPerPage={limitPerPage}
                                    totalItem={Total}
                                />
                            )}
                        </div>
                    </div>
                </Styled>
                <div className="table-body">
                    {dataSlice.map((item, index) => {
                        let elmDataStatus, elmStatus, clsStatus
                        if (item.DataStatus === SEEN) {
                            elmStatus = VerifyCustomer[SEEN].label
                            elmDataStatus = VerifyCustomer[PENDING].value
                            clsStatus = Status[ACCEPT].colorA
                        } else if (
                            item.DataStatus === PENDING &&
                            item.VerifyStatus === VERIFYSUCCESSFULLY
                        ) {
                            elmStatus = ''
                        } else if (item.DataStatus === PENDING) {
                            elmStatus = VerifyCustomer[PENDING].label
                            elmDataStatus = VerifyCustomer[PENDING].value
                            clsStatus = Status[ACCEPT].colorP
                        }

                        let elmVerify
                        if (item.VerifyStatus === VERIFYUNSUCCESSFULLY) {
                            elmVerify =
                                VerifyCustomer[VERIFYUNSUCCESSFULLY].value
                            /* coliVerify = VerifyCustomer[VERIFYUNSUCCESSFULLY].color */
                        } else if (item.VerifyStatus === VERIFYSUCCESSFULLY) {
                            elmVerify = VerifyCustomer[VERIFYSUCCESSFULLY].value
                            /* coliVerify = VerifyCustomer[VERIFYSUCCESSFULLY].color */
                        } else {
                            elmVerify = VerifyCustomer[NOTVERIFY].value
                        }

                        return (
                            <Styled key={index} text={index}>
                                {/* {index === 0 && (
                                    <div className="table-head block">
                                        <div className="cif-code first">
                                            MÃ SỐ CIF
                                        </div>
                                        <div className="cif-code">GTTT</div>
                                        <div className="name-head">
                                            TÊN KHÁCH HÀNG
                                        </div>
                                        <div className="type-custom">
                                            HẠNG KHÁCH HÀNG
                                        </div>
                                        <div className="type-custom">
                                            LOẠI KHÁCH HÀNG
                                        </div>
                                        <div className="registor-gdv">
                                            GDV XÁC THỰC
                                        </div>
                                        <div className="type-data">
                                            TRẠNG THÁI XÁC THỰC
                                        </div>
                                        <div className="time-update">
                                            THỜI GIAN XÁC THỰC
                                        </div>
                                        <div className="time-update">
                                            TRẠNG THÁI XEM
                                        </div>
                                        <div className="time-pendding">
                                            THỜI GIAN CHỜ
                                        </div>
                                        <div className="user-approve">
                                            NGƯỜI PHÊ DUYỆT
                                        </div>
                                        <div>THỜI GIAN PHÊ DUYỆT</div>
                                        <div className="control-page last">
                                            {Total > limitPerPage && (
                                                <PagningControl
                                                    currentPage={
                                                        this.state.currentPage
                                                    }
                                                    onChangePage={
                                                        this.onPageChange
                                                    }
                                                    limitPerPage={limitPerPage}
                                                    totalItem={Total}
                                                />
                                            )}
                                        </div>
                                    </div>
                                )} */}

                                <div
                                    className="item-row block"
                                    onClick={() => this.showInfo(item)}
                                >
                                    <div className="cover">
                                        <div
                                            className="cif-code first text-ellipst w-50"
                                            style={{
                                                cursor:
                                                    !!item.CustomerInfos
                                                        .CustomerNumber &&
                                                    'pointer',
                                            }}
                                        >
                                            {item.CustomerInfos.CustomerNumber}
                                        </div>
                                        {!!item.CustomerInfos
                                            .CustomerNumber && (
                                            <span id="tooltip_">
                                                {
                                                    item.CustomerInfos
                                                        .CustomerNumber
                                                }
                                            </span>
                                        )}
                                    </div>
                                    <div className="cover">
                                        <div
                                            className="type-custom text-ellipst w-70"
                                            style={{
                                                cursor:
                                                    !!item.CustomerInfos
                                                        .IdNumber && 'pointer',
                                            }}
                                        >
                                            {item.CustomerInfos.IdNumber}
                                        </div>
                                        {!!item.CustomerInfos.IdNumber && (
                                            <span id="tooltip_">
                                                {item.CustomerInfos.IdNumber}
                                            </span>
                                        )}
                                    </div>
                                    <div className="cover">
                                        {/* <div className="name-custom text-ellipst">{item.CustomerInfos.FullNameVi ? item.CustomerInfos.FullNameVi : item.CustomerInfos.Name}</div> */}
                                        <div
                                            className="name-custom text-ellipst w-150"
                                            style={{
                                                cursor:
                                                    !!item.CustomerInfos.Name &&
                                                    'pointer',
                                            }}
                                        >
                                            {item.CustomerInfos.Name}
                                        </div>
                                        {/* <span id= 'tooltip_'>{item.CustomerInfos.FullNameVi ? item.CustomerInfos.FullNameVi : item.CustomerInfos.Name}</span> */}
                                        {!!item.CustomerInfos.Name && (
                                            <span id="tooltip_">
                                                {item.CustomerInfos.Name}
                                            </span>
                                        )}
                                    </div>
                                    <div className="cover">
                                        <div
                                            className="type-custom text-ellipst w-107"
                                            style={{
                                                cursor:
                                                    !!item.CustomerInfos
                                                        .SegmentDesc &&
                                                    'pointer',
                                            }}
                                        >
                                            {item.CustomerInfos.SegmentDesc}
                                        </div>
                                        {!!item.CustomerInfos.SegmentDesc && (
                                            <span id="tooltip_">
                                                {item.CustomerInfos.SegmentDesc}
                                            </span>
                                        )}
                                    </div>
                                    <div className="cover">
                                        <div className="type-custom text-ellipst w-100">
                                            {CustomerType[item.CustomerType]}
                                        </div>
                                        {!!item.CustomerType && (
                                            <span id="tooltip_">
                                                {
                                                    CustomerType[
                                                        item.CustomerType
                                                    ]
                                                }
                                            </span>
                                        )}
                                    </div>
                                    <div className="cover">
                                        <div
                                            className="registor-gdv text-ellipst w-107"
                                            style={{
                                                cursor:
                                                    !!item.TellerInfos
                                                        .UserName && 'pointer',
                                            }}
                                        >
                                            {!!item.TellerInfos.UserName
                                                ? item.TellerInfos.UserName
                                                : item.TellerInfos.Name}
                                        </div>
                                        {!!item.TellerInfos.UserName && (
                                            <span id="tooltip_">
                                                {!!item.TellerInfos.UserName
                                                    ? item.TellerInfos.UserName
                                                    : item.TellerInfos.Name}
                                            </span>
                                        )}
                                    </div>
                                    <div className="cover">
                                        <div
                                            className="status-data text-ellipst w-107"
                                            style={{
                                                color:
                                                    elmVerify ===
                                                        VerifyCustomer[
                                                            NOTVERIFY
                                                        ].value ||
                                                    elmVerify ===
                                                        VerifyCustomer[
                                                            VERIFYUNSUCCESSFULLY
                                                        ].value
                                                        ? 'rgb(243, 25, 25)'
                                                        : 'rgb(15, 177, 31)',
                                                cursor:
                                                    !!elmVerify && 'pointer',
                                            }}
                                        >
                                            {elmVerify}
                                        </div>
                                        {!!elmVerify && (
                                            <span id="tooltip_">
                                                {elmVerify}
                                            </span>
                                        )}
                                    </div>
                                    <div className="cover">
                                        <div className="type-custom text-ellipst w-107">
                                            {formatDateTimeToSpecificFormat(
                                                item.CreateAt,
                                                formatDateTime.HHmmDDMMYY
                                            )}
                                        </div>
                                        {!!item.CreateAt && (
                                            <span id="tooltip_">
                                                {formatDateTimeToSpecificFormat(
                                                    item.CreateAt,
                                                    formatDateTime.HHmmDDMMYY
                                                )}
                                            </span>
                                        )}
                                    </div>

                                    <div
                                        className="time-approve"
                                        style={{ color: clsStatus }}
                                    >
                                        {elmStatus}
                                    </div>

                                    {item.VerifyStatus ===
                                        VERIFYUNSUCCESSFULLY ||
                                    item.VerifyStatus === NOTVERIFY ? (
                                        <div>
                                            {elmStatus ===
                                                VerifyCustomer[PENDING]
                                                    .label && (
                                                <CountDownTimeControl
                                                    data={item.CreateAt}
                                                />
                                            )}
                                        </div>
                                    ) : (
                                        <div></div>
                                    )}

                                    <div className="cover">
                                        <div className="user-custom text-ellipst w-107">
                                            {!!item.SupervisorInfos &&
                                                item.SupervisorInfos.UserName}
                                        </div>
                                        {!!item.SupervisorInfos && (
                                            <span id="tooltip_">
                                                {!!item.SupervisorInfos &&
                                                    item.SupervisorInfos.UserName}
                                            </span>
                                        )}
                                    </div>

                                    <div className="cover">
                                        <div className="time-approving text-ellipst w-107">
                                            {!!item.ViewAt &&
                                                item.DataStatus !== 'Pending' &&
                                                item.VerifyStatus !==
                                                    'Successfully' &&
                                                formatDateTimeToSpecificFormat(
                                                    item.CreateAt,
                                                    formatDateTime.HHmmDDMMYY
                                                )}
                                        </div>
                                        {!!item.ViewAt &&
                                            item.DataStatus !== 'Pending' &&
                                            item.VerifyStatus !==
                                                'Successfully' && (
                                                <span id="tooltip_">
                                                    {formatDateTimeToSpecificFormat(
                                                        item.CreateAt,
                                                        formatDateTime.HHmmDDMMYY
                                                    )}
                                                </span>
                                            )}
                                    </div>

                                    <div
                                        className="item-last isLookFalse"
                                        // onClick={item.DataStatus === SEEN ?  () => this.showInfo(item) : () => this.showInfo(item)}>{elmDataStatus}
                                        onClick={() => this.showInfo(item)}
                                    >
                                        {elmDataStatus}
                                    </div>
                                </div>
                            </Styled>
                        )
                    })}
                </div>
            </Bound>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        verifyCustomerReducer: state.verifyCustomerReducer,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getListVerifyFingerHistory: (dataQuery) =>
            dispatch(getListVerifyFingerHistory(dataQuery)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true,
})(CustomerTable)
