import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

// styles
import { WrapperSelectReport } from './index.styles'
import DropDownSearchObj from '../../../Control/DropDownSearchObj'

// action
import { getListReports } from '../../../../../../actions/reportsAction/listReports/listReports'

const SelectReport = () => {
    const dispatch = useDispatch()

    const { listParents, condFilter, condFilterParents } = useSelector(state => state.reports.listReports)

    const [lstDropDown, setLstDropDown] = useState([])
    const [reportsSelected, setReportsSelected] = useState(null)
    const [keySearch, setKeySearch] = useState('')

    useEffect(() => {
        const { ParentCode } = condFilter
        if(!ParentCode) return;
        const reportsSelected = listParents[ParentCode]
        setReportsSelected({
            ...reportsSelected,
            key: reportsSelected.ID,
            value: reportsSelected.Name
        })
    }, [JSON.stringify(condFilter)])

    useEffect(() => {
        const lstDropDown = Object.values(listParents).map(item => ({
            ...item,
            key: item.ID,
            value: item.Name
        }))

        setLstDropDown(lstDropDown)
    }, [listParents])
    
    useEffect(() => {
        if(!reportsSelected) {
            // dispatch(getListReports({}))
            return;
        }
        dispatch(getListReports({
            ...condFilter,
            ParentCode: reportsSelected.CodeName
        }))
    }, [JSON.stringify(reportsSelected)])

    useEffect(() => {
        const { Searchtext } = condFilterParents
        if(Searchtext === keySearch) return;
        dispatch(getListReports({
            ...condFilterParents,
            Searchtext: keySearch
        }))
    }, [keySearch])


    return (
        <WrapperSelectReport>
            <div className="title-select">nhóm báo cáo</div>
            <div className="wrap-dropdown">
                <DropDownSearchObj 
                    lstItemFixed={[]}
                    lstItems={lstDropDown}
                    onChange={(val) => setReportsSelected(val)}
                    onSearch={(keySearch) => setKeySearch(keySearch)}
                    defaultValue={reportsSelected}
                    placeHolder="Tìm kiếm"
                    placeHolderSelect="Chọn nhóm báo cáo"
                />
            </div>
        </WrapperSelectReport>
    )
}

export default SelectReport
