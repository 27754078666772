import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

const Bound = styled.div`
    .switch {
        position: relative;
        display: inline-block;
        width: 46px;
        height: 24px;
    }

    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        transition: 0.4s;
    }

    .slider:before {
        position: absolute;
        content: '';
        height: 22px;
        width: 22px;
        left: 2px;
        bottom: 1px;
        background-color: white;
        transition: 0.4s;
    }

    input:checked + .slider {
        background-color: #2196f3;
    }

    input:focus + .slider {
        box-shadow: 0 0 1px #2196f3;
    }

    input:checked + .slider:before {
        transform: translateX(20px);
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 50px;
    }

    .slider.round:before {
        border-radius: 50%;
    }
`

const ButtonSwtich = ({ status, callBackSwitch, size }) => {
    const [switchBtn, setswitchBtn] = useState(status)
    const [count, setCount] = useState(0)

    const _handleSwitch = () => {
        setCount(count + 1)
    }

    useEffect(() => {
        if (count > 0) callBackSwitch(switchBtn)
    }, [count])

    useEffect(() => {
       setswitchBtn(status)
    }, [status])

    return (
        <Bound size={size}>
            <label className="switch">
                <input
                    type="checkbox"
                    checked={switchBtn}
                    onChange={_handleSwitch}
                />
                <span className="slider round" />
            </label>
        </Bound>
    )
}

export default React.memo(ButtonSwtich)
