import React, { useState } from 'react'

// constant
import { RPW } from '../../../../../../data/userRole'

// component
import CheckBoxItem from '../../../PopupAddPermissionV2/SetupUser/CheckBoxItem'

// styles
import { WrapSubGroupReport, extendStylesText } from './index.styles'

const SubGroupReport = ({ parentReport, objListReports, stateConfig, dispatchConfig, readOnly }) => {
    const lstChildReport = objListReports[parentReport.CodeName] || []
    const lstCodeNameOfChild = lstChildReport.map(item => item.CodeName)
    const [isToggleListChildren, setIsToggleListChildren] = useState(true);

    const _handleCheckGroupReport = () => {
        if (lstChildReport.length === 0) return;
        const { reportPermission, lstRoles } = stateConfig

        if (lstChildReport.some(report => reportPermission.includes(report.CodeName))) {
            // uncheck
            const newReportPermission = reportPermission.filter(item => !lstCodeNameOfChild.includes(item))
            if (newReportPermission.length === 0) {
                dispatchConfig({
                    lstRoles: lstRoles.filter(item => item !== RPW),
                    reportPermission: []
                })
                return
            }
            dispatchConfig({
                reportPermission: newReportPermission
            })
            return;
        }

        // check
        if (reportPermission.length === 0) {
            dispatchConfig({
                lstRoles: [...lstRoles, RPW],
                reportPermission: lstCodeNameOfChild
            })
            return
        }

        dispatchConfig({
            reportPermission: [...reportPermission, ...lstCodeNameOfChild]
        })
    }

    const _handleCheckReport = (codeName) => {
        if (stateConfig.reportPermission.includes(codeName)) {
            const newReportPermission = stateConfig.reportPermission.filter(item => item !== codeName)
            if (newReportPermission.length === 0) {
                dispatchConfig({
                    lstRoles: stateConfig.lstRoles.filter(item => item !== RPW),
                    reportPermission: newReportPermission
                })
                return;
            }
            dispatchConfig({
                reportPermission: newReportPermission
            })
            return;
        }

        if (stateConfig.reportPermission.length === 0) {
            dispatchConfig({
                lstRoles: [...stateConfig.lstRoles, RPW],
                reportPermission: [codeName]
            })
            return;
        }
        dispatchConfig({
            reportPermission: [...stateConfig.reportPermission, codeName]
        })
    }

    const _handleToggle = () => {
        setIsToggleListChildren(pre => !pre)
    }

    return (
        <>
            <div className="wrap-main-role">
                <CheckBoxItem
                    onClick={_handleCheckGroupReport}
                    isChecked={lstChildReport.every(({ CodeName }) => stateConfig.reportPermission.includes(CodeName))}
                    isCheckedSome={lstChildReport.some(({ CodeName }) => stateConfig.reportPermission.includes(CodeName))}
                    isDisabled={readOnly || lstChildReport.length === 0}
                    extendStylesText={extendStylesText}
                >
                    {parentReport.Name}
                </CheckBoxItem>
                <div className="sum-of-sub-role-selected" onClick={_handleToggle}>
                    <span className="down-arrow" />
                </div>
            </div>
            {
                isToggleListChildren &&
                <div className="wrap-list-children">
                    {lstChildReport.map(({ Name, ID, CodeName }) => (
                        <CheckBoxItem
                            key={ID}
                            onClick={() => _handleCheckReport(CodeName)}
                            isChecked={stateConfig.reportPermission.includes(CodeName)}
                            isDisabled={readOnly}
                        >
                            {CodeName} - {Name}
                        </CheckBoxItem>
                    ))}
                </div>
            }
        </>
    )
}

export default SubGroupReport
