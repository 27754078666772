import styled, { css } from 'styled-components'

// const lstPxs = [50, 200, 90, 150, 160, 160, 150, 150, 150, 110, 100]
// const sumPx = lstPxs.reduce((total, curr) => total + curr)
// const gridArr = lstPxs.map(item => `minmax(${item}px, ${Math.round((item/sumPx)*100)}fr)`)

const lstPxs = [115, 290, 170, 260, 170, 170, 170, 170, 200, 200, 170, 170]
const gridArr = lstPxs.map((item) =>
    typeof item === 'number' ? `${item}px` : item
)

const extendStylesTable = css`
    .header,
    .row {
        grid-template-columns: ${gridArr.join(' ')};
    }
`
const extendStylesTeller = css`
    width: 220px;
`

export { extendStylesTable, extendStylesTeller }
