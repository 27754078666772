import React, { useState, useCallback } from 'react'
// import moment from 'moment'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

// styles
import { WrapCustom } from './index.styles'

// image
import ic_calendar from '../../../../../../../../images/ic_calendar.svg'

const CustomDateTime = React.forwardRef(
    ({ value, onClick, isDisableTime }, ref) => {
        return (
            <WrapCustom
                onClick={onClick}
                ref={ref}
                isDisableTime={isDisableTime}
            >
                <div className="value">{value}</div>
                <img src={ic_calendar} alt="open-calendar" />
            </WrapCustom>
        )
    }
)

const SelectDateTime = ({ defaultValue, onChange, label, ...props }) => {
    const [startDate, setStartDate] = useState(defaultValue || new Date())

    const _handleOnChange = useCallback((date) => {
        setStartDate(date)
        onChange(date)
    }, [])

    return (
        <>
            <div className="label-tools-item">{label}</div>
            <div className="tools-item">
                <DatePicker
                    selected={startDate}
                    showTimeSelect
                    onChange={_handleOnChange}
                    customInput={
                        <CustomDateTime
                            isDisableTime={
                                !!props.dateFormat &&
                                props.dateFormat === 'dd/MM/yyyy'
                            }
                        />
                    }
                    dateFormat="dd/MM/yyyy HH:mm"
                    timeFormat="HH:mm"
                    timeIntervals={1}
                    timeCaption=""
                    popperPlacement="bottom-right"
                    {...props}
                />
            </div>
        </>
    )
}

export default SelectDateTime
