import React, { Component } from "react";
import styled from "styled-components";

import { formatDayAndTime } from "../../../tools";

const StyledHeader = styled.div`
  height: 80px;
  background: #fafafa;
  padding: 0 40px 0 20px;
  flex-shrink: 0;
  &,
  & > .right-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  * {
    font-family: "SVN-Gilroy";
    font-weight: bold;
  }

  .title {
    font-size: 32px;
    line-height: 1.29;
    color: #222222;
    margin-left: 20px;
  }

  .right-header__title {
    font-size: 14px;
    line-height: 1.42;
    color: #005993;
  }

  .time {
    font-weight: 500;
    font-size: 14px;
    padding-left: 15px;
  }
`;

export default class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      time: "",
      title: this.props.title
    };

    // get time
    this.getTimeNow = {};
  }

  componentWillMount() {
    this.getTimeNow = setInterval(() => {
      this.setState({ time: formatDayAndTime().getFullTime });
    }, 1000);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      this.setState({ title: nextProps.title });
    }
  }

  render() {
    return (
      <StyledHeader className = 'header'>
        <div className="title">{this.state.title}</div>
        <div className="right-header">
          <div className="right-header__title">Thời gian hệ thống:</div>
          <div className="time">{this.state.time}</div>
        </div>
      </StyledHeader>
    );
  }

  componentWillUnmount() {
    clearInterval(this.getTimeNow);
  }
}
