import React, { Component } from 'react'
import styled from 'styled-components'
import IpValidate from 'ip-validator'

// Images
import ic_close from '../../../images/ic_close_gray.svg';
import ic_close_red from '../../../images/ic_close.svg'

// Components
import InputTextControl from '../../Main/MainScreen/Control/InputTextControl';
import ButtonConfirmPopup from '../MainScreen/Control/ButtonConfirmPopup';
// import PopupHeader from './PopupHeader'

const Bound = styled.div`
    padding: 14px 14px 24px 12px;
    background-color: #fafafa;
    width: 400px;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .popupHeader{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 27px;
        h4{
            font-family: SVN-Gilroy;
            font-size: 20px;
            font-weight: bold;
            color: #000000;
        }
        .ic_close{
            background-image: url(${ic_close});
            background-position:center center;
            background-repeat: no-repeat;
            background-size:cover;
            width:26px;
            height: 26px;
            cursor: pointer;
        }
    }
    .group-input{
        margin-right: 5.8px;
        margin-left: 8px;
    }
    button{
        float: right;
        margin: 23px 5.8px 0 0;
    }
`

export default class PopupAddBranch extends Component {
    state = {
        data: this.props.dataNeedModify? this.props.dataNeedModify.Name:'',
        timeoutAPI: this.props.dataNeedModify? String(this.props.dataNeedModify.APITimeout):'',
        macLCD: this.props.dataNeedModify? String(this.props.dataNeedModify.Mac):'',
    }
    getText(data) {
        // debugger;
        this.setState({ data })
    }
    getTimeoutAPI = (data) => {
        this.setState({ timeoutAPI: data })
    }
    getMacAdressLcd = (data) => {
        this.setState({ macLCD: data.trim() })
    }
    onClick() {
        const {listDevicePGD} = this.props
        // console.log(this.props.dataNeedModify);
        if (!this.state.data.trim()) return;
        //if(this.props.dataNeedModify && !this.state.data.trim()) return;
        const timeoutNb = Number(this.state.timeoutAPI)
        if (this.props.type === 'DeviceKiosk') {
            if (!/^[1-9][0-9]*$/.test(timeoutNb) || timeoutNb < 15 || timeoutNb > 90) {
                this.props.alertPopup({
                    iconData: ic_close_red,
                    content: <>
                        <div>Thời gian time-out</div>
                        <div>phải là số trong khoảng</div>
                        <div>từ 15 đến 90.</div>
                    </>,
                })
                return
            }
        }

        // if (this.props.type === 'DeviceLcd' && !!this.props.dataNeedModify && !!this.props.dataNeedModify.Mac) {
        //     if (!this.state.macLCD) {
        //         this.props.alertPopup({
        //             iconData: ic_close_red,
        //             content: <>
        //                 Mã thiết bị LCD không được để trống
        //             </>,
        //         })
        //         return
        //     }
        //     if (/\s+/g.test(this.state.macLCD)) {
        //         this.props.alertPopup({
        //             iconData: ic_close_red,
        //             content: <>Mã thiết bị LCD không có khoảng trắng</>,
        //         })
        //         return
        //     }

        //     if (IpValidate.ipv4(this.state.macLCD) || IpValidate.ipv6(this.state.macLCD)) {
        //         this.props.alertPopup({
        //             iconData: ic_close_red,
        //             content: <>Mã thiết bị LCD không là địa chỉ IP</>,
        //         })
        //         return
        //     }
        // }

        if(this.props.dataNeedModify){
            const {ID} = this.props.dataNeedModify
            if((this.state.data) &&
            listDevicePGD.find(item => (
                    item.ID !== ID &&
                    item.Name.trim().replace(/\s+/g, " ") === this.state.data.trim().replace(/\s+/g, " ")))
            ){
                if(this.props.type === 'DeviceKiosk'){
                    this.props.alertPopup({
                        iconData: ic_close_red,
                        content: 'Tên thiết bị Kiosk đã tồn tại.'
                    })
                }else if(this.props.type === 'DeviceLcd'){
                    this.props.alertPopup({
                        iconData: ic_close_red,
                        content: 'Tên màn hình LCD đã tồn tại.',
                    })
                }
                return
            }
        }else{
            if((this.state.data &&
                listDevicePGD.find(item => (
                    item.Name.trim().replace(/\s+/g, " ") === this.state.data.trim().replace(/\s+/g, " ")))    
            )){
                if(this.props.type === 'DeviceKiosk'){
                    this.props.alertPopup({
                        iconData: ic_close_red,
                        content: 'Tên thiết bị Kiosk đã tồn tại.'
                    })
                }else if(this.props.type === 'DeviceLcd'){
                    this.props.alertPopup({
                        iconData: ic_close_red,
                        content: 'Tên màn hình LCD đã tồn tại.',
                    })
                }
                return
            }
        }
        if (this.props.idOffice && this.props.dataNeedModify) {
            // Edit Device Kiosk
            this.props.handleOnClick(
                this.props.dataNeedModify.ID,
                this.state.data,
                this.props.type,
                this.props.dataNeedModify.currentPage,
                timeoutNb,
                this.state.macLCD
            )
        } else if (this.state.data !== '') {
            // Add Device Kiosk
            this.props.handleOnClick(
                this.props.idOffice,
                this.state.data,
                this.props.type,
                1,
                timeoutNb,
                this.state.macLCD
            )
            switch (true) {
                case this.props.type === "DeviceKiosk":
                    let AddKioskSuccess = "AddKioskSuccess"
                    this.props.showPopupInBiometric({}, AddKioskSuccess);
                    break;
                case this.props.type === "DeviceLcd":
                    let AddLCDSuccess = "AddLCDSuccess"
                    this.props.showPopupInBiometric({}, AddLCDSuccess);
                    break;
                default:
                    break;
            }
        }
        this.props.outPopup()
    }
    render() {
        // console.log("edit branch",this.props)
        // console.log('dataNeedModify',this.props.dataNeedModify)
        return (
            <Bound>
                <div className="popupHeader">
                    <h4 className="title">{this.props.titleHeader}</h4>
                    <span
                        onClick={() => this.props.outPopup()}
                        className="ic_close"
                    ></span>
                </div>
                <div className="group-input">
                    <InputTextControl
                        getText={(data) => this.getText(data)}
                        title={this.props.title}
                        placeHolder={this.props.placeHolder}
                        defaultValue={
                            this.props.dataNeedModify &&
                            this.props.dataNeedModify.Name
                                ? this.props.dataNeedModify.Name
                                : ''
                        }
                    />
                </div>
                {this.props.type === 'DeviceKiosk' && (
                    <div className="group-input">
                        <InputTextControl
                            getText={(data) => this.getTimeoutAPI(data)}
                            title="thời gian time-out nhận diện khách hàng (giây)"
                            placeHolder="Nhập thời gian, tối đa 90 giây"
                            defaultValue={
                                this.props.dataNeedModify
                                    ? String(
                                          this.props.dataNeedModify.APITimeout
                                      )
                                    : ''
                            }
                        />
                    </div>
                )}
                {/* {this.props.type === 'DeviceLcd' &&
                    !!this.props.dataNeedModify &&
                    !!this.props.dataNeedModify.Mac && (
                        <div className="group-input">
                            <InputTextControl
                                getText={(data) => this.getMacAdressLcd(data)}
                                title="mã thiết bị LCD"
                                placeHolder="Nhập mã thiết bị LCD"
                                defaultValue={
                                    this.props.dataNeedModify
                                        ? String(this.props.dataNeedModify.Mac)
                                        : ''
                                }
                            />
                        </div>
                    )} */}
                <ButtonConfirmPopup
                    onClick={() => this.onClick()}
                    opacity={
                        !this.state.data.trim() ||
                        (this.props.type === 'DeviceKiosk' &&
                            !this.state.timeoutAPI.trim())
                            ? '0.3'
                            : 'unset'
                    }
                    titleConfirm={
                        this.props.dataNeedModify ? 'xác nhận' : 'hoàn tất'
                    }
                />
            </Bound>
        )
    }
}