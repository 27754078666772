import React, { Component } from 'react'
import IpValidate from 'ip-validator'
import styled from 'styled-components'


import ic_close from '../../../images/ic_close_gray.svg'
import InputTextControl from '../MainScreen/Control/InputTextControl';
import SingleChoiseDropDownControl from '../MainScreen/Control/SingleChoiseDropDownControl';
import { connect } from 'react-redux'
import { getListTypeCounterServices, getCounterByOffice } from '../../../actions/counterAction';
import MultichoiceDropDown from '../MainScreen/Control/MultichoiceDropDown';
import { counterNumber } from '../../../data/counterNumber'


const Bound = styled.div`
    padding: 13px 13px 20px 13px;
    width: 454px;
    height: auto;
    background-color:#fafafa;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'SVN-Gilroy';
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    .row_header{
        display: flex;
        justify-content:space-between;
        align-items: center;
        .titleHeader{
            font-size: 20px;
            font-weight: bold;
            line-height: 1.1;
            color: #000000;
        }
        .ic_close {
            cursor: pointer;
        }
    }
    .row_main{
        padding: 0 7px;
        margin: 20px 0 0 0;
        h4 {
            font-size: 11px;
            font-weight: bold;
            line-height: 1.26;
            color: #005993;
            text-transform:uppercase;
            margin: 16px 0 0 0;
        }
        .AllCounter{
            height: 44px;
        }
        
    }
    .div-alert {
        font-family: 'SVN-Gilroy';
        font-size: 13px;
        font-weight: 500;
        color: #d71249;
        margin-top: 7px;
        /*margin-bottom: 7px;*/
    }
    .row_flex{
        display: flex;
        flex-direction: row;
        align-items: center;
        .numberCounter{
            width:200px;
        }
      .checkBox_priority{
          margin: 5px 0 0 44px;
          .radio-field {
            display: flex;
            align-items: center;
            position: relative;
            input[type="checkbox"] {
              position: absolute;
              height: 0;
              width: 0;
              opacity:0;
            }        
            .checkmark {
              height: 16px;
              width: 16px;
              border: solid 0.8px #c8c8c8;
              background: #ffffff;
            }
            .checkmark:after {
              content: "";
              position: absolute;
              display: none;
            }
            input[type="checkbox"]:checked ~ .checkmark:after {
              display: block;
            }
            .checkmark::after {
              left: 4.5px;
              top: 1.5px;
              width: 4px;
              height: 8px;
              border: solid #d71249;
              border-width: 0 3px 3px 0;    
              transform: rotate(45deg);
            }
            .text_label {
              margin: 0 0 0 8px;
              font-size: 14px;
              font-weight: 500;
              line-height: 1.2;
              color: #000000;
            }
          }
      }
    }
    .row_btn{
      margin: 16px 0 0 0;
      display:flex;
      justify-content:flex-end;
      flex-direction: column;
      .guid-text{
        /* height: 36px; */
        width: 264px;
        font-family: SVN-Gilroy;
        font-size: 12px;
        font-weight: 500;
        line-height: 1.3;
        color: #787878;
      }
      .btn-add{
        display: flex;
        justify-content: flex-end;
      }
      .btn_success{
        width: 100px;
        height: 36px;
        border-radius: 18px;
        background-color: #005993;
        font-size: 13px;
        font-weight: bold;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
`

class PopupAddCounter extends Component {
    constructor(props) {
        super(props)
        this.listServiceChoice = null
        this.state = {
            idShow: '',
            dataNumber: [],
            // OfficeID: this.props.dataNeedModify ? this.props.dataNeedModify.AtOfficeID:'',
            //////
            priorityCounter: this.props.dataNeedModify
                ? this.props.dataNeedModify.IsVIP
                : false,
            nameCounter: this.props.dataNeedModify
                ? this.props.dataNeedModify.CounterName
                : '',
            numberCounter: this.props.dataNeedModify
                ? this.props.dataNeedModify.CounterNumber
                : '',
            desktopKey: this.props.dataNeedModify
                ? this.props.dataNeedModify.DeviceMac
                : '',
            data: [],
            isEmptyTrans: false,
            errorDesktopkey: ''
        }
    }

    componentWillMount() {
        let dataLogin = JSON.parse(localStorage.getItem('dataLogin'))
        this.props.getListTypeCounterServices(dataLogin.UID)
        let { listTypeCounterServices } = this.props.counterReducer
        this.setState(
            {
                data: listTypeCounterServices,
                OfficeID: dataLogin.OfficeID,
            }
            //  () => {console.log('data',this.state.data);}
        )
    }
    async componentDidMount() {
        const { total } = this.props.counterReducer
        const { dataLogin } = this.props.userReducer
        await this.props.getCounterByOffice(dataLogin.OfficeID, total, 1, false)
        const { listCounterNumber } = this.props.counterReducer
        const dataNumber = counterNumber.reduce(
            (arr, item) =>
                !listCounterNumber.find((i) => i === item)
                    ? [...arr, item]
                    : arr,
            []
        )
        if (this.props.dataNeedModify)
            this.setState({
                dataNumber: [
                    this.props.dataNeedModify.CounterNumber,
                    ...dataNumber,
                ].sort((a, b) => a - b),
            })
        else this.setState({ dataNumber })
    }

    componentDidUpdate(prevProps, prevState) {
        if(JSON.stringify(prevState.dataNumber) !== JSON.stringify(this.state.dataNumber)) {
            if(!this.state.numberCounter && this.state.dataNumber.length > 0) {
                this.setState({ numberCounter: this.state.dataNumber[0] })
            }
        }
    }
    

    setIdShow = (id) => {
        this.setState({ idShow: id })
    }

    getText = (type) => (text) => {
        this.setState({ [type]: text }, () => {
            // console.log(text);
        })
    }

    getNameCounter = (e) => {
        this.setState({
            nameCounter: e,
        })
    }

    toggleCheckboxChange = () => {
        this.setState(
            {
                priorityCounter: !this.state.priorityCounter,
            },
            () => {
                // console.log(this.state.priorityCounter);

                this.state.priorityCounter && this.multiChoiceService.checkAll()
                // debugger
            }
        )
    }
    onChangeListChoice = (list) => {
        list.forEach((serv) => {
            if (serv && serv.sub && serv.sub.length >= 0) {
                this.listServiceChoice = []
                serv.sub.forEach((element) => {
                    this.listServiceChoice.push(element)
                })
            }
        })
    }

    getDesktopKey = (key) => {
        this.setState({
            desktopKey: key,
        })
    }

    submit = (dataNeedModify) => {
        // debugger
        this.setState({
            isEmptyTrans: false,
            errorDesktopkey: '',
        })
        let {
            // OfficeID,
            numberCounter,
            priorityCounter,
            nameCounter,
        } = this.state
        // let checkAllService = false;

        // debugger;
        const { listCounter } = this.props.counterReducer
        const dataCounter = {
            OfficeID: this.props.userReducer.dataLogin.OfficeID,
            //CounterNum: numberCounter,
            //IsVIP: priorityCounter,
            //CounterName: nameCounter
        }
        if (dataNeedModify) {
            if (
                nameCounter &&
                listCounter.find(
                    (item) =>
                        item.ID !== dataNeedModify.ID &&
                        priorityCounter &&
                        item.CounterName.trim().replace(/\s+/g, ' ') ===
                            nameCounter.trim().replace(/\s+/g, ' ')
                )
            ) {
                this.props.alertPopup('Tên quầy giao dịch đã tồn tại.')
                return
            }
            dataCounter.CounterID = dataNeedModify.ID
            if (dataNeedModify.CounterNumber !== numberCounter)
                dataCounter.CounterNum = numberCounter
            // if(dataNeedModify.IsVIP !== priorityCounter)
            dataCounter.IsVIP = priorityCounter
            if (dataNeedModify.CounterName !== nameCounter)
                dataCounter.CounterName = nameCounter.trim()
            if (dataNeedModify.ServAllService) {
                if (this.multiChoiceService.state.indexChoice === 1)
                    dataCounter.ServiceList = this.listServiceChoice
                else dataCounter.ServAllService = true
            } else {
                if (this.multiChoiceService.state.indexChoice === 0)
                    dataCounter.ServAllService = true
                else if (this.listServiceChoice)
                    dataCounter.ServiceList = this.multiChoiceService.state.subChoice[0].sub
                else dataCounter.ServiceList = dataNeedModify.ServiceList
            }
            // debugger;
            if (Object.keys(dataCounter).length <= 2){
                return
            }
            if (dataCounter.ServiceList && !dataCounter.ServiceList.length) {
                this.setState({ isEmptyTrans: true })
                return
            }

            if (dataNeedModify.DeviceMac && !this.state.desktopKey.trim()) {
                this.setState({
                    errorDesktopkey: 'PC NAME không được để trống.',
                })
                return
            }

            if (
                dataNeedModify.DeviceMac &&
                /\s+/g.test(this.state.desktopKey)
            ) {
                this.setState({
                    errorDesktopkey: 'PC NAME không có khoảng trắng.',
                })
                return
            }

            if (dataNeedModify.DeviceMac && (IpValidate.ipv4(this.state.desktopKey) || IpValidate.ipv6(this.state.desktopKey))) {
                this.setState({
                    errorDesktopkey: 'PC NAME không là địa chỉ IP.',
                })
                return
            }

            dataCounter.DeviceMac = this.state.desktopKey.trim().toUpperCase()
            // debugger;

            this.props.acceptActionCounter(dataCounter)
            // this.props.outPopup('')
            return
        } else {
            if (
                nameCounter &&
                listCounter.find(
                    (item) =>
                        item.CounterName.trim().replace(/\s+/g, ' ') ===
                        nameCounter.trim().replace(/\s+/g, ' ')
                )
            ) {
                this.props.alertPopup('Tên quầy giao dịch đã tồn tại.')
                return
            }
        }

        dataCounter.CounterNum = numberCounter
        dataCounter.CounterName = nameCounter
        if (priorityCounter) dataCounter.IsVIP = true
        // debugger;

        if (!this.listServiceChoice) dataCounter.ServAllService = true
        if (this.listServiceChoice)
            dataCounter.ServiceList = this.listServiceChoice
        if (dataCounter.ServiceList && !dataCounter.ServiceList.length) {
            this.setState({ isEmptyTrans: true })
            return
        }
        this.props.acceptActionCounter(dataCounter)
        this.props.outPopup('')
        let AddTransactionCounterSuccess = 'AddTransactionCounterSuccess'
        this.props.showPopupInBiometric({}, AddTransactionCounterSuccess)
        return
    }

    render() {
        let numberCounter = this.getText('numberCounter')
        let { dataNeedModify } = this.props
        let titleMultiDefault
        if (dataNeedModify) {
            if (dataNeedModify.ServAllService) {
                titleMultiDefault = 'Tất cả giao dịch'
            } else {
                titleMultiDefault = `Danh sách giao dịch (${
                    dataNeedModify.ServiceList
                        ? dataNeedModify.ServiceList.length
                        : 0
                })`
            }
        }
        return (
            <Bound>
                <div className="row_header">
                    <div className="titleHeader">{this.props.titleHeader}</div>
                    <div>
                        <img
                            onClick={() => this.props.outPopup('')}
                            className="ic_close"
                            src={ic_close}
                            alt="abc"
                        />
                    </div>
                </div>
                <div className="row_main">
                    <h4>tên quầy giao dịch </h4>
                    <div className="NameCounter">
                        <InputTextControl
                            name="nameCounter"
                            getText={(value) => this.getNameCounter(value)}
                            placeHolder="Nhập tên quầy giao dịch"
                            isDisabled={
                                !!dataNeedModify &&
                                dataNeedModify.DesktopDevice &&
                                dataNeedModify.TabletDevice
                            }
                            defaultValue={
                                dataNeedModify ? dataNeedModify.CounterName : ''
                            }
                        />
                    </div>
                    <h4>Giao dịch thực hiện</h4>
                    <div className="AllCounter" style={{ marginTop: '5px' }}>
                        <MultichoiceDropDown
                            ref={(ref) => (this.multiChoiceService = ref)}
                            isDisabled={this.state.priorityCounter}
                            onChangeListChoice={this.onChangeListChoice}
                            valueDefault={titleMultiDefault}
                            data={[
                                {
                                    Name: 'Danh sách giao dịch',
                                    ID: 1,
                                    sub: this.state.data,
                                },
                            ]}
                            // dataChoice={null}
                            indexChoice={
                                dataNeedModify
                                    ? dataNeedModify.ServAllService
                                        ? 0
                                        : 1
                                    : 0
                            }
                            dataChoice={
                                dataNeedModify
                                    ? dataNeedModify.ServAllService
                                        ? this.listServiceChoice
                                        : [
                                              {
                                                  ID: 1,
                                                  sub:
                                                      dataNeedModify.ServiceList,
                                              },
                                          ]
                                    : this.listServiceChoice
                            }
                        />
                    </div>
                    {this.state.isEmptyTrans && (
                        <div className="div-alert">
                            Vui lòng chọn ít nhất 1 giao dịch.
                        </div>
                    )}
                    {!!dataNeedModify && dataNeedModify.DesktopDeviceID !== null && (
                        <>
                            <h4>PC NAME</h4>
                            <div className="NameCounter">
                                <InputTextControl
                                    name="desktopKey"
                                    getText={(value) =>
                                        this.getDesktopKey(value)
                                    }
                                    placeHolder="Nhập PC NAME"
                                    defaultValue={dataNeedModify.DeviceMac}
                                />
                            </div>
                            {!!this.state.errorDesktopkey && (
                                <div className="div-alert">{this.state.errorDesktopkey}</div>
                            )}
                        </>
                    )}
                    <h4>Số quầy </h4>
                    <div className="row_flex">
                        <div className="numberCounter">
                            <SingleChoiseDropDownControl
                                id="selectNumberCounter"
                                idShow={this.state.idShow}
                                setIdShow={this.setIdShow}
                                titleChoise="Chọn số quầy"
                                getText={numberCounter}
                                data={this.state.dataNumber}
                                isDisable={
                                    dataNeedModify &&
                                    dataNeedModify.DesktopDevice &&
                                    dataNeedModify.TabletDevice
                                }
                                defaultValue={
                                    // dataNeedModify
                                    //     ? dataNeedModify.CounterNumber
                                    //     : this.state.dataNumber.length > 0
                                    //     ? this.state.dataNumber[0]
                                    //     : ''
                                    this.state.numberCounter
                                }
                            />
                        </div>
                        <div className="checkBox_priority">
                            <label className="radio-field">
                                <input
                                    type="checkbox"
                                    name="content"
                                    value="abc"
                                    checked={this.state.priorityCounter}
                                    onChange={this.toggleCheckboxChange}
                                />
                                <div className="checkmark"></div>
                                <div className="text_label">Quầy ưu tiên</div>
                            </label>
                        </div>
                    </div>
                    <div className="row_btn">
                        <div className="guid-text">
                            (*) Hệ thống sẽ gọi tên quầy dựa trên “số quầy” được
                            cấu hình trong hệ thống.
                        </div>
                        <div className="btn-add">
                            <div
                                onClick={() => {
                                    // debugger;
                                    let {
                                        numberCounter,
                                        nameCounter,
                                    } = this.state
                                    if (!numberCounter || !nameCounter.trim())
                                        return
                                    // const multiChoice = this.multiChoiceService;
                                    // if(this.multiChoiceService.state.indexChoice && !this.multiChoiceService.subChoice[0].sub.length)
                                    // return;
                                    // if(!this.listServiceChoice || (this.listServiceChoice && this.listServiceChoice.length === 0))
                                    // return;
                                    // if(this.listServiceChoice && !this.listServiceChoice.length) return;

                                    this.submit(dataNeedModify)
                                }}
                                className="btn_success"
                                style={{
                                    opacity:
                                        !this.state.numberCounter ||
                                        !this.state.nameCounter.trim()
                                            ? '0.5'
                                            : 'unset',
                                    cursor:
                                        !this.state.numberCounter ||
                                        !this.state.nameCounter.trim()
                                            ? 'default'
                                            : 'pointer',
                                }}
                            >
                                {dataNeedModify ? 'XÁC NHẬN' : 'HOÀN TẤT'}
                            </div>
                        </div>
                    </div>
                </div>
            </Bound>
        )
    }
}

const mapStateToProps = state => {
  return {
    userReducer: state.userReducer,
    counterReducer: state.counterReducer
  }
}
const mapDispatchToProps = dispatch => {
  return {
    /// Counter
    getListTypeCounterServices: (officeId) => dispatch(getListTypeCounterServices(officeId)),
    getCounterByOffice: (officeId, pageSize, pageNumber, isLoad = true) => dispatch(getCounterByOffice(officeId, pageSize, pageNumber, isLoad))
  }
}


export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(PopupAddCounter);