import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";

//Data
import { formatDayAndTime } from "../../../../tools";

//Control
// import LoadingControl from './LoadingControl';

const StyledHeader = styled.div`
    height: 80px;
    background: #fafafa;
    padding: 0 40px 0 20px;
    flex-shrink: 0;
    box-shadow: inset 0px -0.5px 0px rgba(0, 0, 0, 0.12);
    &,
    & > .right-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    * {
        font-family: SVN-Gilroy;
        font-weight: bold;
    }

    .title {
        font-size: 32px;
        line-height: 1.29;
        color: #222222;
        margin-left: ${props => props.isShrink?'0':'20px'};
    }

    .right-header > * {
        /* margin-right: 8px; */
    }
    ${'' /* .loading{
        display: flex;
        align-items: center;
        padding-right: 30px;
        p{
            font-family: SVN-Gilroy;
            font-size: 14px;
            font-weight: 500;
            line-height: 1.25;
            color: #787878;
            padding-left: 6px;
        }
    } */}
    .main-flex{
        display: flex;
        align-items: flex-end;
        line-height: 1;
        .right-header__title {
            font-size: 12px;
            line-height: 1.42;
            color: #005993;
        }

        .time {
            font-weight: 500;
            width: 57px;
            padding-left: 15px;
        }
    }
`;

class HeaderControl extends Component {
    constructor(props) {
        super(props);

        this.state = {
            time: "",
            title: this.props.title,
            isLoading: false
        };

        // get time
        this.getTimeNow = {};
    }

    componentWillMount() {
        this.getTimeNow = setInterval(() => {
            this.setState({ time: formatDayAndTime().getFullTime });
        }, 1000);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps) {
            this.setState({ title: nextProps.title });
        }
    }

    render() {
        return (
            <StyledHeader className='header' isShrink={this.props.isShrink}>
                <div className="title">{this.state.title}</div>
                <div className="right-header">
                    {/* <div className='loading'>
                        <LoadingControl
                            loadingPage={false}
                            size='24px'
                        />
                        <p>Đang tải...</p>
                    </div> */}
                    <div className='main-flex'>
                        <div className="right-header__title">Thời gian hệ thống:</div>
                        <div className="time">{this.state.time}</div>
                    </div>
                </div>
            </StyledHeader>
        );
    }

    componentWillUnmount() {
        clearInterval(this.getTimeNow);
    }
}

const mapStateToProps = (state) => {
    return {
        biometricReducer: state.biometricReducer
    }
}

export default connect(mapStateToProps, null, null, { forwarRef: true })(HeaderControl);
