import styled from 'styled-components'

const Bound = styled.div`
    width: 409px;
    box-sizing: border-box;
    border-radius: 16px;
    background: #ffffff;    
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.16);
    /* padding: 60px 38px; */
    /* animate */
    animation-name: isShowPopup;
    transition-property: bottom;
    transition-duration: 500ms;
    display: relative;
    outline: none;
    @keyframes isShowPopup {
        from {
            top: -100px;
        }
        to {
            top: 45px;
        }
    }
    .ic-close-remove-popup {
        position: absolute;
        right: 22px;
        top: 22px;
        cursor: pointer;
    }
`;
const Content = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
    align-items: center;
    img{
        text-align:center;
    }

    .ic-warning-remove {
        margin-top: 22px;
    }

    .title-popup{
        font-family: 'SVN-Gilroy';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        color: #051321;
        text-align: center;
        padding-top: 12px;
        width: 269px; 
    }

    .note-popup {
        font-family: 'SVN-Gilroy';
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: #000924;
        text-align: center;
        margin-top: 6px;
        padding: 0 21px;
    }

    .block-btn {
        margin-top: 15px;
        margin-bottom: 33px;
        display: flex;
        justify-content: center;
        .add-visitor-btn,
        .discard-btn {
            width: 160px;
            height: 50px;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: 'SVN-Gilroy';
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 22px;
            text-align: center;
            letter-spacing: 0.03em;
            cursor: pointer;
        }
        .add-visitor-btn {
            color: #ffffff;
            background: #33adff;        
            margin-right: 12px;
            border: 0.6px solid #33ADFF;
        }
        .discard-btn {
            background: #ffffff;
            color: #33ADFF;
            border: 0.6px solid #33ADFF;
            box-sizing: border-box;
        }
    }    
`

export {
    Bound,
    Content
}