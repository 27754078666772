import React, { useCallback } from 'react'

// styles
import { WrapperCheckBox, CheckBox, Text } from './index.styles'

// images
import checked_red from '../../../../../../images/checked_red.svg'

const CheckBoxItem = ({
    children,
    onClick,
    isChecked,
    isCheckedSome,
    isDisabled,
    extendStylesText
}) => {
    const _handleClick = useCallback(
        () => {
            if (isDisabled) return;

            onClick()
        },
        [onClick, isChecked, isDisabled],
    )

    const iconInCheckbox = () => {
        if (isChecked)
            return <img src={checked_red} alt="checked" />
        if (isCheckedSome)
            return <div className="checked-some-child"></div>
        return null
    }

    return (
        <WrapperCheckBox onClick={_handleClick}>
            <CheckBox isDisabled={isDisabled}>
                {iconInCheckbox()}
            </CheckBox>
            <Text isDisabled={isDisabled} extendStyles={extendStylesText}>{children}</Text>
        </WrapperCheckBox>
    )
}

export default CheckBoxItem
