import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteBiometricOfCustomer, getListCustomer } from '../../../../../../actions/biometricExecution'
import ButtonConfirmPopup from '../../../Control/ButtonConfirmPopup'
import OverlayFullScreen from '../../../Control/OverlayFullScreen'
import { WrapPopupConfirmDelete } from './index.styles'


export default function PopupConfirmDelete({onClose,onClosePopupInfos, data}) {
    const dispatch = useDispatch()
    const CifsNum = useSelector(state => state.biometricExecutionReducer.CifsNum)
    
    const callbackSuccess = () => {
        dispatch(getListCustomer(CifsNum))
        onClose()
        onClosePopupInfos()
    }

    const handleDeleteBio = () => {
        dispatch(deleteBiometricOfCustomer(data.BioInfos.CustomerNumber, callbackSuccess))
    }


    return (
        <OverlayFullScreen>
            <WrapPopupConfirmDelete>
                <div className="row_question">
                    <span className="img_question"></span>
                </div>
                <div className="row_title">
                    <p className="title">Xác nhận xóa dữ liệu STH</p>
                </div>
                <p></p>
                <div className="row_btn_confirm">
                    <div className="btn_exit">
                        <ButtonConfirmPopup
                            titleConfirm="HỦY BỎ"
                            bg_color="rgb(215, 18, 73)"
                            onClick={onClose}
                        />
                    </div>
                    <div className="btn_accept">
                        <ButtonConfirmPopup
                            titleConfirm="XÁC NHẬN"
                            bg_color="#005993"
                            onClick={handleDeleteBio}
                        />
                    </div>
                </div>
            </WrapPopupConfirmDelete>
        </OverlayFullScreen>
    )
}
