import React, { Component } from 'react'
import styled from 'styled-components';
import { connect } from 'react-redux';

import SubHeaderControl from '../Control/SubHeaderControl';
import PagingControl from '../Control/PagningControl';
import EmptyControl from "../Control/EmptyControl";
import DeviceKios from "./DeviceKios";
// import DeviceTablet from './DeviceTablet';
import ShowTicket from './ShowTicket';

import { getAds, resetAdsReducer, saveTextSearchAds } from '../../../../actions/adsAction';
import { advertisementTabArr, emptyData } from '../../../../data/data'
import { getAllOffice } from '../../../../actions/officeAction';

const Bound = styled.div`
    font-family: 'SVN-Gilroy';
    font-size: 15px;
    font-weight: bold;
    display:flex;
    flex:1;
    flex-direction:column;
    overflow:hidden;
    /*min-width:1260px;*/
    .title {
        background-color:#fafafa;
        height: 48px;
        display: flex;
        flex-direction: row;
        text-align: center;
        align-items: flex-start;            
        .choise {
            display:flex;
            align-items:center;
            justify-content:center;
            width:120px;
            height:calc(100% - 3px);
            cursor: pointer;
        }
    }
    .ad-container{
        display: flex;
        flex: 1;
        overflow: hidden;  
        padding: 0 40px;
        flex-direction: column; 
        .title-wrapper{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            p {
                color: #005993;
                font-size: 11px;
                text-transform: uppercase;
                margin: 26px 0 17px 0;
            }
        }
        .item {
            display: flex;
            flex-direction: column;
            overflow:hidden;
            p{
                text-transform: uppercase;
            }
            .scroll{
                overflow:auto;   
                ::-webkit-scrollbar { 
                    display: none; 
                    }
                .item-child {
                  display: grid;
                  grid-template-columns: 240px 240px 240px 240px;
                  /*justify-content: space-evenly;*/
                  justify-content: space-between;
                  grid-gap: 20px;    
                }    
            }
        }
        
    }
`
const limitPerPage = 8
class AdvertisementContainerV2 extends Component {
  _isMounted = false;
  state = {
    typeSelected: advertisementTabArr[0].key,
    subHeaderData: advertisementTabArr[0].subHeaderData,
    data: [],
    currentPage: 1,
    startIndex: 0,
    endIndex: 10
  }

  componentDidMount() {
    this._isMounted = true;
    const { typeSelected } = this.state;
    this._isMounted && this.pagnationPage();
    this._isMounted && this.props.getAds(typeSelected, limitPerPage, 1);
    this.props.getAllOffice();
  }

  setTypeSelected = (tab) => {
    if (tab) {
      const { typeSelected } = this.state

      if (typeSelected !== tab.key) {
        // this.props.resetAdsReducer()
        this.props.getAds(tab.key, limitPerPage, 1)
      }
      this.setState({
        typeSelected: tab.key,
        subHeaderData: tab.subHeaderData
      })
    }
  }
  pagnationPage() {
    let endIndex = this.state.currentPage * limitPerPage;
    let startIndex = endIndex - limitPerPage;
    this.setState({ startIndex, endIndex })
  }
  onPageChange = (e) => {
    let currentPage = e
    let endIndex = currentPage * limitPerPage;
    let startIndex = endIndex - limitPerPage;
    this.setState({ currentPage, startIndex, endIndex });
    this.props.getAds(this.state.typeSelected, limitPerPage, currentPage)
  }
  showPopupAddAdvert = () => {
    const { typeSelected } = this.state

    switch (typeSelected) {
      case advertisementTabArr[0].key: {
        this.props.showAddAdvertisementKioskPopup()

        break;
      }
      case advertisementTabArr[1].key: {
        this.props.showAddAdvertisementTablePopup()
        break;
      }
      case advertisementTabArr[2].key: {
        this.props.showAddAdvertisementShowTicketPopup()
        break;
      }
      default:
        break;
    }


  }
  renderTab() {
    return advertisementTabArr.map((tab, i) => {
      return (
        <div className='choise' key={i}
          style={this.state.typeSelected === tab.key ?
            { color: tab.activeColor, borderBottom: tab.avtiveBorderBottom }
            :
            { color: tab.disActiveColor }}
          onClick={() => this.setTypeSelected(tab)}>
          {tab.label}
        </div>
      )
    })
  }

  searchFilter = (data) => {
    switch (this.state.typeSelected) {
      case advertisementTabArr[0].key:
        this.props.getAds(advertisementTabArr[0].key, limitPerPage, 1, data)
        break;
      case advertisementTabArr[1].key:
        this.props.getAds(advertisementTabArr[1].key, limitPerPage, 1, data)
        break;
      case advertisementTabArr[2].key:
        this.props.getAds(advertisementTabArr[2].key, limitPerPage, 1, data)
        break;
        default:
          break;
    }
    this.props.saveTextSearchAds(data)
    this.setState({currentPage: 1})
  }

  onResetAdvertisement = () => {
    switch (this.state.typeSelected) {
      case advertisementTabArr[0].key:
        this.props.getAds(advertisementTabArr[0].key, limitPerPage, 1, '')
        break;
      case advertisementTabArr[1].key:
        this.props.getAds(advertisementTabArr[1].key, limitPerPage, 1, '')
        break;
      case advertisementTabArr[2].key:
        this.props.getAds(advertisementTabArr[2].key, limitPerPage, 1, '')
        break;
      default:
        break;
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if (JSON.stringify(prevProps.getAdsReducer.listAds) !==
  //     JSON.stringify(this.props.getAdsReducer.listAds) && prevState.currentPage === this.state.currentPage) {
  //     this.setState({ currentPage: 1 })
  //   }
  // }

  render() {
    const { listAds, totalAds, alert, currentPage } = this.props.getAdsReducer;
    // console.log('test', alert);        
    let { subHeaderData, typeSelected } = this.state
    // let dataSlice = listAds.slice(startIndex, endIndex)

    const arrFilterBranch = this.props.filterOfficeReducer.listOffice.filter(item=>item.OfficeType === "BRANCH")  
    const arrFilterSaving = this.props.filterOfficeReducer.listOffice.filter(item=>item.OfficeType === "SAVING")  
    const arrFilterTrading = this.props.filterOfficeReducer.listOffice.filter(item=>item.OfficeType === "TRADING")  
    return (
      <Bound>
        <div className='title'>
          {this.renderTab()}
        </div>
        <div className='ad-container'>
          <SubHeaderControl
            searchFilter={this.searchFilter}
            data={subHeaderData}
            onReset = { () => this.props.getAds(this.state.typeSelected, limitPerPage, 1, '')}
            typeSearch = 'searchAds'
            onAddMoreSubmit={this.showPopupAddAdvert} />

          {this._isMounted && listAds && listAds.length > 0
            ? (
              <div className="item">
                <div className='title-wrapper'>
                  <p>danh sách quảng cáo</p>
                  {
                    (totalAds > limitPerPage) ?
                      <PagingControl
                        //currentPage={this.state.currentPage}
                        currentPage={currentPage}
                        totalItem={totalAds}
                        limitPerPage={limitPerPage}
                        onChangePage={page => this.onPageChange(page)} /> : null
                  }

                </div>
                <div className='scroll'>
                  <div className="item-child">
                    {
                      // this._isMounted && dataSlice.map((item, i) => {
                      this._isMounted && listAds.map((item, i) => {
                        switch (typeSelected) {
                          case advertisementTabArr[0].key: {
                            return (
                              <DeviceKios
                                key={i}
                                keyValue={i}
                                data={item}
                                arrFilterOffice={{
                                  arrFilterBranch: arrFilterBranch,
                                  arrFilterSaving: arrFilterSaving,
                                  arrFilterTrading: arrFilterTrading,
                                }}
                                onShowPopupEditKiosk = {(data)=>this.props.onShowPopupEditKiosk(data)}
                                // handleDbClick={() => this.props.handleShowPopupEditAdvert(advertisementTabArr[0].key, item)}
                                onShowControl={(offset, from, data) => {
                                  this.props.onShowControl(offset, from, data);
                                }} />
                            )
                          }
                          case advertisementTabArr[1].key: {
                            return (
                              // <DeviceTablet
                              //   key={i}
                              //   keyValue={i}
                              //   data={item}
                              //   onShowControl={(offset, from, data) => {
                              //     this.props.onShowControl(offset, from, data);
                              //   }} />
                              <DeviceKios
                                key={i}
                                keyValue={i}
                                data={item}
                                arrFilterOffice={{
                                  arrFilterBranch: arrFilterBranch,
                                  arrFilterSaving: arrFilterSaving,
                                  arrFilterTrading: arrFilterTrading,
                                }}
                                onShowPopupEditKiosk = {(data)=>this.props.onShowPopupEditKiosk(data)}
                                onShowControl={(offset, from, data) => {
                                  this.props.onShowControl(offset, from, data);
                                }} />
                            )
                          }
                          case advertisementTabArr[2].key: {
                            return (
                              <ShowTicket
                                key={i}
                                keyValue={i}
                                data={item}
                                onShowPopupEditLCD = {(data)=>this.props.onShowPopupEditLCD(data)}
                                arrFilterOffice={{
                                  arrFilterBranch: arrFilterBranch,
                                  arrFilterSaving: arrFilterSaving,
                                  arrFilterTrading: arrFilterTrading,
                                }}
                                onShowControl={(offset, from, data) => {
                                  this.props.onShowControl(offset, from, data);
                                }} />
                            )
                          }
                          default:
                            break;
                        }
                        return null;
                      })
                    }
                  </div>
                </div>
              </div>
            )
            : (<EmptyControl
              alertContent={alert}
              content={emptyData.advertisement} />)
          }
        </div>
      </Bound>
    );
  }
}
const mapStateToProps = (state) => ({
  getAdsReducer: state.getAdsReducer,
  filterOfficeReducer: state.filterOfficeReducer
})

const mapDispatchToProps = dispatch => ({
  getAds: (typeAds, pageSize, pageNumber, searchText = undefined) => dispatch(getAds(typeAds, pageSize, pageNumber, searchText)),
  saveTextSearchAds: (text) => dispatch(saveTextSearchAds(text)),
  resetAdsReducer: () => dispatch(resetAdsReducer()),
  getAllOffice: () => dispatch(getAllOffice())
})

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(AdvertisementContainerV2);