import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux';
import PopupConfirm from './PopupConfirm'
import { forceSignOutUser, filterUser } from '../../../actions/userAction'

const Bound=styled.div`
    display:flex;
    flex-direction:column;
    padding: 5px 0;
    min-width:78px;
    position:absolute;
    top:${props=>props.offset.top}px;
    left:${props=>props.offset.left}px;
    background-color:white;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.06);
    background-color: #ffffff;
    div{
        width:100%;
        height:22px;
        padding:0 7px;
        font-family: 'SVN-Gilroy';
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.68;
        letter-spacing: normal;
        cursor:pointer;
    }
    .modify{
        color:#222222;
    }
    .remove{
        color:#f31919;
    }
`

class PopupControl extends Component {
    state =  {
        offset: this.props.offsetPopup,
        showConfirm: false
    }

    _showConfirmForceSignOutUser = () => {
        this.setState({ showConfirm: true })
    }

    _forceSignOutUser = async () => {
        const { dataReceive } = this.props;
        const uid = dataReceive[0]
        const { currentPage } = dataReceive
        await this.props.forceSignOutUser(uid)
        // if (typeof this.props.userReducer.err === 'object') return // have error

        this.props.filterUser(this.props.userReducer.lastConditionFilterUser, 10, currentPage)
        this._cancelForceSignOutUser()
    }

    _cancelForceSignOutUser = () => {
        this.setState({ showConfirm: false })
        this.props.closePopup()
    }

    render() {
        console.log(this.props.from);
        // console.log(this.props.dataReceive);
        const {from, dataReceive} = this.props;
        if(this.state.showConfirm) {
            return (
                <PopupConfirm
                    width="400px"
                    title="Xác nhận thoát phiên của user"
                    content={`Bạn có chắc muốn thoát phiên giao dịch của user: ${dataReceive[2]} - ${dataReceive[1]}`}
                    acceptAction={this._forceSignOutUser}
                    cancelAction={this._cancelForceSignOutUser}
                />
            )
        }

        return (
            <Bound offset={this.state.offset} ref={ref => this.popupRef = ref}>
                <div className='modify'
                    onClick={()=>{this.props.onModify(from, {...dataReceive,typeControl:"modify"})}}>Tùy chỉnh</div>
                {
                    from === "Permission" &&
                    <div onClick={this._showConfirmForceSignOutUser}>Thoát phiên</div>
                }
                <div className='remove'
                    onClick={()=>{this.props.onRemove(from, {...dataReceive,typeControl:"remove"})}}>Xóa</div>
            </Bound>
        )
    }

    componentDidMount() {
        const {offset} = this.state;
        if (this.popupRef.offsetTop + this.popupRef.offsetHeight > window.innerHeight - window.screenTop){
            offset.top = this.popupRef.offsetTop - this.popupRef.offsetHeight;
            this.setState({offset})
        }
    }
    
}

const mapStateToProps = (state) => ({
    userReducer: state.userReducer
})

const mapDispatchToProps = (dispatch) => ({
    forceSignOutUser: (uid) => dispatch(forceSignOutUser(uid)),
    filterUser: (dataFilter, pageSize, pageNumber) => dispatch(filterUser(dataFilter, pageSize, pageNumber))
})

export default connect(mapStateToProps, mapDispatchToProps)(PopupControl)
