import React, { useReducer, useEffect, useState } from 'react'

// data
import { officeTypes, types } from '../../../../data/dataBranch';

// component
import ButtonConfirmPopup from '../../MainScreen/Control/ButtonConfirmPopup'

// styles
import { WrapApplyLocation } from './index.styles'

// image
import ic_search_white_outline from '../../../../images/ic_search_white_outline.svg';

// helper
import strToStrNoAccent from '../../../../helper/strToStrNoAccent';

const ApplyLocation = ({ 
    listOffice,
    getLstBranchChecked,
    lstChecked,
    isShowSubmit, 
    titleConfirm, 
    onHandleClick,
    keySearchOffice,
    setKeySearchOffice
}) => {
    const [lstOfficeByType, setLstOfficeByType] = useReducer(
        (state, action) => ({...state, ...action}),
        {
            [officeTypes.BRANCH]: [],
            [officeTypes.TRADING]: [],
            [officeTypes.SAVING]: [],
        }
    );
    const [lstOfficeByTypeSearch, setLstOfficeByTypeSearch] = useReducer(
        (state, action) => ({...state, ...action}),
        {
            [officeTypes.BRANCH]: [],
            [officeTypes.TRADING]: [],
            [officeTypes.SAVING]: [],
        }
    );
    const [typeShowChildren, setTypeShowChildren] = useState([]);


    const _handleCheckType = (type, isChecked) => {
        if(isChecked) {
            const newLstChecked = lstChecked
                .filter(item => !lstOfficeByType[type].some(office => office.ID === item.ID))
            getLstBranchChecked(newLstChecked)
            return;
        }

        if(!typeShowChildren.includes(type)) {
            setTypeShowChildren(pre => [...pre, type])
        }

        if(!lstChecked) {
            getLstBranchChecked([...lstOfficeByType[type]])
            return;
        }

        const newLstChecked = lstChecked
            .filter(item => !lstOfficeByType[type].some(office => office.ID === item.ID))
        getLstBranchChecked([...newLstChecked, ...lstOfficeByType[type]])
    }

    const _handleCheckOffice = (office, isChecked) => {
        if(isChecked) {
            const newLstChecked = lstChecked.filter(item => item.ID !== office.ID)
            getLstBranchChecked(newLstChecked)
            return;
        }

        if(!lstChecked) {
            const newLstChecked = [office]
            getLstBranchChecked(newLstChecked)
            return;
        }
        const newLstChecked = [ ...lstChecked, office ]
        getLstBranchChecked(newLstChecked)
    }

    const _handleCheckShowChildren = (type) => {
        if(typeShowChildren.includes(type)) {
            setTypeShowChildren(typeShowChildren.filter(item => item !== type))
            return;
        }
        setTypeShowChildren(pre => [...pre, type])
    }

    const _handleSearchOffice = () => {
        const regSearch = new RegExp(strToStrNoAccent(keySearchOffice), "gi")
        const branchOffice = lstOfficeByType[officeTypes.BRANCH].filter(item => 
            regSearch.test(strToStrNoAccent(item.Name))
            || regSearch.test(strToStrNoAccent(item.BranchCode))
        )
        const tradingOffice = lstOfficeByType[officeTypes.TRADING].filter(item => 
            regSearch.test(strToStrNoAccent(item.Name))
            || regSearch.test(strToStrNoAccent(item.BranchCode))
        )
        const savingOffice = lstOfficeByType[officeTypes.SAVING].filter(item => 
            regSearch.test(strToStrNoAccent(item.Name))
            || regSearch.test(strToStrNoAccent(item.BranchCode))
        )
        setLstOfficeByTypeSearch({
            [officeTypes.BRANCH]: branchOffice,
            [officeTypes.TRADING]: tradingOffice,
            [officeTypes.SAVING]: savingOffice
        })
    }

    useEffect(() => {
        _handleSearchOffice()
    }, [JSON.stringify(lstOfficeByType)]);

    useEffect(() => {
        const {
            [officeTypes.BRANCH]: branchOffice,
            [officeTypes.TRADING]: tradingOffice,
            [officeTypes.SAVING]: savingOffice
        } = lstOfficeByType
        const newTypeShowChildren = []
        const someBranchChecked = branchOffice.some(item => lstChecked?.some(itemChecked => itemChecked.ID === item.ID))
        const someTradingChecked = tradingOffice.some(item => lstChecked?.some(itemChecked => itemChecked.ID === item.ID))
        const someSavingChecked = savingOffice.some(item => lstChecked?.some(itemChecked => itemChecked.ID === item.ID))
        someBranchChecked && newTypeShowChildren.push(officeTypes.BRANCH)
        someTradingChecked && newTypeShowChildren.push(officeTypes.TRADING)
        someSavingChecked && newTypeShowChildren.push(officeTypes.SAVING)
        setTypeShowChildren(newTypeShowChildren)
    }, [JSON.stringify(lstOfficeByType), JSON.stringify(lstChecked)]);

    useEffect(() => {
        const lstBranch = listOffice.filter(office => office.OfficeType === officeTypes.BRANCH)
        setLstOfficeByType({ [officeTypes.BRANCH]: lstBranch })
        setLstOfficeByTypeSearch({ [officeTypes.BRANCH]: lstBranch })
    }, [JSON.stringify(listOffice)]);

    useEffect(() => {
        const lstTrading = listOffice.filter(office => office.OfficeType === officeTypes.TRADING)
        setLstOfficeByType({ [officeTypes.TRADING]: lstTrading })
        setLstOfficeByTypeSearch({ [officeTypes.TRADING]: lstTrading })
    }, [JSON.stringify(listOffice)]);

    useEffect(() => {
        const lstSaving = listOffice.filter(office => office.OfficeType === officeTypes.SAVING)
        setLstOfficeByType({ [officeTypes.SAVING]: lstSaving })
        setLstOfficeByTypeSearch({ [officeTypes.SAVING]: lstSaving })
    }, [JSON.stringify(listOffice)]);

    return (
        <WrapApplyLocation>
            <div className="wrap-search">
                <input
                    type="text"
                    placeholder="Nhập mã, tên chi nhánh"
                    onKeyPress={(e) => {
                        if (e.charCode !== 13) return true
                        _handleSearchOffice()
                        return false
                    }}
                    value={keySearchOffice}
                    onChange={e => setKeySearchOffice(e.target.value)}
                />
                <button className="btn-search" onClick={_handleSearchOffice}>
                    <img src={ic_search_white_outline} alt="search" />
                </button>
            </div>
            <div className='wrapper'>
                {
                    types.map(({ type, name }) => {
                        const isTypeChecked = (lstOfficeByType[type] || [])
                            .every(item => lstChecked?.some(itemChecked => itemChecked.ID === item.ID))
                        return (
                            <React.Fragment key={type}>
                                <div className="wrap-check">
                                    <label className="main-check">
                                        <div className="wrap-checkbox">
                                            <input
                                                type="checkbox"
                                                checked={isTypeChecked}
                                                onChange={() => _handleCheckType(type, isTypeChecked)}
                                            />
                                            <span className="geek-mark"></span>
                                        </div>
                                        <div className="checkbox-name">{`${name} (${lstOfficeByType[type]?.length || 0})`}</div>
                                    </label>
                                    <div className="wrap-btn-show-children" onClick={() => _handleCheckShowChildren(type)}>
                                        <div className={typeShowChildren.includes(type) ? "checky show" : "checky"}></div>
                                    </div>
                                </div>
                                {
                                    typeShowChildren.includes(type) && 
                                    (lstOfficeByTypeSearch[type] || []).map((office) => {
                                        const { ID, Name, BranchCode, OfficeType } = office
                                        const isOfficeChecked = lstChecked?.some(itemChecked => itemChecked.ID === ID)
                                        return (
                                            <div className="wrap-check" key={ID}>
                                                <label className="sub-check">
                                                    <div className="wrap-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            checked={isOfficeChecked}
                                                            onChange={() => _handleCheckOffice(office, isOfficeChecked)}
                                                        />
                                                        <span className="geek-mark"></span>
                                                    </div>
                                                    <div className="checkbox-name">{`${BranchCode} - ${Name}`}</div>
                                                </label>
                                            </div>
                                        )
                                    })
                                }
                            </React.Fragment>
                        )
                    })
                }
            </div>
            {
                isShowSubmit ?
                    <ButtonConfirmPopup
                        titleConfirm={titleConfirm}
                        onClick={(e) => onHandleClick(e)}
                    /> 
                    :
                    <ButtonConfirmPopup
                        titleConfirm={titleConfirm}
                        // onClick={() => { }}
                        opacity="0.5"
                        cursor="default"
                    />

            }
        </WrapApplyLocation>
    )
}

export default ApplyLocation
