import React, { Component } from 'react';
import { connect } from 'react-redux';

//Images
import ic_close from '../../../../images/ic_close_gray.svg';
import { updateBioInfosNote, getBioInfos } from '../../../../actions/supervisorAction/biometricAction';

// styles
import { Bound } from './PopupNote.styles';
// import { DELETE } from '../../../../data/infosType';


class PopupNote extends Component {

    constructor(props) {
        super(props);
        this.state = {
            notice: ''
        }
    }
    handleNotice = (e) => {
        this.setState({
            [e.target.name] : e.target.value
        })
    }

    onSubmit = () => {
        const {data, type} = this.props
        const { notice } = this.state
        if(notice.trim()){
            // console.log(data)
            // this.props.updateBioInfosNote(data.ID, notice)
            // this.props.getBioInfos({
            //     OfficeID: this.props.userReducer.dataLogin.OfficeID,
            //     Status: 'PENDING',
            //     PageSize: 10, 
            //     PageNumber: 1
            // })
            if(this.props.type === 'noteVerify'){
                this.props.onshowPopupVerify({ Note: notice.trim() })
                // this.props.onClosePopup()
            }else if(type === 'noteBiometricInfo'){
                this.props.onClosePopupNoteLv2()
            }else if(type === 'noteHistoryBiometricInfo'){
                this.props.onCloseNoteHistoryLv2()
            }else if(this.props.type === 'noteRemoveFace'){
                this.props.onCloseFaceNote()
            }else{
                this.props.onClosePopup()
                this.props.outPopup()
                // this.props.onCloseCheck()
            }
        }else if(type === 'noteMatchFace'){
            this.props.onClosePopupLv1()
        }else if(type === 'noteBiometricInfo'){
            this.props.onClosePopupNoteLv2()
        }else if(type === 'noteHistoryBiometricInfo'){
            this.props.onCloseNoteHistoryLv2()
        }else if(this.props.type === 'noteRemoveFace'){
            this.props.onCloseFaceNote()
        }
    }

    onClose = () => {
        if(this.props.type === 'noteBiometricInfo'){
            this.props.onClosePopupNoteLv2()
        }else if(this.props.type === 'noteHistoryBiometricInfo'){
            this.props.onCloseNoteHistoryLv2()
        }else if(this.props.type === 'noteMatchFace'){
            this.props.onClosePopupLv1()
        }else if(this.props.type === 'noteRemoveFace'){
            this.props.onCloseFaceNote()
        }else{
            this.props.onClosePopup()
            this.props.outPopup()
        }
    }

    render() {
        const {title, placeholder, rejectRegisterNote, type, data, isActive} = this.props
        let elmHolder, elmBoolean
        switch (type) {
            case 'noteMatchFace':
                elmHolder = rejectRegisterNote
                elmBoolean = true
                break;
            case 'noteBiometricInfo': 
            case 'noteHistoryBiometricInfo': 
            case 'noteRemoveFace': 
                elmHolder = data.RemoveNote
                elmBoolean = true
                break
            default:
                elmHolder = placeholder
                elmBoolean = false
                break;
        }

        return (
            <div
            style = {{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: 999999
            }}>
                <Bound rejectRegisterNote = {rejectRegisterNote} removeNote = {data !== undefined ? data.RemoveNote : null}>
                    <div className="ic_close"
                    onClick = {this.onClose}
                    >
                        <img src={ic_close} alt="ic_close"/>
                    </div>
                    <div className="content">
                    <h3>{title}</h3>
                    {
                        isActive === 1 ?
                        <div className = 'addReason'>
                            <p>{elmHolder[0]}</p>
                            <p>{elmHolder[1]}</p>
                        </div>
                       :
                        <textarea
                            // disabled = {type === 'noteMatchFace' ? true : false}
                            disabled = {elmBoolean}
                            name = 'notice'
                            maxLength = '200'
                            onChange = {this.handleNotice}
                            // placeholder = {type === 'noteMatchFace' ? rejectRegisterNote : placeholder}>
                            placeholder = {elmHolder}>
                        </textarea>
                    }
                    </div>
                    <div className="btn_ok"
                        onClick = {this.onSubmit}>
                        Xác nhận
                    </div>
                </Bound>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        userReducer: state.userReducer,
        biometricReducer: state.biometricReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateBioInfosNote: (idBio, noteBio) => dispatch(updateBioInfosNote(idBio, noteBio)),
        getBioInfos: (dataQuery) => { dispatch(getBioInfos(dataQuery))}
    }
}
export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(PopupNote)