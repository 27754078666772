
/**
 * [description]
 * @param  {string}   key       [description]
 * @param  {number} direction 1 to sort A-Z and -1 to Z-A
 * @return {[type]}             [description]
 */
const sortObjectByKey = (key) => (obj1, obj2) => {
    // // Use toUpperCase() to ignore character casing
    // const item1 = obj1[key].toUpperCase();
    // const item2 = obj2[key].toUpperCase();

    // let comparison = 0;
    // if (item1 > item2) {
    //   comparison = 1;
    // } else if (item1 < item2) {
    //   comparison = -1;
    // }
    // return comparison;
  return obj1[key].localeCompare(obj2[key]);
}

export default sortObjectByKey;