import React from 'react'

// styles
import { TitleCol } from './index.styles'

const TableHeader = () => {
    return (
        <>
            <TitleCol>Mã báo cáo</TitleCol>
            <TitleCol>Tên báo cáo</TitleCol>
        </>
    )
}

export default TableHeader
