import Axios from 'axios'

import * as types from './types'
import { host } from '../host'
import { addLoading, removeLoading } from './commonAction'
import catchErrorApi from '../helper/catchErrorApi'

const apiGetListErrorAcceptBios = host + 'supervisor/filterListErrorAcceptBio'
const apiFixErrorAcceptBios = host + 'supervisor/fixErrorAcceptBios'


export const getListErrorAcceptBios = (CustomerNumber) => async dispatch => {
    dispatch(addLoading())
    const dataRequest = JSON.stringify({
        CustomerNumber
    })

    try {
        const res = await Axios.post(apiGetListErrorAcceptBios, dataRequest)
        const { data } = res;
        const isSuccess = data.Code === 0 && data.ListErrorBio?.length > 0
        if (isSuccess) {
            dispatch({
                type: types.GET_LIST_ERROR_ACCEPT_BIOS,
                payload: {
                    listBioErrors: data.ListErrorBio || []
                }
            })
        }
        else {
            dispatch({
                type: types.GET_LIST_ERROR_ACCEPT_BIOS_ERR,
                payload: {
                    error: 'Không tìm thấy dữ liệu hoặc không có dữ liệu cần xử lý'
                }
            })
        }
    }
    catch (err) {
        const handleError400 = () => {
            // const { Code } = err.response.data;
            // const CODE_NOT_FOUND_QUERY = 3
            // if (Code === CODE_NOT_FOUND_QUERY) {
            //     dispatch({
            //         type: types.GET_DUPL_BIO_DETAILS_ERR,
            //         payload: {
            //             error: "Không tìm thấy thông tin khách hàng"
            //         }
            //     })
            //     return true
            // }
            // return false
        }
        catchErrorApi(
            err,
            dispatch,
            types.GET_LIST_ERROR_ACCEPT_BIOS_ERR,
            handleError400
        )
    }
    dispatch(removeLoading())
}

export const fixErrorAcceptBios = (ID, callbackSuccess) => async dispatch => {
    const dataRequest = JSON.stringify({
        ID
    })

    try {
        const res = await Axios.post(apiFixErrorAcceptBios, dataRequest)
        const { data } = res;
        const isSuccess = data.Code === 0
        if (isSuccess) {
            callbackSuccess()
        }
    }
    catch (err) {
        const handleError400 = () => {
            // const { Code } = err.response.data;
            // const CODE_NOT_FOUND_QUERY = 3
            // if (Code === CODE_NOT_FOUND_QUERY) {
            //     dispatch({
            //         type: types.GET_DUPL_BIO_DETAILS_ERR,
            //         payload: {
            //             error: "Không tìm thấy thông tin khách hàng"
            //         }
            //     })
            //     return true
            // }
            // return false
        }
        catchErrorApi(
            err,
            dispatch,
            types.FIX_ERROR_ACCEPT_BIOS_ERR,
            handleError400
        )
    }
}