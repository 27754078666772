import React, { Component } from 'react'
import styled from 'styled-components';

import ic_more from '../../../../images/ic_more_gray.svg'
import { ADMIN_ROLE } from '../../../../data/userRole';
const DeviceKioskStyle = styled.div`    
    font-family: 'SVN-Gilroy'; 
    font-weight: 500;  
    color: #222222;  
    .device-wrapper {
        cursor: ${props => props.cursor || 'default'};
        display: flex;
        flex-direction: column;        
        padding: 12px;        
        background-color: #fafafa;
        /*width: 100%;*/
        /*min-width: 240px;*/
        height: 288px; 
        position: relative;
        border-radius: 5px;
        .image-bg {
            display: flex;            
            justify-content: center;
            align-items: center; 
            position: relative;  
            background-color: rgba(0,0,0,0.6); 
            font-family: 'SVN-Gilroy'; 
            .button{
                z-index: 99;
                width: 41px;
                height: 24px;
                border-radius: 4px;
                background-color: #0fb11f;
                position: absolute;
                top: 8px;
                left: 8px;                
                font-size: 14px;
                font-weight: bold;
                color: #fafafa;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
            }                 
            .size-image-wrapper {                
                opacity: 0;
                width: 90%;               
                position: absolute;
                left: 8px;
                bottom: 10px;
                display: flex;                
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                .size-image{
                    font-size: 14px;
                    font-weight: 500;
                    color: #ffffff;
                }
                .image-format{
                    width: 40px;
                    height: 20px;                    
                    border-radius: 4px;
                    background-color: #222222;
                    color: #ffffff;
                    font-size: 12px;
                    font-weight: bold;                                            
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;  
                    text-transform: uppercase;
                }                             
            }           
            img {                
                width: 100%;
                height: 140px;
                /*object-fit: cover;*/
            }           
        }   
        .image-bg:hover .size-image-wrapper {
            opacity: 1;
        }
        .image-bg:hover img {
            opacity: 0.3;
        }      
        .device-info-wrapper {
            display: flex;
            flex-direction: column;     
            padding: 0 0 0 8px;                      
            .name-wrapper {
                font-size: 20px;
                font-weight: bold; 
                padding: 15px 0 8px 0;    
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }            
            .apply-wrapper {
                height: 37px;
                font-size: 12px;            
                color: #979797; 
                overflow: hidden;
                text-overflow: ellipsis;
                /* white-space: nowrap;  */
            } 
            .describe-wrapper {
                font-size: 14px; 
                /* height: 60px; */
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }          
        } 
        .icon_image {
            position: absolute;
            bottom: 5px;
            right: 10px;
            img {
                cursor: pointer;
            }
        }                    
    }
    
`
export default class DeviceKiosk extends Component {
  _isMounted = false;
  state = {
    data: this.props.data,
    isHover: false,

  }
  onShowControl(index, data) {
    // console.log('data', data);

    let moreBtn = document.getElementById('more_button_id_' + index);
    if (moreBtn) {
      let bound = moreBtn.getBoundingClientRect()
      let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      let topBound = bound.top + scrollTop
      let leftBound = bound.left + scrollLeft
      let offsetPopup = {
        top: topBound + bound.height / 2,
        left: leftBound - 78 + bound.width / 2
      }
      this.props.onShowControl(offsetPopup, 'deviceKiosk', data)
    }
  }


  render() {
    const { data, arrFilterOffice } = this.props;
    const arrFilterBranch = arrFilterOffice && arrFilterOffice.arrFilterBranch;
    const arrFilterSaving = arrFilterOffice && arrFilterOffice.arrFilterSaving;
    const arrFilterTrading = arrFilterOffice && arrFilterOffice.arrFilterTrading;


    let arrCheckBranch = [];
    let arrCheckSaving = [];
    let arrCheckTrading = [];
    arrFilterOffice && data.ApplyAtOffice && data.ApplyAtOffice.map(item => {
      for (let i = 0; i < arrFilterBranch.length; i++) {
        if (item === arrFilterBranch[i].ID) {
          return arrCheckBranch.push(arrFilterBranch[i])
        }
      }
      return true
    })
    arrFilterOffice && data.ApplyAtOffice && data.ApplyAtOffice.map(item => {
      for (let i = 0; i < arrFilterSaving.length; i++) {
        if (item === arrFilterSaving[i].ID) {
          return arrCheckSaving.push(arrFilterSaving[i])
        }
      }
      return true
    })
    arrFilterOffice && data.ApplyAtOffice && data.ApplyAtOffice.map(item => {
      for (let i = 0; i < arrFilterTrading.length; i++) {
        if (item === arrFilterTrading[i].ID) {
          return arrCheckTrading.push(arrFilterTrading[i])
        }
      }
      return true
    })

    const titleApplyOffice = arrFilterOffice && data.ApplyAtOffice ? `Áp dụng tại` : 'Chưa áp dụng...';
    const ApplyTrading = data.ApplyAtOffice && arrCheckTrading && arrCheckTrading.length > 0 && `${arrCheckTrading.length} PGD`
    const ApplySaving = data.ApplyAtOffice && arrCheckSaving && arrCheckSaving.length > 0 && `${arrCheckSaving.length} Gửi tiết kiệm`
    const ApplyBranch = data.ApplyAtOffice && arrCheckBranch && arrCheckBranch.length > 0 && `${arrCheckBranch.length} Chi nhánh`

    let notApply = "";
    let dataLogin = JSON.parse(localStorage.getItem("dataLogin"))
    if (dataLogin && dataLogin.Roles.includes(ADMIN_ROLE)) {
      notApply = "Chưa áp dụng..."
    }

    return (
      <DeviceKioskStyle
        cursor={dataLogin && dataLogin.Roles.includes(ADMIN_ROLE) ? "pointer" : "default"}
      >
        {
          <div className='device-wrapper'
            onDoubleClick={dataLogin && dataLogin.Roles.includes(ADMIN_ROLE) ? () => this.props.onShowPopupEditKiosk({
              ...data,
              width: this.state.width,
              height: this.state.height,
              titlePopup: "Tùy chỉnh quảng cáo",
              titleAds: "Nhập tên quảng cáo",
              descTitle: "Tối đa 200 ký tự",
              titleBtn: "Xác nhận"
            })
              : () => { }
            }
          >
            <div className='image-bg'>
              <div className='button' style={data.IsActive === true ? { backgroundColor: '#0fb11f' } : { backgroundColor: ' #979797' }}>
                {
                  data.IsActive === true ?
                    'ON'
                    :
                    'OFF'
                }
              </div>
              <img ref={ref => this.imgAds = ref} src={data.fileSrc} alt='' />
              <div className='size-image-wrapper'>
                <div className='size-image'>{`${this.state.width}x${this.state.height} px`}</div>
                <div className='image-format'>.{data.fileType}</div>
              </div>
            </div>
            <div className='device-info-wrapper'>
              <div className='name-wrapper'>{data.Title}</div>
              {
                arrFilterOffice && data.ApplyAtOffice ?
                  <div className='apply-wrapper'>{titleApplyOffice} {ApplyTrading}{(ApplyBranch || ApplySaving) && ApplyTrading ? ', ' : ''}{ApplyBranch}{(ApplyTrading && ApplyBranch && ApplySaving) || (ApplyBranch && ApplySaving) ? ', ' : ''}{ApplySaving}</div>
                  : <div className='apply-wrapper'>{notApply}</div>
              }
              <div className='describe-wrapper'>{data.Description}</div>
            </div>
            {
              this.props.isCustom === true ?
                null
                :
                <div className='icon_image'>
                  <img src={ic_more} alt='' id={'more_button_id_' + this.props.keyValue}
                    onClick={() => this.onShowControl(this.props.keyValue, {
                      ...data,
                      width: this.state.width,
                      height: this.state.height,
                      titlePopup: "Tùy chỉnh quảng cáo",
                      titleAds: "Nhập tên quảng cáo",
                      descTitle: "Tối đa 200 ký tự",
                      titleBtn: "Xác nhận"
                    }
                    )} />
                </div>
            }

          </div>
        }
      </DeviceKioskStyle>
    )
  }

  componentDidMount() {
    this._isMounted = true;
    const thatComponent = this;
    // console.log(this.props.data.fileSrc)
    const img = new Image();
    img.src = this.props.data.fileSrc;
    img.onload = function () {
      thatComponent._isMounted && thatComponent.setState({
        width: this.width,
        height: this.height
      })
    }

  }
  componentWillUnmount() {
    this._isMounted = false;
  }



}
