import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import addNotification, { Notifications } from 'react-push-notification'

import HeaderContainer from './Header/HeaderContainer'
import MenuContainer from './Menu/MenuContainer'
import MainScreenContainer from './MainScreen/MainScreenContainer'
import LoadingControl from "./MainScreen/Control/LoadingControl"
import TimeoutContainer from "./TimeoutContainer"
// import PopupShowBiometricHistory from './Popup/PopupCheckBiometric/PopupShowBiometricHistory';
// import { Redirect } from 'react-router-dom';
// import Test from '../../Test';
// import PopupAlert from './Popup/PopupAlert';
import PopupALertLogout from "./Popup/PopupAlertLogut";

// constant
import { webSocketUrl } from '../../host'
import * as typeChannelSocket from "../../helper/socket/typeChannel"
import { OFFEXSUPERVISOR, OFFINSUPERVISOR } from '../../data/userRole'
import { VERIFYSUCCESSFULLY } from '../../data/dataVerifyCustomer'

// action
import { socketGetBios, getBioInfos } from '../../actions/supervisorAction/biometricAction'
import { socketGetVerifyCustomer, getListVerifyFingerHistory } from '../../actions/supervisorAction/verifyCustomerAction'
import { deviceConnected, getNumOfDataNotViewYet } from '../../actions/commonAction'


const Bound = styled.div`
    display:flex;
    flex-direction:column;
    flex:1;
    position:relative;
    .main-page{
        display:flex;
        flex:1;
        flex-direction:row;
        overflow:hidden;
    }
`
class MainPage extends Component {
    state = {
        // redirect: true
    }
    mainPageRef = React.createRef(null)
    render() {
        // if (this.state.redirect) return <Redirect to="/" />
        return (
            <>
                <TimeoutContainer target={this.mainPageRef.current} />
                <Notifications />
                <Bound ref={this.mainPageRef}>
                    <HeaderContainer
                        dataLogin={this.props.dataLogin}
                        // isLogout={() => this.props.isLogout()}
                        logoutUser={this.props.logoutUser}
                    />
                    <div className="main-page">
                        <MenuContainer dataLogin={this.props.dataLogin} />
                        <MainScreenContainer
                            dataLogin={this.props.dataLogin}
                            resetStore={this.props.resetStore}
                        />
                    </div>
                    {/* <PopupShowBiometricHistory 
                
                /> */}
                    {this.props.isLoading && <LoadingControl size={50} />}
                    {this.props.isLogout && <PopupALertLogout />}
                </Bound>
            </>
        )
    }

    connectWebSocket = () => {
        const that = this
        if (!this.props.userReducer.dataLogin.Token) return
        const token = this.props.userReducer.dataLogin.Token

        let socket = {}
            socket = (this.socket = new WebSocket(
                `${webSocketUrl}?token=${token}`
            ))
        socket.onopen = function (e) {
            // console.log('[open] Connection established, send -> server')
            // socket.send("ping");
            clearInterval(that.timoutReConnectSocket)
            heartbeat()
            // clearInterval(that.timoutReConnectSocket);
        }
        socket.onmessage = function (event) {
            // console.log(`[message] Data received: ${event.data} <- server`)
            // console.log(`SOCKET: ${event.data} <- server`)
            // that: socket,
            this.isReConnect = false
            const res = JSON.parse(event.data)
            // console.log('SOCKET:', res)
            const { Data: data } = res
            switch (res.Event) {
                case typeChannelSocket.PEN_VF:
                    // if(this.props.userReducer.dataLogin.Role === OFFEXSUPERVISOR || this.props.userReducer.dataLogin.Role === OFFINSUPERVISOR){
                    that.props.getNumOfDataNotViewYet()
                    if (data.PendingBio !== null) {
                        // console.log('penidng', data.PendingBio)
                        const { Status } = that.props.biometricReducer.lastConditionFilterUser;
                        if (!Status || Status === data.PendingBio.Status) {
                            that.props.socketGetBios(data.PendingBio)
                        }
                        addNotification({
                            title: 'Phê duyệt sinh trắc học',
                            message: 'Bạn có yêu cầu phê duyệt thông tin STH',
                            native: true,
                            icon: 'favicon.ico',
                        })
                    } else {
                        // console.log('data', data.VerifyFingerHistory)
                        const { DataStatus } = that.props.verifyCustomerReducer.lastConditionFilterVerifyCustomer
                        if (!DataStatus || DataStatus === data.VerifyFingerHistory.DataStatus) {
                            that.props.socketGetVerifyCustomer(
                                data.VerifyFingerHistory
                            )
                        }
                        if(data.VerifyFingerHistory.VerifyStatus === VERIFYSUCCESSFULLY){
                            return;
                        }

                        addNotification({
                            title: ' Xác thực khách hàng',
                            message: 'Bạn có yêu cầu phê duyệt xác thực STH',
                            native: true,
                            icon: 'favicon.ico',
                        })
                    }
                    break
                case typeChannelSocket.PONG: {
                    // console.log(res)
                    that.setState({ isWatingSocketPong: false })
                    break
                }
                case typeChannelSocket.NEW_DEVICE: {
                    that.props.deviceConnected(data.DeviceType, data.Mac, data.ID)
                    break;
                }
                case typeChannelSocket.COMPLETED_REVIEW: {
                    that.props.getNumOfDataNotViewYet()

                    const { lastConditionFilterUser } = that.props.biometricReducer
                    if(typeof(lastConditionFilterUser) === 'object' && Object.keys(lastConditionFilterUser).length)
                        that.props.getBioInfos({ ...lastConditionFilterUser })

                    const { lastConditionFilterVerifyCustomer } = that.props.verifyCustomerReducer
                    if(typeof(lastConditionFilterVerifyCustomer) === 'object' && Object.keys(lastConditionFilterVerifyCustomer).length)
                        that.props.getListVerifyFingerHistory({
                            ...lastConditionFilterVerifyCustomer,
                        })
                    break;
                }
                default:
                    break
            }
        }

        socket.onclose = function (event) {
            // console.log('event', event)
            if (event.wasClean && event.code !== 4000) {
                // console.log(
                //     `[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`
                // )
            } else {
                // e.g. server process killed or network down
                // event.code is usually 1006 in this case
                // console.log('[close] Connection died');
                // that.timoutReConnectSocket = setTimeout(function() {
                //     // console.clear();
                //     console.log("websocket was died. Retry connecting...")
                //     that.connectWebSocket();
                // }, 10000);

                if (!this.isReConnect) {
                    // that.props.alertFailedToConnectSocket()
                    that.timoutReConnectSocket = setTimeout(function () {
                        // console.clear();
                        console.log('websocket was died. Retry connecting...')
                        that.connectWebSocket()
                    }, 5000)
                    this.isReConnect = true
                }
            }
            // that.connectWebSocket()
        }

        socket.onerror = function (error) {
            // console.log(`[error] ${error.message}`);
            // console.dir(error)
            socket.close()
        }

        function heartbeat() {
            if (that.state.isWatingSocketPong) {
                // console.log('errror')
                that.setState({ isWatingSocketPong: false }, () =>
                    socket.close(4000)
                )
                return
            }

            that.setState(
                {
                    isWatingSocketPong: true,
                },
                () => {
                    if (!socket) return
                    if (socket.readyState !== 1) return
                    socket.send('ping')
                    setTimeout(heartbeat, 2000)
                }
            )
        }
        // heartbeat()
    }

    componentDidMount() {
        // this.setState({ redirect: false });
        this.isReConnect = false
        this.connectWebSocket()
        clearInterval(this.timoutReConnectSocket)
        
        // const { Role } = this.props.userReducer.dataLogin;
        // if(Role.match(/SUPERVISOR/)){
        //     this.props.getNumOfDataNotViewYet()
        // }
        const { Roles } = this.props.userReducer.dataLogin;
        if (Roles.includes(OFFEXSUPERVISOR) || Roles.includes(OFFINSUPERVISOR)) {
            this.props.getNumOfDataNotViewYet()
        }
    }

    componentWillUnmount() {
        // console.log("unmount")
        const { Roles } = this.props.userReducer.dataLogin;

        if (Roles.includes(OFFEXSUPERVISOR) || Roles.includes(OFFINSUPERVISOR)) {
            this.socket && this.socket.close()
            clearInterval(this.timoutReConnectSocket)
        }
        // clearInterval(this.timoutReConnectSocket)
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.commonReducer.isLoading,
    isLogout: state.commonReducer.isLogout,
    userReducer: state.userReducer,
    biometricReducer: state.biometricReducer,
    verifyCustomerReducer: state.verifyCustomerReducer,
})

const mapDispatchToProps = (dispatch) => {
    return {
        socketGetBios: (data) => dispatch(socketGetBios(data)),
        socketGetVerifyCustomer: (data) =>
            dispatch(socketGetVerifyCustomer(data)),
        deviceConnected: (deviceType, mac, parentId) =>
            dispatch(deviceConnected(deviceType, mac, parentId)),
        getNumOfDataNotViewYet: () => dispatch(getNumOfDataNotViewYet()),
        getBioInfos: (dataQuery) => {
            dispatch(getBioInfos(dataQuery))
        },
        getListVerifyFingerHistory: (dataQuery) => dispatch(getListVerifyFingerHistory(dataQuery))
    }
}
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(MainPage)
