import React, { Component } from "react";
import { connect } from 'react-redux';
import styled from "styled-components";

// import { transactionTypes } from "../../../../data/dataTransaction";
import { subHeaderData } from '../../../../data/data'

import BlockSubName from "../Control/BlockSubName";
import SubHeaderControl from '../Control/SubHeaderControl'
import { getListTypeCounterServices } from "../../../../actions/counterAction";

const Bound = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;
  padding:0 40px;
  .wrap-transaction {
    overflow:hidden;
    display:flex;
    flex-direction:column;
    width:100%;
    margin-top: 26px;
    &__title {
      font-family: "SVN-Gilroy";
      font-size: 11px;
      font-weight: bold;
      line-height: 1.26;
      color: #005993;
      text-transform: uppercase;
      margin-left: 20px;
      display:flex;
    }
    .scroll{
      display:flex;
      flex:1;
      overflow:hidden;
      margin-top: 17px;
      &__list-transaction {
        width:100%;
        overflow:auto;
        padding-bottom: 40px;
        & > * {
          margin-bottom: 4px;
        }
        ::-webkit-scrollbar { 
          display: none; 
        }
      }
    } 
  }
`;

class TransactionContainer extends Component {
  render() {
    return (
      <Bound>
        <SubHeaderControl
          data={subHeaderData.transaction} />
        <div className="wrap-transaction">
          <div className="wrap-transaction__title">Tên giao dịch</div>
          <div className='scroll'>
            <div className="scroll__list-transaction">
              {/* {transactionTypes.map((type, key) => ( */}
              {this.props.counterReducer.listTypeCounterServices.map((type, key) => (
                <BlockSubName key={key} subname={type.Name} />
              ))}
            </div>
          </div>

        </div>
      </Bound>
    );
  }
  componentDidMount() {
    this.props.getListTypeCounterServices(this.props.userReducer.dataLogin.UID)
  }
  
}

const mapStateToProps = state => ({
  userReducer: state.userReducer,
  counterReducer: state.counterReducer
})

const mapDispatchToProps = dispatch => ({
  getListTypeCounterServices: (userId) => dispatch(getListTypeCounterServices(userId))
})

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(TransactionContainer);