import styled from 'styled-components'

const WrapperCheckBox = styled.div`
    display: flex;

    :hover {
        cursor: pointer;
    }

`;

const CheckBox = styled.div`
    flex-shrink: 0;
    box-sizing: border-box;
    width: 16px;
    height: 16px;
    background: ${props => props.isDisabled ? "#EFEFEF" : "#FFFFFF"};
    border: ${props => props.isDisabled ? "0.75px solid #BEBEBE" : "0.75px solid #D6D6D6"};
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        user-select: none;
    }

    .checked-some-child {
        width: 10px;
        height: 10px;
        background: #de3d6a;
    }
`;

const Text = styled.div`
    margin-left: 8px;
    font-family: SVN-Gilroy;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 128%;
    color: #222222;

    ${props => props.extendStyles}
`;

export {
    WrapperCheckBox,
    CheckBox,
    Text
}