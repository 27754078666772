import Axios from 'axios';

import * as types from './types';
import { host } from "../host";
import {
  addLoading,
  removeLoading,
  alertLogout,
  showErrConnection,
  showErrorBranchCode
} from "./commonAction";
import catchErrorApi from '../helper/catchErrorApi';

const apiCreateOffice = host + "admin/office/create";
const apiFilterOffice = host + "admin/office/filter";
const apiUpdateOffice = host + "admin/office/update";
const apiDeleteOffice = host + "admin/office/delete";

/**
 * 
 * @param {String} name name of office
 * @param {String} address full address of office
 * @param {String} province province of office
 * @param {String} branchCode type of office
 * @param {String} district district of office
 * @param {string} ward ward of office
 * @param {"SAVING" \| "TRADING" \| "BRANCH"} officeType type of office
 */
export const createOffice = (name, address, province, branchCode, district, ward, officeType) => async dispatch => {
  const queryFormatData = JSON.stringify({
    Name: name,
    Address: address,
    Province: province,
    BranchCode: branchCode,
    District: district,
    Ward: ward,
    OfficeType: officeType
  })
  try {
    const res = await Axios.post(apiCreateOffice, queryFormatData);
    const { data } = res;
    dispatch({
      type: types.CREATE_NEW_OFFICE,
      success: Boolean(data)
    })
  }
  catch (err) {
    const handleError400 = () => {
      if (err.response.data.Code === 5) {
        dispatch({
          type: types.CREATE_NEW_OFFICE_ERR,
          err: err.response.data,
        })
        return true;
      }
      return false;
    }

    catchErrorApi(
      err,
      dispatch,
      types.CREATE_NEW_OFFICE_ERR,
      handleError400
    )
  }
}

/**
 * 
 * @param {{
 *  name: string, 
 *  address: string, 
 *  province: string, 
 *  district: string, 
 *  ward: string,
 *  officeType: "SAVING" \| "TRADING" \| "BRANCH"
 * }} dataFilter 
 * @param {number} pageSize 
 * @param {number} pageNumber 
 */
export const filterOffice = (dataFilter, pageSize, pageNumber) => async dispatch => {
  dispatch(addLoading())
  const formatDataFilter = {};
  if (dataFilter.name) formatDataFilter["Name"] = dataFilter.name.trim();
  if (dataFilter.adress) formatDataFilter["Address"] = dataFilter.adress;
  if (dataFilter.province) formatDataFilter["Province"] = dataFilter.province;
  if (dataFilter.district) formatDataFilter["District"] = dataFilter.district;
  if (dataFilter.ward) formatDataFilter["Ward"] = dataFilter.ward;
  if (dataFilter.officeType) formatDataFilter["OfficeType"] = dataFilter.officeType;

  const queryFormatData = JSON.stringify({
    ...formatDataFilter,
    PageSize: pageSize,
    PageNumber: pageNumber
  })

  try {
    const res = await Axios.post(apiFilterOffice, queryFormatData);

    const { data } = res;
    dispatch({
      type: types.FILTER_OFFICE,
      listOffice: data.ListOffice ? data.ListOffice : [],
      total: data.Total,
      dataFilter
    })
    dispatch(removeLoading())
  }
  catch (err) {
    dispatch(removeLoading())

    catchErrorApi(
      err,
      dispatch,
      types.FILTER_OFFICE_ERR,
      () => false
    )
  }
}

export const getAllOffice = () => async dispatch => {
  dispatch(addLoading())
  try {
    const all = await Axios.post(
      apiFilterOffice,
      JSON.stringify({
        PageSize: 1000000,
        PageNumber: 1
      })
    );
    dispatch({
      type: types.FILTER_OFFICE,
      listOffice: all.data.ListOffice ? all.data.ListOffice : [],
      total: all.data.Total,
      dataFilter: {}
    })
  }
  catch (err) {
    catchErrorApi(
      err,
      dispatch,
      types.FILTER_OFFICE_ERR,
      () => false
    )
  }
  dispatch(removeLoading())
}

/**
 * 
 * @param {{
 * ID*: string,
 * Name:  string,
 * Address: string,
 * Province: string,
 * District: string,
 * Ward: string,
 * OfficeType: "SAVING" \| "TRADING" \| "BRANCH"
 * }} dataUpdate 
 */
export const updateOffice = (dataUpdate) => async dispatch => {
  const queryFormatData = JSON.stringify({
    ...dataUpdate
  })

  try {
    const res = await Axios.post(apiUpdateOffice, queryFormatData);
    const { data } = res;
    dispatch({
      type: types.UPDATE_OFFICE,
      success: data.Code === 0,
    })

  }
  catch (err) {
    catchErrorApi(
      err,
      dispatch,
      types.UPDATE_OFFICE_ERR,
      () => false
    )
  }
}

/**
 * 
 * @param {string} idOffice 
 */
export const deleteOffice = (idOffice) => async dispatch => {
  const dataQueryFormat = JSON.stringify({
    ID: idOffice
  });

  try {
    const res = await Axios.post(apiDeleteOffice, dataQueryFormat);
    const { data } = res;

    dispatch({
      type: types.DELETE_OFFICE,
      success: data.Code === 0
    })
  }
  catch (err) {
    const handleError400 = () => {
      if (err.response.data.Code === 5) {
        dispatch({
          type: types.DELETE_OFFICE_ERR,
          err: err.response.data
        })
        return true;
      }
      return false;
    }

    catchErrorApi(
      err,
      dispatch,
      types.DELETE_OFFICE_ERR,
      handleError400
    )
  }
}

export const clearErrorDeleteOffice = () => ({
  type: types.CLEAR_ERR_DELETE_OFFICE
})

export const resetStateOffice = () => (
  {
    type: types.RESET_STATE_OFFICE
  }
)

export const alertFillterOffice = () => {
  return {
    type: types.ALERT_CONTENT
  }
}


export const saveTextSearchOffice = text => ({
  type: types.SAVE_TEXT_OFFICE,
  textSearch: text
})

export const clearTextSearchOffice = () => ({
  type: types.CLEAR_TEXT_OFFICE,
})