// export const branch = {
//     branchHCM:1,
//     branchHN:2,
//     branchDaNang:3,
//     branchHaiPhong:4
// }
// export const typeBranch = [
//     branch.branchHCM,
//     branch.branchHN,
//     branch.branchDaNang,
//     branch.branchHaiPhong
// ]

// export default function showBranchDetail(value){
//     switch(value.branch){
//         case branch.branchHCM:
//             {
//                 value.name='Chi nhánh Hồ Chí Minh'
//                 value.data=[
//                     {room:'PGD Bến Thành',address:'79A Ham Nghi, p.Nguyen Thai Binh, Q1, tp.HCM'},
//                     {room:'PGD Bến Thành',address:'79A Ham Nghi, p.Nguyen Thai Binh, Q1, tp.HCM'},
//                     {room:'PGD Bến Thành',address:'79A Ham Nghi, p.Nguyen Thai Binh, Q1, tp.HCM'},
//                     {room:'PGD Bến Thành',address:'79A Ham Nghi, p.Nguyen Thai Binh, Q1, tp.HCM'},
//                     {room:'PGD Bến Thành',address:'79A Ham Nghi, p.Nguyen Thai Binh, Q1, tp.HCM'},
//                 ]
//                 break;
//             }
//         case branch.branchHN:
//             {
//                 value.name='Chi nhánh Ha Noi'
//                 value.data=[
//                     {room:'PGD Hoan Kiem',address:'79A Ham Nghi, p.Nguyen Thai Binh, Q1, tp.HCM'},
//                     {room:'PGD Hoan Kiem',address:'79A Ham Nghi, p.Nguyen Thai Binh, Q1, tp.HCM'},
//                     {room:'PGD Hoan Kiem',address:'79A Ham Nghi, p.Nguyen Thai Binh, Q1, tp.HCM'},
//                     {room:'PGD Hoan Kiem',address:'79A Ham Nghi, p.Nguyen Thai Binh, Q1, tp.HCM'},
//                     {room:'PGD Hoan Kiem',address:'79A Ham Nghi, p.Nguyen Thai Binh, Q1, tp.HCM'},
//                 ]
//                 break;
//             }
//         case branch.branchDaNang:
//             {
//                 value.name='Chi nhánh Da Nang'
//                 value.data=[
//                     {room:'PGD Da Lanh',address:'79A Ham Nghi, p.Nguyen Thai Binh, Q1, tp.HCM'},
//                     {room:'PGD Da Lanh',address:'79A Ham Nghi, p.Nguyen Thai Binh, Q1, tp.HCM'},
//                     {room:'PGD Da Lanh',address:'79A Ham Nghi, p.Nguyen Thai Binh, Q1, tp.HCM'},
//                     {room:'PGD Da Lanh',address:'79A Ham Nghi, p.Nguyen Thai Binh, Q1, tp.HCM'},
//                     {room:'PGD Da Lanh',address:'79A Ham Nghi, p.Nguyen Thai Binh, Q1, tp.HCM'},
//                 ]
//                 break;
//             }
//         case branch.branchHaiPhong:
//             {
//                 value.name='Chi nhánh Hai Phong'
//                 value.data=[
//                     {room:'PGD Hai Phong',address:'79A Ham Nghi, p.Nguyen Thai Binh, Q1, tp.HCM'},
//                     {room:'PGD Hai Phong',address:'79A Ham Nghi, p.Nguyen Thai Binh, Q1, tp.HCM'},
//                     {room:'PGD Hai Phong',address:'79A Ham Nghi, p.Nguyen Thai Binh, Q1, tp.HCM'},
//                     {room:'PGD Hai Phong',address:'79A Ham Nghi, p.Nguyen Thai Binh, Q1, tp.HCM'},
//                     {room:'PGD Hai Phong',address:'79A Ham Nghi, p.Nguyen Thai Binh, Q1, tp.HCM'},
//                 ]
//                 break;
//             }
//     }
//     return value
// }

// export const branchs=[
//     {
//         branchName:'Chi nhánh Hồ Chí Minh',
//         id: 1,
//         rooms:[
//             {
//                 name:'PGD Bến Thành',
//                 address:'Số 19 Phan Chu Trinh, Quận 1, TP. Hồ Chí Minh',
//                 counter:1,
//                 kiosk:2
//             },
//             {
//                 name:'PGD Nguyễn Du',
//                 address:'Số 65, Nguyễn Du, Quận 1, TP. Hồ Chí Minh',
//                 counter:0,
//                 kiosk:0
//             },
//             {
//                 name:'PGD Trường Sơn',
//                 address:'Số 34 Phan Đình Giót, Quận Tân Bình, TP. Hồ Chí Minh',
//                 counter:0,
//                 kiosk:0
//             },
//             {
//                 name:'PGD Vinhomes Tân Cảng',
//                 address:'Tầng trệt, tầng 1 L2.SH01, tòa nhà Landmark 2, Vinhomes Central Park, số 720A đường Điện Biên Phủ, phường 22, quận Bình Thạnh, TP. Hồ Chí Minh',
//                 counter:0,
//                 kiosk:0
//             },
//             {
//                 name:'PGD Thành Thái',
//                 address:'Số 145 Thành Thái, Quận 10, TP. Hồ Chí Minh',
//                 counter:0,
//                 kiosk:0
//             },
//             {
//                 name:'PGD  Tân Sơn Nhất',
//                 address:'Tầng 1, sảnh ga đi quốc Nội, sân bay Tân Sơn Nhất, quận Tân Bình, TP. Hồ Chí Minh',
//                 counter:0,
//                 kiosk:0
//             },
//         ]
//     },
//     {
//         branchName:'Chi nhánh Đông Sài Gòn',
//         id: 2,
//         rooms:[
//             {
//                 name:'PGD Bến Thành',
//                 address:'Số 19 Phan Chu Trinh, Quận 1, TP. Hồ Chí Minh',
//                 counter:1,
//                 kiosk:2
//             },
//             {
//                 name:'PGD Nguyễn Du',
//                 address:'Số 65, Nguyễn Du, Quận 1, TP. Hồ Chí Minh',
//                 counter:0,
//                 kiosk:0
//             },
//             {
//                 name:'PGD Trường Sơn',
//                 address:'Số 34 Phan Đình Giót, Quận Tân Bình, TP. Hồ Chí Minh',
//                 counter:0,
//                 kiosk:0
//             },
//             {
//                 name:'PGD Vinhomes Tân Cảng',
//                 address:'Tầng trệt, tầng 1 L2.SH01, tòa nhà Landmark 2, Vinhomes Central Park, số 720A đường Điện Biên Phủ, phường 22, quận Bình Thạnh, TP. Hồ Chí Minh',
//                 counter:0,
//                 kiosk:0
//             },
//             {
//                 name:'PGD Thành Thái',
//                 address:'Số 145 Thành Thái, Quận 10, TP. Hồ Chí Minh',
//                 counter:0,
//                 kiosk:0
//             },
//             {
//                 name:'PGD  Tân Sơn Nhất',
//                 address:'Tầng 1, sảnh ga đi quốc Nội, sân bay Tân Sơn Nhất, quận Tân Bình, TP. Hồ Chí Minh',
//                 counter:0,
//                 kiosk:0
//             },
//         ]
//     },
//     {
//         branchName:'Chi nhánh Tây Sài Gòn',

//         id:3,
//         rooms:[
            
//         ]
//     },
//     {
//         branchName:'Chi nhánh Nam Sài Gòn',
//         id:4,
//         rooms:[
            
//         ]
//     },
//     {
//         branchName:'Chi nhánh Hồ Chí Minh',
//         id: 5,
//         rooms:[
//             {
//                 name:'PGD Bến Thành',
//                 address:'Số 19 Phan Chu Trinh, Quận 1, TP. Hồ Chí Minh',
//                 counter:1,
//                 kiosk:2
//             },
//             {
//                 name:'PGD Nguyễn Du',
//                 address:'Số 65, Nguyễn Du, Quận 1, TP. Hồ Chí Minh',
//                 counter:0,
//                 kiosk:0
//             },
//             {
//                 name:'PGD Trường Sơn',
//                 address:'Số 34 Phan Đình Giót, Quận Tân Bình, TP. Hồ Chí Minh',
//                 counter:0,
//                 kiosk:0
//             },
//             {
//                 name:'PGD Vinhomes Tân Cảng',
//                 address:'Tầng trệt, tầng 1 L2.SH01, tòa nhà Landmark 2, Vinhomes Central Park, số 720A đường Điện Biên Phủ, phường 22, quận Bình Thạnh, TP. Hồ Chí Minh',
//                 counter:0,
//                 kiosk:0
//             },
//             {
//                 name:'PGD Thành Thái',
//                 address:'Số 145 Thành Thái, Quận 10, TP. Hồ Chí Minh',
//                 counter:0,
//                 kiosk:0
//             },
//             {
//                 name:'PGD  Tân Sơn Nhất',
//                 address:'Tầng 1, sảnh ga đi quốc Nội, sân bay Tân Sơn Nhất, quận Tân Bình, TP. Hồ Chí Minh',
//                 counter:0,
//                 kiosk:0
//             },
//         ]
//     },
//     {
//         branchName:'Chi nhánh Đông Sài Gòn',
//         id: 6,
//         rooms:[
//             {
//                 name:'PGD Bến Thành',
//                 address:'Số 19 Phan Chu Trinh, Quận 1, TP. Hồ Chí Minh',
//                 counter:1,
//                 kiosk:2
//             },
//             {
//                 name:'PGD Nguyễn Du',
//                 address:'Số 65, Nguyễn Du, Quận 1, TP. Hồ Chí Minh',
//                 counter:0,
//                 kiosk:0
//             },
//             {
//                 name:'PGD Trường Sơn',
//                 address:'Số 34 Phan Đình Giót, Quận Tân Bình, TP. Hồ Chí Minh',
//                 counter:0,
//                 kiosk:0
//             },
//             {
//                 name:'PGD Vinhomes Tân Cảng',
//                 address:'Tầng trệt, tầng 1 L2.SH01, tòa nhà Landmark 2, Vinhomes Central Park, số 720A đường Điện Biên Phủ, phường 22, quận Bình Thạnh, TP. Hồ Chí Minh',
//                 counter:0,
//                 kiosk:0
//             },
//             {
//                 name:'PGD Thành Thái',
//                 address:'Số 145 Thành Thái, Quận 10, TP. Hồ Chí Minh',
//                 counter:0,
//                 kiosk:0
//             },
//             {
//                 name:'PGD  Tân Sơn Nhất',
//                 address:'Tầng 1, sảnh ga đi quốc Nội, sân bay Tân Sơn Nhất, quận Tân Bình, TP. Hồ Chí Minh',
//                 counter:0,
//                 kiosk:0
//             },
//         ]
//     },
// ]

export const branchs = [
    {id: 'PGD1869', room:'PGD Bến Thành',address:'79A Hàm Nghi, P.Nguyễn Thái Bình, Q1, TP.HCM',type:0, counter:0,kiosk:0},
    {id: 'PGD1869', room:'PGD Nguyễn Du',address:'79A Hàm Nghi, P.Nguyễn Thái Bình, Q1, TP.HCM',type:1, counter:0,kiosk:0},
    {id: 'PGD1869', room:'PGD Trường Sơn',address:'79A Hàm Nghi, P.Nguyễn Thái Bình, Q1, TP.HCM',type:1, counter:0,kiosk:0},
    {id: 'PGD1869', room:'PGD Trường Sơn',address:'79A Hàm Nghi, P.Nguyễn Thái Bình, Q1, TP.HCM',type:0, counter:2,kiosk:0},
    {id: 'PGD1869', room:'PGD Nguyễn Du',address:'79A Hàm Nghi, P.Nguyễn Thái Bình, Q1, TP.HCM',type:2, counter:0,kiosk:7},
    {id: 'PGD1869', room:'PGD  Tân Sơn Nhất',address:'79A Hàm Nghi, P.Nguyễn Thái Bình, Q1, TP.HCM',type:2, counter:0,kiosk:1},
    {id: 'PGD1869', room:'PGD Thành Thái',address:'79A Hàm Nghi, P.Nguyễn Thái Bình, Q1, TP.HCM',type:2, counter:0,kiosk:1},
    {id: 'PGD1869', room:'PGD  Tân Sơn Nhất',address:'79A Hàm Nghi, P.Nguyễn Thái Bình, Q1, TP.HCM',type:2, counter:0,kiosk:1},
    {id: 'PGD1869', room:'PGD Vinhomes Tân Cảng',address:'79A Hàm Nghi, P.Nguyễn Thái Bình, Q1, TP.HCM',type:2, counter:0,kiosk:1},
    {id: 'PGD1869', room:'PGD Thành Thái',address:'79A Hàm Nghi, P.Nguyễn Thái Bình, Q1, TP.HCM',type:0, counter:0,kiosk:0},
    {id: 'PGD1869', room:'PGD Thành Thái',address:'79A Hàm Nghi, P.Nguyễn Thái Bình, Q1, TP.HCM',type:1, counter:0,kiosk:0},
]

export const officeTypes = {
    TRADING: 'TRADING',
    BRANCH: 'BRANCH',
    SAVING: 'SAVING',
}

export const types = [
    
    {
        type: 'BRANCH',
        name: 'Chi nhánh',
        // toggle: false,
    },
    {
        type: 'SAVING',
        name: 'Gửi tiết kiệm',
        // toggle: false,
    },
    {
        type: 'TRADING',
        name: 'Phòng giao dịch',
        // toggle: false,
    },
]
        
