import Axios from 'axios';

import * as types from './types';
import { host } from "../host";
// import { resetStore } from './userAction';
// import { getInfoImageOrVideo } from '../tools';
import {
  addLoading,
  removeLoading,
} from "./commonAction";
import catchErrorApi from '../helper/catchErrorApi';

// heplper
// import sortObjectByKey from "../helper/sortObjectByKey"

// api
const apiCreateCounter = host + "admin/counter/create";
const apiUpdateCounter = host + "admin/counter/update";
const apiGetCounterByOffice = host + "admin/counter/get-counter-by-office";
const apiDeleteCounter = host + "admin/counter/delete";
const apiGenDesktopKeys = host + "admin/counter/generate-desktop-keys";
    
const apiGenTabletKeys = host + "admin/counter/generate-tablet-keys";
const apiGetListTypeCounterServices = host + "admin/counter-services/getAll"
const apiRemoveCounterLink = host + "admin/counter/rm-device-link"

export const removeCounterLink = (CounterID, callbackSuccess, callbackError) => async dispatch => {

  dispatch(addLoading())
  const dataRequest = JSON.stringify({
    CounterID
  })

  try {
    const res = await Axios.post(apiRemoveCounterLink, dataRequest)
    dispatch(removeLoading())
    dispatch({
      type: types.REMOVE_COUNTER_LINK,
      counterId: CounterID
    })
    callbackSuccess && callbackSuccess()
  }
  catch(err){
    dispatch(removeLoading())

    const errCode = err?.response?.data?.Code;
    if([21,22].includes(errCode)){
      callbackError(errCode)
      return;
    }

    catchErrorApi(
      err,
      dispatch,
      types.REMOVE_COUNTER_LINK_ERR,
      () => false
    )
  }
}

/**
 * create counter
 * @param {{
 * OfficeID*: string,
 * CounterNum*: string,
 * ServAllService*: boolean, - check serve all service, if this field is false, it's must be removed in object
 * IsVIP: boolean, - set priority counters
 * ServiceList*: array[string], - list id of services, if ServAllService === true, this field was remove in object
 * CounterName*: string
 * }} dataCreate data used to create counter
 */
export const createCounter = (dataCreate) => async dispatch => {
  const queryDataFormat = JSON.stringify({
    ...dataCreate
  })

  try {
    await Axios.post(apiCreateCounter, queryDataFormat);
    dispatch({
      type: types.CREATE_COUNTER
    })
  }
  catch (err) {
    catchErrorApi(
      err,
      dispatch,
      types.CREATE_COUNTER_ERR,
      () => false
    )
  }
}

/**
 * 
 * @param {{
 * CounterID*: string,
 * OfficeID*: string,
 * CounterNum: string,
 * ServAllService: boolean,
 * ServiceList: array[string],
 * CounterName: string
 * }} dataUpdate 
 */
export const updateCounter = (dataUpdate) => async dispatch => {
  
  const queryFormatData = JSON.stringify({
    ...dataUpdate
  })

  try {
    await Axios.post(apiUpdateCounter, queryFormatData);
    dispatch({
      type: types.UPDATE_COUNTER
    })
  }
  catch (err) {
    const handleError400 = () => {
      const { Code } = err.response.data;
      const isErrorHaveTicketInCounter = [16, 21, 22].includes(Code);

      if (isErrorHaveTicketInCounter) {
        dispatch({
          type: types.UPDATE_COUNTER_ERR,
          payload: {
            code: err.response.data.Code
          }
        })
        return true;
      }

      return false;
    }

    catchErrorApi(
      err,
      dispatch,
      types.UPDATE_COUNTER_ERR,
      handleError400
    )
  }
}

/**
 * 
 * @param {string*} officeId id of office
 * @param {number*} pageSize size of a page
 * @param {number*} pageNumber number of page need load
 * @param {boolean} isLoad re-render if true 
 */
export const getCounterByOffice = (officeId, pageSize, pageNumber, isLoad = true) => async dispatch => {
  dispatch(addLoading())
  const queryDataFormat = JSON.stringify({
    OfficeID: officeId,
    PageSize: pageSize,
    PageNumber: pageNumber
  })

  if (!isLoad && !pageSize) {
    dispatch({
      type: types.GET_LIST_COUNTER_NUMBER,
      listCounterNumber: []
    })
    dispatch(removeLoading())
    return;
  }

  try {
    const res = await Axios.post(apiGetCounterByOffice, queryDataFormat);
    const { data } = res;
    if (isLoad)
      dispatch({
        type: types.GET_COUNTER_BY_OFFICE,
        listCounter: data.ListCounter ? data.ListCounter : [],
        total: data.Total,
      })
    else
      dispatch({
        type: types.GET_LIST_COUNTER_NUMBER,
        listCounterNumber: data.ListCounter ? data.ListCounter.map(i => i.CounterNumber) : []
      })
    dispatch(removeLoading())
  }
  catch (err) {
    dispatch(removeLoading())
    catchErrorApi(
      err,
      dispatch,
      types.GET_COUNTER_BY_OFFICE_ERR,
      () => false
    )
  }
}

/**
 * 
 * @param {string*} counterId id of counter
 */
export const deleteCounter = (counterId) => async dispatch => {


  const queryDataFormat = JSON.stringify({
    CounterID: counterId
  })

  try {
    await Axios.post(apiDeleteCounter, queryDataFormat);
    dispatch({
      type: types.DELETE_COUNTER
    })
  }
  catch (err) {
    const handleError400 = () => {
      const { Code } = err.response.data;
      const isErrorHaveTicketInCounter = Code === 21 || Code === 22;

      if (isErrorHaveTicketInCounter) {
        dispatch({
          type: types.DELETE_COUNTER_ERR,
          payload: {
            code: err.response.data.Code
          }
        })
        return true;
      }

      return false;
    }

    catchErrorApi(
      err,
      dispatch,
      types.DELETE_COUNTER_ERR,
      handleError400
    )
  }
}

/**
 * 
 * @param {string*} counterId id of counter need generate key
 * @param {string*} officeId if of office which have counter need generate key
 */
export const genDesktopKey = (counterId, officeId) => async dispatch => {
  const queryDataFormat = JSON.stringify({
    CounterID: counterId,
    OfficeID: officeId
  })

  try {
    const res = await Axios.post(apiGenDesktopKeys, queryDataFormat);
    const { data } = res;
    dispatch({
      type: types.GEN_DESKTOP_KEY,
      data
    })
  }
  catch (err) {
    catchErrorApi(
      err,
      dispatch,
      types.GEN_DESKTOP_KEY_ERR,
      () => false
    )
  }
}

/**
 *
 * @param {string*} counterId id of counter need generate key
 * @param {string*} officeId if of office which have counter need generate key
 */
export const genTabletKey = (counterId, officeId) => async dispatch => {
  const queryDataFormat = JSON.stringify({
    CounterID: counterId,
    OfficeID: officeId
  })

  try {
    const res = await Axios.post(apiGenTabletKeys, queryDataFormat);
    const { data } = res;
    dispatch({
      type: types.GEN_TABLET_KEY,
      data
    })
  }
  catch (err) {
    catchErrorApi(
      err,
      dispatch,
      types.GEN_TABLET_KEY_ERR,
      () => false
    )
  }
}

/**
 * 
 * @param {string*} userId id of user was loged in to web
 */
export const getListTypeCounterServices = (userId) => async dispatch => {
  const queryDataFormat = JSON.stringify({
    UID: userId
  })

  try {
    const res = await Axios.post(apiGetListTypeCounterServices, queryDataFormat);
    const { data } = res;

    dispatch({
      type: types.GET_LIST_TYPE_COUNTER_SERVICES,
      listService: data.ListService ? data.ListService : []
    })
  }
  catch (err) {
    catchErrorApi(
      err,
      dispatch,
      types.GET_LIST_TYPE_COUNTER_SERVICES_ERR,
      () => false
    )
  }
}

export const disConnectCounterTeller = (counterID) => dispatch => {
  if (counterID) 
    dispatch({
      type: types.DISCONNECT_COUNTER_TELLER,
      counterID
    })
  else dispatch({
    type: types.DISCONNECT_COUNTER_ERR,
    err: "Error Disconnect Counter !!!"
  })
} 
export const disConnectCounterTablet = (counterID) => dispatch => {
  if (counterID) 
    dispatch({
      type: types.DISCONNECT_COUNTER_TELLER,
      counterID
    })
  else dispatch({
    type: types.DISCONNECT_COUNTER_ERR,
    err: "Error Disconnect Counter !!!"
  })
} 

export const  clearKeyGen = () => ({
  type: types.CLEAR_KEY_GEN
})

export const clearKeyGenCounter = (counterId, key) => ({
  type: types.CLEAR_KEY_GEN_COUNTER,
  payload: {
    counterId,
    key
  }
})

