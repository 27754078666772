import * as types from "../actions/types";

const initialState = {
    listBioErrors: [],
    error: ''
}

export default function bioErrorReducer(state = initialState, action) {
    switch (action.type) {
        case types.GET_LIST_ERROR_ACCEPT_BIOS: {
            return {
                ...state,
                ...action.payload,
                error: ''
            }
        }
        case types.GET_LIST_ERROR_ACCEPT_BIOS_ERR: {
            return {
                ...state,
                ...action.payload,
                listBioErrors: []
            }
        }
        default:
            return state
    }
}