import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux';

import PopupAdvertiseRoot from './PopupAdvertiseRoot';

import { popupType } from '../../../data/data'
import PopupTransaction from './PopupTransaction';
import PopupConfirm from './PopupConfirm';
import PopupNewImage from './PopupNewImage';
import PopupAddBranch from './PopupAddBranch';
import PopupSuccess from './PopupSuccess';
import PopupControl from './PopupControl';

import FlowAddAdvertisement from './FlowAddAdvertisement';
// import PopupOutOfLimit from './PopupOutOfLimit';
import PopupAlert from './PopupAlert';
import PopupCheckBiometricCustomer from './PopupCheckBiometric/PopupCheckBiometricCustomer';
import PopupCheckCustomer from './PopupCheckCustomer/PopupCheckCustomer';
import PopupAddPermissionV2 from './PopupAddPermissionV2';
import PopupAccountPermissionV2 from './PopupAccountPermissionV2';


/* import action */
import {
  createOffice,
  updateOffice,
  deleteOffice,
  filterOffice,
  clearErrorDeleteOffice
} from '../../../actions/officeAction';
import { createAds, getAds, updateAds, deleteAds, setStatusAds } from '../../../actions/adsAction';
import { deleteUser, filterUser, addUser, updateUser } from '../../../actions/userAction';
import PopupDeletePermission from './PopupDeletePermission';
import { formatSize } from '../../../tools';
import FlowOfficeCheckNumber from './FlowOfficeCheckNumber';
import PopupAddCounter from './PopupAddCounter';
import { typeAds } from '../../../data/advertisementData';
import { DELETE_COUNTER_ERR, UPDATE_COUNTER_ERR } from '../../../actions/types';
import { fixErrorAcceptBios, getListErrorAcceptBios } from '../../../actions/bioErrorAction';

// import { types } from '../../../data/dataBranch';
import { createKiosk, getAllKiosk, deleteKiosk, updateKiosk } from '../../../actions/kioskAction';
import { createCounter, updateCounter, deleteCounter, getCounterByOffice } from '../../../actions/counterAction';
import { getAllLCD, createLCD, updateLCD, deleteLCD } from '../../../actions/LCDAction';
import { clearError } from '../../../actions/commonAction';
// import { UPDATE_LCD } from '../../../actions/types';
import { acceptBioInfos, getBioInfos, getBioHistoryList } from '../../../actions/supervisorAction/biometricAction';
// import PopupAlert from './PopupAlert';

// img
import ic_close from '../../../images/ic_close.svg'


const Bound = styled.div`
    width:100%;
    min-height:100vh;
    background-color:${props => props.nonOverlay ? 'transparent' : 'rgba(0, 0, 0, 0.6)'} ;
    position:absolute;
    top:0;
    left:0;
    z-index:100;
`

class PopupContainer extends Component {
  constructor(props) {
    super(props)
    this.isMouseEnterPopup = false;
    // this.nameData=null
    this.state = {
      nonOverlay: false,
      content: null,
    }
  }
  onClosePopup = () => {
    this.props.closePopup()
  }
  componentDidMount() {
    this.getPopupContent(this.props.popupRef);
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps) { }
  }
  getData = data => {
    let Data = { data }
    Object.assign(this.props.popupRef.data, Data)
    this.props.getData(this.props.popupRef)
  }

  // ----------- User
  deleteUser = (id) => {
    this.props.deleteUser(id)
    this.props.filterUser({}, 10, 1)
    this.onClosePopup()
  }

  // createUser = async  (data) => {
  //   await this.props.createUser(data)
  //   // const {err} = this.props.userReducer;
  //   // debugger;
  //   // const { lastConditionFilterUser } = this.props.userReducer;
  //   // this.props.filterUser({}, 10, 1)
  //   // if (this.props.userReducer.err === "" || this.props.userReducer.err === {}) {
  //   //   this.props.showPopupAddUserSuccess()
  //   //   this.props.filterUser({}, 10, 1)
  //   //   return;
  //   // }
  // }

  updateUser = async (dataUpdate, idUser, currentPage) => {
    await this.props.updateUser(dataUpdate, idUser)
    if (typeof this.props.userReducer.err === 'object') {
      const { UPDATE_USER_ERR } = this.props.userReducer.err
      if (UPDATE_USER_ERR === 11) {
        // clear error
        this.props.clearError('UPDATE_USER_ERR', 'userReducer')
        // show error
        // this.setState({ showModal: true })
        this.props.alertPopup({
          content:
            'Không thể thực hiên được, vì User không có quyền trên hệ thống VietinBank',
          iconData: ic_close,
        })
        return
      }
    }
    this.onClosePopup()
    this.props.filterUser(this.props.userReducer.lastConditionFilterUser, 10, currentPage)
  }
  // -----------

  // delete office + advertisement + deviceKiosk + deviceLcd + CounterOffAdmin
  deleteItem = async (dataNeedDelete) => {
    // console.log(dataNeedDelete);
    if (dataNeedDelete.ID && dataNeedDelete.TypeAds) {
      this.props.deleteAds(dataNeedDelete.ID);
      let checkTypeAds = typeAds.find(item => item.type === dataNeedDelete.TypeAds)
      if (dataNeedDelete.TypeAds === checkTypeAds.type) {
        this.props.getAds(checkTypeAds.type, 8, 1);
      } else if (dataNeedDelete.TypeAds === checkTypeAds.type) {
        this.props.getAds(checkTypeAds.type, 8, 1);
      } else {
        this.props.getAds(checkTypeAds.type, 8, 1);
      }
    }
    // delete office in admin
    else if (dataNeedDelete.ID && dataNeedDelete.OfficeType) {
      // if (dataNeedDelete.NumOfCounter > 0 && dataNeedDelete.NumOfKiosk > 0) {
      //   await this.props.onShowRemoveBranchError({ dataNeedDelete })
      // } else {
      await this.props.deleteOffice(dataNeedDelete.ID);
      // await this.props.filterOffice({}, 10, dataNeedDelete.currentPage)
      // }
      const { err } = this.props.deleteOfficeReducer;
      // debugger;
      if (err && err.Code === 5) {
        this.props.onShowRemoveBranchError({ ...err, Name: dataNeedDelete.Name })
        this.props.clearErrorDeleteOffice();
        return;
      }

      this.props.filterOffice({}, 10, dataNeedDelete.currentPage)
    }
    // delete kiosk device + lcd in branch
    else if (dataNeedDelete.ID && dataNeedDelete.type && dataNeedDelete.key === "deleteDevice") {
      // console.log(dataNeedDelete.type)  
      if (dataNeedDelete.type === "DeviceLcd") {
        await this.props.deleteLCD(dataNeedDelete.ID)
        // await this.props.getAllLCD(dataNeedDelete.AtOffice, 8, dataNeedDelete.currentPage)
        await this.props.getAllLCD(dataNeedDelete.AtOffice, 8, 1)
      } else if (dataNeedDelete.type === "DeviceKiosk") {
        await this.props.deleteKiosk(dataNeedDelete.ID)
        // await this.props.getAllKiosk(dataNeedDelete.AtOffice, 8, dataNeedDelete.currentPage)
        await this.props.getAllKiosk(dataNeedDelete.AtOffice, 8, 1)
      }
    }
    else if (dataNeedDelete.ID && dataNeedDelete.CounterName && dataNeedDelete.type === "deleteCounter") {
      await this.props.deleteCounter(dataNeedDelete.ID)
      const { err } = this.props.counterReducer;
      if (err && typeof (err) === "object" && err.type === DELETE_COUNTER_ERR) {
        if (err.code === 21) {
          this.props.alertPopup({
            content: 'Không thể xoá quầy vì quầy đang phục vụ khách hàng',
            iconData: ic_close
          })
        }
        if (err.code === 22) {
          this.props.alertPopup({
            content: 'Không thể xoá quầy vì quầy đang ở trạng thái giao dịch',
            iconData: ic_close
          })
        }
        this.props.clearError(DELETE_COUNTER_ERR, 'counterReducer')
        this.onClosePopup();
        return;
      }
      await this.props.getCounterByOffice(this.props.userReducer.dataLogin.OfficeID, 8, dataNeedDelete.currentPage)
    }
    //// Check disCnonnect Counter 
    else if (dataNeedDelete.ID && dataNeedDelete.TabletDevice && dataNeedDelete
      .DesktopDevice && dataNeedDelete.key === "disConnect") {
      const type = dataNeedDelete.type;
      switch (true) {
        case type === "disConnectCounterTeller":
          break;
        case type === "disConnectCounterTablet":
          break;

        default:
          break;
      }
    }
    this.onClosePopup();
  }


  // updateAds kiosk
  updateKiosK = (id, dataChange) => {
    if (id && dataChange) {
      this.props.updateAds(id, dataChange)
      let checkTypeAds = typeAds.find(item => item.type === dataChange.TypeAds)
      if (dataChange.type === checkTypeAds.type) {
        this.props.getAds(checkTypeAds.type, 8, 1);
      } else if (dataChange.type === checkTypeAds.type) {
        this.props.getAds(checkTypeAds.type, 8, 1);
      } else {
        this.props.getAds(checkTypeAds.type, 8, 1);
      }
      this.onClosePopup();
    }
  }
  updateOffice = async (data, currentPage) => {
    let dataUpdate = {
      ID: data.id,
      Name: data.NameTransactionRoom,
      Address: data.AddressTransactionRoom,
      Province: data.city,
      District: data.district,
      BranchCode: data.TypeBranchCode,
      Ward: data.ward,
      OfficeType: data.typeTrans
    }
    await this.props.updateOffice(dataUpdate);
    await this.props.filterOffice({}, 10, currentPage)
  }

  createOffice = async (name, address, province, branchCode, district, ward, officeType) => {
    await this.props.createOffice(name, address, province, branchCode, district, ward, officeType);
    this.props.filterOffice({}, 10, 1)
  }

  /// Counter
  acceptActionCounter = async (data, dataModify) => {
    if (dataModify) {
      await this.props.updateCounter(data)
      const { err } = this.props.counterReducer;
      if (err && typeof (err) === "object" && err.type === UPDATE_COUNTER_ERR) {
        if (err.code === 21) {
          this.props.alertPopup({
            content: <>
              <div>Không thể cập nhật quầy</div>
              <div>vì quầy đang phục vụ</div>
              <div>khách hàng</div>
            </>,
            iconData: ic_close
          })
        }
        if (err.code === 22) {
          this.props.alertPopup({
            content: <>
              <div>Không thể cập nhật quầy </div>
              <div>vì quầy đang ở trạng thái</div>
              <div>giao dịch</div>
            </>,
            iconData: ic_close
          })
        }
        if (err.code === 16) {
          this.props.alertPopup({
            content: <>
              <div>PC Name vừa nhập </div>
              <div>đã được khai báo trên hệ thống.</div>
              <div>Vui lòng nhập tên khác</div>
            </>,
            iconData: ic_close,
            width: '264px'
          })
        }
        this.props.clearError(UPDATE_COUNTER_ERR, 'counterReducer')
        this.onClosePopup();
        return;
      }

      await this.props.getCounterByOffice(this.props.userReducer.dataLogin.OfficeID, 8, dataModify.currentPage)
      this.onClosePopup();
    } else {
      await this.props.createCounter(data)
      await this.props.getCounterByOffice(this.props.userReducer.dataLogin.OfficeID, 8, 1)
    }
  }

  /// Create + update TradingRoom (Kiosk + LCD)
  acceptActionTradingRoom = async (id, name, type, currentPage, timeoutAPI, macLCD) => {
    if (id === this.props.userReducer.dataLogin.OfficeID) {
      if (type === 'DeviceKiosk') {
        await this.props.createKiosk(id, name, timeoutAPI)
        await this.props.getAllKiosk(this.props.userReducer.dataLogin.OfficeID, 8, 1)
        // Promise.all([this.props.createKiosk(id,name), this.props.getAllKiosk(this.props.userReducer.dataLogin.OfficeID, 8,1)])
      } else {
        await this.props.createLCD(id, name)
        await this.props.getAllLCD(this.props.userReducer.dataLogin.OfficeID, 8, 1)
        // Promise.all([this.props.createLCD(id,name), this.props.getAllLCD(this.props.userReducer.dataLogin.OfficeID, 8,1)])
      }
    } else {
      if (type === 'DeviceKiosk') {
        const dataUpdate = {
          Timeout: timeoutAPI
        }
        await this.props.updateKiosk(id, name, dataUpdate)
        // await this.props.getAllKiosk(this.props.userReducer.dataLogin.OfficeID, 8, currentPage)
        await this.props.getAllKiosk(this.props.userReducer.dataLogin.OfficeID, 8, 1)
        // Promise.all([this.props.updateKiosk(id,name), this.props.getAllKiosk (this.props.userReducer.dataLogin.OfficeID, 8,currentPage)])
      } else if (type === 'DeviceLcd') {
        // await this.props.updateLCD(id, name, macLCD)
        await this.props.updateLCD(id, name)
        // await this.props.getAllLCD(this.props.userReducer.dataLogin.OfficeID, 8, currentPage)
        await this.props.getAllLCD(this.props.userReducer.dataLogin.OfficeID, 8, 1)
        // Promise.all([this.props.updateLCD(id,name), this.props.getAllLCD(this.props.userReducer.dataLogin.OfficeID, 8,currentPage)])
      }
    }
  }

  getPopupContent(popupRef) {
    let content = null;
    let nameData, listDevicePGD

    switch (popupRef.data.type) {
      case 'DeviceKiosk':
        listDevicePGD = this.props.listKiosk
        break;
      case 'DeviceLcd':
        listDevicePGD = this.props.listLCD
        break;
      default:
        break;
    }
    if (popupRef.data) {
      if (popupRef.data.nameLCD) {
        nameData = popupRef.data.nameLCD
      } else if (popupRef.data.dataNeedDelete) {
        if (popupRef.data.dataNeedDelete.Name)
          nameData = popupRef.data.dataNeedDelete.Name
        else if (popupRef.data.nameData)
          nameData = popupRef.data.nameData
        else
          nameData = popupRef.data.dataNeedDelete.CounterName

      }
      else {
        nameData = 'Chưa được đặt tên'
      }
    }
    // debugger
    switch (popupRef.type) {
      case popupType.configAdvertiseTable:
        {
          content = <PopupAdvertiseRoot
            descTitle={popupRef.data.descTitle}
            title={popupRef.data.title}
            titleHeader='Thêm mới quảng cáo'
            outPopup={() => {
              this.onClosePopup()
            }} />
          break;
        }
      case popupType.configTransaction:
        {
          content = <PopupTransaction
            alertPopup={this.props.alertPopup}
            titleHeader={popupRef.data.title}
            dataNeedModify={popupRef.data.dataNeedModify}
            outPopup={() => {
              this.onClosePopup()
            }}
            handleOnClick={
              popupRef.data.dataNeedModify
                ? this.updateOffice
                : this.createOffice
            }
            showPopupInBiometric={this.props.showPopupInBiometric}
          />
          break;
        }
      case popupType.configAddBranch: {
        content = <PopupAddBranch
          // typeAdvert={popupRef.data.typeAdvert}
          listDevicePGD={listDevicePGD}
          titleHeader={popupRef.data.titleHeader}
          title={popupRef.data.title}
          type={popupRef.data.type}
          placeHolder={popupRef.data.placeHolder}
          dataNeedModify={popupRef.data.dataNeedModify} outPopup={() => {
            this.onClosePopup()
          }}
          showPopupInBiometric={this.props.showPopupInBiometric}
          // getData={(data) => this.getData(data)}
          idOffice={this.props.userReducer.dataLogin.OfficeID}
          alertPopup={this.props.alertPopup}
          handleOnClick={this.acceptActionTradingRoom} />
        break;
      }
      case popupType.configSuccess: {
        content = <PopupSuccess
          titleHeader='Tạo tài khoản thành công'
          outPopup={() => {
            this.onClosePopup()
          }}
          acceptAction={() => {
            this.onClosePopup()
          }}
        />
        break;
      }
      case popupType.configAddPermission: {
        // content = <PopupAddPermission
        //   titleHeader='Thêm mới tài khoản'
        //   contentTitle='Tìm kiếm tài khoản Vietinbank'
        //   outPopup={() => {
        //     this.onClosePopup()
        //   }}
        //   addPermissionUser={this.createUser}
        //   showPopupInBiometric={this.props.showPopupInBiometric}
        // />
        content = <PopupAddPermissionV2
          outPopup={this.onClosePopup}
          showPopupInBiometric={this.props.showPopupInBiometric}
        />
        break;
      }
      case popupType.configConfirm:
        {
          content = <PopupConfirm
            title={popupRef.data.title}
            dataNeedDelete={popupRef.data.dataNeedDelete}
            nameData={nameData}
            // nameData={popupRef.data.dataNeedDelete.Name}
            // nameKiosk={popupRef.data.dataNeedDelete.Name}
            // nameLCD={popupRef.data.nameLCD}
            contentDelete={popupRef.data.contentDelete}
            contentBlue={popupRef.data.contentBlue}
            width={popupRef.data.widthPopup}
            acceptAction={this.deleteItem}
            cancelAction={() => { this.onClosePopup() }}
          />
          break;
        }
      case popupType.configAdvertiseNewImage:
        {
          content = <PopupNewImage
            title='Thêm mới quảng cáo'
            outPopup={() => {
              this.onClosePopup()
            }} />
          break;
        }
      case popupType.popupControlType:
        {
          content = <PopupControl
              offsetPopup={popupRef.data.offsetPopup}
              from={popupRef.data.from}
              dataReceive={popupRef.data.dataReceive}
              onModify={this.props.onModify}
              onRemove={
                this.props.onRemoveConfirm
              }
              closePopup={this.onClosePopup}
            />
          this.setState({ nonOverlay: true })
          break;
        }
      case popupType.configAdvertisementPopup:
        {
          content = <FlowAddAdvertisement
            showPopupInBiometric={this.props.showPopupInBiometric}
            typeAdvert={popupRef.data.typeAdvert}
            outPopup={() => this.onClosePopup()}
            handleOnSubmit={this.props.createAds}
            handleReLoadData={this.props.getAds}
          />
          break;
        }
      case popupType.configFlowCheckOffice:
        {
          content = <FlowOfficeCheckNumber
            // typeAdvert={popupRef.data.typeAdvert}
            contentDelete={popupRef.data.contentDelete}
            branchName={popupRef.data.dataShow.Name}
            NumOfCounter={popupRef.data.dataShow.NumberOfUnDeletedCounters}
            NumOfKiosk={popupRef.data.dataShow.NumberOfUnDeletedKiosks}
            NumOfUser={popupRef.data.dataShow.NumberOfUnDeletedUsers}
            NumOfLCD={popupRef.data.dataShow.NumberOfUnDeletedLCDs}
            outPopup={() => this.onClosePopup()}
          />
          break;
        }
      case popupType.configAccountPermission:
        {
          // content = <PopupAccountPermission
          //   from={popupRef.data.form}
          //   titleHeader='Thông tin tài khoản'
          //   outPopup={() => this.onClosePopup()}
          //   dataNeedModify={popupRef.data.dataNeedModify}
          //   dataNeedDelete={popupRef.data.dataNeedDelete}
          //   handleOnClick={
          //     popupRef.data.dataNeedModify ? () => { } : this.props.createOffice
          //   }
          //   // updatePermissionUser = {this.updateUser}
          //   updatePermissionUser={
          //     popupRef.data.dataNeedModify ?
          //       (data, uId, currentPage) => {
          //         this.updateUser(data, uId, currentPage)
          //       } : ''
          //   }
          // />
          content = <PopupAccountPermissionV2
            dataNeedModify={popupRef.data.dataNeedModify}
            outPopup={this.onClosePopup}
            alertPopup={this.props.alertPopup}
          />
          break;
        }
      case popupType.configDeletePermission:
        {
          content = <PopupDeletePermission
            from={popupRef.data.form}
            title={popupRef.data.title}
            dataNeedDelete={popupRef.data.dataNeedDelete}
            cancelAction={() => this.onClosePopup()}
            titleHeader='Thông tin tài khoản'
            outPopup={() => this.onClosePopup()}
            dataNeedModify={popupRef.data.dataNeedModify}
            handleOnClick={
              popupRef.data.dataNeedModify ? () => { } : this.props.createOffice
            }
            acceptAction={(id, role) => this.deleteUser(id, role)}
          />
          break;
        }
      case popupType.configAdvertiseKiosk:
        {
          // console.log(popupRef.data)   
          content = (
            <PopupAdvertiseRoot
              title={popupRef.data.dataNeedModify.titlePopup}
              TitleAds={popupRef.data.dataNeedModify.titleAds}
              descTitle={
                popupRef.data.dataNeedModify.descTitle ||
                popupRef.data.descTitle
              }
              titleConfirm={popupRef.data.titleConfirm}
              formatSize={formatSize}
              imgInfo={{
                url: popupRef.data.dataNeedModify.fileSrc,
                name: popupRef.data.dataNeedModify.FileName,
                width: popupRef.data.dataNeedModify.width,
                height: popupRef.data.dataNeedModify.height,
                size: popupRef.data.dataNeedModify.fileSize,
              }}
              handleOnSubmit={this.props.updateAds}
              handleReLoadData={this.props.getAds}
              dataNeedModify={popupRef.data.dataNeedModify}
              typeAds={popupRef.data.dataNeedModify.TypeAds}
              outPopup={() => {
                this.onClosePopup()
              }}
            />
          )
          break;
        }
      case popupType.configAddCounter:
        {
          content = <PopupAddCounter
            alertPopup={this.props.alertPopup}
            outPopup={() => this.onClosePopup()}
            dataNeedModify={popupRef.data.dataNeedModify}
            acceptActionCounter={async (data) => await this.acceptActionCounter(data, popupRef.data.dataNeedModify)}
            titleHeader={popupRef.data.titleHeader}
            showPopupInBiometric={this.props.showPopupInBiometric}
          />
          break;
        }
      case popupType.configOutOfLimit:
        {
          content = <PopupAlert
            title={popupRef.data.title}
            info={popupRef.data.info}
            note={popupRef.data.note}
            width={popupRef.data.width}
            isCenter={popupRef.data.isCenter}
            outPopup={() => {
              this.onClosePopup()
            }}
          />
          break;
        }
      case popupType.configCheckBiometricCustomer:
        {
          // debugger
          content = <PopupCheckBiometricCustomer
            showPopupInBiometric={this.props.showPopupInBiometric}
            dataUser={popupRef.data.dataUser}
            dataCurrent={popupRef.data.dataCurrent}
            isFlowBioErrorExecute={popupRef.data.isFlowBioErrorExecute}
            outPopup={() => {
              this.onClosePopup()
            }}
            lastConditionFilterUser={this.props.biometricReducer.lastConditionFilterUser}
            roles={this.props.userReducer.dataLogin.Roles}
            getBioHistoryList={this.props.getBioHistoryList}
            fixErrorAcceptBios={this.props.fixErrorAcceptBios}
            alertPopup={this.props.alertPopup}
            getListErrorAcceptBios={this.props.getListErrorAcceptBios}
          />
          break;
        }
      case popupType.configCheckCustomer:
        {
          content = <PopupCheckCustomer
            showPopupCheckCustomer={this.props.showPopupCheckCustomer}
            dataUser={popupRef.data.dataUser}
            // dataBiometric = {popupRef.data.dataBiometric ? popupRef.data.dataBiometric : []}
            outPopup={() => {
              this.onClosePopup()
            }}
            alertPopup={this.props.alertPopup}
          />
          break;
        }
      default:
        break;
    }
    this.setState({ content })
  }
  render() {
    // debugger
    return (
      <Bound nonOverlay={this.state.nonOverlay}
        onMouseDown={() => {
          if (this.props.popupRef.type !== popupType.popupControlType)
            return;
          if (!this.isMouseEnterPopup) {
            this.onClosePopup();
          }
        }}
      >
        <div
          onMouseEnter={() => {
            if (this.props.popupRef.type !== popupType.popupControlType)
              return;
            return this.isMouseEnterPopup = true
          }}
          onMouseLeave={() => {
            if (this.props.popupRef.type !== popupType.popupControlType)
              return;
            return this.isMouseEnterPopup = false
          }}
          onMouseOver={() => {
            if (this.props.popupRef.type !== popupType.popupControlType)
              return;
            if (!this.isMouseEnterPopup)
              this.isMouseEnterPopup = true
          }}
        >
          {this.state.content}
        </div>
      </Bound>
    )
  }
}
const mapStateToProps = (state) => ({
  counterReducer: state.counterReducer,
  userReducer: state.userReducer,
  biometricReducer: state.biometricReducer,
  deleteOfficeReducer: state.deleteOfficeReducer,
  listKiosk: state.kioskReducer.listKiosk,
  listLCD: state.LCDReducer.listLCD
})

const mapDispatchToProps = (dispatch) => ({
  //// office
  createOffice: (
    name,
    address,
    province,
    branchCode,
    district,
    ward,
    officeType
  ) =>
    dispatch(
      createOffice(
        name,
        address,
        province,
        branchCode,
        district,
        ward,
        officeType
      )
    ),
  filterOffice: (datafilter, pageSize, pageNumber) =>
    dispatch(filterOffice(datafilter, pageSize, pageNumber)),
  updateOffice: (dataUpdate) => dispatch(updateOffice(dataUpdate)),
  deleteOffice: (id) => dispatch(deleteOffice(id)),
  clearErrorDeleteOffice: () => dispatch(clearErrorDeleteOffice()),
  //// ads
  createAds: (
    title,
    desc,
    upload,
    typeAds,
    active,
    applyOffice = undefined
  ) => dispatch(createAds(title, desc, upload, typeAds, active, applyOffice)),
  getAds: (typeAds, pageSize, pageNumber) =>
    dispatch(getAds(typeAds, pageSize, pageNumber)),
  updateAds: (id, dataChange) => dispatch(updateAds(id, dataChange)),
  deleteAds: (id) => dispatch(deleteAds(id)),
  setStatusAds: (id, status) => dispatch(setStatusAds(id, status)),
  //// user
  deleteUser: (id) => dispatch(deleteUser(id)),
  filterUser: (dataFilter, pageSize, pageNumber) =>
    dispatch(filterUser(dataFilter, pageSize, pageNumber)),
  createUser: (listInfoUser) => dispatch(addUser(listInfoUser)),
  updateUser: (dataUpdate, idUser) =>
    dispatch(updateUser(dataUpdate, idUser)),
  /// TrandingRoom
  createKiosk: (idOffice, nameKiosk, timeout) =>
    dispatch(createKiosk(idOffice, nameKiosk, timeout)),
  updateKiosk: (idKiosk, nameKiosk, dataUpdate) =>
    dispatch(updateKiosk(idKiosk, nameKiosk, dataUpdate)),
  getAllKiosk: (idOffice, pageSize, pageNumber) =>
    dispatch(getAllKiosk(idOffice, pageSize, pageNumber)),
  deleteKiosk: (idKiosk) => dispatch(deleteKiosk(idKiosk)),
  createLCD: (idOffice, nameLCD) => dispatch(createLCD(idOffice, nameLCD)),
  updateLCD: (idLCD, nameLCD, macLCD) =>
    dispatch(updateLCD(idLCD, nameLCD, macLCD)),
  deleteLCD: (idLCD) => dispatch(deleteLCD(idLCD)),
  getAllLCD: (idOffice, pageSize, pageNumber) =>
    dispatch(getAllLCD(idOffice, pageSize, pageNumber)),
  //// counter
  createCounter: (dataCreate) => dispatch(createCounter(dataCreate)),
  updateCounter: (dataUpdate) => dispatch(updateCounter(dataUpdate)),
  deleteCounter: (counterId) => dispatch(deleteCounter(counterId)),
  getCounterByOffice: (officeId, pageSize, pageNumber) =>
    dispatch(getCounterByOffice(officeId, pageSize, pageNumber)),
  // disConnectCounter: (counterID) => dispatch(disConnectCounter)
  //// supervisor
  acceptBioInfos: (idBio, noteBio) =>
    dispatch(acceptBioInfos(idBio, noteBio)),
  getBioInfos: (dataQuery) => {
    dispatch(getBioInfos(dataQuery))
  },
  getBioHistoryList: (
    customerNumber,
    pageSize,
    pageNumber,
    lessThanCreateAt
  ) =>
    dispatch(
      getBioHistoryList(
        customerNumber,
        pageSize,
        pageNumber,
        lessThanCreateAt
      )
    ),
  clearError: (typeErr, reducerTarget) =>
    dispatch(clearError(typeErr, reducerTarget)),
  fixErrorAcceptBios: (id, callbackSuccess) =>
    dispatch(fixErrorAcceptBios(id, callbackSuccess)),
  getListErrorAcceptBios: (custNumber) =>
    dispatch(getListErrorAcceptBios(custNumber))
})

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(PopupContainer);