
import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
// Img
import ic_close from '../../../images/ic_close_gray.svg';
// Component
import SingleChoiseDropDownControl from '../MainScreen/Control/SingleChoiseDropDownControl'
import DropDownSearchObjV2 from '../MainScreen/Control/DropDownSearchObjV2';

// Data
import { setPermissionSingleChoise } from '../../../data/singleChoiseData'
import * as userRoles from "../../../data/userRole";
//Office
import { filterOffice, alertFillterOffice } from '../../../actions/officeAction';
import { getAllOffice } from '../../../actions/officeAction';
//User
import { filterUser } from '../../../actions/userAction';
import LoadingControl from '../MainScreen/Control/LoadingControl';

const Bound = styled.div`
    width:100%;
    min-height:100vh;
    position:absolute;
    top:0;
    left:0;
    z-index:10;
    font-family: 'SVN-Gilroy';
    .loading{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 108px 248px 0 0;
        p{
            font-family: SVN-Gilroy;
            font-size: 14px;
            font-weight: 500;
            line-height: 1.25;
            color: #787878;
            padding-left: 6px;
        }
    }
    .popup-filter-bound{
        display:flex;
        flex-direction:column;
        width: 210px;
        padding: 30px 20px 0 20px;
        height: calc(100vh - 80px - 80px - 30px);
        background-color:white;
        position:absolute;
        bottom:0;
        right:-240px;
        transition:right 0.5s;
        align-items:center;
        .div_check{
            width: 100%;
            margin: 0 0 20px 0;

            .title-dropdown {
                font-family: 'SVN-Gilroy';
                font-size: 11px;
                font-weight: bold;
                color: #005993;
                text-transform: uppercase;
                margin-bottom: 5px;
            }
        }
        .div_header{
            width:100%;
            margin: 0 0 20px 0;
            display:flex;
            justify-content:space-between;
            align-items: center;
        .title{
            font-size: 14px;
            font-weight: bold;
            color: #000000;
            width: 64px;
            height: 36px;
            border-radius: 18px;
            background-color: #005993;
            display:flex;
            justify-content: center;
            align-items: center;
            color:#ffffff;
            cursor: pointer;
        }
        .close-btn{
            width:20px;
            height:20px;
            cursor: pointer;
        }
        }
    }
`
class PopupPermissionFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idShow: "",
            data: setPermissionSingleChoise,
            AtOffice: '',
            Role: '',
            listOffice: '',
            listRole: '',
            isLoading: false
        }
    }

    getText = (type) => text => {
        this.setState({ [type]: text })
    }

    getAtOffice = (type) => text => {
        this.setState({ [type]: text })
    }

    setIdShow = (id) => {
        this.setState({ idShow: id })
    }

    onSubmit = () => {
        this.setState({
            isLoading: true
        });
        const { Role, AtOffice } = this.state
        let dataFilter = {}
        if (Role) {
            dataFilter['Role'] = Role.type
            // debugger
            // if(Role === 'Giao dịch viên'){
            //     dataFilter.Role = 'TELLER'
            // }else if(Role === 'Quản trị viên PGD'){
            //     dataFilter.Role = 'OFFADMIN'
            // }
        }
        if (AtOffice) {
            // dataFilter['AtOffice'] = AtOffice.id
            // dataFilter.AtOffice = AtOffice.id
            dataFilter.AtOffice = AtOffice.key
        }
        if (this.props.searchRef) {

            const text = this.props.searchRef.state.txtText;
            if (text) {
                dataFilter['Search'] = text;
            }
            //clear ref
            // debugger;
        }
        if (Object.keys(dataFilter).length !== 0) {
            this.props.filterUser(dataFilter, 10, 1)
            this.props.alertContent('')
            this.props.onClose()
        } else {
            this.props.onClose()
            return
        }
    }

    _handleSearch = (keySearch) => {
        this.props.filterOffice({ name: keySearch }, 1000000, 1)
    }

    _handleChange = (arrOfice) => {
        this.setState({ AtOffice: arrOfice[0] })
    }

    componentDidMount() {
        if (this.props.userReducer.dataLogin.Roles.includes(userRoles.ADMIN_ROLE))
            this.props.getAllOffice()
    }

    render() {
        let { listOffice } = this.props.filterOfficeReducer;
        listOffice = listOffice.map(({ ID, BranchCode, Name }) => ({
            // id: ID,
            // name: Name,
            key: ID,
            value: `${BranchCode} - ${Name}`,
        }))
        let listRole = this.state.data.rank

        const getDropdownRole = permissions => object =>
            permissions.some(item => object.permissions.includes(item))

        const getDropdownFollowRole = getDropdownRole(this.props.userReducer.dataLogin.Roles)
        listRole = listRole.filter(getDropdownFollowRole);

        const Role = this.getText("Role");
        const atOffice = this.getAtOffice("AtOffice");
        return (
            <Bound
                onMouseDown={() => {
                    this.props.onClose()
                }}>
                {
                    this.state.isLoading &&
                    <div className='loading'>
                        <LoadingControl loadingPage={false} size='24px' />
                        <p>Đang tải...</p>
                    </div>
                }
                <div
                    className='popup-filter-bound'
                    id='filter_permission_id'
                    onMouseDown={(e) => {
                        e.stopPropagation()
                    }}
                >
                    <div className="div_header">
                        <img
                            src={ic_close}
                            alt='close'
                            className='close-btn'
                            onClick={() => {
                                this.props.onClose()
                            }} />
                        <div onClick={() => this.onSubmit()} className='title'>FILTER</div>
                    </div>
                    <div className="div_check">
                        <SingleChoiseDropDownControl
                            id="selectRole"
                            getText={Role}
                            data={listRole}
                            title='Loại tài khoản'
                            titleChoise='Chọn loại tài khoản'
                            idShow={this.state.idShow}
                            setIdShow={this.setIdShow} />
                    </div>
                    {
                        // {/* this.props.userReducer.dataLogin.Role === userRoles.ADMIN_ROLE &&
                        // ((this.state.Role && this.state.Role.type !== userRoles.INBIOCOLLECTOR) ||
                        //     !this.state.Role) && */}
                        this.props.userReducer.dataLogin.Roles.includes(userRoles.ADMIN_ROLE) &&
                        <div className="div_check">
                            {/* <SingleChoiseDropDownControl
                                id="selectAtOffice"
                                // getText={this.getAtOffice}
                                getText={atOffice}
                                data={listOffice}
                                title='điểm giao dịch'
                                titleChoise='Chọn điểm giao dịch'
                                idShow={this.state.idShow}
                                setIdShow={this.setIdShow} /> */}
                            <div className="title-dropdown">Điểm giao dịch</div>
                            <DropDownSearchObjV2
                                lstItemFixed={[]}
                                lstItems={listOffice}
                                defaultValue={undefined}
                                onChange={this._handleChange}
                                onSearch={this._handleSearch}
                                haveSearch={true}
                                placeHolder="Nhập mã, tên chi nhánh"
                                placeHolderSelect='Chọn điểm giao dịch'
                            />
                        </div>
                    }
                </div>
            </Bound>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        userReducer: state.userReducer,
        filterOfficeReducer: state.filterOfficeReducer
    }
}
const mapDispatchToProps = dispatch => ({
    filterOffice: (dataFilter, pageSize, pageNumber) => dispatch(filterOffice(dataFilter, pageSize, pageNumber)),
    getAllOffice: () => dispatch(getAllOffice()),
    filterUser: (dataFilter, pageSize, pageNumber) => dispatch(filterUser(dataFilter, pageSize, pageNumber)),
    alertContent: () => dispatch(alertFillterOffice()),
})

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(PopupPermissionFilter)
