import styled, { css } from 'styled-components'

const Grid3Col = styled.div`
    display: grid;
    grid-template-columns: 
        minmax(100px, 1fr) 
        minmax(69%, 9fr) 
        minmax(auto, 3fr);
    grid-gap: 5px;
    align-items: center;
    & > *:first-child {
        margin-left: 25px;
    }
    ${props => props.extendStyles}
`;

const HeaderStyles = css`
    background: #0059930d;
    height: 40px;
`;
const HEIGHT_ITEM = 48
const reportItemStyles = css`
    background: #EFEFEF;
    height: ${HEIGHT_ITEM}px;
    box-shadow: inset 0px -0.5px 0px rgba(0, 0, 0, 0.12);
    font-family: SVN-Gilroy;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 128%;
    color: #222222;
`;

const WrapRowData = styled.div`
    max-height: ${HEIGHT_ITEM * 8}px;
    overflow-y: auto;
`;

export {
    Grid3Col, 
    HeaderStyles,
    reportItemStyles,
    WrapRowData
}