import React, { useEffect, useState, useCallback } from 'react'
import { css } from 'styled-components'

// styles
import { extendStylesDropdownResultAuth } from './index.styles'

// component
import DropDown from '../DropDown'

// data
import { verifyResults } from '../../../../../../../../data/dataVerifyCustomer';

const itemAllResult = {
    key: undefined,
    value: 'Tất cả',
}

const lstResult = Object.values(verifyResults).map(({ key, text }) => ({ key, value: text }))

const SelectResultAuth = ({ defaultValue, onChange, extendStyles }) => {

    const [lstItems, setLstItems] = useState([])
    const [value, setValue] = useState(defaultValue || itemAllResult)

    const _handleOnChange = useCallback((obj) => {
        setValue(obj)
        onChange(obj)
    }, [])

    useEffect(() => {
        setLstItems([itemAllResult, ...lstResult])
    }, [])

    return (
        <DropDown
            lstItemFixed={[]}
            lstItems={lstItems}
            defaultValue={value}
            onChange={_handleOnChange}
            haveSearch={false}
            extendStyles={css`${extendStylesDropdownResultAuth}${extendStyles}`}
            maxNumShowDropdown={lstItems.length}
            label="Kết quả xác thực"
        />
    )
}

export default SelectResultAuth
