import React, { Component } from 'react';
import styled from 'styled-components';
import { convertNumber } from '../../../../tools';
import { SAVINGDEP, DEPINT, DEPEXT, WITHDRAW, M, month, MAE, Y, year, YAE, TRANSINT, TRANSEXTACC, TRANSEXTCARD, TRANSEXT } from '../../../../data/dataVerifyCustomer';

const Bound = styled.div`
    overflow-y: scroll;
    overflow-x: hidden;
    height: 480px;
    margin-top: 3px;
    ::-webkit-scrollbar {
        width: 3px;
    }
    ::-webkit-scrollbar-thumb {
        background: #c8c8c8; 
    }
    .inform_service{
        display: flex;
        justify-content: space-between;
        min-height: 42.02px;
        background: #FAFAFA;
        box-shadow: inset 0px -0.5px 0px rgba(0, 0, 0, 0.15);
        padding: 11px 10px 15.46px 20px;
        font-family: SVN-Gilroy;
        font-style: normal;
        font-size: 14px;
        line-height: 128%;
        h3{
            font-weight: bold;
            color: #222222;
            flex: 1.6;
        }
        &_account{
            flex: 1;
            font-weight: 500;
            justify-content: flex-start;
            p{
                color: #B4B4B4;
                text-align: left;
                &:not(:first-child){
                    margin-top: 8px;
                }
            }
            span.type-figure{
                color: #005993;
            }
        }
    }
`

class UserServiceDetail extends Component {
    render() {
        const {ServiceDetails} = this.props.dataUser
        return (
            <Bound>
            {
                ServiceDetails && ServiceDetails.map((item, i) => {
                    let elmService, elmTextService, elmFromAcc, elmToAcc, elmTransactionAmount, elmTerm, elmTextTerm, elmBank, elmTextBank
                    switch (item.ServiceType) {
                        case SAVINGDEP:
                            elmService = 'Gửi tiết kiệm'
                            elmTextTerm = 'Kỳ hạn: '
                            switch (item.SavingDeposit.TermType) {
                                case M:
                                    elmTerm = item.SavingDeposit.Term + month
                                    break;
                                case MAE:
                                    elmTerm = item.SavingDeposit.Term + month
                                    break;
                                case Y:
                                    elmTerm = item.SavingDeposit.Term + year
                                    break;
                                case YAE:
                                    elmTerm = item.SavingDeposit.Term + year
                                    break;
                                default:
                                    break;
                            }
                            elmFromAcc = item.SavingDeposit.ToAccountNumber
                            elmTransactionAmount = convertNumber(item.SavingDeposit.TransactionAmount)
                            break;
                        case DEPINT:
                            elmTextService = 'TK: '
                            elmService = 'Nộp tiền vào TK tại VietinBank'
                            elmFromAcc = item.DepositInternal.ToAccountNumber
                            elmTransactionAmount = convertNumber(item.DepositInternal.TransactionAmount)
                            break;
                        case DEPEXT:
                            elmTextService = 'TK: '
                            elmService = 'Nộp tiền vào TK ngân hàng khác'
                            elmTextBank = 'Ngân hàng: '
                            elmFromAcc = item.DepositExternal.ToAccountNumber
                            elmBank = item.DepositExternal.ToBankCode
                            elmTransactionAmount = convertNumber(item.DepositExternal.TransactionAmount)
                            break;
                        case WITHDRAW:
                            elmTextService = 'Từ TK: '
                            elmService = 'Rút tiền'
                            elmFromAcc = item.WithDraw.FromAccountNumber
                            elmTransactionAmount = convertNumber(item.WithDraw.TransactionAmount)
                            break;
                        case TRANSINT:
                            elmTextService = 'Từ TK: '
                            elmService = 'Chuyển khoản trong hệ thống'
                            elmFromAcc = item.TransInternal.FromAccountNumber
                            elmToAcc = item.TransInternal.ToAccountNumber
                            elmTransactionAmount = convertNumber(item.TransInternal.TransactionAmount)
                            break;
                        case TRANSEXTACC:
                            elmTextService = 'Số TK: '
                            elmService = 'Chuyển khoản nhanh ngoài NHCT đến số tài khoản'
                            elmFromAcc = item.TransExternalAcc.ToAccountNumber
                            elmTransactionAmount = convertNumber(item.TransExternalAcc.TransactionAmount)
                            elmBank = item.TransExternalAcc.ToBankCode
                            break;
                        case TRANSEXTCARD:
                            elmTextService = 'Số thẻ: '
                            elmService = 'Chuyển khoản nhanh ngoài NHCT đến số thẻ'
                            elmFromAcc = item.TransExternalCard.AccountNumber
                            elmTransactionAmount = convertNumber(item.TransExternalCard.TransactionAmount)
                            elmBank = item.TransExternalCard.ToCardNumber
                            break;
                        case TRANSEXT:
                            elmTextService = 'Số TK: '
                            elmService = 'Chuyển khoản nhanh ngoài NHCT thông thường'
                            elmFromAcc = item.TransExternal.ToAccountNumber
                            elmTransactionAmount = convertNumber(item.TransExternal.TransactionAmount)
                            elmBank = item.TransExternal.ToBankCode
                            break;
                        default:
                            break;
                    }
                    return(
                    <React.Fragment key = {i}>
                        <div className = 'inform_service'>
                            <h3>{i+1}. {elmService}</h3>
                            <div className = 'inform_service_account'>
                            {
                                !!elmTextService &&
                                <React.Fragment>
                                    <p>{elmTextService} &nbsp;
                                        <span className = 'type-figure'>{elmFromAcc}</span>
                                    </p>
                                </React.Fragment>
                            }
                            {
                                item.ServiceType === TRANSINT &&
                                <React.Fragment>
                                    <p>Đến TK: &nbsp;
                                        <span className = 'type-figure'>{elmToAcc}</span>
                                    </p>
                                </React.Fragment>
                            }
                            <p>Số tiền: &nbsp;
                                <span className = 'type-figure'>{elmTransactionAmount} VNĐ</span>
                            </p>
                            {
                                !!elmTerm &&
                                <React.Fragment>
                                    <p>{elmTextTerm} &nbsp;
                                        <span className = 'type-figure'>{elmTerm}</span>
                                    </p>
                                </React.Fragment>
                            }
                            {
                                (item.ServiceType === DEPEXT || item.ServiceType === TRANSEXTACC || item.ServiceType === TRANSEXT || item.ServiceType === TRANSEXTCARD) &&
                                <React.Fragment>
                                    <p>{elmTextBank} &nbsp;
                                        <span className = 'type-figure'>{elmBank}</span>
                                    </p>
                                </React.Fragment>
                            }
                            </div>
                        </div>
                    </React.Fragment>
                )})
            }
            </Bound>
        )
    }
}

export default UserServiceDetail;