import React, { useCallback, useState, useEffect } from 'react'

// styles
import { extendStylesDropdownRating } from './index.styles'

// component
import DropDown from '../DropDown'

const itemAllRating = {
    key: undefined,
    value: 'Tất cả đánh giá',
}

const lstServicesRating = [
    {
        key: 'ST',
        value: 'Hài lòng với dịch vụ',
    },
    {
        key: 'NSTA',
        value: 'Không hài lòng với thái độ phục vụ',
    },
    {
        key: 'NSTS',
        value: 'Không hài lòng nghiệp vụ nhân viên',
    },
    {
        key: 'NSTT',
        value: 'Không hài lòng thời gian giao dịch',
    },
    {
        key: '',
        value: 'Không đánh giá',
    },
]

const SelectServicesRating = ({ defaultValue, onChange }) => {
    const [lstServices, setLstServices] = useState([])
    const [servicesSelected, setServicesSelected] = useState(
        defaultValue || itemAllRating
    )

    const _handleOnChange = useCallback((obj) => {
        setServicesSelected(obj)
        onChange(obj)
    }, [])

    useEffect(() => {
        setLstServices([itemAllRating, ...lstServicesRating])
    }, [])

    return (
        <DropDown
            lstItemFixed={[]}
            lstItems={lstServices}
            defaultValue={servicesSelected}
            onChange={_handleOnChange}
            haveSearch={false}
            extendStyles={extendStylesDropdownRating}
            maxNumShowDropdown={lstServicesRating.length}
            label="KQ đánh giá"
        />
    )
}

export default SelectServicesRating
