import React, { Component } from 'react'

// styles
import { Bound } from './index.styles'

// component
import CountDownTimeControl from '../../Control/CountDownTimeControl'

// data
import { PENDING } from '../../../../../data/infosType'

// helper
import {
    formatDateTimeToSpecificFormat,
    formatDateTime,
} from '../../../../../helper/formatTime'

const CustomerType = {
    EXCUST: 'Thông thường',
    INCUST: 'Nội bộ',
}

class Table extends Component {

    _handleShowInfo = (data) => {
        this.props.showPopupCheckBiometric(data, data.CurrentBioData)
    }

    render() {

        const { list } = this.props

        return (
            <Bound >
                <div className="bio-head block">
                    <div className="cif-code first">
                        MÃ SỐ CIF
                    </div>
                    <div className="cif-code">GTTT</div>
                    <div className="name-head">TÊN KHÁCH HÀNG</div>
                    <div className="class-custom">
                        HẠNG KHÁCH HÀNG
                    </div>
                    <div className="type-custom">
                        LOẠI KHÁCH HÀNG
                    </div>
                    <div className="registor-gdv">
                        GDV ĐĂNG KÝ
                    </div>
                    <div className="type-data">
                        LOẠI DỮ LIỆU
                    </div>
                    <div className="type-status">
                        TRẠNG THÁI
                    </div>
                    <div className="time-waitting">
                        THỜI GIAN CHỜ
                    </div>
                    <div className="time-register">
                        THỜI GIAN ĐĂNG KÝ
                    </div>
                    <div className="user-approve">
                        NGƯỜI PHÊ DUYỆT
                    </div>
                    <div className="time-update">
                        THỜI GIAN PHÊ DUYỆT
                    </div>

                    <div className="control-page last">

                    </div>
                </div>
                <div className="bio-body">
                    {list.map((item) => {
                        return (
                            <div key={item.ID} className="item-row block" onClick={() => this._handleShowInfo(item)}>
                                <div className="cover" title={item.CustomerInfos.CustomerNumber}>
                                    <div className="cif-code first text-ellipst w-50">
                                        {item.CustomerInfos.CustomerNumber}
                                    </div>
                                </div>
                                <div className="cover" title={item.CustomerInfos.IdNumber}>
                                    <div className="gttt-custom text-ellipst w-72">
                                        {item.CustomerInfos.IdNumber}
                                    </div>
                                </div>
                                <div className="cover" title={item.CustomerInfos.Name}>
                                    <div className="name-custom text-ellipst w-147">
                                        {item.CustomerInfos.Name}
                                    </div>
                                </div>
                                <div className="cover" title={item.CustomerInfos.SegmentDesc}>
                                    <div className="class-custom text-ellipst w-100">
                                        {item.CustomerInfos.SegmentDesc}
                                    </div>
                                </div>
                                <div className="cover" title={CustomerType[item.CustomerType]}>
                                    <div className="type-custom text-ellipst w-100">
                                        {CustomerType[item.CustomerType]}
                                    </div>
                                </div>
                                <div className="cover" title={item.TellerInfos?.UserName || item.TellerInfos?.Name || ""}>
                                    <div className="registor-gdv text-ellipst w-100">
                                        {item.TellerInfos?.UserName || item.TellerInfos?.Name || ""}
                                    </div>
                                </div>
                                <div className="cover" title={item.elmBioInfosType}>
                                    <div
                                        className="registor-gdv text-ellipst w-100"
                                        style={{
                                            cursor: !!item.colorsInfosType && 'pointer',
                                            color: item.colorsInfosType,
                                        }}
                                    >
                                        {item.elmBioInfosType}
                                    </div>
                                </div>
                                <div className="cover">
                                    <div
                                        className="registor-gdv text-ellipst w-100"
                                        style={{
                                            fontWeight: 600,
                                            color: item.clsStatus,
                                        }}
                                    >
                                        {item.valueStatus}
                                    </div>

                                </div>
                                {
                                    item.Status === PENDING ? (
                                        <div className="item-counttime">
                                            <CountDownTimeControl
                                                data={item.CreateAt}
                                            />
                                        </div>
                                    ) : (
                                        <div></div>
                                    )
                                }
                                <div
                                    className="cover"
                                    title={
                                        formatDateTimeToSpecificFormat(
                                            item.CreateAt,
                                            formatDateTime.HHmmDDMMYY
                                        )
                                    }
                                >
                                    <div className="time-registor-gdv text-ellipst w-118">
                                        {formatDateTimeToSpecificFormat(
                                            item.CreateAt,
                                            formatDateTime.HHmmDDMMYY
                                        )}
                                    </div>
                                </div>

                                <div
                                    className="cover"
                                    title={
                                        item.SupervisorInfo?.UserName
                                        || (!item.SupervisorInfo
                                            && item.BioInfosType === 'NOTREGISTER'
                                            && item.Status === 'ACCEPT'
                                            && 'Auto')
                                    }
                                >
                                    <div className="user-approve-gdv text-ellipst w-118">
                                        {item.SupervisorInfo?.UserName}
                                    </div>

                                    <p className="self-approval">
                                        {
                                            !item.SupervisorInfo
                                            && item.BioInfosType === 'NOTREGISTER'
                                            && item.Status === 'ACCEPT'
                                            && 'Auto'
                                        }
                                    </p>
                                </div>

                                <div
                                    className="cover"
                                    title={
                                        !!item.CompleteAt
                                        && item.Status !== 'PENDING'
                                        && formatDateTimeToSpecificFormat(
                                            item.CompleteAt,
                                            formatDateTime.HHmmDDMMYY
                                        )
                                    }
                                >
                                    <div className="time-approve-gdv text-ellipst w-118">
                                        {
                                            !!item.CompleteAt
                                            && item.Status !== 'PENDING'
                                            && formatDateTimeToSpecificFormat(
                                                item.CompleteAt,
                                                formatDateTime.HHmmDDMMYY
                                            )
                                        }
                                    </div>
                                </div>
                                <div
                                    className="item-last"
                                    style={{
                                        color: item.colorStatus,
                                        textDecoration: 'underline',
                                    }}
                                    onClick={() => this._handleShowInfo(item)}
                                >
                                    Chi tiết
                                </div>
                            </div>
                        )
                    })}
                </div>
            </Bound>
        )
    }

}

export default Table