import React, { Component } from 'react';
import styled from 'styled-components';

import ButtonConfirmPopup from '../../MainScreen/Control/ButtonConfirmPopup';
import { color } from '../../../../data/store';
import ic_question from '../../../../images/Icons_Notification_Question.svg'

const Bound = styled.div`
    ${'' /* padding: 20px 32px 15px 32px; */}
    background-color: #fafafa;
    /* height: auto; */
    width: 75%;
    height: 100%;
    overflow: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'SVN-Gilroy';
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    z-index: 999;
    box-shadow: 0px 0px 10px 0px #0000008a;
    font-size: 11px;
    ::-webkit-scrollbar {
        display: none;
    }
    table {
        width: 100%;
    }

    h4 {
        font-weight: normal;
    }

    .lable-checkbox {
        display: flex;
    }

    .checkbox {
        width: 16px;
        height: 16px;
        margin: 0 5px 0 0;
    }
    .row_btn_confirm{
        display:flex;
        align-items: center;
        justify-content: center;
        margin: 10px 0 0 0;
        #button{
            width: 60px;
            height: 23px;
        }
        .btn_exit{
            margin: 0 20px 0 0;
        }
        .titleConfirm{
            font-size: 10.5px;
        }
    }
    .row_title{
        padding: 20px 20px 0 55px;
        .pr-20{
            padding-right: 20px;
        }
    }
    p{
        padding-top: 1px;
    }
`
export default class FormRegisterPrint extends Component {
    printForm = () => {
        window.print()
    }
    render() {
        const {data} = this.props
        return (
            <div
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    zIndex: 999
                }}>
                <Bound id= 'form' colorContent={this.props.colorContent} width={this.props.width}>
                    <br/>
                    <div className="row_title">
                        <p align="right">
                            <em>
                                <br/>
                            </em>
                        </p>
                        
                        <h4 align="right" className = 'pr-20'>
                            <span><em>Biểu mẫu </em></span>
                            <em>01/ĐK</em>
                            <em></em>
                        </h4>
                        <p align="center" style = {{paddingBottom: '5px'}}>
                            <strong> CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM</strong>
                        </p>
                        <p align="center" style = {{textDecoration: 'underline'}}>
                            <strong> </strong>
                            <strong>Độc lập - Tự do - Hạnh phúc</strong>
                        </p>
                        <p align="right" className = 'pr-20'>
                            <strong> </strong>
                            <em>{data.length > 0 ? data : '………'}, ngày {data.length > 0 ? data : '…..'}tháng{data.length > 0 ? data : '…….'}năm {data.length > 0 ? data : '…..'}</em>
                            <br/>
                        </p>
                        <p align="right">
                            <em>
                                <br/>
                            </em>
                        </p>
                        <p align="center">
                            <strong>GIẤY ĐỀ NGHỊ ĐĂNG KÝ THÔNG TIN SINH TRẮC HỌC</strong>
                        </p>
                        <p align="center">
                            <strong>
                                <br />
                            </strong>
                        </p>
                        
                        <p>
                            <strong>Kính gửi</strong>
                            :
                                <strong>
                                    NGÂN HÀNG TMCP CÔNG THƯƠNG VIỆT NAM CHI NHÁNH {data.length > 0 ? data : '.......................................................................................................................'}
                                </strong>
                        </p>
                        <p>
                            <strong>A. </strong>
                            <strong>THÔNG TIN KHÁCH HÀNG</strong>
                        </p>
                        <p>
                            Họ tên: {data.length > 0 ? data : '...................................................................................................................................................................................................................................................'}
                        </p>
                        <p>
                            Số CMND/Hộ chiếu/Thẻ CCCD: {data.length > 0 ? data : '.................................................'} Cấp
                            ngày: {data.length > 0 ? data : '.......................................'} Nơi cấp: {data.length > 0 ? data :'.....................................................................'}
                        </p>
                        <p>
                            Địa
                            chỉ: {data.length > 0 ? data : '..................................................................................................................................................................................................................................................'}
                        </p>
                        <p>
                            Số điện
                            thoại: {data.length > 0 ? data : '.............................................................................................'}
                            Email: {data.length > 0 ? data : '.............................................................................................................................'}
                        </p>
                        <p>
                            <strong>B. </strong>
                            <strong>NỘI DUNG ĐỀ NGHỊ</strong>
                        </p>
                        <p>
                            Đề nghị VietinBank đăng ký :
                        </p>
                        <p></p>
                        <p className='lable-checkbox'>
                            <input 
                                type="checkbox" 
                                name = 'biometric'
                                checked
                                className="checkbox"/> Thông tin sinh trắc học khuôn mặt
                        </p> <p></p>
                        <p className='lable-checkbox'>
                            <input type="checkbox"
                                name = 'biometric'
                                checked
                                className="checkbox"/> Thông tin sinh trắc học vân tay
                        </p>
                        <p>Tên ngón tay đăng ký: </p>
                        <p>
                            Ngón tay thứ nhất: {data.length > 0 ? data : '................................................................................................'} Ngón
                            tay thứ hai: {data.length > 0 ? data : '..........................................................................................'}
                        </p>
                        <p>
                            <strong>C. </strong>
                            <strong>CAM KẾT</strong>
                        </p>
                        <p style = {{textAlign: 'justify'}}>
                            - Tôi xác nhận đã đọc, hiểu rõ, đồng ý với các quy định trong “Điều khoản,
                            điều kiện quản lý và sử dụng thông tin sinh trắc học trong quá trình giao dịch
                            tại VietinBank” theo Phụ lục đính kèm và các thông tin theo giấy đề nghị này. 
                            Bản Điều khoản, điều kiện này là một phần không thể tách rời của Giấy đề nghị đăng ký thông tin sinh trắc học.
                        </p>
                        <p style = {{textAlign: 'justify'}}>
                            - Tôi đồng ý sử dụng số điện thoại nêu tại mục A để <span style = {{color: 'red'}}>nhận các thông báo liên quan
                            đến thay đổi thông tin sinh trắc học và </span>sử dụng cho các dịch vụ đã đăng ký tại VietinBank (ngoại trừ dịch vụ SMS Banking). 
                        </p>
                        <p style = {{textAlign: 'justify'}}> - Kịp thời thông báo cho VietinBank biết khi thông tin sinh trắc học của tôi có thay đổi.</p>
                        <p style = {{textAlign: 'justify'}}>
                            - Đã được Vietinbank cung cấp đầy đủ thông tin về các sản phẩm, dịch vụ mà tôi đăng ký tại
                            Giấy đăng ký thông tin sinh trắc học này cũng như các thông tin về bản Điều khoản, điều kiện
                            quản lý, sử dụng thông tin STH trong quá trình giao dịch tại Vietinbank trước khi ký Giấy đề nghị này.
                        </p>
                        <p style = {{textAlign: 'justify'}}>
                            - Tôi cam đoan và hoàn toàn chịu trách nhiệm về tính chính xác, hợp pháp của các thông tin trên Giấy 
                            đề nghị đăng ký thông tin sinh trắc học.
                        </p>
                        <p style = {{textAlign: 'justify'}}>
                            Giấy đề nghị này có hiệu lực từ ngày ký và được lập thành 02 bản có nội
                            dung như nhau, khách hàng giữ 01 bản, Chi nhánh/PGD {data.length > 0 ? data : '...............................'} giữ 01 bản làm
                            căn cứ thực hiện.
                        </p>
                        <p align="right">
                            <em>
                                <br/>
                            </em>
                        </p>
                        <table border="0" cellSpacing="0" cellPadding="0">
                            <tbody>
                                <tr>
                                    <td width="295" valign="top">
                                    </td>
                                    <td width="342" valign="top">
                                        <p align="center">
                                            <strong>KHÁCH HÀNG</strong>
                                        </p>
                                        <p></p>
                                        <p></p>
                                        <p align="center">
                                            <em>(Ký và ghi rõ họ tên)</em> 
                                            <br/>
                                            <br/>
                                            <br/>
                                            <br/>
                                            <br/>
                                            <br/>
                                            <br/>
                                        </p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
   
                        <p align="center">
                            <strong>Xác nhận của Ngân hàng TMCP Công Thương Việt Nam</strong>
                        </p>
                        <p>
                            Đã thực hiện đăng ký thông tin sinh trắc học theo đề nghị của KH vào hồi 
                            {data.length > 0 ? data : ' ...........'} ngày {data.length > 0 ? data : '........'} tháng {data.length > 0 ? data : '........'} năm {data.length > 0 ? data : '........'}
                        </p>
                        <br/>
                        <table border="0" cellSpacing="0" cellPadding="0">
                            <tbody>
                                <tr>
                                    <td width="294" valign="top">
                                        <p align="center">
                                            <strong>GIAO DỊCH VIÊN</strong>
                                        </p>
                                        <p align="center">
                                            <em>(ký , ghi rõ họ tên)</em>
                                        </p>
                                    </td>
                                    <td width="320" valign="top">
                                        <p align="center">
                                            <strong>ĐẠI DIỆN VIETINBANK</strong>
                                            <sup >
                                                <strong>1</strong>
                                            </sup>
                                        </p>
                                        <p align="center">
                                            <em>(ký, ghi rõ họ tên, đóng dấu)</em>
                                        </p>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <br/>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div>
                            <br/>
                            <hr align="left" size="1" width="33%" style = {{marginBottom: '7px'}}/>
                            <div id="ftn1">
                                <p>
                                <sup>1</sup>
                                    Đại diện VietinBank là lãnh đạo Chi nhánh/Phòng kế toán/Phòng giao
                                    dịch/Phòng dịch vụ khách hàng
                                </p>
                            </div>
                        </div>
                        <p align="center">
                            <strong>
                                <br />
                            </strong>
                        </p>
                        <p align="center">
                            <strong>
                                <br />
                            </strong>
                        </p>
                        <div className="row_btn_confirm">
                            <div className="btn_exit">
                                <ButtonConfirmPopup
                                    titleConfirm='HỦY BỎ'
                                    bg_color={this.props.bg_color_close ? this.props.bg_color_close : '#f31919'}
                                    onClick={() => { this.props.cancelAction() }}
                                />
                            </div>
                            <div className="btn_accept">
                                <ButtonConfirmPopup
                                    titleConfirm='XÁC NHẬN'
                                    bg_color={color.blue93}
                                    onClick={this.printForm}
                                />
                            </div>
                        </div>
                    </div>
                    

                </Bound>
            </div>
        )
    }
}

