import React, { Component } from 'react';
import styled from 'styled-components';

const BoundStyle = styled.div`
    display:flex;
    flex-direction:column;
    flex:1;
    font-family: 'SVN-Gilroy';
    margin-bottom: 13px;
    opacity: ${props => props.isDisabled?"0.5":"unset"};
    .title-text {        
        font-size: 11px;
        font-weight: bold;        
        color: #005993;
        text-transform: uppercase;
        margin-bottom: 5px;
    }
    input {
        padding: 10px;
        height: 24px;       
        font-size: 14px;
        font-weight: 500;
        font-family: 'SVN-Gilroy';
        line-height: 1.2;
        border-radius: 4px;
        border: solid 0.5px #c8c8c8;
        background-color: rgba(255,255,255,0.9);
        color: #222222;
        &::placeholder{
            color: #bababa;
        }      
    }    

`
export default class InputTextControl extends Component {
    constructor(props){
        super(props)
        this.state = {
            txtText: this.props.defaultValue ? this.props.defaultValue:''
        }
    }
    onChange = e => {
        let value = e.target.value
        // debugger;
        this.setState({
            txtText:value
        },()=>{this.props.getText(this.state.txtText)})
    }
    render() {
        // console.log(this.props)
        return (
            <BoundStyle isDisabled={this.props.isDisabled}>
                <p className='title-text'>{this.props.title}</p>
                <input  
                    type='text'
                    name='txtText' 
                    value={this.state.txtText}
                    onChange={this.onChange}
                    disabled={this.props.isDisabled}
                    placeholder={this.props.placeHolder}
                >                
                </input>
                
            </BoundStyle>
        )
    }
}
