export const ADMIN_ROLE = "ADMIN";
export const ADMIN_OFFICE_ROLE = "OFFADMIN";
export const TELLER = "TELLER";
export const OFFINSUPERVISOR = "OFFINSUPERVISOR";
export const OFFEXSUPERVISOR = "OFFEXSUPERVISOR";
export const INBIOCOLLECTOR = "INBIOCOLLECTOR";
export const RPW = "RPW";
export const IFW = "IFW";
export const IFW_SID = "SID";//SearchID
export const IFW_SC = "SC";//SearchCif
export const IFW_DUP = "DUP";//DupInfos
export const IFW_SBB = "SBB";//SearchByBio
