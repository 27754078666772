import React, { useState, useCallback, useEffect, useRef } from 'react'

// styles
import { Wrapper, Select, DropDown, Search } from './index.styles'

// images
import icon_down from '../../../../../images/ic_arrow_down_gray.svg'
import icon_search from '../../../../../images/ic_search_gray.png'
import check_blue from '../../../../../images/check_blue.svg'

// hooks
import useOutsideClick from '../../../../../helper/Hooks/useOutsideClick'


const DropDownItem = ({ children, isChecked, onClick }) => (
    <div className="item" title={children} onClick={onClick}>
        <div>{children}</div>
        {
            isChecked &&
            <img src={check_blue} alt="checked" />
        }
    </div>
)


const DropDownSearch = ({
    lstItemFixed,
    lstItems,
    onChange,
    defaultValue,
    placeHolder,
    extendStylesSelect,
    extendStylesDropdown
}) => {
    const ref = useRef(null)
    const [isShow, setIsShow] = useState(false)
    const [checkedData, setCheckedData] = useState(defaultValue || "")
    const [lstItemFlex, setLstItemFlex] = useState(lstItems);

    useEffect(() => {
        setCheckedData(defaultValue)
    }, [defaultValue])

    useEffect(() => {
        if (!isShow) {
            setLstItemFlex(lstItems)
        }
    }, [isShow])

    useEffect(() => {
        setLstItemFlex(lstItems)
    }, [lstItems])

    useOutsideClick(ref, () => {
        setIsShow(false)
    })

    useEffect(() => {
        onChange(checkedData)
    }, [checkedData])

    const _handleChecked = useCallback(
        (val) => {
            setIsShow(!isShow)
            setCheckedData(val)
        },
        [isShow],
    )

    const _handleSearch = useCallback(
        (e) => {
            const { value } = e.target
            if (!value.trim()) {
                setLstItemFlex(lstItems)
                return;
            }
            const lstItemFlex = lstItems.filter(item => item.match(value.trim()))
            setLstItemFlex(lstItemFlex)
        },
        [lstItems],
    )

    return (
        <Wrapper ref={ref}>
            <Select onClick={() => setIsShow(!isShow)} extendStylesSelect={extendStylesSelect}>
                <div title={checkedData}>{checkedData}</div>
                <img src={icon_down} alt="open-drop-down" />
            </Select>
            {
                isShow &&
                <DropDown extendStylesDropdown={extendStylesDropdown}>
                    <Search>
                        <img src={icon_search} alt="search" />
                        <input
                            autoFocus="autofocus"
                            type="text"
                            placeholder={placeHolder}
                            onChange={_handleSearch}
                        />
                    </Search>
                    <div className="lst-fixed-item">
                        {
                            lstItemFixed.map((item, key) =>
                                <DropDownItem
                                    key={key}
                                    onClick={() => _handleChecked(item)}
                                    isChecked={checkedData === item}
                                >
                                    {item}
                                </DropDownItem>
                            )
                        }
                    </div>
                    <div className="lst-scroll-item">
                        {
                            lstItemFlex.map((item, key) =>
                                <DropDownItem
                                    key={key}
                                    onClick={() => _handleChecked(item)}
                                    isChecked={checkedData === item}
                                >
                                    {item}
                                </DropDownItem>
                            )
                        }
                    </div>
                </DropDown>
            }
        </Wrapper>
    )
}

export default DropDownSearch
