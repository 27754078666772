import React from 'react';
import { BrowserRouter as Router } from "react-router-dom";

import MainContainer from './ui/MainContainer'
import PromtLeave from './helper/PromtLeave';

class App extends React.Component {

  render() {
    return (
      <Router getUserConfirmation={PromtLeave}>
        <MainContainer/>
      </Router>
    );
  }

}


export default App