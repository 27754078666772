import React from 'react'

// style
// import { Export } from './index.styles'
import { Button } from '../ButtonFilter/index.styles'

const ExportExcel = ({ onClick, isDisable = false }) => {
    return (
        <Button className="tools-item" onClick={onClick} isDisable={isDisable}>
            Xuất excel
        </Button>
    )
}

export default ExportExcel
