import React from 'react'
import Lottie from 'react-lottie'

// import animation
import loadingAnimation from "../../../../helper/loadingAnimation.json";

/**
 * 
 * @param {string="250px"} size size of icon loading(width === height === size)
 * @param {boolean=true} loadingPage true if loading fullscreen, else loading element
 */
export default function LoadingControl({size = "250px", loadingPage = true}) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  return (
    loadingPage?
      <div 
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 999999999
        }}
      >
        <div 
          style={{
            width: "100px", 
            height: "100px", 
            background:"rgba(124, 211, 247, 0.65)",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: "10px",
            textAlign: "center",
            fontFamily: "SVN-Gilroy",
            fontSize: "14px",
            fontWeight: 500,
            fontStyle: "normal",
            lineHeight: 1.25
          }}
        >
          <Lottie
            width={size}
            height={size}
            options={defaultOptions}
            isClickToPauseDisabled={true}
            style={{marginTop: "14px"}}
          />
          <div style={{marginTop: "8px"}}>Đang tải...</div>
        </div>
      </div>
      :
      <Lottie
        width={size}
        height={size}
        options={defaultOptions}
        isClickToPauseDisabled={true}
        style={{
          display: "inline-block",
          margin: 0
        }}
      />
  )
}
