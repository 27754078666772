import styled from 'styled-components';

import ic_question from '../../../../../images/Icons_Notification_Question.svg'

const WrapOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10;
    /* background: rgba(0, 0, 0, 0.1); */
`


const PopupConfirmResetWrapDiv = styled.div`
    padding: 20px 32px 15px 32px;
    background-color: #fafafa;
    width: 256px;
    height: auto;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-40%, -50%);
    font-family: 'SVN-Gilroy';
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    z-index: 999;
    box-shadow: 0px 0px 10px 0px #0000008a;
    border-radius: 10px;
    animation: fadeInConfirm 0.6s;
    @keyframes fadeInConfirm {
        0% {
            opacity: 0.7;
            top: 25%;
        }
        100% {
            opacity: 1;
            top: 40%;
        }
    }
    .row_question {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 53px;
        .img_question {
            background-image: url(${ic_question});
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            width: 53px;
            height: 53px;
            object-fit: contain;
        }
    }
    .row_title {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 20px 0 0 0;
        flex-direction: column;
        font-size: 16px;
        .title {
            line-height: 1.13;
            color: #222222;
            text-align: center;
            font-weight: bold;
        }
        .contentAlert {
            font-size: 14px;
            font-weight: 500;
            line-height: 1.2;
            color: #f31919;
            text-align: center;
            margin: 12px 0 0 0;
            text-align: center;
            span {
                display: block;
            }
        }
    }
    .row_btn_confirm {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 25px 0 0 0;
        .btn_exit {
            margin: 0 20px 0 0;
        }
    }
`

export { WrapOverlay, PopupConfirmResetWrapDiv }
