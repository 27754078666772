import styled, { css } from 'styled-components'

const WrapReport = styled.div``

const lstPxs = [115, 290, 150, 250, 400, 150, 150, 190, 190]
// const sumPx = lstPxs.reduce((total, curr) => total + curr)
// const gridArr = lstPxs.map(
//     (item) => `minmax(${item}px, ${Math.round((item / sumPx) * 100)}fr)`
// )

const gridArr = lstPxs.map(item => `${item}px`)

const extendStylesTable = css`
    .header,
    .row {
        grid-template-columns: ${gridArr.join(' ')};
    }
`

const extendStylesTeller = css`
    width: 158px;
`

export { WrapReport, extendStylesTable, extendStylesTeller }
