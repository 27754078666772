import React from 'react'
import moment from 'moment'

// styles
import { WrapCustomerInfoDupFinger } from './index.styles'

// helper
import getChannelName from '../../../../../../helper/getChannelName'

const MOCK_IMG = 'https://picsum.photos/200/300'

const CustTypes = {
    EXCUST: 'KH Thông thường',
    INCUST: 'Nội bộ',
}

const CustomerInfoDupFinger = ({
    dupInfos: {
        infos: {
            FullNameVi,
            CustomerNumber,
            Name,
            CustomerType,
            IdNumber,
            IdIssueDate,
            IdIssuePlace,
            MobileNumber,
            Address,
        } = {},
        bioInfos: {
            faceImg,
            fingerImgs = [],
            fingerName,
            faceChanelExecute,
            fingerChanelExecute
        } = {},
        TellerInfos,
        SupervisorInfos,
        RequestAt,
        acceptAt,
        BranchCode,
        OfficeName
    } = {},
    ...props
}) => {
    return (
        <WrapCustomerInfoDupFinger {...props}>
            <div className="field"><span>Số CIF:</span> {CustomerNumber}</div>
            <div className="row main-row">
                <div>
                    <div className="title-content">Khuôn mặt khách hàng</div>
                    <div className="wrap-face">
                        <img src={faceImg} alt="face" />
                    </div>
                    <div className="field chanel"><span>Kênh thu thập:</span> {getChannelName(faceChanelExecute)}</div>
                </div>
                <div>
                    <div className="title-content">Thông tin CIF</div>
                    <div className="wrap-cif-infos">
                        <div className="field single-field"><span>Họ tên KH:</span> {Name}</div>
                        <div className="field single-field"><span>Loại KH:</span> {CustTypes[CustomerType]}</div>
                        <div className="field single-field"><span>Số điện thoại:</span> {MobileNumber}</div>
                        <div className="field single-field"><span>GTTT:</span> {IdNumber}</div>
                        <div className="field single-field"><span>Ngày cấp:</span> {IdIssueDate}</div>
                        <div className="field single-field"><span>Nơi cấp:</span> {IdIssuePlace}</div>
                        <div className="field single-field"><span>Địa chỉ:</span> {Address}</div>
                    </div>
                </div>
            </div>
            <div className="row main-row">
                <div>
                    {
                        fingerImgs.length > 0 &&
                        <>
                            <div className="title-content">Vân tay bị trùng</div>
                            <div className="row row-finger">
                                {
                                    fingerImgs.map(item => (
                                        <div className="wrap-finger" key={item}>
                                            <img src={item} alt="finger" />
                                        </div>
                                    ))
                                }
                            </div>
                            <div className="finger-name">{fingerName}</div>
                            <div className="field chanel"><span>Kênh thu thập:</span> {getChannelName(fingerChanelExecute)}</div>
                        </>
                    }
                </div>
                <div>
                    <div className="title-content">Thông tin tác nghiệp STH vân tay</div>
                    <div className="wrap-cif-infos">
                        <div className="field single-field"><span>CN/PGD:</span> {BranchCode + ' - ' + OfficeName}</div>
                        <div className="field single-field"><span>GDV/ Điện toán:</span> {TellerInfos?.UserName}</div>
                        <div className="field single-field"><span>TG tác nghiệp:</span> {RequestAt ? moment(RequestAt).format('DD/MM/YYYY HH:mm:ss') : ''}</div>
                        <div className="field single-field"><span>KSV/BGĐ:</span> {SupervisorInfos?.UserName}</div>
                        <div className="field single-field"><span>TG phê duyệt:</span> {acceptAt ? moment(acceptAt).format('DD/MM/YYYY HH:mm:ss') : ''}</div>
                    </div>
                </div>
            </div>
        </WrapCustomerInfoDupFinger>
    )
}

export default CustomerInfoDupFinger
