import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import Axios from "axios";
import packageJson from '../package.json';

// import component
import App from './App';

// import file
import * as serviceWorker from './serviceWorker';
import configureStore from './configureStore';
import setAuth from './helper/setAuth';
import { setCurrentUser } from './actions/userAction';
// import * as userRole from "./data/userRole"
import { isMatchDataLoginFormatResponse } from './helper/checkFormatResponeData';

// import style
import './index.css';

console.log("version:", packageJson.version);


// import './grid_bootstrap.css';

const store = configureStore()
const TIME_OUT_MILISECOND = 30000;
Axios.defaults.timeout = TIME_OUT_MILISECOND;

// check login
let dataLogin = JSON.parse(localStorage.getItem("dataLogin"));
if (dataLogin /*&& isMatchDataLoginFormatResponse(dataLogin, dataLogin.Role)*/) {
  // console.log("login");
  // Axios.defaults.withCredentials = true;
  setAuth(dataLogin)
  store.dispatch(setCurrentUser(dataLogin))
}
else{
  // debugger;
  localStorage.removeItem("dataLogin")
  // Axios.defaults.withCredentials = false
  setAuth(false)
}


ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
