import * as types from "../../actions/types";

const initialState = {
  Total: 0,
  listVerifyCustomerHistory: [],
  lastConditionFilterVerifyCustomer: {},
  textSearch: '',
  codes: ''
}

export default function verifyCustomerReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_VERIFY_FINGER_HISTORY:
      return {
        ...state,
        Total: action.Total,
        listVerifyCustomerHistory: action.listVerifyHistory,
        lastConditionFilterVerifyCustomer: action.lastConditionFilterHistory,
      }
    case types.GET_VERIFY_FINGER_HISTORY_ERR:
      return {
        ...state,
        err: ''
      }
      case types.UPDATE_VERIFY_FINGER:
        return {
          ...state,
          codes: action.Code
        }
      case types.UPDATE_VERIFY_FINGER_ERR:
        return {
          ...state,
          err: ''
        }
      case types.SAVE_TEXT_VERIFY_CUSTOMER:
        return{
          ...state,
          textSearch: action.textSearch
        }
      case types.CLEAR_TEXT_VERIFY_CUSTOMER:
        return {
          ...state,
          textSearch: ''
        }
      case types.SOCKET_GET_VERIFY_CUSTOMER: {
        if(action.data !== null)
          return {
            ...state,
            listVerifyCustomerHistory: [action.data, ...state.listVerifyCustomerHistory],
            // lastConditionFilterVerifyCustomer: state.lastConditionFilterVerifyCustomer,
            Total: state.Total + 1
          }
          return state
      }
  	default:
  		return state
  }
}