import Axios from "axios";

const setAuth = (isAuth) => {
  if (isAuth) {
    // console.log("authe");
    Axios.defaults.withCredentials = process.env.NODE_ENV === 'production' ? false : true;
    Axios.defaults.headers.post["CSRF-TOKEN"] = isAuth.CsrfToken;
    Axios.defaults.headers.common.office = isAuth.OfficeID || "";
    Axios.defaults.timeout = 120 * 1000;
    return;
  }
  Axios.defaults.withCredentials = false;
  delete Axios.defaults.headers.common["CSRF-TOKEN"];
}

export default setAuth;