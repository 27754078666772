import React, { Component } from 'react'
import styled from 'styled-components';
import { connect } from 'react-redux'
// Images
import ic_close from '../../../images/ic_close_gray.svg'
// Component
import InputTextControl from '../MainScreen/Control/InputTextControl';
import SingleChoiseDropDownControl from '../MainScreen/Control/SingleChoiseDropDownControl';
import ButtonConfirmPopup from '../MainScreen/Control/ButtonConfirmPopup';
// Data
import { color } from '../../../data/store'
import { location } from '../../../data/singleChoiseData';
import { types } from '../../../data/dataBranch';
import { 
  getArrayCity, 
  getDistrictArrayByCityCode, 
  getWardArrayByDistrictCode, 
  getNameCity, 
  getNameWard, 
  getNameDistrict 
} from '../../../tools'

/* import action */
import {getAllOffice} from '../../../actions/officeAction';

const Bound = styled.div`
    padding: 16px 14px 24px 14px;
    width: 500px;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fafafa;
    .popupHeader{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .title{
          font-size:20pt;
        }
        .ic_close{
            background-image: url(${ic_close});
            background-position:center center;
            background-repeat: no-repeat;
            background-size:cover;
            width:26px;
            height: 26px;
            cursor: pointer;
        }
    }
    .popupMainBody{
        padding: 20px 5px 0 5px;
        .type-transaction {
          margin: 0 0 40px 0;
          /* width: 280px; */
          display: flex;
            .type-officeName{
              width: 70%;
              padding-right: 4.084%;
            }
            .code-officeName{
              width: 30%;
            }
            .titleTypeTrans{
                font-family: 'SVN-Gilroy';
                font-size: 11px;
                font-weight: bold;
                line-height: 1.26;
                color: #005993;
                text-transform: uppercase;
            }
            select.dropdown-control{
                width: 280px;
                min-width: 280px;
                background-position: top 20px right 17px;
            }
        }
        .info_Address{
            display: flex;
            justify-content: space-around;
            align-items: center;
            position: relative;
            img{
                position:absolute;
                /* right:0; */
            }
            .city{
                width: 32%;
                margin-right: 4%;
            }
            .district{
                width: 28%;
            }
            .ward{
                width: 32%;
                margin-left: 4%;
            }
        }
        .btn_confirm{
            margin: 24px 0 0 0;
            float:right;
        }
    }
`

const MAX_CHARACTERS = 200;
class PopupTransaction extends Component {

  state = {
    data: location,
    dataCity: getArrayCity(),
    dataDistrict: this.props.dataNeedModify ?
      getDistrictArrayByCityCode(this.props.dataNeedModify.Province)
      :
      [],
    dataWard: this.props.dataNeedModify ?
      getWardArrayByDistrictCode(this.props.dataNeedModify.District)
      :
      [],
    ///////test///////////
    NameTransactionRoom: this.props.dataNeedModify ?
      this.props.dataNeedModify.Name
      :
      "",
    TypeBranchCode: this.props.dataNeedModify ? 
      this.props.dataNeedModify.BranchCode
      :
      "",
    AddressTransactionRoom: this.props.dataNeedModify ?
      this.props.dataNeedModify.Address
      :
      "",
    typeTrans: this.props.dataNeedModify ?
      types.find(item => item.type === this.props.dataNeedModify.OfficeType).name
      :
      "",
    city: this.props.dataNeedModify ?
      getNameCity(this.props.dataNeedModify.Province)[0]
      :
      "",
    district: this.props.dataNeedModify ?
      getNameDistrict(this.props.dataNeedModify.District)[0]
      :
      "",
    ward: this.props.dataNeedModify ?
      getNameWard(this.props.dataNeedModify.Ward)[0]
      :
      "",
    idShow: "",
    resetDistWard: false,
    resetWard: false,
    isCompleteShowErr: false
  }
  getBranchName(e) {
    this.setState({
      NameTransactionRoom: e.trim()
    })
  }

  getBranchCode(e) {
    this.setState({
      TypeBranchCode: e.trim()
    })
  }
  getAddress(e) {
    this.setState({
      AddressTransactionRoom: e.trim()
    })
  }

  getText = (type) => async (text) => {
    if (type === 'city')
      this.setState({
        // district: '',
        // ward: '',
        // dataWard: [],
        // resetDistWard: true,
        // resetWard: false,
        dataDistrict: getDistrictArrayByCityCode(text.code),
      })
    else if (type === 'district')
      this.setState({
        // resetWard: true,
        // resetDistWard: false,
        // ward: '',
        dataWard: getWardArrayByDistrictCode(text.code),
      })
    // else if (type === 'ward')
    //   this.setState({
    //     resetWard: false,
    //     resetDistWard: false,
    //   })
    // else if (type === 'ward')
    // this.setState({
    //   resetData: 0
    // });
    this.setState({ [type]: text })
  }

  setIdShow = (id) => {
    this.setState({ idShow: id })
  }
  async componentDidMount() {
    this.props.getAllOffice();
    // if (this.props.dataNeedModify) {
    //   const { dataNeedModify } = this.props;
    //   if (dataNeedModify.Province && dataNeedModify.District && dataNeedModify.Ward) {
    //     await this.setState({
    //       // dataCity: getArrayCity(),
    //       dataDistrict: getDistrictArrayByCityCode(dataNeedModify.Province),
    //       dataWard: getWardArrayByDistrictCode(dataNeedModify.District),
    //     });
    //   }
    //   return;
    // }

    // this.setState({
    //   // dataCity: getArrayCity()
    // })
    // let nameCity = ""
    // let nameDistrict = ""
    // let nameWard = ""
    // if (dataNeedModify) {
    //   nameCity = getNameCity(dataNeedModify.Province)[0]
    //   nameDistrict = getNameDistrict(dataNeedModify.District)[0]
    //   nameWard = getNameWard(dataNeedModify.Ward)[0]
    // }
    // this.setState({
    //   city: nameCity,
    //   district: nameDistrict,
    //   ward: nameWard
    // })
  }

  onSubmit = () => {
    let { NameTransactionRoom, TypeBranchCode, AddressTransactionRoom, typeTrans} = this.state;
    // debugger;
    const {listOffice} = this.props;
    let typeTransInData = "";
    // if (typeTrans === 'Phòng giao dịch') {
    //   typeTrans = 'TRADING'
    // } else if (typeTrans === 'Chi nhánh') {
    //   typeTrans = 'BRANCH'
    // } else {
    //   typeTrans = 'SAVING'
    // }

    if(typeTrans)
      types.forEach(item => {
        if(item.name === typeTrans){
          typeTransInData = item.type;
        }
      })
    // Check match ID BranchCode
    if(this.props.dataNeedModify){
      const {ID} = this.props.dataNeedModify;
      if((TypeBranchCode) && 
        listOffice.find(item => 
          (
            item.ID !== ID &&
            item.BranchCode.trim().replace(/\s+/g, " ") === 
              TypeBranchCode.trim().replace(/\s+/g, " ")) 
            // && typeTransInData && typeTransInData === item.OfficeType
          )
      ){
        if(!this.state.isCompleteShowErr || this.props.createOfficeReducer.Code === 5){
          this.props.alertPopup('Mã chi nhánh/PGD đã tồn tại.');
          // this.setState({isCompleteShowErr: true})
          return
        }
      }
    }else{
      if(
          (TypeBranchCode) && 
          listOffice.find(item => 
            item.BranchCode.trim().replace(/\s+/g, " ") === 
              TypeBranchCode.trim().replace(/\s+/g, " ")
            // && typeTransInData && typeTransInData === item.OfficeType
          )
        ){
        if(!this.state.isCompleteShowErr || this.props.createOfficeReducer.Code === 5){
          this.props.alertPopup('Mã chi nhánh/PGD đã tồn tại.');
          // this.setState({isCompleteShowErr: true})
          return;
        }
      }
    }

    // Check match nameTransactionRoom(Name PGD)
    if(this.props.dataNeedModify){
      const {ID} = this.props.dataNeedModify;
      if((NameTransactionRoom) && 
        // NameTransactionRoom !== Name && 
        listOffice.find(item => 
          (
            item.ID !== ID &&
            item.Name.trim().replace(/\s+/g, " ") === 
              NameTransactionRoom.trim().replace(/\s+/g, " ")) 
            // && typeTransInData && typeTransInData === item.OfficeType
          )
      ){
        if(!this.state.isCompleteShowErr){
          this.props.alertPopup('Chi nhánh/PGD đã tồn tại.');
          // this.setState({isCompleteShowErr: true})
          return
        }
      }
    }else{
      if(
          (NameTransactionRoom) && 
          listOffice.find(item => 
            item.Name.trim().replace(/\s+/g, " ") === 
              NameTransactionRoom.trim().replace(/\s+/g, " ")
            // && typeTransInData && typeTransInData === item.OfficeType
          )
        ){
        if(!this.state.isCompleteShowErr){
          this.props.alertPopup('Chi nhánh/PGD đã tồn tại.');
          // this.setState({isCompleteShowErr: true})
          return
        }
      }
    }

    ////// Update
    if (this.props.dataNeedModify) {
      // const { resetDistWard, resetWard } = this.state;
      // console.log('state', this.state, '  dataNeed ', this.props.dataNeedModify);
      let data = this.props.dataNeedModify;
      // console.log('data', data);
      let id = data.ID;
      let currentPage = data.currentPage

      // this.setState({

      //   NameTransactionRoom: this.state.NameTransactionRoom && this.state.NameTransactionRoom,

      //   AddressTransactionRoom: this.state.AddressTransactionRoom && this.state.AddressTransactionRoom,

      //   typeTrans: this.state.typeTrans ? this.state.typeTrans : data.OfficeType,
      // }, () => {
        let { NameTransactionRoom, TypeBranchCode, AddressTransactionRoom, city, district, ward, typeTrans } = this.state;
        // console.log('dataUpdate', NameTransactionRoom, AddressTransactionRoom, city, district, ward, typeTrans);
        // debugger
        if (NameTransactionRoom.length > MAX_CHARACTERS || AddressTransactionRoom.length > MAX_CHARACTERS) {
          this.props.alertPopup('Vui lòng nhập dưới 200 kí tự')
          return;
        }
        // debugger;
        if (NameTransactionRoom.trim() && TypeBranchCode.trim() && AddressTransactionRoom.trim() && city && district && ward && typeTrans) {
          // 
          // debugger;
          let data = {}
          
          if((TypeBranchCode) && 
          // NameTransactionRoom !== Name && 
          listOffice.find(item => 
            (
              item.ID !== id &&
              item.BranchCode.trim().replace(/\s+/g, " ") === 
                TypeBranchCode.trim().replace(/\s+/g, " ")) 
            )
          ){
            data = {
               id,
               NameTransactionRoom,
              //  TypeBranchCode,
               AddressTransactionRoom,
               city: city.code,
               district: district.code,
               ward: ward.code,
               typeTrans
             }
             if(!this.state.isCompleteShowErr){
              this.props.alertPopup('Mã chi nhánh/PGD đã tồn tại.');
              // this.setState({isCompleteShowErr: true})
              return
             }
          }else{
            data = {
              id,
              NameTransactionRoom,
              TypeBranchCode,
              AddressTransactionRoom,
              city: city.code,
              district: district.code,
              ward: ward.code,
              typeTrans
            }
          }

          // if (data.typeTrans === 'Phòng giao dịch') {
          //   data.typeTrans = 'TRADING'
          // } else if (typeTrans === 'Chi nhánh') {
          //   data.typeTrans = 'BRANCH'
          // } else {
          //   data.typeTrans = 'SAVING'
          // }
          types.forEach(item => {
            if(item.name === typeTrans){
              data.typeTrans = item.type;
            }
          })
          this.props.handleOnClick(data, currentPage);
          this.props.outPopup();
        } else {
          this.props.alertPopup('Vui lòng điền đầy đủ thông tin')
        }
      // });
    } else {
      /////// Add new
      let { NameTransactionRoom, TypeBranchCode, AddressTransactionRoom, city, district, ward, typeTrans } = this.state;

      let cityChoice = this.state.city.code
      let districtChoice = this.state.district.code
      let wardChoice = this.state.ward.code
      // console.log(this.state);

      if (NameTransactionRoom.length > MAX_CHARACTERS || AddressTransactionRoom.length > MAX_CHARACTERS) {
        this.props.alertPopup('Vui lòng nhập dưới 200 kí tự')
        return;
      }

      if (NameTransactionRoom.trim() && TypeBranchCode.trim() && AddressTransactionRoom.trim() && city && district && ward && typeTrans) {
        // debugger;
        let typeTransItem = ""
        // if (typeTrans === 'Phòng giao dịch') {
        //   typeTrans = 'TRADING'
        // } else if (typeTrans === 'Chi nhánh') {
        //   typeTrans = 'BRANCH'
        // } else {
        //   typeTrans = 'SAVING'
        // }
        
        types.forEach(item => {
          if(item.name === typeTrans){
            typeTransItem = item.type;
          }
        })

        if((TypeBranchCode) && 
          // NameTransactionRoom !== Name && 
          listOffice.find(item => 
            (
              item.BranchCode.trim().replace(/\s+/g, " ") === 
                TypeBranchCode.trim().replace(/\s+/g, " ")) 
            )
          ){
             if(!this.state.isCompleteShowErr){
              this.props.alertPopup('Mã chi nhánh/PGD đã tồn tại.');
              // this.setState({isCompleteShowErr: true})
              return
             }
          }
          
          // console.log(NameTransactionRoom, AddressTransactionRoom, cityChoice, districtChoice, wardChoice, typeTrans);
          this.props.handleOnClick(NameTransactionRoom, AddressTransactionRoom, cityChoice, TypeBranchCode, districtChoice, wardChoice, typeTransItem)
          this.props.outPopup();
          let BranchSuccess = "BranchSuccess"
          
        this.props.showPopupInBiometric({}, BranchSuccess)
      } else {
        this.props.alertPopup('Vui lòng điền đầy đủ thông tin')
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.city && this.state.city !== prevState.city) {
      if (prevState.city && prevState.city.code === this.state.city.code) return;
      // debugger;
      this.setState({
        dataDistrict: getDistrictArrayByCityCode(this.state.city.code),
        district: "",
        ward: ""
      })
      return;
    }
    if (this.state.district && this.state.district !== prevState.district) {
      // debugger;
      if (prevState.district && prevState.district.code === this.state.district.code) return;
      this.setState({
        dataWard: getWardArrayByDistrictCode(this.state.district.code),
        ward: ""
      })
      return;
    }
  }

  render() {

    // console.log('dataNeedModify',this.props.dataNeedModify)
    const officeTrans = this.getText("typeTrans");
    const cityTrans = this.getText("city");
    const districtTrans = this.getText("district");
    const wardTrans = this.getText("ward");
    const { dataNeedModify } = this.props;
    let checkType;
    if (dataNeedModify) {
      checkType = types.find(item => item.type === dataNeedModify.OfficeType);
    }
    // debugger;

    return (
      <Bound>
        <div className="popupHeader">
          <h4 className='title'>{this.props.titleHeader}</h4>
          <span onClick={() => this.props.outPopup()} className='ic_close'></span>
        </div>
        <div className="popupMainBody">
          <div className='type-transaction'>
            <div className = 'type-officeName'>
              <p className='titleTypeTrans'>loại VP giao dịch</p>
              <SingleChoiseDropDownControl
                id="selectTypeTrans"
                idShow={this.state.idShow}
                setIdShow={this.setIdShow}
                titleChoise='Chọn loại VP'
                getText={officeTrans}
                data={this.state.data.office}
                defaultValue={dataNeedModify ? checkType.name : ''}
              />
            </div>
            <div className="code-officeName">
              <InputTextControl title='MÃ CN/PGD'
                getText={value => this.getBranchCode(value)}
                placeHolder='Nhập mã CN/PGD'
                defaultValue={dataNeedModify ? dataNeedModify.BranchCode : ''}
              />
            </div>
          </div>
          <InputTextControl title='TÊN CN/PGD'
            getText={value => this.getBranchName(value)}
            placeHolder='Nhập tên phòng giao dịch'
            defaultValue={dataNeedModify ? dataNeedModify.Name : ''}
          />
          <InputTextControl title='ĐỊA CHỈ'
            getText={value => this.getAddress(value)}
            placeHolder='Nhập địa chỉ'
            defaultValue={dataNeedModify ? dataNeedModify.Address : ''}
          />
          <div className="info_Address">
            <div className="city">
              <SingleChoiseDropDownControl
                id="selectCity"
                idShow={this.state.idShow}
                setIdShow={this.setIdShow}
                getText={cityTrans}
                titleChoise='Chọn thành phố'
                data={this.state.dataCity}
                title='TỈNH/TP'
                defaultValue={this.state.city ? this.state.city.name : ""}
              />
            </div>
            <div className="district">
              <SingleChoiseDropDownControl
                id="selectDistrict"
                idShow={this.state.idShow}
                setIdShow={this.setIdShow}
                getText={districtTrans}
                titleChoise='Chọn quận/ huyện'
                data={this.state.city ? this.state.dataDistrict : null}
                title='QUẬN/ HUYỆN'
                defaultValue={this.state.district ? this.state.district.name : ""}
              //resetDistWard={this.state.resetDistWard}
              />
            </div>
            <div className="ward">
              <SingleChoiseDropDownControl
                id="selectWard"
                idShow={this.state.idShow}
                setIdShow={this.setIdShow}
                getText={wardTrans}
                titleChoise='Chọn phường/xã'
                data={this.state.district ? this.state.dataWard : null}
                title='PHƯỜNG/ XÃ'
                defaultValue={this.state.ward ? this.state.ward.name : ''}
              //resetWard={this.state.resetWard}
              //resetDistWard={this.state.resetDistWard}
              />
            </div>
          </div>
          <div className="btn_confirm">
            <ButtonConfirmPopup bg_color={color.blue93}
              onClick={() => this.onSubmit()}
              titleConfirm='HOÀN TẤT' />
          </div>
        </div>
      </Bound>
    )
  }
}

const mapStateToProps = state => ({
  listOffice: state.filterOfficeReducer.listOffice,
  updateOfficeReducer: state.updateOfficeReducer,
  createOfficeReducer: state.createOfficeReducer
})
const mapDispatchToProps = dispatch => ({
  getAllOffice: () => dispatch(getAllOffice())
})
export default connect(mapStateToProps, mapDispatchToProps)(PopupTransaction)
