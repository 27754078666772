import { combineReducers } from "redux";

import duplicateBioReducer from './duplicateBioReducer'

const queryReducer = combineReducers({
    duplicateBioReducer
})

export default queryReducer

