import styled from 'styled-components'

const TitleCol = styled.div`
    font-family: SVN-Gilroy;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 126%;
    color: #005993;
    text-transform: uppercase;
`;

export {
    TitleCol
}