import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { WrapBiometricExecution } from './index.styles'
import TableBio from './TableBio'
import ic_search_gray from '../../../../images/ic_search_gray.png'
import { getListCustomer } from '../../../../actions/biometricExecution'


const BiometricExecution = () => {
    const dispatch = useDispatch()
    
    const [inputSearch, setInputSearch] = useState('');
    
    const {data, error} = useSelector(state => state.biometricExecutionReducer)

    const _handleSearch = () => {
        dispatch(getListCustomer(inputSearch))
    }

    return (
        <WrapBiometricExecution>
            <div className='search-bio'>
                <div className="wrap-search">
                    <div className="wrapper-input">
                        <img src={ic_search_gray} alt="search" />
                        <input
                            onKeyPress={(e) => {
                                if (e.charCode !== 13) return true
                                _handleSearch()
                                return false
                            }}
                            value={inputSearch}
                            onChange={(e) => setInputSearch(e.target.value)}
                            placeholder="Nhập số CIF"
                            type="text"
                            autoFocus="autofocus"
                        />
                    </div>
                    <button
                        className={`btn-search${!inputSearch ? ' disabled' : ''}`}
                        onClick={_handleSearch}
                        disabled={!inputSearch}
                    >Tìm kiếm</button>
                </div>
                <div className="error">{error}</div>
            </div>
            {
                data?.Cifs?.length > 0 &&
                <div className="wrap-table">
                    <TableBio data={data}/>
                </div>
            }
        </WrapBiometricExecution>
    )
}
export default BiometricExecution