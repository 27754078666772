import styled, { css } from 'styled-components'

const lstPxs = [115, 290, 150, 250, 400, 275, 150]
// const sumPx = lstPxs.reduce((total, curr) => total + curr)
// const gridArr = lstPxs.map(
//     (item) => `minmax(${item}px, ${Math.round((item / sumPx) * 100)}fr)`
// )
const gridArr = lstPxs.map(item => `${item}px`)

const extendStylesTable = css`
    .header,
    .row {
        grid-template-columns: ${gridArr.join(' ')};
    }
`

const WrapTools = styled.div`
    margin: 0 25px;
    position: relative;
    z-index: 2;
`

const RowTools = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;

    & > .tools-item {
        margin-left: 12px;
        flex-shrink: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        & > .label-tools-item {
            font-family: SVN-Gilroy;
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 128%;
            color: #005993;
            margin-right: 10px;
        }
    }

    &:last-child {
        margin-top: 10px;
    }

    .btn-visible {
        visibility: hidden;
    }
`

const extendStylesTeller = css`
    width: 220px;
`

export { extendStylesTable, WrapTools, RowTools, extendStylesTeller }
