import React from 'react'

// styles
import { WrapperEmpty } from './index.styles'

const EmptyReportSelected = () => {
    return (
        <WrapperEmpty>
            <div className="title">Chưa có dữ liệu báo cáo</div>
            <div className="details">Hệ thống sẽ tự động cập nhật khi có dữ liệu</div>
        </WrapperEmpty>
    )
}

export default EmptyReportSelected
