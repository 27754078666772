import styled from 'styled-components'

export const Bound = styled.div`
    width: 320px;
    height: 228px;
    background: #fafafa;
    position: absolute;
    top: 120px;
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 27px 27px 27px;
    box-sizing: border-box;
    font-family: SVN-Gilroy;
    font-style: normal;

    .content {
        font-weight: 500;
        font-size: 16px;
        color: #222222;
        line-height: 20px;
        margin: 20px 0 0 0;
    }
    .name {
        font-weight: bold;
        font-size: 16px;
        color: #222222;
        line-height: 20px;
    }

    .block_btn {
        display: flex;
        margin: 35px 0 0 0;

        .close {
            padding: 8px 12px;
            margin: 0 5px;
            width: 89px;
            height: 32px;
            background: #d71249;
            border-radius: 18px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ffffff;
            font-size: 12.5px;
            text-transform: uppercase;
            font-weight: bold;
            cursor: pointer;
        }

        .accept {
            padding: 8px 12px;
            margin: 0 5px;
            width: 89px;
            height: 32px;
            background: #005993;
            border-radius: 18px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ffffff;
            font-size: 12.5px;
            text-transform: uppercase;
            font-weight: bold;
            cursor: pointer;
        }
    }

    animation: showPopupConfirmEform 0.6s;
    @keyframes showPopupConfirmEform {
        0% {
            opacity: 0.7;
            top: 0;
        }
        100% {
            opacity: 1;
            top: 120px;
        }
    }
`
