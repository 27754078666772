import React, { Component } from "react";
import styled from "styled-components";
import { connect } from 'react-redux';

//Data

//Images
import ic_cancel_white from "../../../../images/ic_close_history.svg";
import { BioInfosType, ACCEPT, REGISTER, REJECT, Status, UPDATE, NR, NR_FINGER } from "../../../../data/infosType";
// import { getBioHistoryInfo } from "../../../../actions/supervisorAction/biometricAction";
import { formatDateTimeToSpecificFormat, formatDateTime } from "../../../../helper/formatTime";
import PopupShowBiometricHistory from "./PopupShowBiometricHistory";
import PagingControl from '../../MainScreen/Control/PagningControl';
import EmptyControl from '../../MainScreen/Control/EmptyControl';

const Bound = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 650px;
  min-height: auto;
  /* height: 380px; */
  font-family: 'SVN-Gilroy';
  z-index: 999;
  box-shadow: 0px 10px 15px 0px #0000007a;
  .popup-header {
    cursor: move;
    box-sizing: border-box;
    background-color: #005993;
    padding: 10.1px 10.2px 7.8px 20px;
    border-radius: 10px 10px 0 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 2.3px solid #efefef;
    p {
      font-size: 16px;
      font-weight: bold;
      line-height: 1.25;
      color: #ffffff;
    }
    img {
      cursor: pointer;
    }
  }
  .popup-activity {
    background-color: #fafafa;
    max-height: 395px;
    overflow: auto;
    .pagning{
      float: right;
      position: fixed;
      bottom: 0;
      right: 0;
      background-color: #fafafa;
       /* width: 100%; */
      justify-content: flex-end;
    }
    ::-webkit-scrollbar {
      width: 3px;
      visibility: hidden;
    }
    ::-webkit-scrollbar-thumb {
      background: #c4c4c4;
      border-radius: 2px;
    }
    .detail-activity {
      cursor: pointer;
      padding: 13px 23px 8px 20px;
      box-shadow: inset 0 -0.5px 0 0 rgba(0, 0, 0, 0.15);
      background-color: #ffffff;
      margin-bottom: 4px;
      display: flex;
      .activity {
        font-size: 14px;
        font-weight: 500;
        flex: 1;
        .time {
          font-size: 11px;
          line-height: 1.3;
          color: #979797;
          padding-top: 8px;
        }
        span:nth-child(1) {
          color: #005993;
        }
        .result {
          font-size: 11px;
          margin-top: 4px;
          span:nth-child(1) {
            color: #f31919;
          }
          span:nth-child(2) {
            color: #0fb11f;
          }
        }
        p {
          font-size: 11px;
          line-height: 1.3;
          color: #979797;
          margin-top: 4px;
        }
      }
      .main-activity {
        font-weight: 500;
        .readMore {
          display: flex;
          justify-content: flex-end;
          cursor: pointer;
          span {
            font-size: 14px;
            line-height: 1.25;
            color: #005993;
            padding-left: 4px;
            text-decoration:underline;
          }
        }
        
      }
    }
  }
`;

const limitPerPage = 6
var _ = require('lodash');

class PopupHistoryCustomer extends Component {
  state = {
    currentPage: 1,
    startIndex: 0,
    endIndex: 6,
    isShowPopupLv2: false,
    data: {},
    slideIndex: 1,
    ina: 0
  }

  actionReadMore = (dataInform, historyID) => {
    // if(historyID !== '' && historyID !== '000000000000000000000000'){
    //   this.props.getBioHistoryInfo(historyID)
    // }
    const { data } = this.state
    data.data = dataInform
    this.setState({
      isShowPopupLv2: true,
    })
  }


  onNextHistory = () => {
    let { data } = this.state.data
    let { ina } = this.state

    let { lstBioHistoryList } = this.props.biometricReducer
    let index = _.findIndex(lstBioHistoryList, item => item.ID === data.ID)
    index += 1
    data.data = lstBioHistoryList[index]
    this.setState({
      data
    })
  }

  onPreviousHistory = () => {
    let { data } = this.state.data
    let { lstBioHistoryList } = this.props.biometricReducer
    const indexHistory = _.findIndex(lstBioHistoryList, item => item.ID === data.ID)
    data.data = lstBioHistoryList[indexHistory - 1]
    this.setState({
      data
    })
  }

  onClosePopupLv2() {
    this.setState({
      isShowPopupLv2: false
    })
  }

  paginationPage() {
    let endIndex = this.state.currentPage * limitPerPage
    let startIndex = endIndex - limitPerPage
    this.setState({
      startIndex, endIndex
    })
  }

  onPageChange = page => {
    let currentPage = page;
    let endIndex = currentPage * limitPerPage;
    let startIndex = endIndex - limitPerPage;
    this.setState({ currentPage, startIndex, endIndex });
  }

  render() {
    const { lstBioHistoryList, lstBioHistoryInfo } = this.props.biometricReducer
    let elmType
    let { startIndex, endIndex } = this.state
    let dataSlice = lstBioHistoryList.slice(startIndex, endIndex)
    // console.log(dataSlice.length, lstBioHistoryList.length)
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 999
        }}
      >
        <Bound ref={ref => this.popupHistoryCustomer = ref} >
          <div className="popup-header">
            <p className="popup-header-title">Lịch sử Đăng ký/ Cập nhật dữ liệu Sinh trắc học</p>
            <img
              src={ic_cancel_white}
              alt={ic_cancel_white}
              onClick={this.props.onClosePopup}
            />
          </div>
          <div className="popup-activity" style={{ height: lstBioHistoryList.length === 0 && '300px' }}>

            {
              lstBioHistoryList.length === 0 &&
              <EmptyControl text_title='Chưa có dữ liệu lịch sử sinh trắc học' text='Hệ thống sẽ tự động cập nhật khi có dữ liệu' />
            }
            {
              dataSlice.map((item, i) => {

                if (item.BioInfosType === REGISTER) {
                  if (item.BioStatus === ACCEPT) {
                    elmType = BioInfosType[REGISTER].nameLabel
                  } else {
                    elmType = Status[REJECT].nameLabel
                  }
                } else if (item.BioInfosType === UPDATE) {
                  if (item.BioStatus === ACCEPT) {
                    elmType = BioInfosType[UPDATE].nameLabel
                  } else {
                    elmType = Status[REJECT].nameLabel
                  }
                } else if (item.BioInfosType === NR) {
                  if (item.BioStatus === ACCEPT) {
                    elmType = BioInfosType[NR].nameLabel
                  } else {
                    elmType = Status[REJECT].nameLabel
                  }
                } else if (item.BioInfosType === NR_FINGER) {
                  if (item.BioStatus === ACCEPT) {
                    elmType = BioInfosType[NR].nameLabel
                  } else {
                    elmType = Status[REJECT].nameLabel
                  }
                }
                return (
                  <div
                    className="detail-activity"
                    key={i}
                    onClick={() => this.actionReadMore(item, item.HistoryID)}
                  >
                    <div className="activity">
                      <span>
                        {item.CustomerInfos.Name} ({item.OfficeName})
                          </span>
                      <span
                        style={(() => {
                          if (
                            item.typeAction === 'register' ||
                            item.typeAction === 'update'
                          ) {
                            return { color: '#222222' }
                          } else if (item.typeAction === 'remove') {
                            return { color: '#f31919' }
                          }
                        })()}
                      >
                        {' '}
                        {elmType + ' dữ liệu sinh trắc học.'}
                      </span>
                      {/* <p>{item.SupervisorInfos.Name} ({item.OfficeName }) đã duyệt</p> */}
                      <div className="time">
                        {formatDateTimeToSpecificFormat(
                          item.AcceptAt,
                          formatDateTime.HHmmDDMMYY
                        )}
                      </div>
                    </div>
                    <div className="main-activity">
                      <div
                        className="readMore"
                        onClick={() =>
                          this.actionReadMore(item, item.HistoryID)
                        }
                      >
                        <span>Xem thêm</span>
                      </div>
                    </div>
                  </div>
                )
              })
            }
            {
              lstBioHistoryList.length > limitPerPage &&
              <PagingControl
                currentPage={this.state.currentPage}
                totalItem={lstBioHistoryList.length}
                limitPerPage={limitPerPage}
                onChangePage={page => this.onPageChange(page)}
              />
            }
          </div>
        </Bound>
        {
          this.state.isShowPopupLv2 &&
          <PopupShowBiometricHistory
            data={lstBioHistoryInfo}
            lstBioHistoryList={lstBioHistoryList}
            dataInformCustom={this.state.data.data && this.state.data.data}
            onClosePopup={this.onClosePopupLv2.bind(this)}
            onNextHistory={this.onNextHistory}
            onPreviousHistory={this.onPreviousHistory}
            dataSlice={dataSlice}
          />
        }
      </div>
    );
  }
  componentDidMount() {
    // console.dir(this.popupShowBiometricHistory)
    this.dragElement(this.popupHistoryCustomer, ".popup-header")
  }

  dragElement = (elmnt, headerClass) => {
    // console.log('header', headerClass)
    var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
    const header = elmnt.querySelector(headerClass)
    if (header) {
      /* if present, the header is where you move the DIV from:*/
      header.onmousedown = dragMouseDown;
    } else {
      /* otherwise, move the DIV from anywhere inside the DIV:*/
      elmnt.onmousedown = dragMouseDown;
    }

    function dragMouseDown(e) {
      e = e || window.event;
      e.preventDefault();
      // get the mouse cursor position at startup:
      pos3 = e.clientX;
      pos4 = e.clientY;
      document.onmouseup = closeDragElement;
      // call a function whenever the cursor moves:
      document.onmousemove = elementDrag;
    }
    const that = this;
    function elementDrag(e) {
      e = e || window.event;
      e.preventDefault();
      // calculate the new cursor position:
      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;
      pos3 = e.clientX;
      pos4 = e.clientY;
      // set the element's new position:
      const tmpTop = (elmnt.offsetTop - pos2);
      const tmpLeft = (elmnt.offsetLeft - pos1);
      const { scrollHeight, scrollWidth, clientHeight, clientWidth } = that.popupHistoryCustomer;
      const { innerHeight, innerWidth } = window;

      if (tmpTop < scrollHeight / 2 && pos2 > 0) {
        elmnt.style.top = scrollHeight / 2 + "px";
      }
      else if (tmpTop > scrollHeight / 2 + innerHeight - clientHeight) {
        elmnt.style.top = scrollHeight / 2 + innerHeight - clientHeight + "px";
      }
      else {
        elmnt.style.top = tmpTop + "px";
      }

      if (tmpLeft < scrollWidth / 2 && pos1 > 0) {
        elmnt.style.left = scrollWidth / 2 + "px";
      }
      else if (tmpLeft > scrollWidth / 2 + innerWidth - clientWidth) {
        elmnt.style.left = scrollWidth / 2 + innerWidth - clientWidth + "px";
      }
      else {
        elmnt.style.left = tmpLeft + "px";
      }
    }

    function closeDragElement() {
      /* stop moving when mouse button is released:*/
      document.onmouseup = null;
      document.onmousemove = null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    biometricReducer: state.biometricReducer
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    // getBioHistoryInfo: (historyID) => dispatch(getBioHistoryInfo(historyID))
  }
}
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(PopupHistoryCustomer);
