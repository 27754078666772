import styled from 'styled-components'

const WrapList = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;

export {
    WrapList
}