import styled from 'styled-components'

const WrapQueryByBioImage = styled.div`
    min-width: 440px;
    min-height: 290px;
    background: #FAFAFA;
    border-radius: 10px;
    font-family: 'SVN-Gilroy';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .title {
        height: 60px;
        display: flex;
        align-items: center;
        background: #FFFFFF;
        padding: 18px 20px 20px;
        box-sizing: border-box;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 22px;
        color: #222222;
        border-radius: 10px 10px 0 0;

        :hover {
            cursor: move;
        }
    }

    .ic-close {
        position: absolute;
        top: 8px;
        right: 8px;

        &:hover {
            cursor: pointer;
        }
    }

    .content {
        display: flex;
        justify-content: space-between;
        margin-top: 18px;
        padding: 0 20px;
    }

    .error {
        margin: 10px 0 15px;
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        line-height: 128%;
        color: #F31919;
        padding: 0 20px;
        min-height: 14px;
    }
`;

export {
    WrapQueryByBioImage
}