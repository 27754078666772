import Axios from 'axios'

import * as types from './types'
import { addLoading, removeLoading } from './commonAction'
import catchErrorApi from '../helper/catchErrorApi'
import { apiGetCif } from './queryAction/queryByCif'
import { host } from '../host'

const apiDeleteBiometricOfCustomer = host + 'supervisor/delete-bio'

export const getListCustomer = (CifsNum) => async dispatch => {
    dispatch(addLoading())
    const dataRequest = JSON.stringify({
        CifsNum,
        Type: 0
    })

    try {
        const res = await Axios.post(apiGetCif, dataRequest)
        const { data } = res;
        const isSuccess = data.Code === 0 && !!data.Cifs
        if (isSuccess) {
            
            dispatch({
                type: types.GET_LIST_CUSTOMER,
                payload: {data, CifsNum}
            })
        }
        else {
            dispatch({
                type: types.GET_LIST_CUSTOMER_ERR,
                payload: {
                    error: 'Không tìm thấy dữ liệu khách hàng'
                }
            })
        }
    }
    catch (err) {
        catchErrorApi(
            err,
            dispatch,
            types.GET_LIST_CUSTOMER_ERR,
            () => null
        )
    }
    dispatch(removeLoading())
}

export const deleteBiometricOfCustomer = (CustomerNumber, callbackSuccess) => async dispatch => {
    dispatch(addLoading())
    const dataRequest = JSON.stringify({
        CustomerNumber
    })

    try {
        const res = await Axios.post(apiDeleteBiometricOfCustomer, dataRequest)
        const { data } = res;
        const isSuccess = data.Code === 0
        if (isSuccess) {
            dispatch({
                type: types.DELETE_BIOMETRIC_OF_CUSTOMER,
                payload: data
            })
            callbackSuccess && callbackSuccess()
        }
    }
    catch (err) {
        catchErrorApi(
            err,
            dispatch,
            types.DELETE_BIOMETRIC_OF_CUSTOMER_ERR,
            () => null
        )
    }
    dispatch(removeLoading())
}