import styled from 'styled-components'

const Input = styled.input`
    outline: none;
    border: none;
    box-sizing: border-box;
    height: 36px;
    width: 220px;
    border-radius: 4px;
    background: #fafafa;
    padding: 9px 7px;
    text-overflow: ellipsis;
    font-family: SVN-Gilroy;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 128%;
    color: #222222;
`

export { Input }
