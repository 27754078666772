import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'

import icon from './../../../../images/ic_search_gray.png'
// import { debounce } from '../../../../helper/debounce'
import { clearTextSearchDataBiometric } from '../../../../actions/supervisorAction/biometricAction'
import { clearTextVerifyCustomer } from '../../../../actions/supervisorAction/verifyCustomerAction'
import { clearTextSearchOffice } from '../../../../actions/officeAction'
import { clearTextSearch } from '../../../../actions/userAction'
import { clearTextSearchAds } from '../../../../actions/adsAction'

const Bound = styled.div`    
    display:flex;
    align-items: center;
    form{
        position: relative;
        padding: 0 10px;
        .btn-search{
            position: absolute;
            right: 12px;
            border: none;
            top: 3px;
            width: 30px;
            background: transparent;
            cursor: pointer;
            img{width:100%}
            outline:none;
        }
        input{
            width: 320px;
            height: 36px;
            border-radius: 4px;
            background-color: #ffffff;
            border: none;
            padding: 0 35px 0 10px;
            outline:none;
            font-family:'SVN-Gilroy';
            font-size: 14px;
            font-weight: 500;
            line-height: 1.68;
            color: #222222;
            &::placeholder{
                opacity:.25;
            }
        }
    }
`

class SearchControl extends Component {
    state = {
        // txtName:''
        txtText: '',
    }
    componentDidMount() {
        // this.handleSearchDebounced = debounce(function () {
            // do stuff here
            // this.props.searchFilter(this.state.txtText)
        // }, 1500);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.textSearch !== this.props.textSearch) {
            if (this.props.textSearch === "") {
                this.setState({
                    txtText: ''
                })
            }
        }
    }


    onChange = async e => {
        // let name = e.target.name
        let value = e.target.value
        await this.setState({
            // [name]: value
            txtText: value
        })
        //////////// Debounc search
        // await this.handleSearchDebounced();
    }


    onSubmit = async event => {
        event.preventDefault();
        // this.props.searchFilter(this.state.txtName);
        await this.props.searchFilter(this.state.txtText);
        if(this.props.typeSearch === 'searchBiometric'){
            this.props.clearTextSearchDataBiometric()
        }else if(this.props.typeSearch === 'searchVerifyCustomer'){
            this.props.clearTextSearchVerifyCustomer()
        }else if(this.props.typeSearch === 'searchBranch'){
            this.props.clearTextSearchOffice()
        }else if(this.props.typeSearch === 'searchPermission'){
            this.props.clearTextSearch()
        }else if(this.props.typeSearch === 'searchAds'){
            this.props.clearTextSearchAds()
        }
    }

    render() {
        return (
            <Bound>
                <form onSubmit={this.onSubmit}>
                    <input type="text"
                        name="txtText"
                        value={this.state.txtText}
                        onChange={this.onChange}
                        placeholder={this.props.inputname} />
                    <button type="submit" className="btn-search">
                        <img src={icon} alt="ic_search" />
                    </button>
                </form>
            </Bound>
        )
    }
}

const mapStateToProps = state => ({
    userReducer: state.userReducer,
    biometricReducer: state.biometricReducer,
    adsReducer: state.getAdsReducer
})
const mapDispatchToProps = dispatch => ({
    clearTextSearchOffice: () => dispatch(clearTextSearchOffice()),
    clearTextSearchAds: () => dispatch(clearTextSearchAds()),
    clearTextSearch: () => dispatch(clearTextSearch()),
    clearTextSearchDataBiometric: () => dispatch(clearTextSearchDataBiometric()),
    clearTextSearchVerifyCustomer: () => dispatch(clearTextVerifyCustomer())
})

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(SearchControl)