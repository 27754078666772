import styled, { css } from 'styled-components' 

const extendStylesSelect = css`
    background: #FAFAFA;
    border: none;
    height: 36px;
    padding: 9px 7px;
`;

const extendStylesDropdown = css`
    background: #FAFAFA;
    border: none;
    margin-top: 3px;
`;

const DropDownTellerStyles = styled.div`
    ${props => props.extendStyles}
`;

export {
    extendStylesSelect,
    extendStylesDropdown,
    DropDownTellerStyles
}