import React, { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'

// styles
import { extendStylesDropdownTrans } from './index.styles'

// action
import { getListTypeCounterServices } from '../../../../../../../../actions/counterAction'

// component
import DropDown from '../DropDown'

const itemAllTrans = {
    key: undefined,
    value: 'Tất cả giao dịch',
}

// const lstTrans = [
//     {
//         key: 'DEPINT',
//         value: 'Nộp tiền trong VietinBank'
//     },
//     {
//         key: 'WITHDRAW',
//         value: 'Rút tiền'
//     },
//     {
//         key: 'SAVINGDEP',
//         value: 'Gửi tiết kiệm'
//     },
//     {
//         key: 'DEPEXT',
//         value: 'Nộp tiền ngoài VietinBank'
//     },
//     {
//         key: 'TRANSINT',
//         value: 'Chuyển khoản trong VietinBank'
//     },
//     {
//         key: 'TRANSEXT',
//         value: 'Chuyển khoản ngoài VietinBank thông thường'
//     },
//     {
//         key: 'TRANSEXTCARD',
//         value: 'Chuyển khoản nhanh ngoài VietinBank đến số thẻ (≤300trđ)'
//     },
//     {
//         key: 'TRANSEXTACC',
//         value: 'Chuyển khoản nhanh ngoài VietinBank đến số tài khoản (≤300trđ)'
//     },
//     {
//         key: 'FCY',
//         value: 'Ngoại tệ'
//     },
//     {
//         key: 'CARD',
//         value: 'Thẻ'
//     },
//     {
//         key: 'OTHER',
//         value: 'Khác'
//     },
//     {
//         key: 'CORPCUST',
//         value: 'Khách hàng doanh nghiệp'
//     },
//     {
//         key: 'WDSVG',
//         value: 'Rút tiết kiệm'
//     },
//     {
//         key: 'DIRECTCALL',
//         value: 'Trống (Vé load CIF)'
//     },
// ]

const SelectTrans = ({
    defaultValue,
    onChange,
    isNotSelectAll,
    lstTrans = [],
}) => {
    // const dispatch = useDispatch()

    // const { counterReducer, userReducer } = useSelector((state) => ({
    //     counterReducer: state.counterReducer,
    //     userReducer: state.userReducer,
    // }))

    const [lstServices, setLstServices] = useState([])
    const [servicesSelected, setServicesSelected] = useState(
        isNotSelectAll ? defaultValue || null : defaultValue || itemAllTrans
    )

    const _handleOnChange = useCallback((obj) => {
        setServicesSelected(obj)
        onChange(obj)
    }, [])

    useEffect(() => {
        if (isNotSelectAll) {
            setLstServices(lstTrans)
            if (!servicesSelected) {
                setServicesSelected(lstTrans[0])
            }
            return
        }
        setLstServices([itemAllTrans, ...lstTrans])
    }, [lstTrans])

    // useEffect(() => {
    //     dispatch(getListTypeCounterServices(userReducer.dataLogin.UID))
    // }, [userReducer.dataLogin])

    return (
        <DropDown
            lstItemFixed={[]}
            lstItems={lstServices}
            defaultValue={servicesSelected}
            onChange={_handleOnChange}
            haveSearch={false}
            extendStyles={extendStylesDropdownTrans}
            maxNumShowDropdown={9}
            label="Loại GD"
        />
    )
}

export default SelectTrans
