import React, { Component } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {connect} from 'react-redux';

// image
import ic_close from '../../../../images/ic_close_gray.svg'

// component
// import {TabLayout} from '../PopupCheckBiometric/PopupCheckBiometricCustomer'
import ButtonConfirmPopup from '../../MainScreen/Control/ButtonConfirmPopup';
import UserInformation from '../PopupCheckBiometric/UserInformation';
import UserServiceDetail from '../PopupCheckBiometric/UserServiceDetail';

// data
import { SEEN, VerifyCustomer, PENDING, VERIFYUNSUCCESSFULLY, VERIFYSUCCESSFULLY, NOTVERIFY } from '../../../../data/dataVerifyCustomer';
import { Status, ACCEPT, NR } from '../../../../data/infosType';

// action
import { updateVerifyFingerDataStatus, getListVerifyFingerHistory } from '../../../../actions/supervisorAction/verifyCustomerAction';
import { getNumOfDataNotViewYet } from '../../../../actions/commonAction';

const Bound = styled.div`
  /*width: 741px;*/
  width: 700px;
  height: fit-content;
  background-color: #fafafa;
  position: absolute;
  top: 50%;
  left:50%;
  transform:translate(-50%,-50%);
  font-family: 'SVN-Gilroy';
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  border-radius: 10px;
  .header {
    padding: 16px 10px 0 20px;
    width: calc(100% - 30px);
    height: 68px;
    background-color: #ffffff;
    border-radius: 10px 10px 0 0;
    border-bottom: 3px solid #e3e3e3c4;
    .block_header_title {
      display: flex;
      justify-content:space-between;
      .title {
        font-size: 24px;
        font-weight: bold;
        line-height: 1.08;
        color: #000000;
      }
      img {
        cursor: pointer;
      }
    }
  }
`
export const TabLayout = styled.div`
  display: flex;
  justify-content:space-between;
  margin: 10px 0 0 0;
  .title_content {
    display: flex;
    .tab_title_single {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.25;
      color: #005993;
      margin: 0 0 7px 20px;
    }
    .tab_title {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.25;
      color: #c8c8c8;
      margin: 0 13px 0 0;
      cursor: pointer;
      padding: 0px 8px 15px 8px;
    }
  }
  .title_info {
    display: flex;
    .cif {
      display: flex;
      font-size: 16px;
      font-weight: 500;
      color: #005993;
      margin: 0 25px 0 0;
      .text_cif{
        color: #222222;
      }
    }
    .status {
      display: flex;
      font-size: 16px;
      font-weight: 500;
      color: #005993;
      .text_status {
        color:#979797;
        margin: 0 15px 0 0;
      }
    }
  }
`
const Header = styled.div`
  width: 100%;
  height: 84px;
  background-color: #ffffff;
  border-radius: 10px 10px 0 0;
`
const Footer = styled.div`
    width: 100%;
    height: 80px;
    background-color: #fafafa;
    border-radius: 0 0 10px 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  .block_btn {
    margin: 0 20px 0 0;
  }
`
const digit = '000000000000000000000000';
class PopupCheckCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      changeTab: 1,
      dataUser: this.props.dataUser,
      dataBiometric: this.props.dataBiometric && this.props.dataBiometric
    }
  }
  
  verifySuccessCallback = () => {
    const { lastConditionFilterVerifyCustomer } = this.props.verifyCustomerReducer
    this.props.getListVerifyFingerHistory({
      ...lastConditionFilterVerifyCustomer,
    })
    this.props.getNumOfDataNotViewYet()
    this.props.outPopup()
    this.props.alertPopup('Dữ liệu khách hàng đã được xem')
  }
  
  onUpdateVerifyFingerDataStatus = () =>{
    const {dataUser} = this.state;
    if(!!dataUser.ID && dataUser.ID !== digit){
      this.props.updateVerifyFingerDataStatus(dataUser.ID, this.verifySuccessCallback)
      // let m = moment().utcOffset(0);
      // m.set({hour:0,minute:0,second:0,millisecond:0})
      // m.toISOString()
    }
  }

  changeTab = (changeTab) => {
    this.setState({
      changeTab
    })
  }

  render() {
    let { dataUser, changeTab } = this.state;
    let elmDataStatus,elmVerify, clsStatus

    let content_tab_layout = null;
    if (changeTab === 1) {
      content_tab_layout = <UserInformation
        typeInternal='ksv'
        dataUser = {dataUser}
      />
    } else if (changeTab === 2) {
      content_tab_layout = <UserServiceDetail 
        dataUser = {dataUser}
      />
    }

    if (dataUser.DataStatus === SEEN) {
      elmDataStatus = VerifyCustomer[SEEN].label
      clsStatus= Status[ACCEPT].colorA
    }else {
      elmDataStatus = VerifyCustomer[PENDING].label
      clsStatus= Status[ACCEPT].colorP
    }

    if (dataUser.VerifyStatus === VERIFYUNSUCCESSFULLY) {
      elmVerify = VerifyCustomer[VERIFYUNSUCCESSFULLY].value
      /* coliVerify = VerifyCustomer[VERIFYUNSUCCESSFULLY].color */
    } else if(dataUser.VerifyStatus === VERIFYSUCCESSFULLY) {
      elmVerify = VerifyCustomer[VERIFYSUCCESSFULLY].value
      /* coliVerify = VerifyCustomer[VERIFYSUCCESSFULLY].color */
    }else{
      elmVerify = VerifyCustomer[NOTVERIFY].value
    }
    return (
      <Bound>
        <Header>
        <div className="header">
          <div className="block_header_title">
            <div className="title">
              Thông tin dữ liệu
            </div>
            <img
              onClick={this.props.outPopup}
              src={ic_close} alt="ic_close" />
          </div>
          <TabLayout>
            <div className="title_content">
                {
                dataUser ?
                  <React.Fragment>
                    <p
                      onClick={() => this.changeTab(1)}
                      style={{ borderBottom: changeTab === 1 && '2.5px solid #005993', color: changeTab === 1 && '#222222' }}
                      className='tab_title'>Thông tin dữ liệu</p>
                    {
                      dataUser.VerifyStatus !== VERIFYSUCCESSFULLY && dataUser.ServiceDetails &&
                      <p
                        onClick={() => this.changeTab(2)}
                        style={{ borderBottom: changeTab === 2 && '2.5px solid #005993', color: changeTab === 2 && '#222222' }}
                        className='tab_title'>Thông tin giao dịch</p>
                    }
                  </React.Fragment>
                  :
                  <p className='tab_title_single'>Thông tin dữ liệu</p>
              }
            </div>
              <div className="title_info">
                <div className="cif">
                  CIF: <p className='text_cif'> &nbsp; {dataUser?.CustomerInfos.CustomerNumber}</p>
                </div>
                {
                  elmVerify === VerifyCustomer[VERIFYUNSUCCESSFULLY].value || elmVerify === VerifyCustomer[NOTVERIFY].value  ? 
                    <div className="status">
                      Trạng thái: <p className='text_status' style = {{color: clsStatus}}> &nbsp;{elmDataStatus}</p>
                    </div>
                  : null
                }
              </div>
          </TabLayout>
        </div>
        </Header>
        <div className="main_container">
          {content_tab_layout}
        </div>
        <Footer>
            <div className="block_btn">
            {
              (dataUser.VerifyStatus !== VERIFYSUCCESSFULLY) &&
                <ButtonConfirmPopup
                  width='80px'
                  bg_color={elmDataStatus === VerifyCustomer[SEEN].label && 'rgba(0, 89, 147, 0.1)'} //'#005993' 
                  titleConfirm='Đã xem'
                  onClick = {elmDataStatus === VerifyCustomer[SEEN].label ?  null : this.onUpdateVerifyFingerDataStatus}
                  // onClick = {this.onUpdateVerifyFingerDataStatus}
                />
            }
            </div>
        </Footer>
      </Bound>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    verifyCustomerReducer: state.verifyCustomerReducer
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateVerifyFingerDataStatus: (id, successCallback) => { dispatch(updateVerifyFingerDataStatus(id, successCallback))},
    getListVerifyFingerHistory: (dataQuery) => dispatch(getListVerifyFingerHistory(dataQuery)),
    getNumOfDataNotViewYet: () => dispatch(getNumOfDataNotViewYet())
  }
}
export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: null})(PopupCheckCustomer)